import { columns, csvColumns, importColumns } from "../data/asset-owner-fields"

export const recordsReducer = (state, action) => {
  switch (action.type) {
    case "setRecords":
      return {
        ...state,
        list: action.payload.map(item => {
          return {
            ...item.attributes,
            id: item.pk,
            pk: item.pk,
            sk: item.sk,
            version: item.version,
            isSelected: false,
          }
        }),
      }

    case "setCSVRecords": {
      try {
        let dataToBeImported = []

        action.payload.map(csvData => {
          if (
            csvData.Email !== undefined &&
            csvData.Email !== "" &&
            csvData.Name !== undefined &&
            csvData.Name !== ""
          ) {
            // console.log("csvData  ", csvData)
            let importdata = {}
            importColumns.map(column => {
              const fieldValue = csvData[column]
              const dataField = csvColumns[column]
              importdata[dataField] = fieldValue
            })
            action.dataToBeImported.push(importdata)
          }
        })
        const totaldata = [...state.list, ...action.dataToBeImported]
        return {
          ...state,
          list: totaldata.map(item => {
            return { ...item, id: item.id, pk: item.pk, isSelected: false }
          }),
        }
      } catch (e) {
        // console.log(e)
      }
    }
    case "Add": {
      const list = [...state.list]
      const record = action.payload.data.attributes
      record.pk = action.payload.data.pk
      record.id = action.payload.data.pk
      record.sk = action.payload.data.sk
      const recordExists = list.find(r => r.id === record.id)
      if (recordExists) {
        const index = list.indexOf(recordExists)
        list.splice(index, 1, record)
        return { ...state, list: list }
      }
      list.push({ ...record })
      return { ...state, list: list }
    }
    case "Edit": {
      const list = [...state.list]
      const record = action.payload.data
      const recordExists = list.find(r => r.id === record.id)
      if (recordExists) {
        const index = list.indexOf(recordExists)
        list.splice(index, 1, record)
        return { ...state, list: list }
      }
      list.push({ ...record })
      return { ...state, list: list }
    }
    case "Delete": {
      const records = [...state.list]
      const dataToBeDeleted = [...state.list]
      dataToBeDeleted.map(data => {
        if (data.isSelected) {
          const index = records.indexOf(data)
          records.splice(index, 1)
        }
      })
      return { ...state, list: records }
    }

    default:
      return state
  }
}

export const dataReducer = (state, action) => {
  switch (action.type) {
    case "setDataToModalonChange":
      return {
        ...state,
        [action.data.id]: action.data.value,
      }

    case "setRecords":
      const data = action.data
      return { ...state, ...data }

    default:
      return state
  }
}

export const multiplerecordsreducer = (state, action) => {
  const totaldata = [...state.list, ...action]
  return {
    ...state,
    list: totaldata.map(item => {
      return { ...item, id: item.id }
    }),
  }
}
