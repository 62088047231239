import { columns, csvColumns, importColumns } from '../data/asset-fields';
import { convertDateTimeString } from "../../../services/commonService"

export const recordsReducer = (state, action) => {
  switch (action.type) {
    case 'setRecords':
      return {
        ...state,
        list: action.payload.map((item) => {
          return {
            ...item.attributes,
            id: item.sk,
            pk: item.pk,
            version: item.version,
            isSelected: false,
          };
        }),
      };

    case 'setCSVRecords': {
      try {
        let dataToBeImported = [];
        action.payload.map((csvData) => {
          let importdata = {};
          importColumns.map((column) => {
            const fieldValue = csvData[column];
            const dataField = csvColumns[column];
            importdata[dataField] = fieldValue;
          });
          action.dataToBeImported.push(importdata);
        });
        const totaldata = [...state.list, ...action.dataToBeImported];
        return {
          ...state,
          list: totaldata.map((item) => {
            return { ...item, id: item.id, pk: item.pk, isSelected: false };
          }),
        };
      } catch (e) {
        // console.log(e);
      }
    }

    case 'Edit':
    case 'Add': {
      const list = [...state.list];
      let record = action.payload.data.attributes;
      if (action.payload.page === 'Edit') {
        record = action.payload.data;
      } else {
        record.pk = action.payload.data.pk
        record.id = action.payload.data.sk
      }
      const recordExists = list.find((r) => r.id === record.id);
      if (recordExists) {
        const index = list.indexOf(recordExists);
        list.splice(index, 1, record);
        return { ...state, list: list };
      }
      list.push({ ...record });
      return { ...state, list: list };
    }
    case 'Delete': {

      // const records = [...state.list];
      // const dataToBeDeleted = action.payload;
      // // dataToBeDeleted.map((data) => {
      // //   if (data.isSelected) {
      // //     const index = records.indexOf(data);
      // //     records.splice(index, 1);
      // //   }
      // // });
      // const index = records.indexOf(dataToBeDeleted);
      // records.splice(index, 1);
      // return { ...state, list: records };

      const records = [...state.list]
      const dataToBeDeleted = [...state.list]
      dataToBeDeleted.map(data => {
        if (data.isSelected) {
          const index = records.indexOf(data)
          records.splice(index, 1)
        }
      })
      return { ...state, list: records }
    }

    default:
      return state;
  }
};

export const dataReducer = (state, action) => {
  switch (action.type) {
    case 'setDataToModalonChange':
      return {
        ...state,
        [action.data.id]: action.data.value,
      };

    case 'setRecords':
      const data = action.data;
      return { ...state, ...data };
    // return {
    //   ...state,
    //   list: action.data.map((item) => {
    //     return { ...item, id: item.id, isSelected: false };
    //   }),
    // };

    default:
      return state;
  }
};

export const multiplerecordsreducer = (state, action) => {
  const totaldata = [...state.list, ...action];
  return {
    ...state,
    list: totaldata.map((item) => {
      return { ...item, id: item.id };
    }),
  };
};

export const liveUpdatesReducer = (state, action) => {

  return {
    ...state,
    list: action.payload.map((item) => {
      return {
        ...item,
        // finishedDate : (convertDateTimeString(new Date(item.finishedDate))),
        finishedDate: (convertDateTimeString(item.finishedDate)),
        deliveryDate: (convertDateTimeString(item.deliveryDate)),
      };
    }),
  };


};


export const customFieldReducer = (state, action) => {
  switch (action.type) {
    case 'setDataOnFieldChange':
      return {
        ...state,
        [action.data.id]: action.data.value,
      };

    case 'setRecords':
      const list = [state];
      const data = action.data;
      state.push({ data });
      return { state };
    default:
      return state;
  }
};

export const customFieldsMultipleRecordsReducer = (state, action) => {
  const totaldata = [...state.list, ...action];
  return {
    ...state,
    list: totaldata.map((item) => {
      return { ...item, id: item.id };
    }),
  };
};

export const customDropDownFieldReducer = (state, action) => {
  switch (action.type) {
    case 'setDataOnFieldChange':
      return {
        ...state,
        [action.data.id]: action.data.value,
      };

      case 'setNewCustomFields':
        const customFields = [...state.list];
        let newlyAddedField = action.data.value
        return { newlyAddedField };

    case 'setCustomFields':
      const customList = [...state.list];
      const mode = action.data.mode;
      let newField = action.data.value
      if (mode === "Add") {

        customList.push({ ...newField });
        return { ...state, list: customList };
      } else if (mode === "Edit") {
        const recordExists = customList.find((r) => r.id === newField.id);
        if (recordExists) {
          const index = customList.indexOf(recordExists);
          customList.splice(index, 1, newField);
          return { ...state, list: customList };
        }
        // const index = customList.indexOf(newField);
        // customList.splice(index, 1, newField);
        // return {...state, list: customList};
      } else if (mode === "Delete") {
        const recordExists = customList.find((r) => r.id === newField.id);
        if (recordExists) {
          const index = customList.indexOf(recordExists);
          customList.splice(index, 1);
          return { ...state, list: customList };
        }
      } else if(mode=== "setInitialData"){
        newField.isSelected = false
        customList.push({ ...newField });
        return { ...state, list: customList };
      }
      customList.push({ ...newField });
      return { ...state, list: customList };

    case 'setRecords':
      const list = [state];
      const data = action.data;
      // state.push({data});
      return { ...state, ...data };

    case 'setSelectedFields':
      const row = action.data.rowValue;
      // let newField = action.data.value;
      // customList.push({...newField});
      return { ...state, ...row };
    default:
      return state;
  }
};


export const emailAlertReducer = (state, action) => {
  switch (action.type) {
    case 'setDataOnFieldChange':
      return {
        ...state,
        [action.data.id]: action.data.value,
      };

      case 'setNewCustomFields':
        const customFields = [...state.list];
        let newlyAddedField = action.data.value
        return { newlyAddedField };

    case 'setCustomFields':
      const customList = [...state.list];
      const mode = action.data.mode;
      let newField = action.data.value
      if (mode === "Add") {

        customList.push({ ...newField });
        return { ...state, list: customList };
      } else if (mode === "Edit") {
        const recordExists = customList.find((r) => r.dataField === newField.dataField);
        if (recordExists) {
          const index = customList.indexOf(recordExists);
          customList.splice(index, 1, newField);
          return { ...state, list: customList };
        }
        // const index = customList.indexOf(newField);
        // customList.splice(index, 1, newField);
        // return {...state, list: customList};
      } else if (mode === "Delete") {
        const recordExists = customList.find((r) => r.dataField === newField.dataField);
        if (recordExists) {
          const index = customList.indexOf(recordExists);
          customList.splice(index, 1);
          return { ...state, list: customList };
        }
      } else if(mode=== "setInitialData"){
        newField.isSelected = false
        customList.push({ ...newField });
        return { ...state, list: customList };
      }
      customList.push({ ...newField });
      return { ...state, list: customList };

    case 'setRecords':
      const list = [state];
      const data = action.data;
      // state.push({data});
      return { ...state, ...data };

    case 'setSelectedFields':
      const row = action.data.rowValue;
      // let newField = action.data.value;
      // customList.push({...newField});
      return { ...state, ...row };
    default:
      return state;
  }
};