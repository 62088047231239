import React, { useReducer, useEffect, useState } from "react"
import { Row, Col } from "reactstrap"
import { useAuth0 } from "@auth0/auth0-react"
import axios from "axios"
import { useToasts } from "react-toast-notifications"
import _ from "lodash"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Spinner from "../../components/Common/Spinner"
//reducers
import { multiplerecordsreducer } from "./reducer/reducers"
import { metquayAssetsreducer } from "./reducer/assetReducer"

//Import columns
import { columns,  assetColumnsForAssetOwnerLogin,} from "./data/asset-fields"

//Import table
import { TableForManagedAssets } from "./Components/TableForManagedAssets"

//Service
import { getAssetOwner } from "../../services/assetownerService"
import { getAssetsFromMetquay, getFilteredAssetsFromMetquay } from "../../services/dashboard-services"
import { decryptKeyValues } from "../../services/commonService"

import { user_metadata } from "../../nameSpaceConfig"

const MetquayAssets = props => {
  const apiurl = process.env.REACT_APP_AUTH0_API_URL
  const { getAccessTokenSilently, getAccessTokenWithPopup, user } = useAuth0()
  const userMetadata = user[user_metadata]
  const [assetCount, setAssetCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [records, dispatchRecords] = useReducer(metquayAssetsreducer, {
    list: [],
  })
  const [multipleRecords, dispatchMultipleRecords] = useReducer(
    multiplerecordsreducer,
    { list: [] }
  )
  const [selectRow, setSelectRow] = useState([])
  const [first , setFirst] = useState(0)
  const [last , setLast] = useState(50)
  const [totalAssets , setTotalAssets] = useState(0)
  const[currentPage , setCurrentPage] = useState(0)
  const [totalResults , setTotalResults] = useState(50)
  const [prevPage , setPrevPage] = useState(0)
  const [filterCondition, setFilterCondition] = useState("")
  const [searchKey, setSearchKey] = useState("")
  const [count, setCount] = useState({})
  const [goodToggle, setGoodToggle] = useState(false)
  const [warningToggle, setWarningToggle] = useState(false)
  const [dangerToggle, setDangerToggle] = useState(false)
  const [metquayAssetOwnerKey, setMetquayAssetOwnerKey] = useState(" ")
  const [metquayUrl, setMetquayUrl] = useState(" ")
  const [metquaySecretKey, setMetquaySecretKey] = useState(" ")
  const [assetOwnerData , setAssetOwnerData] = useState({})
  /**
   * getAccessToken method is used to fecth jwt access token from auth0
   * @param {string} audience - the api URL
   * @param {string} claim - the permission
   */
  const getAccessToken = async (audience, claim) => {
    let token
    try {
      token = await getAccessTokenSilently({
        audience,
        claim,
      })
    } catch (exception) {
      token = await getAccessTokenWithPopup({ audience, claim })
    }
    return token
  }

const getNextAssetsData = async ()=>{
  const jwtToken = await getAccessToken(apiurl , 'add:asset')
  await getAssetsFromMetquay(jwtToken , metquayUrl , metquaySecretKey , metquayAssetOwnerKey , first , )
  .then(result =>{
    setFirst(first + 50)
    setPrevPage(first - 50)
    setCurrentPage(currentPage + 1)
    dispatchRecords({ type: "setRecords", payload: result.data.resultData.items })
  })
}
const getPrevAssetsData = async ()=>{
  const jwtToken = await getAccessToken(apiurl , 'add:asset')
  await getAssetsFromMetquay(jwtToken , metquayUrl , metquaySecretKey , metquayAssetOwnerKey , prevPage  )
  .then(result =>{
    setFirst(prevPage + 50)
    if(prevPage === 0){
      setPrevPage(prevPage)
    }else{
      setPrevPage(prevPage - 50)
    }
    setCurrentPage(currentPage - 1)
    dispatchRecords({ type: "setRecords", payload: result.data.resultData.items })
  })
}
  const getFilterCount = async (metquayUrl,metquaySecretKey,metquayAssetOwnerKey) =>{
    let count = {}
    const jwtToken = await getAccessToken(apiurl, "add:asset")
    // count['ALLGOODASSETSCOUNT'] =await fetchFilterRecords(metquayUrl,metquaySecretKey,metquayAssetOwnerKey,'dueAfter90Days')
    // count['ALLWARNINGASSETSCOUNT'] = await fetchFilterRecords(metquayUrl,metquaySecretKey,metquayAssetOwnerKey,'dueIn90Days')
    // count['ALLDANGERASSETSCOUNT'] = await fetchFilterRecords(metquayUrl,metquaySecretKey,metquayAssetOwnerKey,'expired')
    await getFilteredAssetsFromMetquay(jwtToken, metquayUrl,metquaySecretKey,metquayAssetOwnerKey, 0, searchKey,'dueAfter90Days' )
        .then(result => {
          count['ALLGOODASSETSCOUNT'] =result.data.resultData.total
        })
        .catch(error => {
          // console.log(error)
        })
        await getFilteredAssetsFromMetquay(jwtToken, metquayUrl,metquaySecretKey,metquayAssetOwnerKey, 0, searchKey,'dueIn90Days' )
        .then(result => {
          count['ALLWARNINGASSETSCOUNT'] = result.data.resultData.total
        })
        .catch(error => {
          // console.log(error)
        })
        await getFilteredAssetsFromMetquay(jwtToken, metquayUrl,metquaySecretKey,metquayAssetOwnerKey, 0, searchKey,'expired' )
        .then(result => {
          count['ALLDANGERASSETSCOUNT'] =result.data.resultData.total 
        })
        .catch(error => {
          // console.log(error)
        })
        setCount(count)
  }

  /**
   * onFilter method is used to filter asset data based on selected condition
   * @param {object} e
   * @param {string} condition
   */
  const onFilter = async (
    e,
    condition,
    isGoodSelected,
    isWarningSelected,
    isDangerSelected
  ) => {
    setFilterCondition(condition)
    let sortKeyValue = ""
    let primaryKeyValue = ""
    setGoodToggle(isGoodSelected)
    setWarningToggle(isWarningSelected)
    setDangerToggle(isDangerSelected)
    if (
      userMetadata.role.startsWith("Service Provider") ||
      userMetadata.role.startsWith("Asset Owner")
    ) {
      primaryKeyValue = decryptKeyValues(userMetadata.pk)
      sortKeyValue = decryptKeyValues(userMetadata.sk)
    }
    const jwtToken = await getAccessToken(apiurl, "edit:assets")
    if (condition === "") {
      // setSearchKey("")
      getAssetRecords(searchKey)
    } else {
      let endPoint =''
      if(condition === "good" ){
        endPoint='dueAfter90Days'
  
      }else if( condition === "warning" ){
        endPoint='dueIn90Days'
  
      }else if( condition === "danger"){
        endPoint='expired'
  
      }
      const jwtToken = await getAccessToken(apiurl, "add:asset")
      await getFilteredAssetsFromMetquay(jwtToken, metquayUrl,metquaySecretKey,metquayAssetOwnerKey, 0, searchKey,endPoint )
        .then(result => {
          dispatchRecords({ type: "setRecords", payload: result.data.resultData.items })
          setAssetCount(result.data.resultData.total)
        })
        .catch(error => {
          // console.log(error)
        })
    }
  }

  const onSearchKeyChange = _.debounce(async e => {
    // e.persist();
    setSearchKey(e)
    let endPoint =''
    if(filterCondition === "good" ){
      endPoint='dueAfter90Days'

    }else if( filterCondition === "warning" ){
      endPoint='dueIn90Days'

    }else if( filterCondition === "danger"){
      endPoint='expired'

    }
    const jwtToken = await getAccessToken(apiurl, "add:asset")
    await getFilteredAssetsFromMetquay(jwtToken, metquayUrl,metquaySecretKey,metquayAssetOwnerKey, 0, e,endPoint )
      .then(result => {
        dispatchRecords({ type: "setRecords", payload: result.data.resultData.items })
        setAssetCount(result.data.resultData.total)
      })
      .catch(error => {
        // console.log(error)
      })
    

  }, 1000)

  /**
   * selectedRow method is used to set the values of selected row to selectRow.
   * @param {object} row -  selected row data
   * @param {boolean} isSelected -  isSelected flag
   */
  const selectedRow = (row, isSelected) => {
    if (isSelected) {
      setSelectRow(row)
    } else {
      setSelectRow([])
    }
  }

  /**
   * multipleRows method is used when all rows are selected.
   * @param {list} rows - list of selected rows
   */
  const multipleRows = rows => {
    rows.map(row => {
      selectedRow(row, row.isSelected)
    })
    dispatchMultipleRecords(rows)
  }
  /**
   * getAssetRecords method is used to load asset records.
   */
  const getAssetRecords = async searchKey => {
    let data
    let decodedPrimaryKey = ""
    let decodedSortKey = ""
    if (
      userMetadata.role.startsWith("Service Provider") ||
      userMetadata.role.startsWith("Asset Owner")
    ) {
      decodedPrimaryKey = decryptKeyValues(userMetadata.pk)
      decodedSortKey = decryptKeyValues(userMetadata.sk)
    }
    let serviceProviderKey = ""
    let assetOwnerKey = ""
    let url = ""
    let lastkey = null
    const jwtToken = await getAccessToken(apiurl, "add:asset")
    await getAssetOwner(jwtToken, null, decodedPrimaryKey)
      .then(result => {
        setAssetOwnerData(result)
        serviceProviderKey = result[0].attributes.metquaySecretKey
        assetOwnerKey = result[0].attributes.metquayAssetOwnerKey
        url = result[0].attributes.metquayurl
        setMetquaySecretKey(result[0].attributes.metquaySecretKey)
        setMetquayUrl(result[0].attributes.metquayurl)
        setMetquayAssetOwnerKey(result[0].attributes.metquayAssetOwnerKey)
      })
      .catch(error => {})
    await getAssetsFromMetquay(jwtToken, url,serviceProviderKey,assetOwnerKey, 0)
      .then(result => {
        dispatchRecords({ type: "setRecords", payload: result.data.resultData.items })
        setAssetCount(result.data.resultData.total)
      })
      .catch(error => {
        // console.log(error)
      })
      getFilterCount(url,serviceProviderKey,assetOwnerKey)
    
  }

  useEffect(() => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    /**
     * getInitialData method is used to fetch all the records from db and display in table.
     */
    const getInitialData = async () => {
      try {
        setLoading(true)
        getAssetRecords()
        
        setLoading(false)
      } catch (error) {
        if (axios.isCancel(error)) {
        } else {
          throw error
        }
      }
    }
    getInitialData()
    return () => {
      source.cancel()
    }
  }, [])



  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Assets Management"
            breadcrumbItem="Managed Assets"
          />

          <Row>
            <div className="col-md-12 text-start">
              {assetCount} Asset(s) found
            </div>
          </Row>
          <Row>
            <Col>
              {loading ? (
                <Spinner />
              ) : (
                <TableForManagedAssets
                  columnsCustomer={
                    userMetadata.role.startsWith("Asset Owner")
                      ? assetColumnsForAssetOwnerLogin
                      : columns
                  }
                  assetOwnerData={assetOwnerData}
                  records={records.list}
                  multipleRows={multipleRows}
                  selectedRow={selectedRow}
                  selectedAsset={selectRow}
                  userData={userMetadata}
                  getNextAssetsData = {getNextAssetsData}
                  getPrevAssetsData = {getPrevAssetsData}
                  firstPage = {first}
                  lastPage = {last}
                  currentPage ={currentPage}
                  onFilter={onFilter}
                  onSearchKeyChange={onSearchKeyChange}
                  isGood={goodToggle}
                  isWarning={warningToggle}
                  isDanger={dangerToggle}
                  count={count}
                  searchKey={searchKey}
                ></TableForManagedAssets>
              )}
            </Col>
          </Row>
        </div>
      </div>

    </React.Fragment>
  )
}

export default MetquayAssets
