import React, {  useState, useEffect } from "react";
import axios from "axios"
import { useAuth0 } from "@auth0/auth0-react"
import Spinner from "../../../components/Common/Spinner"

import { user_metadata } from "../../../nameSpaceConfig"
import { useToasts } from "react-toast-notifications"

import { TableForExistingColumns } from "./TableForExistingColumns";

//Import columns
import{ existingFieldColumns } from "../data/asset-fields"

import {
  getAssetOwner,
} from "../../../services/assetownerService"
import { getServiceProviderById } from "../../../services/serviceProvider"
import { decryptKeyValues } from "../../../services/commonService"

export const RemoveFieldsTab = (props) => {
  const { removeExistingFields, assetColumnsForAssetOwnerLogin, addedDefaultFields } = props;
  const apiurl = process.env.REACT_APP_AUTH0_API_URL
  const { getAccessTokenSilently, getAccessTokenWithPopup, user } = useAuth0()
  const userMetadata = user[user_metadata]
  const { addToast } = useToasts()
  const [fieldsToBeDeleted, setFieldsToBeDeleted] = useState([])
  const [selectRow, setSelectRow] = useState([])
  const [isDisabled, setIsDisabled ] = useState(true)
  const [loading, setLoading] = useState(false)

  /**
   * getAccessToken method is used to fecth jwt access token from auth0
   * @param {string} audience - the api URL
   * @param {string} claim - the permission
   */
   const getAccessToken = async (audience, claim) => {
    let token
    try {
      token = await getAccessTokenSilently({
        audience,
        claim,
      })
    } catch (exception) {
      token = await getAccessTokenWithPopup({ audience, claim })
    }
    return token
  }

  const selectedRow = (row, isSelected) => {
    setIsDisabled(false)
    fieldsToBeDeleted.map((field, index) => {
      if (field.dataField === row.dataField) {
        // field.toBeRemoved = isSelected
        if(field.toBeRemoved === undefined){
          field.toBeRemoved = true
        }else{
          field.toBeRemoved = ! field.toBeRemoved
        }
        
      }
    })
  }

  /**
  * multipleRows method is used when all rows are selected.
  * @param {list} rows - list of selected rows
  */
   const multipleRows = rows => {
    rows.map(row => {
      selectedRow(row, row.isSelected)
    })
    // dispatchMultipleRecords(rows)
  }

  /**
   * onRemoveFields function is called to delete the selected fields form the list
   * @param {event} e 
   * @param {string} fieldSelected 
   */
  const onRemoveFields = async (e, fieldSelected) => {
    fieldsToBeDeleted.map((field, index) => {
      if (field.dataField === fieldSelected) {
        field.toBeRemoved = e.target.checked
      }
    })
  }

  /** 
   * submitData method is called when ok button is clicked in modal.
   * @param {object} e -  click event
  */
  const onDeleteButtonClick = async (e) => {
    e.preventDefault();
    setLoading(true)
    let deletedFields = fieldsToBeDeleted
    setFieldsToBeDeleted(deletedFields)
    let error = true
    
    deletedFields.map(field =>{
      if(field.dataField === 'Asset#'){
        if(field.toBeRemoved === true){
          error = false
          addToast("Asset# cannot be Disabled", {
            appearance: 'error',
            autoDismiss: true,
          })
        }
      }else if(field.dataField === 'ServicedDate'){
        if(field.toBeRemoved === true){
          error = false
          addToast("Serviced  Date cannot be Disabled", {
            appearance: 'error',
            autoDismiss: true,
          })
        }
      }
      else if(field.dataField === 'DueDate'){
        if(field.toBeRemoved === true){
          error = false
          addToast("Due Date cannot be Disabled", {
            appearance: 'error',
            autoDismiss: true,
          })
        }
      }

      return 
    })
    if(error){
      await removeExistingFields(fieldsToBeDeleted)
      
    }  
    setLoading(false)  
  };


  const getInitialData = async () => {
    let deData = null
    let existingFields = []
    let deletedFields = assetColumnsForAssetOwnerLogin
    try {
      const jwtToken = await getAccessToken(apiurl, "edit:customer")
      if (userMetadata !== undefined && userMetadata.pk !== undefined) {
          deData = decryptKeyValues(userMetadata.pk)
      }
      if (userMetadata.role.startsWith("Asset Owner")) {
          await getAssetOwner(jwtToken, "", deData).then(result => {
            if(result[0].attributes.preferences !== undefined && result[0].attributes.preferences !== null){
              existingFields = result[0].attributes.preferences.existingFields
            }
              existingFields = result[0].attributes.preferences.existingFields
          })
      } else {
          await getServiceProviderById(jwtToken, "", deData)
              .then(result => {
                if(result[0].attributes.preferences !== undefined && result[0].attributes.preferences !== null){
                  existingFields = result[0].attributes.preferences.existingFields
                }
                  existingFields =result[0].attributes.preferences.existingFields
              })
              .catch(error => {
              })
      }
      if(existingFields !== undefined){
        deletedFields.map(field  =>{
          existingFields.map(addedFields =>{
            if(addedFields.dataField === field.dataField){
              field.toBeRemoved = addedFields.toBeRemoved
              field.isDeleted = addedFields.toBeRemoved
            }
          })
        })
      }
      
      setFieldsToBeDeleted(deletedFields)
  } catch (error) {
      if (axios.isCancel(error)) {
      } else {
          throw error
      }
    
}
  }


  useEffect(() => {
    getInitialData()

    
}, [])

  return (
    <React.Fragment>
       {loading ? (
                    <Spinner />
                  ) : (
                    <div>
                    
      <div className="form-group text-end">
        <button
          color="warning"
          className="btn btn-warning m-2"
          type="submit"
          onClick={onDeleteButtonClick} disabled={isDisabled}
        >
          {/* Update Changes */}
          Enable/ Disable Fields
        </button>

      </div>
      <div className="row">
       
        <TableForExistingColumns customColumns={existingFieldColumns} existingFields={fieldsToBeDeleted} multipleRows={multipleRows}/>
        </div>
        </div>
                  )}

    </React.Fragment>
  );
};