import React, { useReducer, useEffect, useState } from "react"
import { useToasts, ToastProvider } from "react-toast-notifications"
import { Row, Col, Button } from "reactstrap"
import { useAuth0 } from "@auth0/auth0-react"
import axios from "axios"
import CryptoJS from "crypto-js"
import "react-toastify/dist/ReactToastify.css"
import Joyride from "react-joyride";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
//Import Table
import { TableForAsset } from "./TableForAsset"
//Import columns
import { columns } from "../data/asset-owner-fields"

//Import Modal
import { ServiceProviderModal } from "./ServiceProviderModal"
import { ConfirmationModal } from "./ConfirmationModal"
//reducers
import {
  recordsReducer,
  multiplerecordsreducer,
} from "../reducer/serviceProviderReducer"
import {
  getRecords,
  updateData,
  getRecordsById,
  addCsvData,
  editData,
  getCount,
} from "../../../services/commonService"
import { addUserData } from "../../../services/userService"

import { user_metadata, roleConfig } from "../../../nameSpaceConfig"
import Spinner from "../../../components/Common/Spinner"
import { Link } from "react-router-dom"

export const CommonComponent = props => {
  const { pageType, title } = props
  const apiurl = process.env.REACT_APP_AUTH0_API_URL
  const { getAccessTokenSilently, getAccessTokenWithPopup, user } = useAuth0()
  const userMetadata = user[user_metadata]
  const { addToast } = useToasts()
  const [loading, setLoading] = useState(false)
  const [assetCount, setAssetCount] = useState(0)
  const [formModal, setFormModal] = useState(false)
  const [modal, setModal] = useState(false)
  const [records, dispatchRecords] = useReducer(recordsReducer, { list: [] })
  const [selectRow, setSelectRow] = useState([])
  const [
    multipleRecords,
    dispatchMultipleRecords,
  ] = useReducer(multiplerecordsreducer, { list: [] })
  const [enableOnlineTutorials, setEnableOnlineTutorial] = useState(false)
  const [loggedUserName, setLoggedUserName] = useState("")

  /**
   * getAccessToken method is used to fecth jwt access token from auth0
   * @param {string} audience - the api URL
   * @param {string} claim - the permission
   */
  const getAccessToken = async (audience, claim) => {
    let token
    try {
      token = await getAccessTokenSilently({
        audience,
        claim,
      })
    } catch (exception) {
      token = await getAccessTokenWithPopup({ audience, claim })
    }
    return token
  }

  /**
   * To toggle modal for Delete options
   */
  const toggle = () => {
    setModal(!modal)
  }

  /**
   * To toggle modal for Add/ Edit options
   */
  const toggleFormModal = () => {
    setFormModal(!formModal)
  }

  /**
   * to display toaster
   * @param {string} message 
   * @param {string} type 
   */
  const createToaster = (message, type) => {
    addToast(message, {
      appearance: type,
      autoDismiss: true,
    })
  }

  /**
   * selectedRow method is used to set the values of selected row to selectRow.
   * @param {object} row -  selected row data
   * @param {boolean} isSelected -  isSelected flag
   */
  const selectedRow = (row, isSelected) => {
    if (isSelected) {
      setSelectRow(row)
    } else {
      setSelectRow([])
    }
  }

  /**
   * multipleRows method is used when all rows are selected.
   * @param {list} rows - list of selected rows
   */
  const multipleRows = rows => {
    rows.map(row => {
      selectedRow(row, row.isSelected)
    })
    dispatchMultipleRecords(rows)
  }
  /**
   * updateCSV method is used to add the data from CSV to table.
   * @param {object} csvdata - data to be imported.
   */
  const updateCsv = async csvdata => {
    let dataToBeImported = []
    const jwtToken = await getAccessToken(apiurl, "add:asset")
    dispatchRecords({
      type: "setCSVRecords",
      payload: csvdata,
      dataToBeImported: dataToBeImported,
    })
    let userData = {}
    if (userMetadata.pk !== undefined) {
      userData.pk = CryptoJS.enc.Base64.parse(userMetadata.pk).toString(
        CryptoJS.enc.Utf8
      )
    }
    await addCsvData(pageType, dataToBeImported, userData, jwtToken)
      .then(result => { })
      .catch(error => {
        createToaster(error.response.data, "error")
      })
    userData.role = userMetadata.role
  }

  /**
   * isDisabled method is used to check the mode and to disable/ enable add, edit and delete button
   * @param {string} action - current mode.
   */
  const isDisabled = action => {
    let disabled = true
    let selectedRowCount = 0
    records.list.map(record => {
      if (record.isSelected) {
        disabled = false
        selectedRowCount += 1
      }
    })
    if (action === "Edit") {
      if (selectedRowCount === 1) {
        return false
      } else {
        return true
      }
    }
    return disabled
  }

  /**
   * updateRecords method is used to update/add records in db.
   * @param {object} record - data to be updated
   */
  const updateRecords = async record => {
    setLoading(true)
    try {
      if (record.mode === "Delete") {
        const jwtToken = await getAccessToken(apiurl, "add:asset")
        let dataToBeDeleted = []
        records.list.map(record => {
          if (record.isSelected) {
            const assetData = {}
            assetData.sk = record.sk
            assetData.pk = record.pk
            assetData.email = record.email
            assetData.version = record.version
            dataToBeDeleted.push(assetData)
          }
        })

        await updateData(pageType, dataToBeDeleted, "Delete", jwtToken)
          .then(result => {
            setLoading(false)
            createToaster("Data has been deleted successfully", "info")
            dispatchRecords({ type: "Delete", payload: records })
            setAssetCount(records.list.length - dataToBeDeleted.length)
          })
          .catch(error => {
            setSelectRow([])
            createToaster(error.response.data, "error")
          })
        setSelectRow([])
        setLoading(false)
      } else if (record.mode === "Add") {
        const jwtToken = await getAccessToken(apiurl, "add:asset")
        if (pageType === "assetOwners") {
          if (userMetadata.role.startsWith("Service Provider")) {
            record.serviceProviderId = CryptoJS.enc.Base64.parse(
              userMetadata.pk
            ).toString(CryptoJS.enc.Utf8)
            record.serviceProviderName = loggedUserName
          }
        }
        let insertedAssetOwnerData = {}

        await updateData(pageType, record, record.mode, jwtToken)
          .then(result => {
            insertedAssetOwnerData = result.data
            setLoading(false)
            createToaster("Data has been added successfully", "info")
            record.isSelected = false
            dispatchRecords({
              type: record.mode,
              payload: { data: result.data },
            })
          })
          .catch(error => {
            createToaster(error.response.data, "error")
            setLoading(false)
          })
        if (
          insertedAssetOwnerData.pk !== undefined &&
          insertedAssetOwnerData.pk !== null
        ) {
          const userData = insertedAssetOwnerData.attributes
          userData.userType = "DefaultUser"
          userData.firstName = insertedAssetOwnerData.attributes.name
          userData.emailAddress = insertedAssetOwnerData.attributes.email
          userData.pk = insertedAssetOwnerData.pk
          userData.sk = insertedAssetOwnerData.sk
          userData.role =
            pageType === "assetOwners" ? "Asset Owner Default User" : "Service Provider Default User"
          userData.serviceProviderName = loggedUserName

          await addUserData(userData, record.mode, jwtToken).then(result => {

          })
            .catch(error => {
              createToaster(error.response.data, "error")
              setLoading(false)
            })
        }
        setSelectRow([])
        setAssetCount(records.list.length + 1)
      } else {
        const jwtToken = await getAccessToken(apiurl, "add:asset")
        if (pageType === "assetOwners") {
          if (userMetadata.role.startsWith("Service Provider")) {
            record.serviceProviderId = CryptoJS.enc.Base64.parse(
              userMetadata.pk
            ).toString(CryptoJS.enc.Utf8)
          }
        }
        // if (userMetadata !== undefined && userMetadata.pk !== undefined) {
        //   record.pk = CryptoJS.enc.Base64.parse(userMetadata.pk).toString(
        //     CryptoJS.enc.Utf8
        //   )
        // }
        await editData(pageType, record, selectRow, jwtToken)
          .then(result => {
            setLoading(false)
            record.isSelected = false
            dispatchRecords({
              type: record.mode,
              payload: { data: result.data },
            })
            createToaster('Data has been edited successfully', "info")
          })
          .catch(error => {
            setLoading(false)
            createToaster(error.response.data, "error")
          })
        setSelectRow([])
        setLoading(false)
      }
    } catch (expection) {
      createToaster("Couldnot perform this operation.", "error")
    }
  }

  useEffect(() => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    let data
    let deData
    /**
     * getInitialData method is used to fetch all the records from db and display in table.
     */
    const getInitialData = async () => {
      setLoading(true)
      try {
        if (userMetadata.role === "Admin") {
          const jwtToken = await getAccessToken(apiurl, "add:asset")
          await getRecords(pageType, jwtToken, source.token)
            .then(result => {
              data = result
              setLoading(false)
              dispatchRecords({ type: "setRecords", payload: data })               

              // createToaster('Data has been loaded successfully',"info" )
            })
            .catch(error => {
              createToaster(error.response.data, "error")
              setLoading(false)
            })
          if (data.length > 0) {
            await getCount(jwtToken, null, pageType).then(result => {
              //
              if (pageType === "assetOwners") {

                setAssetCount(result.data.attributes.ALLASSETOWNERSCOUNT)
              } else {
                setAssetCount(result.data.attributes.ALLSERVICEPROVIDERSCOUNT)
              }

            })
          }

        } else {
          if (userMetadata !== undefined && userMetadata.pk !== undefined) {
            const jwtToken = await getAccessToken(apiurl, "add:asset")

            deData = CryptoJS.enc.Base64.parse(userMetadata.pk).toString(
              CryptoJS.enc.Utf8
            )
            if (userMetadata.role.startsWith("Service Provider")) {
              await getRecordsById("serviceProviders", jwtToken, source.token, deData)
                .then(result => {
                  setLoggedUserName(result[0].attributes.name)
                })
                .catch(error => {
                })
            }
            await getRecordsById(pageType, jwtToken, source.token, deData)
              .then(result => {
                data = result
                setLoading(false)
                dispatchRecords({ type: "setRecords", payload: data })
                // createToaster('Data has been loaded successfully',"info" )
              })
              .catch(error => {
                createToaster(error.response.data, "error")
                setLoading(false)
              })
            if (data.length > 0) {
              await getCount(jwtToken, deData, pageType).then(result => {
                //
                if (pageType === "assetOwners") {
                  setAssetCount(data.length)
                } else {
                  setAssetCount(data.length)
                }
              })
            }

          } else {
            createToaster('You are not authorised to view this data.', "error")
          }
        }
      } catch (error) {
        createToaster('Data cannot be loaded.', "error")
      }
    }
    getInitialData()
    return () => {
      source.cancel()
    }
  }, [])


  return (
    <React.Fragment>
      <ToastProvider>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Asset Management" breadcrumbItem={title} />
            <Row>
              <div className="col-md-12 text-end">
                {
                  userMetadata.role.startsWith("Service Provider") &&
                 (
                  <Link to='/bulk-invite'>
                  <Button
                    className="mb-2 me-2"
                    color="primary"
                  >
                    Invite Asset Owners
                  </Button>
                </Link>
                 )
                }
                <Button
                  className="mb-2 me-2"
                  color="primary"
                  onClick={toggleFormModal}
                  disabled={!isDisabled("Add")}
                >
                  Add
                </Button>
                <Button
                  className="mb-2 me-2"
                  color="primary"
                  onClick={toggleFormModal}
                  disabled={isDisabled("Edit")}
                >
                  Edit
                </Button>

                <ServiceProviderModal
                  updateRecords={updateRecords}
                  action={selectRow.length === 0 ? "Add" : "Edit"}
                  modalheader={
                    selectRow.length === 0 ? "Add " + title : "Edit " + title
                  }
                  columns={columns}
                  selectedRow={selectRow}
                  open={formModal}
                  toggle={toggleFormModal}
                  type={"formComponent"}
                  title={title}
                  userMetadata={userMetadata}
                />

                <Button
                  id="UncontrolledPopover2"
                  className="me-2 mb-2"
                  color="warning"
                  onClick={toggle}
                  disabled={isDisabled("Delete")}
                >
                  Delete
                </Button>
              </div>
            </Row>
            <ConfirmationModal
              action="Delete"
              updateRecords={updateRecords}
              selectedRow={selectRow}
              header={"Delete " + title}
              open={modal}
              toggle={toggle}
            />
            <Row>
              <div className="col-md-12 text-start">
                {assetCount} {title}(s) found
              </div>
            </Row>
            <Row>
              <Col>
                {loading ? (
                  <Spinner />
                ) : (
                  <TableForAsset
                    columnsCustomer={columns}
                    records={records.list}
                    multipleRows={multipleRows}
                    selectedRow={selectedRow}
                    updateCsv={updateCsv}
                  />
                )}
              </Col>
            </Row>
          </div>
        </div>
      </ToastProvider>
    </React.Fragment>
  )
}