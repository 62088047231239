import http from "./httpService"
import CryptoJS from "crypto-js"
import { getAssets, addAssetsData } from "./assetService"
import {
  getAssetOwner,
  addAssetOwnerData,
  getAssetOwnerById,
  addAssetOwnerCsvData,
  updateAssetOwnerData,
  getAssetOwnersCount,
} from "./assetownerService"
import {
  getServiceProvider,
  addServiceProviderData,
  getServiceProviderById,
  addServiceProviderCsvData,
  getServiceProviderCount,
  updateServiceProviderData,
} from "./serviceProvider"
import { getUsers, addUserData } from "./userService"

/**
 *getRecords method is used to fetch all the records based on pageType.
 * @param {string} pageType - assets/ serviceprovider/ assetOwners/ user
 * @param {string} cancelToken - token fecthed from axios.
 */
export async function getRecords(pageType, jwtToken, cancelToken) {
  switch (pageType) {
    case "assets":
      return getAssets(jwtToken, cancelToken)
    case "assetOwners":
      return getAssetOwner(jwtToken, cancelToken)
    case "serviceProviders":
      const data = await getServiceProvider(jwtToken, cancelToken)
      return data
    case "users":
      return getUsers(jwtToken, cancelToken)
  }
}

/**
 *getRecordsById method is used to fetch all the records based on pageType.
 * @param {string} pageType - assets/ serviceprovider/ assetOwners/ user.
 * @param {string} jwtToken - token fecthed from axios.
 * @param {string} cancelToken - token fecthed from axios.
 * @param {string} pk - hash key value.
 */
export async function getRecordsById(pageType, jwtToken, cancelToken, pk) {
  switch (pageType) {
    case "assets":
      return getAssets(jwtToken, cancelToken)
    case "assetOwners":
      return getAssetOwnerById(jwtToken, cancelToken, pk)
    case "serviceProviders":
      const data = await getServiceProviderById(jwtToken, cancelToken, pk)
      return data
    case "users":
      return getUsers(jwtToken, cancelToken)
  }
}

/**
 * updateRecords method is used to add/update/delete records based on pageType.
 * @param {string} pageType - assets/ serviceprovider/ assetOwners/ user
 * @param {object} data - data to be added/ updated/ deleted
 * @param {string} action - action to be performed
 * @param {string} _id - id of data incase of update
 */
export async function updateData(pageType, data, action, _id) {
  switch (pageType) {
    case "assets":
      return addAssetsData(data, action, _id)
    case "assetOwners":
      return addAssetOwnerData(data, action, _id)
    case "serviceProviders":
      return addServiceProviderData(data, action, _id)
    case "users":
      return addUserData(data, action, _id)
  }
}
export const noOfFreeTrialDaysLeft = (days, trialDays) => {
  let freeTrialDays =
    trialDays === undefined || trialDays === null ? 15 : trialDays
    return (freeTrialDays - days)
}
export async function editData(pageType, data, oldData, jwtToken) {
  switch (pageType) {
    case "assetOwners":
      return updateAssetOwnerData(data, oldData, jwtToken)
    case "serviceProviders":
      return updateServiceProviderData(data, oldData, jwtToken)
  }
}

export async function addCsvData(pageType, data, userData, jwtToken) {
  switch (pageType) {
    case "assetOwners":
      return addAssetOwnerCsvData(data, userData, jwtToken)
    case "serviceProviders":
      return addServiceProviderCsvData(data, userData, jwtToken)
  }
}

/**
 *getRecords method is used to fetch all the records based on pageType.
 * @param {string} pageType - assets/ serviceprovider/ assetOwners/ user
 * @param {string} cancelToken - token fecthed from axios.
 */
export async function getCount(jwtToken, primaryKey, pageType) {
  switch (pageType) {
    case "assetOwners":
      return getAssetOwnersCount(jwtToken, primaryKey)
    case "serviceProviders":
      const data = await getServiceProviderCount(jwtToken, primaryKey)
      return data
  }
}

function clean(obj) {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === ""
    ) {
      delete obj[propName]
    }
  }
  return obj
}

/**
 * decryptKeyValues method is used to decrypt the primary/ sort key values fetched from autho user.
 * @param {string} keyValue
 * @returns string
 */
export function decryptKeyValues(keyValue) {
  return CryptoJS.enc.Base64.parse(keyValue).toString(CryptoJS.enc.Utf8)
}

/**
 * checkLoggedInDays method is used to check the difference between current date and logged in date
 * @param {string} createdDate
 * @returns number
 */
export function checkLoggedInDays(createdDate) {
  var date1 = new Date(createdDate)
  var date2 = new Date()
  return parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10)
}

export function convertDateTimeString(dateString) {
  if (dateString === null || dateString === undefined) {
    return " "
  } else {
    const date = new Date(dateString),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2)
    return [date.getFullYear(), mnth, day].join("-")
  }
}

export function convertFromTimestampAndFindDiff(endDate) {
  var date = new Date(0) // The 0 there is the key, which sets the date to the epoch
  date.setUTCSeconds(endDate)
  var date2 = new Date()
  // console.log(d)
  return parseInt((date - date2) / (1000 * 60 * 60 * 24), 10)
}

/**
 * convertUSCentToUSD function is used to convert a whole number in cents to readable USD
 * @param {string} priceInCents
 * @returns
 */
export function convertUSCentToUSD(priceInCents) {
  return (priceInCents / 100).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  })
}

/**
 * convertTimeStamptoDateString function is used to convert date in timestamp to date string
 * @param {number} dateInTimeStamp
 * @returns
 */
export function convertTimeStamptoDateString(dateInTimeStamp) {
  return new Date(dateInTimeStamp * 1000).toLocaleDateString()
}

export function mapCustomerFromStripe(customers, productId) {
  let customerList = []
  customers.map(customer => {
    // if(customer.subscriptions.)
    if (
      customer.subscriptions.data !== undefined &&
      customer.subscriptions.data[0] !== undefined
    ) {
      let subscriptionDetails = customer.subscriptions.data[0]
      if (subscriptionDetails.plan.product === productId) {
        let customerObject = {}
        customerObject.userName = customer.name
        customerObject.userEmail = customer.email
        customerObject.createdAt = convertTimeStamptoDateString(
          customer.created
        )
        customerObject.noOfUsers = customer.subscriptions.data[0].quantity
        customerObject.isCanceled = customer.subscriptions.data[0]
          .cancel_at_period_end
          ? "Canceled"
          : "Active"
        customerObject.subscriptionEndDate = convertTimeStamptoDateString(
          customer.subscriptions.data[0].current_period_end
        )
        const canceledDate = customer.subscriptions.data[0].canceled_at
        customerObject.canceledDate =
          canceledDate === null
            ? ""
            : convertTimeStamptoDateString(
                customer.subscriptions.data[0].canceled_at
              )
        customerList.push(customerObject)
      }
    }
  })
  return customerList
}