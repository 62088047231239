import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

import SubscriptionDetails from "../../CommonForBoth/TopbarDropdown/SubscriptionDetails"

import { useAuth0 } from "@auth0/auth0-react";

//services
import { decryptKeyValues } from "../../../services/commonService"
import {getAssetOwner} from "../../../services/assetownerService"
import {getServiceProviderById} from "../../../services/serviceProvider"
//
import { user_metadata } from "../../../nameSpaceConfig"
import { withTranslation } from "react-i18next"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const apiurl = process.env.REACT_APP_AUTH0_API_URL
  const [menu, setMenu] = useState(false)
  const { user, getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();
  const userMetadata = user[user_metadata]
  const [username, setusername] = useState(user.name)
  const [metquayurl, setMetquayurl] = useState(" ")

  /**
   * getAccessToken method is used to fecth jwt access token from auth0
   * @param {string} audience - the api URL
   * @param {string} claim - the permission
   */
   const getAccessToken = async (audience, claim) => {
    let token
    try {
      token = await getAccessTokenSilently({
        audience,
        claim,
      })
    } catch (exception) {
      token = await getAccessTokenWithPopup({ audience, claim })
    }
    return token
  }

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.displayName)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.username)
      }
    }

    const getInitialData = async () => {
      
      const jwtToken = await getAccessToken(apiurl, "edit:assets")
      if (userMetadata.role.startsWith("Asset Owner")|| userMetadata.role.startsWith("Service Provider")) {
      let sortKeyValue = ""
        let primaryKeyValue = ""
        
          primaryKeyValue = decryptKeyValues(userMetadata.pk)
          sortKeyValue = decryptKeyValues(userMetadata.sk)
     
        let details = {}
        // let pageType = userMetadata.role.startsWith("Asset Owner") ? "assetOwners" : "serviceProviders"
        // await getAssetOwner(jwtToken, null, primaryKeyValue).then(result => {
        //   details = result[0]
        // })
        if ( userMetadata.role.startsWith("Asset Owner") )  {
          await getAssetOwner(jwtToken, null, primaryKeyValue).then(result => {
         details = result[0]
       })
       }else if ( userMetadata.role.startsWith("Service Provider"))  {
         await getServiceProviderById(jwtToken, null, primaryKeyValue).then(result => {
         details = result[0]
       })
       }
// await getRecordsById(pageType, jwtToken, null, primaryKeyValue).then(result => {
//           details = result[0]
//         })
        
        if(details !== undefined  && details.attributes !== undefined ){
          if(details.attributes.metquayurl !== undefined & details.attributes.metquayurl !== null){
            setMetquayurl(details.attributes.metquayurl)
          }
        }
      }
    }
    getInitialData()


  }, [props.success])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user.picture}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{user.name}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block"/>
          {( userMetadata.role.startsWith("Asset Owner") || userMetadata.role.startsWith("Service Provider" ))   && (metquayurl !== undefined && metquayurl !== " ")?(
                    <div className="text-center ">
                    <SubscriptionDetails />
                  </div>
                  ) : (
                    " "
                  )}
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {/* <DropdownItem tag="a" href="/profile">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1"/>
            {props.t("Profile")}{" "}
          </DropdownItem> */}
          {/* <DropdownItem tag="a" href="/crypto-wallet">
            <i className="bx bx-wallet font-size-16 align-middle me-1"/>
            {props.t("My Wallet")}
          </DropdownItem> */}
          {/* <DropdownItem tag="a" href="#">
            <span className="badge bg-success float-end">11</span>
            <i className="bx bx-wrench font-size-16 align-middle me-1"/>
            {props.t("Settings")}
          </DropdownItem>
          <DropdownItem tag="a" href="auth-lock-screen">
            <i className="bx bx-lock-open font-size-16 align-middle me-1"/>
            {props.t("Lock screen")}
          </DropdownItem> */}
          {/* <div className="dropdown-divider"/> */}
          <Link to="/assetManagement-preferences" className="dropdown-item">
          <i class='bx bxs-customize font-size-16 align-middle me-1 text-danger'></i>
            <span>{props.t("Preferences")}</span>
          </Link>
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"/>
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
}

const mapStatetoProps = state => {
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
