import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import { useAuth0 } from "@auth0/auth0-react"
import { Link, useLocation } from "react-router-dom"
import classnames from "classnames"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
//Import Table
import { ProductDetails } from "./Components/ProductDetails"
import { ProductFeatures } from "./Components/ProductFeatures"
import { UserListForPlan } from "./Components/UserListForPlan"



const PlanDetailPage = props => {
  const location = useLocation()
  const { productId, productName } = location.state
  const apiurl = process.env.REACT_APP_AUTH0_API_URL
  const { getAccessTokenSilently, getAccessTokenWithPopup, user } = useAuth0()
  const [customActiveTab, setcustomActiveTab] = useState("1")

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  /**
   * getAccessToken method is used to fecth jwt access token from auth0
   * @param {string} audience - the api URL
   * @param {string} claim - the permission
   */
  const getAccessToken = async (audience, claim) => {
    let token
    try {
      token = await getAccessTokenSilently({
        audience,
        claim,
      })
    } catch (exception) {
      token = await getAccessTokenWithPopup({ audience, claim })
    }
    return token
  }

  /**
   * getInitialData method is used to fetch all the records from db and display in table.
   */
  const getInitialData = async () => {
    const jwtToken = await getAccessToken(apiurl, "edit:assets")
  }

  useEffect(() => {
    getInitialData()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Asset Management"
            breadcrumbItem="Product Details"
          />
          <div className="m-1">
            <Link
              to="asset-management-plans"
              className="btn btn-primary waves-effect waves-light btn-sm"
            >
              <i className="mdi mdi-arrow-left ml-1">Back to Plans</i>
            </Link>
          </div>
          <h2>{productName}</h2>
          <Card>
            <CardBody>
              <CardTitle className="h4"></CardTitle>

              <Nav tabs className="nav-tabs-custom nav-justified">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "1",
                    })}
                    onClick={() => {
                      toggleCustom("1")
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className="fas fa-home"></i>
                    </span>
                    <span className="d-none d-sm-block">Product Details</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "2",
                    })}
                    onClick={() => {
                      toggleCustom("2")
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className="fa fa-cog"></i>
                    </span>
                    <span className="d-none d-sm-block">Add Features</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "3",
                    })}
                    onClick={() => {
                      toggleCustom("3")
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className="far fa-user"></i>
                    </span>
                    <span className="d-none d-sm-block">Users</span>
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent
                activeTab={customActiveTab}
                className="p-3 text-muted"
              >
                <TabPane tabId="1">
                  <Row>
                    <Col sm="12">
                      <ProductDetails productId={productId} />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col sm="12">
                      <ProductFeatures
                        productId={productId}
                        productName={productName}
                      />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="3">
                  <Row>
                    <Col sm="12">
                      {/* <ProductFeatures productId={productId} /> */}
                      <UserListForPlan productId={productId} />
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PlanDetailPage
