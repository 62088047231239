import React, { useState, useEffect } from "react"

import { Link, Redirect } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react"
import CryptoJS from "crypto-js"

//service method
import { getAuthUserDetails } from "../../../services/userService"
import {
  getSessionDetails,
  getSubscriptionDetails,
  getProductDetailById,
  sendMailAfterSuccessfulPayment
} from "../../../services/paymentService"
import {
  updateSubscriptionDetailsInAssetOwner,
  getAssetOwner,
} from "../../../services/assetownerService"
import {getServiceProviderById, updateSubscriptionDetailsInServcieProvider} from "../../../services/serviceProvider"

import { user_metadata } from "../../../nameSpaceConfig"

const SubscriptionDetails = () => {
  const [plan, setPlan] = useState("")
  const [badgeColor, setBadgeColor] = useState("")
  const [toRedirect, setToRedirect] = useState(false)
  const apiurl = process.env.REACT_APP_AUTH0_API_URL
  const { getAccessTokenSilently, getAccessTokenWithPopup, user } = useAuth0()
  const userMetadata = user[user_metadata]
  const [usersPurchased, setUsersPurchased] = useState(0)
  /**
   * getAccessToken method is used to fecth jwt access token from auth0
   * @param {string} audience - the api URL
   * @param {string} claim - the permission
   */
  const getAccessToken = async (audience, claim) => {
    let token
    try {
      token = await getAccessTokenSilently({
        audience,
        claim,
      })
    } catch (exception) {
      token = await getAccessTokenWithPopup({ audience, claim })
    }
    return token
  }

  /**
   * checkLoggedInDays method is sued to check the difference between current date and logged in date
   * @param {string} createdDate
   * @returns number
   */
  const checkLoggedInDays = createdDate => {
    var date1 = new Date(createdDate)
    var date2 = new Date()
    return parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10)
  }

  /**
   * checkFreeTrialExpiry method is used to check whether the free trial has expired or not
   * @param {number} days
   */
  const checkFreeTrialExpiry = (days, trialDays) => {
    // if (days <= 8) {
    //   setPlan("Free trial 30 days")
    //   setBadgeColor("rounded-pill  font-size-12 badge-soft-success")
    //   setToRedirect(false)
    // } else {
    //   setToRedirect(true)
    // }
    let freeTrialDays = trialDays === undefined || trialDays === null ? 15 : trialDays
    if (days <= freeTrialDays) {
      setPlan(`Free trial ${freeTrialDays - days} days remaining`)
      setBadgeColor("rounded-pill  font-size-12 badge-soft-success")
      setToRedirect(false)
    } else {
      setToRedirect(true)
    }
  }

  /**
   * decryptKeyValues method is used to decrypt the primary/ sort key values fetched from autho user.
   * @param {string} keyValue
   * @returns string
   */
  const decryptKeyValues = keyValue => {
    return CryptoJS.enc.Base64.parse(keyValue).toString(CryptoJS.enc.Utf8)
  }

  useEffect(() => {
    const getInitialData = async () => {
      const jwtToken = await getAccessToken(apiurl, "edit:assets")
      const search = window.location.search // could be '?foo=bar'
      const params = new URLSearchParams(search)
      if (
        (userMetadata.role.startsWith("Asset Owner")) ||
        userMetadata.role.startsWith("Service Provider")
      ) {
        const sessionId = params.get("session_id")
        let sortKeyValue = ""
        let primaryKeyValue = ""

        primaryKeyValue = decryptKeyValues(userMetadata.pk)
        sortKeyValue = decryptKeyValues(userMetadata.sk)
        if (sessionId !== undefined && sessionId !== null) {
          let subscriptionId = ""
          let customerId = ""
          let productId = ""
          let quantity = 1
          let planName = ''
          let details = {}
          await getSessionDetails(jwtToken, sessionId).then(result => {
            subscriptionId = result.data.subscription
            customerId = result.data.customer
          })
          await getSubscriptionDetails(jwtToken, subscriptionId).then(
            result => {
              productId = result.data.plan.product
              setUsersPurchased(result.data.quantity)
              quantity = result.data.quantity
              if (result.data.status === "active") {
                setToRedirect(false)
              } else {
                setToRedirect(true)
              }
            }
          )

          await getProductDetailById(jwtToken, productId).then(result => {
            setPlan(result.data.name)
            planName = result.data.name
            setBadgeColor("rounded-pill  font-size-12 badge-soft-success")
          })
          if (userMetadata.role.startsWith("Asset Owner"))  {
            await updateSubscriptionDetailsInAssetOwner(
              jwtToken,
              primaryKeyValue,
              sortKeyValue,
              subscriptionId,
              customerId,
              quantity
            )
              .then(result => {})
              .catch(error => {
                // console.log("error")
                // console.log(error)
              })
              
              await getAssetOwner(jwtToken, null, primaryKeyValue).then(result => {
                details = result[0]

              })
              await sendMailAfterSuccessfulPayment(jwtToken, details.attributes.email, details.attributes.name, planName, "", false).then(result => {})
  
         }else if ( userMetadata.role.startsWith("Service Provider"))  {
          await updateSubscriptionDetailsInServcieProvider(
            jwtToken,
            primaryKeyValue,
            sortKeyValue,
            subscriptionId,
            customerId,
            quantity
          )
            .then(result => {})
            .catch(error => {
              // console.log("error")
              // console.log(error)
            })
            await getServiceProviderById(jwtToken, null, primaryKeyValue).then(result => {
              details = result[0]
            })
            await sendMailAfterSuccessfulPayment(jwtToken, details.attributes.email, details.attributes.name, planName, "", false).then(result => {

            })

         }


          

          
        } else {
          let details = {}
          // let pageType = userMetadata.role.startsWith("Asset Owner") ? "assetOwners" : "serviceProviders"

          // await getAssetOwner(jwtToken, null, primaryKeyValue).then(result => {
          //   details = result[0]
          // })
          // await getRecordsById(pageType, jwtToken, null, primaryKeyValue).then(result => {
          //   details = result[0]
          // })
          if ( userMetadata.role.startsWith("Asset Owner") )  {
             await getAssetOwner(jwtToken, null, primaryKeyValue).then(result => {
            details = result[0]
          })
          }else if ( userMetadata.role.startsWith("Service Provider"))  {
            await getServiceProviderById(jwtToken, null, primaryKeyValue).then(result => {
            details = result[0]
          })
          }
          if (
            details !== undefined &&
            details.attributes !== undefined
          ) {
            // console.log(
            //   " assetOwnerDetails.attributes.subscriptionId = ",
            //   assetOwnerDetails.attributes.subscriptionId
            // )
            if (
              (details.attributes.subscriptionId !== undefined) &
              (details.attributes.subscriptionId !== null)
            ) {
              let productId = ""
              await getSubscriptionDetails(
                jwtToken,
                details.attributes.subscriptionId
              ).then(result => {
                productId = result.data.plan.product
                setUsersPurchased(result.data.quantity)
                if (result.data.status === "active") {
                  setToRedirect(false)
                } else {
                  setToRedirect(true)
                }
              })
              await getProductDetailById(jwtToken, productId).then(result => {
                setPlan(result.data.name)
              })
            } else {
              if(userMetadata.sk === 'QVNTRVRPV05FUg==' || details.pk.startsWith('SERVICEPROVIDER')){
              getAuthUserDetails(jwtToken, user.sub).then(result => {
                const diff = checkLoggedInDays(result.data.created_at)
                checkFreeTrialExpiry(diff, details.attributes.freeTrialDays)
              })
            }else{
                setPlan("Asset Owner Loyalty")
                setToRedirect(false)
            }
            }
          }
        }
      }
    }
    getInitialData()
    return () => {}
  }, [])

  return (
    <React.Fragment>
      {toRedirect === true ? (
        <Redirect to={{ pathname: "/assetManagement-payment" }} />
      ) : (plan === "" ? "" :
            <Link to="/assetManagement-payment" className={"rounded-pill  font-size-12 badge-soft-success"}>
              {plan}
            </Link>        
      )}
    </React.Fragment>
  )
}

export default SubscriptionDetails
