import React from "react"
import {  Card, CardBody, Button } from "reactstrap"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css"

import { planDetailFields } from "../data/plan-detail-fields"

export const planNameFormatter = (cell, row) => {
  return <Button>Name</Button>
}

export const PlanDetailTable = ({ productsList }) => {
  return (
    <Card>
      <CardBody>
        <ToolkitProvider
          bootstrap4
          keyField="dataField"
          columns={planDetailFields}
          data={productsList}
        >
          {props => (
            <React.Fragment>
              <BootstrapTable
                {...props.baseProps}
                keyField="id"
                bordered={false}
                // selectRow={selectRow}
                // pagination={paginationFactory(paginationOptions)}
                wrapperClasses="table-responsive"
              />
            </React.Fragment>
          )}
        </ToolkitProvider>
      </CardBody>
    </Card>
  )
}
