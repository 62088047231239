import AWS from 'aws-sdk';
import http from "./httpService"
AWS.config.update({
    accessKeyId: process.env.REACT_APP_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
  })
var QUEUE_URL;
var sqs = new AWS.SQS({region: "us-east-1"});

process.env.REACT_APP_NODE_ENV === 'production' ? QUEUE_URL = process.env.REACT_APP_SQSBULKINVITE_URL_PROD : QUEUE_URL = process.env.REACT_APP_SQSBULKINVITE_URL

const chunkSize = 1;

export async function sendMessage(assetOwners , jwtToken , metquaySecretKey , metquayUrl ,serviceProviderId , serviceProviderName , videoUrl) {
    // Here the assetOwners is the list of asset owners which the serviceprovider has added whose name is also provided. 
    sendInvitationDetails(jwtToken , assetOwners , serviceProviderName)
    for (let i = 0; i < assetOwners.length; i += chunkSize) {
        const chunk = assetOwners.slice(i, i + chunkSize);
        var newMessage = {
            chunk: chunk,
            token : jwtToken,
            metquaySecretKey : metquaySecretKey,
            metquayUrl : metquayUrl,
            serviceProviderId : serviceProviderId,
            serviceProviderName : serviceProviderName,
            videoUrl :videoUrl
        }
        const params = {
            QueueUrl: QUEUE_URL,
            MessageBody: JSON.stringify(newMessage)
        };
        await sqsMessage(params)
    }
}

async function sqsMessage(params){
    const result = await sqs.sendMessage(params, (err, data) => {
        if (err) {
          console.log("Could Not perform operation")
            // Handle error
            // console.log(err)
           
        } else {
            console.log('Message sent successfully.');
            // console.log(params);
            // console.log(data)
        }
    });
    return result
}

async function sendInvitationDetails(jwtToken , aoList , serviceProviderName){
    http.setJwt(jwtToken)
    const data = {
        aoList: aoList,
        serviceProviderName :serviceProviderName
    }
    await http.post(`/serviceProvider/send-invitation-details`, data)
}