import React, { useEffect, useState } from 'react'
import { columnsForServiceProviderInquiry, columnsForServiceProviderQuotes, columnsForServiceProviderSentQuote } from './columnForServiceProviderQuotes'
import { Input, Radio } from 'antd';
const { Search } = Input;
import { Card, Table } from 'antd'
import CurrencySelection from './CurrencySelection';


  function searchObjectsByKey(arr, searchKey) {
    const filteredArray = arr.filter((obj) => {
      for (const key in obj) {
        const value = obj[key];
        if (value && typeof value === 'string' && value.toLowerCase().includes(searchKey.toLowerCase())) {
          return true;
        }
      }
      return false;
    });

    return filteredArray;
  }
const ServiceProviderMetquayIntegrated = ({quotes , user , setQuotes , assetsArray , userId}) => {
  const [value, setValue] = useState(1);
  const [quotesArr , setQuotesArr] = useState([])
  const [columns , setColumns] = useState([])
  const onSearch = (value) => {
    if(value.length === 0){
      setQuotes(assetsArray)
    }else{
      const result = searchObjectsByKey(quotes, value)
      setQuotes(result)
    }
    
  }
  useEffect(() => {
    const awaitingReviewArray = quotes.filter(item => item.status === "Awaiting Review");
    const otherStatusArray = quotes.filter(item => item.status !== "Awaiting Review");
    if(value === 1){
      setQuotesArr(awaitingReviewArray)
      setColumns(columnsForServiceProviderQuotes)
    }else{
      setQuotesArr(otherStatusArray)
      setColumns(columnsForServiceProviderSentQuote)
    }
  }, [quotes , value])

  const onChange = (e) => {
    setValue(e.target.value);
  };
  const assetsTable = (data) => {
    return <Table columns={columns} dataSource={data} pagination={false} />;
  };
  return (
    <div>
    <Card extra={<>
    <div style={{display:'flex' , gap:'5px'}}>
          <CurrencySelection userId={userId} user={user}/>
          <Search
            placeholder="Search"
            onSearch={onSearch}
            style={{
              width: 200,
            }}
        />         
    </div>
    </>}
    title={
      <Radio.Group onChange={onChange} value={value}>
        <Radio value={1}>Inquiries</Radio>
        <Radio value={2}>Quotes</Radio>
      </Radio.Group>
    }
    loading={false}>
      <Table dataSource={quotesArr} columns={columnsForServiceProviderInquiry} 
      rowKey={(record)=>(record.pk)}
        expandable={{
          expandedRowRender : (record) => assetsTable(record.attributes.assets),
        }}
        scroll={{ x: 800 , y: 350 }}
      />
    </Card>
    </div>
  )
}

export default ServiceProviderMetquayIntegrated