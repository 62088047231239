import { Input, Modal, Table } from 'antd';
import React, { useEffect, useState } from 'react'

const RequestForQuotesModal = ({setIsModalOpen , isModalOpen , assets , assetOwnerData , toast , createRequstForQuote , getAccessTokenFunction , onSelectAll}) => {
    const [finalAssetList , setFinalAssetList] = useState(assets)
    useEffect(() => {
      setFinalAssetList(assets)
    }, [assets])
    
    const columnsForRequestForQuotesModal = [
        {
          title: 'Asset ID',
          dataIndex: 'tagNo',
        },
          {
            title: 'Instrument Name',
            dataIndex: 'instrumentName',
          },
          {
            title: 'Instrument Category',
            dataIndex: 'instrumentCategory',
          },
          {
            title: 'Type',
            dataIndex: 'itemType',
          },
          {
          title: 'Comments',
          dataIndex: 'tagNo', 
          render: (tagNo) =>{
            return(  
            <Input
              onChange={(e) => handleCommentChange(tagNo, e?.target?.value ? e.target.value : "")}
            />
          )},
        },
    ]
    const handleOk = async() => {
        const jwtToken = await getAccessTokenFunction()
        await createRequstForQuote(jwtToken , assetOwnerData[0].pk, assetOwnerData[0].sk  , assetOwnerData[0].attributes.name, finalAssetList)
        onSelectAll(false , [])
        toast.success("Request for quote sent")
        setIsModalOpen(false)
    }
      
    const handleCancel = () => {setIsModalOpen(false)};
    // Function to update comments for an asset
    const handleCommentChange = (assetId, comment) => {
        const updatedAssets = finalAssetList.map((asset) =>
        asset.tagNo === assetId ? { ...asset, comment: comment } : asset
        );
        setFinalAssetList(updatedAssets);
    };

  return (
    <div>
        <Modal title="Confirm" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} width={'1000px'}>
            <Table columns={columnsForRequestForQuotesModal} dataSource={assets} />
        </Modal>
    </div>
  )
}

export default RequestForQuotesModal

