import React, { useState, useEffect, useReducer } from "react"
import {
  Card,
  CardBody,
  Badge,
  Button,
  Row,
  Col,
  Input,
} from "reactstrap"
import { Link } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"

import { useAuth0 } from "@auth0/auth0-react"
import { useToasts } from "react-toast-notifications"
import CryptoJS from "crypto-js"
import { user_metadata, roleConfig } from "../../nameSpaceConfig"

//Service files
import { getWorksInLast30Days } from "../../services/dashboard-services"
//Reducers
import { liveUpdatesReducer } from "../Asset-management/reducer/reducers"
//Columns
import { liveUpdatesColumns } from "../Asset-management/data/asset-fields"

export const planNameAddFeatures = (cell, row) => {
  let badgeColour = "primary"
  return (
    <Link
      to={{
        pathname: "/asset-management-product",
        state: {
          productId: row.id,
          productName: row.name,
        },
      }}
    >
      <Badge className="font-size-12 badge-soft-primary">View Product</Badge>
    </Link>
  )
}

export const statusFieldFormatter = (cell, row) => {
  let badgeColour = row.isCanceled === "Canceled" ? "Danger" : "Success"
  return (
    <Badge className={"font-size-12 badge-soft-" + badgeColour} pill>
      {row.isCanceled}
    </Badge>
  )
}

export const statusColumnFormatter = (cell, row) => {
  let badgeColour =
    row.status.toLowerCase() === "finished"
      ? "success"
      : row.status.toLowerCase() === "closed"
      ? "secondary"
      : row.status.toLowerCase() === "started"
      ? "warning"
      : "danger"
  badgeColour =
    row.status.replace("_", " ").toLowerCase() === "mark for certificate"
      ? "primary"
      : row.status.replace("_", " ").toLowerCase() === "marked for certificate"
      ? "primary"
      : badgeColour
  return (
    <Badge className={"font-size-12 badge-soft-" + badgeColour} pill>
      {/* {row.status.replace('_', ' ')} */}
      {row.status === "MARK_FOR_CERTIFICATE"
        ? "MARKED FOR CERTIFICATE"
        : row.status.replace("_", " ")}
    </Badge>
  )
}

const LiveUpdates = props => {
  // const {
  //   columns,
  // } = props
  const { metquayUrl, metquaySecretKey, metquayAssetOwnerKey, isAssetOwner } =
    props

  const apiurl = process.env.REACT_APP_AUTH0_API_URL
  const { getAccessTokenSilently, getAccessTokenWithPopup, user } = useAuth0()
  const userMetadata = user[user_metadata]
  const { addToast } = useToasts()
  const [records, dispatchRecords] = useReducer(liveUpdatesReducer, {
    list: [],
  })
  const [first, setFirst] = useState(0)
  const [limit, setLimit] = useState(10)
  const [searchKey, setSearchKey] = useState("")
  const [last, setLast] = useState(10)
  const [currentPage, setCurrentPage] = useState(0)
  const [totalWorks, setTotalWorks] = useState(0)
  const [totalResults, setTotalResults] = useState(10)

  /**
   * decryptKeyValues method is used to decrypt the primary/ sort key values fetched from autho user.
   * @param {string} keyValue
   * @returns string
   */
  const decryptKeyValues = keyValue => {
    return CryptoJS.enc.Base64.parse(keyValue).toString(CryptoJS.enc.Utf8)
  }

  /**
   * getAccessToken method is used to fecth jwt access token from auth0
   * @param {string} audience - the api URL
   * @param {string} claim - the permission
   */
  const getAccessToken = async (audience, claim) => {
    let token
    try {
      token = await getAccessTokenSilently({
        audience,
        claim,
      })
    } catch (exception) {
      token = await getAccessTokenWithPopup({ audience, claim })
    }
    return token
  }

  const getPreviousData = async e => {
    const jwtToken = await getAccessToken(apiurl, "add:asset")
    await getWorksInLast30Days(
      jwtToken,
      metquayUrl,
      metquaySecretKey,
      metquayAssetOwnerKey,
      first,
      isAssetOwner,
      searchKey
    )
      .then(result => {
        setFirst(first - 10)
        setCurrentPage(currentPage - 1)
        // setTotalResults(result.data.resultData.items.length)
        dispatchRecords({
          type: "setRecords",
          payload: result.data.resultData.items,
        })
      })
      .catch(error => {
        // console.log(error)
      })
  }

  const getNextData = async e => {
    const jwtToken = await getAccessToken(apiurl, "add:asset")
    await getWorksInLast30Days(
      jwtToken,
      metquayUrl,
      metquaySecretKey,
      metquayAssetOwnerKey,
      first,
      isAssetOwner,
      searchKey
    )
      .then(result => {
        setFirst(first + 10)
        setCurrentPage(currentPage + 1)
        // setTotalResults(result.data.resultData.items.length)
        dispatchRecords({
          type: "setRecords",
          payload: result.data.resultData.items,
        })
      })
      .catch(error => {
        // console.log(error)
      })
  }

  const onSearchKeyChange = _.debounce(async e => {
    // e.persist();
    setSearchKey(e)
    const jwtToken = await getAccessToken(apiurl, "add:asset")
    await getWorksInLast30Days(
      jwtToken,
      metquayUrl,
      metquaySecretKey,
      metquayAssetOwnerKey,
      0,
      isAssetOwner,
      e
    )
      .then(result => {
        setFirst(10)
        setCurrentPage(currentPage - 1)
        setTotalResults(result.data.resultData.items.length)
        dispatchRecords({
          type: "setRecords",
          payload: result.data.resultData.items,
        })
      })
      .catch(error => {
        // console.log(error)
      })
  }, 1000)

  useEffect(() => {
    let assetsCount
    let deData
    const getInitialData = async () => {
      let data
      let decodedPrimaryKey = ""
      let decodedSortKey = ""
      if (
        userMetadata.role.startsWith("Service Provider") ||
        userMetadata.role.startsWith("Asset Owner")
      ) {
        decodedPrimaryKey = decryptKeyValues(userMetadata.pk)
        decodedSortKey = decryptKeyValues(userMetadata.sk)
      }
      let lastkey = null
      const jwtToken = await getAccessToken(apiurl, "add:asset")

      // await getServiceProviderData(jwtToken, metquayUrl, metquaySecretKey, 'works/createdInLast30days', metquayAssetOwnerKey, isAssetOwner).then(result =>{
      //   dispatchRecords({ type: "setRecords", payload: result.data.resultData })
      //   setFirst(first + 10)
      // }).catch(error =>{
      //   // console.log(error)
      //   if(error.response !== undefined){
      //     addToast(error.response.data, {
      //       appearance: "error",
      //       autoDismiss: true,
      //     })
      //   }else{
      //     addToast("Metquay is unavaiable at the moment", {
      //       appearance: "error",
      //       autoDismiss: true,
      //     })
      //   }

      // })
      await getWorksInLast30Days(
        jwtToken,
        metquayUrl,
        metquaySecretKey,
        metquayAssetOwnerKey,
        0,
        isAssetOwner,
        searchKey
      )
        .then(result => {
          setFirst(first + 10)
          // setTotalResults(result.data.resultData.items.length)
          dispatchRecords({
            type: "setRecords",
            payload: result.data.resultData.items,
          })

          setTotalWorks(result.data.resultData.total)
          let noOfPages = result.data.resultData.total / 10
          noOfPages = Math.ceil(noOfPages)
          setCurrentPage(1)
          setLast(noOfPages)
        })
        .catch(error => {
          // console.log(error)
          if (error.response !== undefined) {
            addToast(error.response.data, {
              appearance: "error",
              autoDismiss: true,
            })
          } else {
            addToast("Oops! Metquay is unavailable at the moment!", {
              appearance: "error",
              autoDismiss: true,
            })
          }
        })
    }
    getInitialData()
  }, [])

  return (
    <Card>
      <CardBody>
        <h4 className="card-title mb-3">Live Updates</h4>
        <ToolkitProvider
          bootstrap4
          keyField="id"
          data={records.list}
          columns={liveUpdatesColumns}
          search
          // exportCSV={{ onlyExportFiltered: true, exportAll: false }}
        >
          {props => (
            <React.Fragment>
              <div className="col-md-12 text-end">
                <Row>
                  <div className="col-md-12 text-start">
                    {totalWorks} Work(s) found
                  </div>
                  <Col className="text-end">
                    <div style={{ flex: 1, float: "right", marginRight: "1%" }}>
                      <Input
                        placeholder="Search"
                        type="search"
                        className="mb-2 me-2 "
                        defaultValue={searchKey}
                        onChange={({ target: { value } }) =>
                          onSearchKeyChange(value)
                        }
                      />
                    </div>
                  </Col>
                </Row>
              </div>

              <BootstrapTable
                {...props.baseProps}
                keyField="id"
                bordered={false}
                data={records.list}
                // selectRow={selectRow}
                // pagination={paginationFactory(paginationOptions)}
                wrapperClasses="table-responsive"
              />
            </React.Fragment>
          )}
        </ToolkitProvider>

        <Row>
          <div className="col-md-12 text-end">
            <Button
              className="mb-2 me-2"
              color="primary"
              onClick={getPreviousData}
              disabled={totalResults < 10 || first <= 10}
            >
              Prev
            </Button>

            <Button
              className="mb-2 me-2"
              color="primary"
              onClick={getNextData}
              disabled={totalResults < 10 || currentPage === last}
            >
              Next
            </Button>
          </div>
        </Row>
        <div className="col-md-12">
          {records.list.length} record(s) per page
        </div>
      </CardBody>
    </Card>
  )
}

export default LiveUpdates