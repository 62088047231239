import axios from "axios"
import http from "./httpService"

/**
 * getAssets method is used to fetch assets
 * @param {string} jwtToken
 * @param {string} primaryKey
 * @param {object} lastEvaluatedKey
 * @param {boolean} scannedIndex
 * @param {string} role
 * @param {string} filterCondition
 * @returns list
 */
export async function getAssets(
  jwtToken,
  primaryKey,
  lastEvaluatedKey,
  scannedIndex,
  role,
  // filterCondition,
  searchKey,
  sortKeyValue,
  // isGood, isWarning, isDanger,
) {
  http.setJwt(jwtToken)
  let params = {}
  if (primaryKey === "") {
    params.primaryKey = "ASSETOWNER"
  } else {
    params.primaryKey = primaryKey
  }
  if (lastEvaluatedKey !== null && lastEvaluatedKey !== undefined) {
    params.pk = lastEvaluatedKey.pk
    params.sk = lastEvaluatedKey.sk
    params.type = "ASSET"
  }
  params.scannedIndex = scannedIndex
  params.role = role
  // params.isGood = isGood
  // params.isWarning = isWarning
  // params.isDanger = isDanger
  params.searchKey = searchKey
  params.sortKeyValue = sortKeyValue
  if(role === 'Service Provider'){
    if (lastEvaluatedKey !== null && lastEvaluatedKey !== undefined) {
      params.pk = lastEvaluatedKey.pk
      params.sk = lastEvaluatedKey.sk
      params.type = "ASSET"
      params.serviceProviderId = lastEvaluatedKey.serviceProviderId
    }
    const assets = await http.get(`/assets/getAssetsForServiceProviderLogin`, { params })
    return assets.data
  }else{
    const assets = await http.get(`/assets`, { params })
    return assets.data
  }
  
  
}

/**
 * getAssetsPerSp method is used to fetch all assets under a service provider.
 * @param {string} cancelToken - token generated from axios.
 * @param {string} pk - service provider id
 */
export async function getAssetsWithLimit(
  jwtToken,
  lastEvaluatedKey,
  scannedIndex,
  primaryKeyValue
) {
  http.setJwt(jwtToken)
  let params = {}
  if (primaryKeyValue !== null) {
    params = {
      pk: lastEvaluatedKey.pk,
      sk: lastEvaluatedKey.sk,
      type: "ASSET",
      scannedIndex: scannedIndex,
      primaryKeyValue: primaryKeyValue,
    }
  } else {
    params = {
      pk: lastEvaluatedKey.pk,
      sk: lastEvaluatedKey.sk,
      type: "ASSET",
      scannedIndex: scannedIndex,
    }
  }

  const assets = await http.get(`/assets/nextSetAssets`, {
    params,
  })
  // const data = assets.data.map(item => (item = clean(item)))
  // return data
  return assets.data
}

/**
 * getAssetsPerSp method is used to fetch all assets under a service provider.
 * @param {string} cancelToken - token generated from axios.
 * @param {string} pk - service provider id
 */
export async function getAssetsPerSp(cancelToken, jwtToken, pk) {
  http.setJwt(jwtToken)
  const assets = await http.get(`/assets/perSp`, { params: { pk: pk } })
  // const data = assets.data.map(item => (item = clean(item)))
  return assets.data
  // return data
}

/**
 * getAssetsCount method is used to count the total number of assets.
 * @param {string} cancelToken - token generated from axios.
 */
export async function getAssetsCount( jwtToken, primaryKey) {
  http.setJwt(jwtToken)
  const assetsCount = await http.get(`/assets/count`, { params: { primaryKey: primaryKey } })
  return assetsCount
}

/**
 * getAssetsCount method is used to count the total number of assets.
 * @param {string} cancelToken - token generated from axios.
 */
export async function getAssetsCountById(cancelToken, jwtToken, pk) {
  http.setJwt(jwtToken)
  const assetsCount = await http.get(`/assets/countById`, {
    params: { pk: pk },
  })
  return assetsCount
}

/**
 * getAssetsCountPerMonth method is used to fetch the assets cpunt per month.
 * @param {string} cancelToken - token generated from axios.
 */
export async function getAssetsCountPerMonth(cancelToken, jwtToken) {
  http.setJwt(jwtToken)
  const assetsCount = await http.get(`/assets/permonth`, {
    cancelToken: cancelToken,
  })
  return assetsCount
}

/**
 * getAssetsCountPerMonth method is used to fetch the assets cpunt per month.
 * @param {string} cancelToken - token generated from axios.
 */
export async function getAssetsCountPerMonthById(cancelToken, jwtToken, pk) {
  http.setJwt(jwtToken)
  const assetsCount = await http.get(`/assets/permonthById`, {
    params: { pk: pk },
  })
  return assetsCount
}

export async function addCsvData(data, userData, jwtToken) {
  http.setJwt(jwtToken)
  const assets = await http.post(`/assets/importData`, {
    insertDetails: data,
    userDetails: userData,
  })
  return assets
}

/**
 * addAssetsData method is used to add / update/ delete asset data to db.
 * @param {object} data - data to be added/ updated/ deleted.
 * @param {string} action - action to be performed add/ update/ delete
 * @param {string} jwtToken - jwtToken from auth0
 */
export async function addAssetsData(data, action, jwtToken) {
  const newData = clean(data)
  http.setJwt(jwtToken)
  if (action === "Add") {
    const assets = await http.post(`/assets`, newData)
    return assets
  } else if (action === "Edit") {
    delete newData.isSelected
    const assets = await http.put(`/assets`, newData)
    return assets
  } else if (action === "Delete") {
    const headers = {}
    const assets = await http.delete(
      `/assets/${newData.id}`,
      { data },
      {
        headers,
      }
    )
    return assets
  }
}

/**
 * updateAssetsData method is used to add / update/ delete asset data to db.
 * @param {object} data - data to be added/ updated/ deleted.
 * @param {string} action - action to be performed add/ update/ delete
 * @param {string} jwtToken - jwtToken from auth0
 */
export async function updateAssetsData(data, oldData, jwtToken) {
  const newData = clean(data)
  http.setJwt(jwtToken)
  delete newData.isSelected
  const dataToBeUpdated = {}
  dataToBeUpdated.newData = newData
  dataToBeUpdated.oldData = oldData
  const assets = await http.put(`/assets`, dataToBeUpdated)
  return assets
}

export async function getAssetFields(jwtToken) {
  http.setJwt(jwtToken)
  const assetData = await http.get(`/assets/assetNumber`)
  return assetData.data
}

export async function getAssetFieldsById(jwtToken, pk) {
  http.setJwt(jwtToken)
  http.setJwt(jwtToken)
  const assetData = await http.get(`/assets/assetNumberById`, {
    params: { pk: pk },
  })
  return assetData.data
}

/**
 * getFilteredAssets method is used to fetch assets based on given filter conditions from db
 * @param {string} jwtToken
 * @param {string} primaryKey
 * @param {string} filterCondition
 * @returns
 */
export async function getFilteredAssets(
  jwtToken,
  primaryKey,
  lastEvaluatedKey,
  scannedIndex,
  role,
  filterCondition,
  searchKey,
  sortKeyValue,
  // isGood, isWarning, isDanger,
  limit,
) {
  http.setJwt(jwtToken)
  let params = {}
  if (primaryKey === "") {
    params.primaryKey = "ASSETOWNER"
  } else {
    params.primaryKey = primaryKey
  }
  if (lastEvaluatedKey !== null && lastEvaluatedKey !== undefined) {
    params.pk = lastEvaluatedKey.pk
    params.sk = lastEvaluatedKey.sk
    // params.dueDate = lastEvaluatedKey.dueDate
    params.DueDate = lastEvaluatedKey.DueDate
  }
  params.scannedIndex = scannedIndex
  params.role = role
  // params.isGood = isGood
  // params.isWarning = isWarning
  // params.isDanger = isDanger
  params.filterCondition = filterCondition
  params.searchKey = searchKey
  params.sortKeyValue = sortKeyValue
  params.limit = limit
  let assets = []
  if (primaryKey.startsWith ("SERVICE")) {
     assets = await http.get(`/assets/filteredAssetDataForSP`, { params })
  }else{
     assets = await http.get(`/assets/filteredAssetData`, { params })
  }
  
  return assets.data
}

/**
 * getSearchAssets method is used to fetch assets based on given search key from db
 * @param {string} jwtToken
 * @param {string} primaryKey
 * @param {string} searchKey
 * @returns asset list
 */
export async function getSearchAssets(jwtToken, primaryKey, searchKey) {
  http.setJwt(jwtToken)
  let params = {}
  if (primaryKey === "") {
    params.primaryKey = "ASSETOWNER"
  } else {
    params.primaryKey = primaryKey
  }
  params.searchKey = searchKey
  const assets = await http.get(`/assets/searchAssetData`, { params })
  return assets.data
}

export async function fetchUploadedReportNames( jwtToken, assetNumber, assetOwnerId) {
  http.setJwt(jwtToken)
  const fileDetails = await http.get(`/assets/getReportFileDetails`, { params: { assetNumber: assetNumber, assetOwnerId: assetOwnerId } })
  return fileDetails
}

export async function deleteReportFile( jwtToken, assetNumber, reportNumber, assetOwnerId) {
  http.setJwt(jwtToken)
  const fileDetails = await http.get(`/assets/delete-report-file`, { params: { assetNumber: assetNumber, reportNumber: reportNumber, assetOwnerId: assetOwnerId  } })
  return fileDetails
}

export async function putLocationInAsset( jwtToken, assetNumber,assetOwnerPk , location) {
  http.setJwt(jwtToken)
  const assetLocationObj = await http.post(`/assets/putlocationinasset` , { data: {assetNumber : assetNumber , assetOwnerPk : assetOwnerPk , location:location}})
  return assetLocationObj.data
}

export async function getLocationOfAsset( jwtToken, assetNumber,assetOwnerPk ) {
  http.setJwt(jwtToken)
  const LocationOfAsset = await http.get(`/assets/getLocationOfAsset` ,  { params : {assetNumber : assetNumber , assetOwnerPk : assetOwnerPk}})
  return LocationOfAsset.data
}

export async function getAllLocations( jwtToken , assetOwnerPk){
  http.setJwt(jwtToken)
  const allAssets = await http.get(`/assets/get-locations` , {params : {assetOwnerPk : assetOwnerPk}})
  return allAssets.data
}

export async function getAssetLocation(lat , lng){
  const location = await http.get(`/assets/get-location-string` , {params : {lat : lat , lng : lng}})
  return location.data
}

export async function createRequstForQuote( jwtToken, assetOwnerId,serviceProviderId,name , assets) {
  http.setJwt(jwtToken)
  const result = await http.post(`/assets/create-request` , { data: {assetOwnerId : assetOwnerId , serviceProviderId : serviceProviderId , assets:assets , name:name}})
  return result.data
}

export async function getQuotesForServiceProvider( jwtToken, serviceProviderId ) {
  http.setJwt(jwtToken)
  const quotes = await http.get(`/assets/get-roq-for-serviceProvider` ,  { params : {serviceProviderId : serviceProviderId}})
  return quotes.data
}

export async function getQuotesForAssetOwner(jwtToken  , serviceProviderId, assetOwnerId ) {
  http.setJwt(jwtToken)
  const quotes = await http.get(`/assets/get-roq-for-assetOwner` ,  { params : {serviceProviderId : serviceProviderId , assetOwnerId: assetOwnerId}})
  return quotes.data
}
function clean(obj) {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === ""
    ) {
      delete obj[propName]
    }
  }
  return obj
}
