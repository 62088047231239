import React, { useReducer, useEffect, useState } from "react"
import { Row, Col } from "reactstrap"
import { useAuth0 } from "@auth0/auth0-react"
import axios from "axios"
import _ from "lodash"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Spinner from "../../components/Common/Spinner"
//reducers
import { multiplerecordsreducer } from "./reducer/reducers"
import { metquayWorksreducer } from "./reducer/assetReducer"

//Import columns
import { columns,  assetColumnsForAssetOwnerLogin,} from "./data/asset-fields"

//Import table
import { WorkTable } from "./Components/WorkTable"

//Service
import { getAssetOwner } from "../../services/assetownerService"
import { getWorksFromMetquay } from "../../services/dashboard-services"
import { decryptKeyValues } from "../../services/commonService"
import {getServiceProviderById} from "../../services/serviceProvider"

import { user_metadata } from "../../nameSpaceConfig"

const MetquayWorks = props => {
  const apiurl = process.env.REACT_APP_AUTH0_API_URL
  const { getAccessTokenSilently, getAccessTokenWithPopup, user } = useAuth0()
  const userMetadata = user[user_metadata]
  const [assetCount, setAssetCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [records, dispatchRecords] = useReducer(metquayWorksreducer, {
    list: [],
  })
  const [selectRow, setSelectRow] = useState([])
  const [multipleRecords, dispatchMultipleRecords] = useReducer(
    multiplerecordsreducer,
    { list: [] }
  )
  const [searchKey, setSearchKey] = useState("")
  const [count, setCount] = useState({})
  const [metquayAssetOwnerKey, setMetquayAssetOwnerKey] = useState(" ")
  const [metquayUrl, setMetquayUrl] = useState(" ")
  const [metquaySecretKey, setMetquaySecretKey] = useState(" ")
  const [first, setFirst] = useState(0) 
  const [last, setLast] = useState(50)
  const [currentPage, setCurrentPage]=useState(0)
  const [totalWorks, setTotalWorks] = useState(0)
  const [totalResults, setTotalResults] = useState(50)
  const [enableOnlineTutorials, setEnableOnlineTutorial] = useState(false)
  const [prevPage, setPrevPage] = useState(0)

  const onToggleUserGuide = rows => {
    setEnableOnlineTutorial(!enableOnlineTutorials)
  }

  /**
   * getAccessToken method is used to fecth jwt access token from auth0
   * @param {string} audience - the api URL
   * @param {string} claim - the permission
   */
  const getAccessToken = async (audience, claim) => {
    let token
    try {
      token = await getAccessTokenSilently({
        audience,
        claim,
      })
    } catch (exception) {
      token = await getAccessTokenWithPopup({ audience, claim })
    }
    return token
  }


  /**
   * selectedRow method is used to set the values of selected row to selectRow.
   * @param {object} row -  selected row data
   * @param {boolean} isSelected -  isSelected flag
   */
  const selectedRow = (row, isSelected) => {
    if (isSelected) {
      setSelectRow(row)
    } else {
      setSelectRow([])
    }
  }

  /**
   * multipleRows method is used when all rows are selected.
   * @param {list} rows - list of selected rows
   */
  const multipleRows = rows => {
    rows.map(row => {
      selectedRow(row, row.isSelected)
    })
    dispatchMultipleRecords(rows)
  }

  const getPreviousData = async (e) => {
    const jwtToken = await getAccessToken(apiurl, "add:asset")
    // await getWorksInLast30Days(jwtToken, metquayUrl, metquaySecretKey, metquayAssetOwnerKey,first, isAssetOwner, searchKey).then(result =>{
    //   dispatchRecords({ type: "setRecords", payload: result.data.resultData.items })
    //   setFirst(first - 50)
    // }).catch(error =>{
    //   // console.log(error)
    // })

    await getWorksFromMetquay(jwtToken, metquayUrl,metquaySecretKey,metquayAssetOwnerKey, prevPage,userMetadata.role.startsWith("Asset Owner")? true : false, searchKey )
      .then(result => {
        setFirst(prevPage + 50)
        if(prevPage === 0){
          setPrevPage( prevPage)
        } else{
          setPrevPage( prevPage - 50)
        }
        
        setCurrentPage(currentPage - 1)
        dispatchRecords({ type: "setRecords", payload: result.data.resultData.items })
      })
      .catch(error => {
        // console.log(error)
      })
  }

  const getNextData = async (e) => {
    const jwtToken = await getAccessToken(apiurl, "add:asset")
    // await getWorksInLast30Days(jwtToken, metquayUrl, metquaySecretKey, metquayAssetOwnerKey,first, isAssetOwner, searchKey).then(result =>{
    //   dispatchRecords({ type: "setRecords", payload: result.data.resultData.items })
    //   setFirst(first + 50)
    // }).catch(error =>{
    //   // console.log(error)
    // })

    await getWorksFromMetquay(jwtToken, metquayUrl,metquaySecretKey,metquayAssetOwnerKey, first,userMetadata.role.startsWith("Asset Owner")? true : false, searchKey )
      .then(result => {
        setFirst(first + 50)
        setPrevPage(first - 50)
        setCurrentPage(currentPage + 1)
        dispatchRecords({ type: "setRecords", payload: result.data.resultData.items })
      })
      .catch(error => {
        // console.log(error)
      })
  }

  

  const onSearchKeyChange = _.debounce(async e => {
    // e.persist();
    setSearchKey(e)
    const jwtToken = await getAccessToken(apiurl, "add:asset")
    await getWorksFromMetquay(jwtToken, metquayUrl,metquaySecretKey,metquayAssetOwnerKey, 0,userMetadata.role.startsWith("Asset Owner")? true : false, e )
      .then(result => {
        // setFirst(first + 50)
        setFirst(50)
        setCurrentPage(currentPage - 1)
        setTotalResults(result.data.resultData.items.length)
        dispatchRecords({ type: "setRecords", payload: result.data.resultData.items })
      })
      .catch(error => {
        // console.log(error)
      })
  
    

  }, 1000)


  /**
   * getAssetRecords method is used to load asset records.
   */
  const getWorkRecords = async searchKey => {
    let serviceProviderKey = ""
    let assetOwnerKey = ""
    let url = ""
    const jwtToken = await getAccessToken(apiurl, "add:asset")
    if (userMetadata.role.startsWith("Asset Owner")) {
      const decodedPrimaryKey = decryptKeyValues(userMetadata.pk)
      await getAssetOwner(jwtToken, null, decodedPrimaryKey)
            .then(result => {
              serviceProviderKey = result[0].attributes.metquaySecretKey
              assetOwnerKey = result[0].attributes.metquayAssetOwnerKey
              url = result[0].attributes.metquayurl
              setMetquaySecretKey(result[0].attributes.metquaySecretKey)
              setMetquayUrl(result[0].attributes.metquayurl)
              setMetquayAssetOwnerKey(result[0].attributes.metquayAssetOwnerKey)
            })
            .catch(error => {
            })
    }else if (userMetadata.role.startsWith("Service Provider")) {
      const decodedPrimaryKey = decryptKeyValues(userMetadata.pk)
      // const jwtToken = await getAccessToken(apiurl, "add:asset")
      await getServiceProviderById(jwtToken, "", decodedPrimaryKey)
      .then(result => {
        serviceProviderKey = result[0].attributes.metquaySecretKey
              assetOwnerKey = result[0].attributes.metquayAssetOwnerKey
              url = result[0].attributes.metquayurl
              setMetquaySecretKey(result[0].attributes.metquaySecretKey)
              setMetquayUrl(result[0].attributes.metquayurl)
              setMetquayAssetOwnerKey(result[0].attributes.metquayAssetOwnerKey)
      })
      .catch(error => {
      })
    }
    if(assetOwnerKey === undefined || assetOwnerKey=== null){
      assetOwnerKey=''
      setMetquayAssetOwnerKey('')
    }
    await getWorksFromMetquay(jwtToken, url,serviceProviderKey,assetOwnerKey, 0,userMetadata.role.startsWith("Asset Owner")? true : false, searchKey )
      .then(result => {
        setFirst(first + 50)
        dispatchRecords({ type: "setRecords", payload: result.data.resultData.items })
        setTotalWorks(result.data.resultData.total)
        let noOfPages = result.data.resultData.total / 50
        // if(result.data.resultData.total % 50 > 0){
        //   noOfPages = noOfPages + 1
        // }
        noOfPages = Math.ceil(noOfPages)
        setCurrentPage(1)
        setLast(noOfPages)
      })
      .catch(error => {
        // console.log(error)
      })
  }

  useEffect(() => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    /**
     * getInitialData method is used to fetch all the records from db and display in table.
     */
    const getInitialData = async () => {
      try {
        setLoading(true)
        getWorkRecords()
        setLoading(false)
      } catch (error) {
        if (axios.isCancel(error)) {
        } else {
          throw error
        }
      }
    }
    getInitialData()
    return () => {
      source.cancel()
    }
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Assets Management"
            breadcrumbItem="Works"
          />

          <Row>
            <div className="col-md-12 text-start">
              {totalWorks} Work(s) found
            </div>
          </Row>
          <Row>
            <Col>
              {loading ? (
                <Spinner />
              ) : (
                <WorkTable
                  columnsCustomer={
                    userMetadata.role.startsWith("Asset Owner")
                      ? assetColumnsForAssetOwnerLogin
                      : columns
                  }
                  records={records.list}
                  // multipleRows={multipleRows}
                  // selectedRow={selectedRow}
                  getNextAssetData={getNextData}
                  getPreviousAssetData={getPreviousData}
                  onSearchKeyChange={onSearchKeyChange}
                  userData={userMetadata}
                  firstPage={first}
                  lastPage={last}
                  currentPage={currentPage}
                  // firstKey={firstKey}
                  // lastEvaluatedKey={lastEvaluatedKey}
                  // onFilter={onFilter}
                  // isGood={goodToggle}
                  // isWarning={warningToggle}
                  // isDanger={dangerToggle}
                  count={count}
                  searchKey={searchKey}
                  totalResults={totalResults}
                ></WorkTable>
              )}
            </Col>
          </Row>
        </div>
      </div>

    </React.Fragment>
  )
}

export default MetquayWorks