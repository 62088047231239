import React from "react"
import QRCodeCanvas from 'qrcode.react';
import AWS from "aws-sdk"
import { Col } from "reactstrap"
import html2pdf from 'html2pdf.js';
import QRCode from "react-qr-code";


export const qrCodeColumnFormatter = (cell, row) => {
  return <QRCodeGenerator row={row}></QRCodeGenerator>
}

/**
 * QRCodeGenerator to generate QR code from asset data
 * @param {object} props 
 * @returns QRCode
 */
export const QRCodeGenerator = props => {
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
  })
  const { row } = props
  let objectUrl = ''
  let reportNumber = row.reportNumber
  if(row.barcode !== undefined && row.barcode !== null){
    // reportNumber = row.reportUrl
    // objectUrl = reportUrl
    reportNumber ='Report 1'
    objectUrl = 'https://tracefii-report-upload-bucket-test.s3.amazonaws.com/tracefii-asset-management/ASSETOWNERID%238c7107f9-8911-412b-8c5c-46869b4b2325/100210-2403261/2542.pdf'
  }else if(row.pk !== undefined && row.pk !== null){
    if(row.reportNumber !== undefined && row.reportNumber !== null){
      reportNumber = row.reportNumber.replaceAll("/", "*-*")
    }
    const params = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key:
      // process.env.REACT_APP_DIR_NAME + "/" + row.pk + "/" +row.assetNumber + "/" + row.reportNumber,
      process.env.REACT_APP_DIR_NAME + "/" + row.pk + "/" +row['Asset#'] + "/" + reportNumber,
    }
    const s3 = new AWS.S3()
      var s3url = s3.getSignedUrl('getObject',  params);
      
      // objectUrl = s3url.split("?AWSAccessKeyId")[0] + '.pdf'
      objectUrl = s3url.split("?AWSAccessKeyId")[0]
      if(! objectUrl.endsWith(".pdf")){
        objectUrl = s3url.split("?AWSAccessKeyId")[0] + '.pdf'
      }
  }
 const objectUrl2 = objectUrl.split(".pdf")
  
  objectUrl=objectUrl.split('"').join('');

  return (
    <React.Fragment>
    {
      reportNumber === undefined ? '':
      <Col>
      
    {/* <QRCode value={(objectUrl)} />
    <p > <a href={objectUrl}  className="btn btn-primary waves-effect waves-light btn-sm" target="_blank" rel="noopener noreferrer">
    View report
     className="e-link" 
  </a></p> */}

<QRCodeCanvas value={(objectUrl)} />
    <p > <a href={objectUrl} style={{  marginTop: "1%" }} target="_blank" rel="noopener noreferrer">
    View report
  </a></p>

  

    
  </Col>
    }
    </React.Fragment>
  
  )
}



/**
 * QRCodeGenerator to generate QR code from asset data
 * @param {object} props 
 * @returns QRCode
 */
export const QRCodeGeneratorForWorks = props => {
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
  })
  // const { row } = props
  return (
    <React.Fragment>
      
      {
      (props === undefined || props === null || props === "" )? '': 
      <Col>
      <QRCodeCanvas value={(encodeURI(props))} />
      {/* <p><a href={props} style={{  marginTop: "1%" }} className="btn btn-primary waves-effect waves-light btn-sm" target="_blank" rel="noopener noreferrer">
    View report
  </a></p> */}
      <p><a href={props} style={{  marginTop: "1%" }} target="_blank" rel="noopener noreferrer">
    View report
  </a></p>
      </Col>

    }
    
    </React.Fragment>
  
  )
}

const handleQRDownload = (e , qrValue)=>{
  const element = document.getElementById('qr-code'); 
  const options = {
    margin:[0,0,0,0],
    filename: `${qrValue.assetNo}.pdf`,
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: 'in', format: 'c9', orientation: 'landscape' , moveTo: (15 , 20) },
    output : {
      compress: false,
      precision: 6,
      orientation: 'portrait',
      unit: 'in',
      format: 'a2',
      fontSize: 12,
      lineHeight: 1.2,
      useCORS: true,
      moveTo : moveTo(15 , 21)
    }
  };
  html2pdf().from(element).set(options).save();
}

export const demoQrCode = (cell ,row)=>{
  const qrValue = {
    assetNo : row["Asset#"],
    pk : row['pk'],
  }
  return (<>
  <div style={{height: "auto", maxWidth: 48, margin :"-1.5rem auto" ,width: "100%"}}>
  <div style={{ height: "auto", margin: "1.5rem auto", maxWidth: 96, width: "100%" }} onClick={e => handleQRDownload(e , qrValue)} id="qr-code">
    <QRCode
      size={1024}
      style={{ height: "auto", maxWidth: "100%", width: "100%" }}
      value={JSON.stringify(qrValue)}
      viewBox={`0 0 1024 1024`}
    />
  </div>
  </div>
  </>)
}
