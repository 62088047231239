import React  from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

export const CancelSubscriptionModal = (props) => {
  const { cancelSubscriptionForUser,  open, toggle } = props;

  /** 
   * submitData method is called when ok button is clicked in modal.
   * @param {object} e -  click event
  */
  const submitData = (e) => {
    e.preventDefault();
    cancelSubscriptionForUser();
    toggle();
  };

  return (
    <React.Fragment>
      <Modal isOpen={open}>
        <ModalHeader toggle={toggle}>Cancel Subscription</ModalHeader>
        <ModalBody>
          <p>Are you sure you want to cancel the subscription?</p>
        </ModalBody>
        <ModalFooter>
          <div className="form-group text-right">
            <button
              className="btn btn-danger m-2"
              type="submit"
              onClick={submitData}
            >
              Yes
            </button>
            <button className="btn btn-primary" onClick={toggle}>
              No
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};
