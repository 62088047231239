import AWS from 'aws-sdk';

AWS.config.update({
    accessKeyId: process.env.REACT_APP_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
  })
var QUEUE_URL;
var sqs = new AWS.SQS({region: "us-east-1"});

process.env.REACT_APP_NODE_ENV === 'production' ? QUEUE_URL = process.env.REACT_APP_SQSRESENDINVITATION_PROD : QUEUE_URL = process.env.REACT_APP_SQSRESENDINVITATION

const chunkSize = 1;

export async function sendSQSInvitations(assetOwners , jwtToken , serviceProviderName) {
    for (let i = 0; i < assetOwners.length; i += chunkSize) {
        const chunk = assetOwners.slice(i, i + chunkSize);
        var newMessage = {
            chunk: chunk,
            token : jwtToken,
            serviceProviderName : serviceProviderName,
        }
        console.log(newMessage);
        const params = {
            QueueUrl: QUEUE_URL,
            MessageBody: JSON.stringify(newMessage)
        };
        await sqsMessage(params)
    }
}

async function sqsMessage(params){
    const result = await sqs.sendMessage(params, (err, data) => {
        if (err) {
          console.log("Could Not perform operation")
            // Handle error
            console.log(err)
           
        } else {
            console.log('Message sent successfully.');
            // console.log(params);
            // console.log(data)
        }
    });
    return result
}