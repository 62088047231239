import React, { useState, useReducer, useEffect } from "react"
import { Input, Label, InputGroup, Row } from "reactstrap"

import axios from "axios"
import { useToasts } from "react-toast-notifications"
import Dropdown from "react-dropdown"
import { useAuth0 } from "@auth0/auth0-react"

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { dataReducer } from "../reducer/reducers"

import { DynamicFieldsModal } from "../PreferenceComponents/DynamicFieldsModal"

import {
  categoryValues,
  manufacturerValues,
  modelValues,
  // totalColumns,
} from "../data/asset-fields"

import "../css/modal-style.css"
import "../css/modal-style.css"
import "react-dropdown/style.css"
import { getServiceProviderNames } from "../../../services/serviceProvider"
import { getAssetOwnerNames } from "../../../services/assetownerService"
import { getUserNamesById } from "../../../services/userService"
import { decryptKeyValues } from "../../../services/commonService"

const activeStatus = ["Active", "Inactive"]
const serviceTypeDropdownValues = ["Calibration", "PM", "Calibration & PM"]

export const AssetDetails = props => {
  const apiurl = process.env.REACT_APP_AUTH0_API_URL
  const { getAccessTokenSilently, getAccessTokenWithPopup, user } = useAuth0()
  const { addToast } = useToasts()
  const [count, setCount] = useState(0)
  const [data, dispatchData] = useReducer(dataReducer, [])
  const [serviceDate, setLastCalDate] = useState(new Date())
  const [dueDate, setCalDueDate] = useState(
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() + 1
    )
  )
  const [assetOwnerData, setAssetOwners] = useState([])
  const [serviceProviderData, setServiceProviders] = useState([])
  const [ownerData, setOwnerData] = useState([])
  const [spKeyValueMap, setSpKeyValueMap] = useState({})
  const [aoKeyValueMap, setAoKeyValueMap] = useState({})
  const [spName, setSpNameMap] = useState({})
  const [aoName, setAoNameMap] = useState({})
  const [aoAddress, setAoAddressMap] = useState({})
  const [spAddress, setSpAddressMap] = useState({})

  const {
    updateRecords,
    action,
    selectedRow,
    toggle,
    componentType,
    userMetadata,
    totalFieldsForModal,
  } = props

  /**
   * getAccessToken method is used to fecth jwt access token from auth0
   * @param {string} audience - the api URL
   * @param {string} claim - the permission
   */
  const getAccessToken = async (audience, claim) => {
    let token
    try {
      token = await getAccessTokenSilently({
        audience,
        claim,
      })
    } catch (exception) {
      token = await getAccessTokenWithPopup({ audience, claim })
    }
    return token
  }

  /**
   * setDataForEdit method is used to populate the modal with selected row values
   */
  const setDataForEdit = () => {
    if (action === "Edit" && count === 0) {
      dispatchData({ type: "setRecords", data: selectedRow })
      setCount(count + 1)
    }
  }

  /**
   * to display toaster
   * @param {string} message
   * @param {string} type
   */
  const createToaster = (message, type) => {
    addToast(message, {
      appearance: type,
      autoDismiss: true,
    })
  }

  /**
   * onChange method is used to map the data to the field on changing the value in modal.
   * @param {object} e - data
   * @param {string} header - field header
   * @param {string} type - type of the field changed
   */
  const onChange = (e, header, type) => {
    setDataForEdit()
    if (type === "date") {
      if (header === "ServicedDate") {
        const selectedDate = new Date(e)
        const month = ("0" + (selectedDate.getMonth() + 1)).slice(-2)
        const day = ("0" + selectedDate.getDate()).slice(-2)
        const dateInYearMonthDayFormat = [
          selectedDate.getFullYear(),
          month,
          day,
        ].join("-")
        // setLastCalDate(dateInYearMonthDayFormat)
        setLastCalDate(new Date(dateInYearMonthDayFormat))
        setCalDueDate(new Date(dateInYearMonthDayFormat))
      } else if (header === "DueDate") {
        setCalDueDate(e)
      }
      dispatchData({
        type: "setDataToModalonChange",
        data: { id: header, value: e },
      })
    } else {
      dispatchData({
        type: "setDataToModalonChange",
        data: { id: e.target.id, value: e.target.value },
      })
    }
  }

  /**
   * dropDownChange function is called when servicetype and status dropdown component value is changed
   * @param {event} e
   * @param {string} dataField
   */
  const dropDownChange = async (e, dataField) => {
    setDataForEdit()
    dispatchData({
      type: "setDataToModalonChange",
      data: { id: dataField, value: e.value },
    })
  }

  /**
   * getKey method is used to get the key value from map
   * @param {string} val
   * @param {map} map
   * @returns string
   */
  const getKey = (val, map) => {
    return Object.keys(map).find(key => map[key] === val)
  }

  /**
   * cancelData function is called when cancel button in the modal is clicked
   * @param {event} e
   */
  const cancelData = e => {
    e.preventDefault()
    setCount(0)
    setLastCalDate(new Date())
    setCalDueDate(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() + 1
      )
    )
    toggle()
  }

  /**
   * submitData method is called when we click the ok button in modal.
   * @param {object} e
   */
  const submitData = e => {
    e.preventDefault()
    data["mode"] = action
    if (componentType === "userComponent") {
      data["componentType"] = "user"
    }
    if (userMetadata.role.startsWith("Asset Owner")) {
      data["assetOwnerId"] = decryptKeyValues(userMetadata.pk)
      if (data["assetOwnerId"].startsWith("USER")) {
        const sortKeyValue = decryptKeyValues(userMetadata.sk)
        data["sortKeyValue"] = sortKeyValue
        const keyList = sortKeyValue.split("#")
        for (var i = 0; i < keyList.length; i++) {
          if (keyList[i] === "ASSETOWNERID") {
            data["assetOwnerId"] = keyList[i] + "#" + keyList[i + 1]
          }
        }
      }
      data["assetOwnerName"] = aoName[data["assetOwnerId"]]
      data["assetOwnerDetails"] = aoAddress[data["assetOwnerId"]]
    } else if (userMetadata.role.startsWith("Service Provider")) {
      data["serviceProviderId"] = decryptKeyValues(userMetadata.pk)
      data["serviceProviderName"] = spName[data["assetOwnerId"]]
      data["spDetails"] = spAddress[data["assetOwnerId"]]
    }

    setLastCalDate(new Date())
    setCalDueDate(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() + 1
      )
    )
    updateRecords(data)
    toggle()
    setCount(0)
  }

  /**
   * setDataForField function is used to set the data for the corresponding field
   * @param {string} field
   * @param {map} dataMap
   * @param {string} key
   */
  const setDataForField = (field, dataMap, key) => {
    dispatchData({
      type: "setDataToModalonChange",
      data: { id: field, value: dataMap[key] },
    })
  }

  /**
   * accountChange function is called when service provider and asset owner component value is changed
   * @param {event} e
   * @param {string} dataField
   */
  const accountChange = (e, dataField) => {
    setDataForEdit()
    if (dataField === "ownerName") {
      dispatchData({
        type: "setDataToModalonChange",
        data: { id: "ownerName", value: e.target.value },
      })
    } else if (dataField === "serviceProviderName") {
      const key = getKey(e.target.value, spKeyValueMap)
      if (key !== undefined && key !== null) {
        data["serviceProviderId"] = key
        setDataForField(dataField, spName, key)
        setDataForField("spDetails", spAddress, key)
      } else {
        data["serviceProviderName"] = e.target.value
        // setDataForField(dataField, e.target.value, key)
        // setDataForField("spDetails", spAddress, key)
      }

    } else if (dataField === "assetOwnerName") {
      const key = getKey(e.target.value, aoKeyValueMap)
      data["assetOwnerId"] = key
      setDataForField(dataField, aoName, key)
      setDataForField("assetOwnerDetails", aoAddress, key)

    }
  }

  useEffect(() => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    dispatchData({
      type: "setDataToModalonChange",
      data: { id: "status", value: "Active" },
    })
    dispatchData({
      type: "setDataToModalonChange",
      data: { id: "serviceType", value: "Calibration" },
    })
    const currentDate = new Date()
    const month = ("0" + (currentDate.getMonth() + 1)).slice(-2)
    const day = ("0" + currentDate.getDate()).slice(-2)
    const dateInYearMonthDayFormat = [
      currentDate.getFullYear(),
      month,
      day,
    ].join("-")
    // setLastCalDate(dateInYearMonthDayFormat)
    setLastCalDate(new Date(dateInYearMonthDayFormat))
    setCalDueDate(new Date(dateInYearMonthDayFormat))
    dispatchData({
      type: "setDataToModalonChange",
      data: { id: "ServicedDate", value: currentDate },
    })
    dispatchData({
      type: "setDataToModalonChange",
      data: { id: "DueDate", value: currentDate },
    })
    /**
     * getInitialData method is used to fetch all the records from db and display in table.
     */
    const getInitialData = async () => {
      try {
        const jwtToken = await getAccessToken(apiurl, "add:asset")
        // const pk = CryptoJS.enc.Base64.parse(userMetadata.pk).toString(
        //   CryptoJS.enc.Utf8
        // )
        await getServiceProviderNames(jwtToken)
          .then(result => {
            setServiceProviders(result.names)
            setSpKeyValueMap(result.values)
            setSpNameMap(result.nameofCustomer)
            setSpAddressMap(result.address)
          })
          .catch(error => {
            createToaster(
              "Error occurred while loading service provider data",
              "error"
            )
          })

        await getAssetOwnerNames(jwtToken)
          .then(result => {
            setAssetOwners(result.names)
            setAoKeyValueMap(result.values)
            setAoNameMap(result.nameofCustomer)
            setAoAddressMap(result.address)
          })
          .catch(error => {
            createToaster(
              "Error occurred while loading asset owner data",
              "error"
            )
          })

        if (
          userMetadata.role.startsWith("Service Provider") ||
          userMetadata.role.startsWith("Asset Owner")
        ) {
          const pk = decryptKeyValues(userMetadata.pk)
          // decodedSortKey = decryptKeyValues(userMetadata.sk)
          await getUserNamesById(jwtToken, pk)
            .then(result => {
              setOwnerData(result.data)
            })
            .catch(error => { })
        }
      } catch (error) {
        if (axios.isCancel(error)) {
        } else {
          throw error
        }
      }
    }
    getInitialData()
    return () => {
      source.cancel()
    }
  }, [])

  return (
    <React.Fragment>
      <form className="pl-3 pr-3 " onSubmit={submitData}>
        {
          totalFieldsForModal.length > 0 ?
            <DynamicFieldsModal totalFieldsForModal={totalFieldsForModal} userMetadata={userMetadata}
              toggle={toggle} updateRecords={updateRecords} action={action} selectedRow={selectedRow}/> :
            <div>
              <div className="row">
                {userMetadata.role.startsWith("Service Provider") ? (
                  <div className={"col form-group"}>
                    <label htmlFor="assetOwnerName"> Asset Owner </label>
                    <Input
                      className="form-control"
                      list="assetOwnerNames"
                      name="assetOwnerName"
                      id="assetOwnerName"
                      autoComplete="off"
                      defaultValue={
                        action === "Add" ? "" : selectedRow["assetOwnerName"]
                      }
                      onChange={e => accountChange(e, "assetOwnerName")}
                    />
                    <datalist id="assetOwnerNames">
                      {assetOwnerData.map(item => (
                        <option value={item} />
                      ))}
                    </datalist>
                  </div>
                ) : (
                  ""
                )}
                {userMetadata.role.startsWith("Asset Owner") ? (
                  <div className={"col form-group"}>
                    <label htmlFor="serviceProviderName"> Service Provider </label>
                    <Input
                      className="form-control"
                      list="serviceProviderNames"
                      name="serviceProviderName"
                      id="serviceProviderName"
                      autoComplete="off"
                      defaultValue={
                        action === "Add" ? "" : selectedRow["serviceProviderName"]
                      }
                      onChange={e => accountChange(e, "serviceProviderName")}
                    />
                    <datalist id="serviceProviderNames">
                      {serviceProviderData.map(item => (
                        <option value={item} />
                      ))}
                    </datalist>
                  </div>
                ) : (
                  ""
                )}
                <div className={" col form-group "}>
                  <label htmlFor={"Asset#"}>
                    Asset#<span className="required-field"></span>
                  </label>
                  <Input
                    id={"Asset#"}
                    name={"Asset#"}
                    className="form-control"
                    defaultValue={action === "Add" ? "" : selectedRow["assetNumber"]}
                    onChange={onChange}
                    maxlength={15}
                    required={true}
                    data-error="This is a required field."
                  ></Input>
                </div>
                <div className={"col form-group "}>
                  <label htmlFor={"Serial#"}> Serial# </label>
                  <Input
                    id={"Serial#"}
                    name={"Serial#"}
                    className="form-control"
                    defaultValue={action === "Add" ? "" : selectedRow["Serial#"]}
                    onChange={onChange}
                    maxlength={15}
                  ></Input>
                </div>
              </div>
              <div className="row">
                <div className={"col form-group"} style={{ marginTop: "1%" }}>
                  <label htmlFor="Category"> Category </label>
                  <Input
                    className="form-control"
                    list="categories"
                    name="categories"
                    id="Category"
                    autoComplete="off"
                    defaultValue={action === "Add" ? "" : selectedRow["Category"]}
                    onChange={onChange}
                  />
                  <datalist id="categories">
                    {categoryValues.map(item => (
                      <option value={item} />
                    ))}
                  </datalist>
                </div>
                <div className={"col form-group"} style={{ marginTop: "1%" }}>
                  <label htmlFor="Make"> Make </label>
                  <Input
                    className="form-control"
                    list="Makes"
                    name="Makes"
                    id="Make"
                    autoComplete="off"
                    defaultValue={action === "Add" ? "" : selectedRow["Make"]}
                    onChange={onChange}
                  />
                  <datalist id="Makes">
                    {manufacturerValues.map(item => (
                      <option value={item} />
                    ))}
                  </datalist>
                </div>
                <div className={"col form-group"} style={{ marginTop: "1%" }}>
                  <label htmlFor="Model">Model</label>
                  <Input
                    className="form-control"
                    list="Models"
                    name="Models"
                    id="Model"
                    autoComplete="off"
                    defaultValue={action === "Add" ? "" : selectedRow["Model"]}
                    onChange={onChange}
                  />
                  <datalist id="Models">
                    {modelValues.map(item => (
                      <option value={item} />
                    ))}
                  </datalist>
                </div>
              </div>
              <div className="row">
                <div className={"col form-group "} style={{ marginTop: "1%" }}>
                  <label htmlFor={"Description"}> Description </label>
                  <Input
                    id={"Description"}
                    name={"Description"}
                    className="form-control"
                    defaultValue={action === "Add" ? "" : selectedRow["Description"]}
                    onChange={onChange}
                    maxlength={50}
                    data-error="This is a required field."
                  ></Input>
                </div>
                <div className={"col form-group "} style={{ marginTop: "1%" }}>
                  <Label>Serviced Date</Label>
                  <InputGroup>
                    <Flatpickr
                      className="form-control d-block"
                      onChange={date => onChange(date, "ServicedDate", "date")}
                      options={{
                        altInput: true,
                        altFormat: "F j, Y",
                        dateFormat: "Y-m-d",
                        maxDate: new Date(),
                        defaultDate:
                          action === "Add"
                            ? new Date(serviceDate)
                            : new Date(selectedRow["ServicedDate"]),
                      }}
                    />
                  </InputGroup>
                </div>
                <div className={"col form-group"} style={{ marginTop: "1%" }}>
                  <Label>Due Date</Label>
                  <InputGroup>
                    <Flatpickr
                      className="form-control d-block"
                      onChange={date => onChange(date, "DueDate", "date")}
                      options={{
                        altInput: true,
                        altFormat: "F j, Y",
                        dateFormat: "Y-m-d",
                        // minDate: new Date(ServicedDate),
                        minDate: action === "Add"
                          ? new Date(serviceDate)
                          : new Date(selectedRow["ServicedDate"]),
                        defaultDate:
                          action === "Add"
                            ? new Date(serviceDate)
                            : new Date(selectedRow["DueDate"]),
                      }}
                    />
                  </InputGroup>
                </div>
              </div>
              <div className="row">
                <div className={"col form-group "} style={{ marginTop: "1%" }}>
                  <label htmlFor={"Location"}> Location </label>
                  <Input
                    id={"Location"}
                    name={"Location"}
                    className="form-control"
                    defaultValue={action === "Add" ? "" : selectedRow["location"]}
                    onChange={onChange}
                    maxlength={50}
                    data-error="This is a required field."
                  ></Input>
                </div>
                <div className={"col form-group "} style={{ marginTop: "1%" }}>
                  <label htmlFor={"OEMAccuracy"}> OEM Accuracy </label>
                  <Input
                    id={"OEMAccuracy"}
                    name={"OEMAccuracy"}
                    className="form-control"
                    defaultValue={action === "Add" ? "" : selectedRow["OEMAccuracy"]}
                    onChange={onChange}
                  ></Input>
                </div>
                <div className={"col form-group "} style={{ marginTop: "1%" }}>
                  <label htmlFor={"Range"}> Range </label>
                  <Input
                    id={"Range"}
                    name={"Range"}
                    className="form-control"
                    defaultValue={action === "Add" ? "" : selectedRow["Range"]}
                    onChange={onChange}
                    maxlength={50}
                    data-error="This is a required field."
                  ></Input>
                </div>
              </div>
              <div className="row">
                <div className={"col form-group"} style={{ marginTop: "1%" }}>
                  <label htmlFor={"UnitofMeasurement"}>Unit of Measurement</label>
                  <Input
                    id={"UnitofMeasurement"}
                    name={"UnitofMeasurement"}
                    className="form-control"
                    defaultValue={action === "Add" ? "" : selectedRow["UnitofMeasurement"]}
                    onChange={onChange}
                  ></Input>
                </div>
                <div className={"col form-group "} style={{ marginTop: "1%" }}>
                  <label htmlFor={"ServiceFrequency(Days)"}>
                    Service Frequency (Days)
                  </label>
                  <Input
                    id={"ServiceFrequency(Days)"}
                    type="number"
                    name={"ServiceFrequency(Days)"}
                    className="form-control"
                    defaultValue={
                      action === "Add" ? "" : selectedRow["ServiceFrequency(Days)"]
                    }
                    onChange={onChange}
                    maxlength={50}
                    data-error="This is a required field."
                  ></Input>
                </div>
                <div className={"col form-group"} style={{ marginTop: "1%" }}>
                  <label htmlFor="Owner">Owner </label>
                  <Input
                    className="form-control"
                    list="Owners"
                    name="Owner"
                    id="Owner"
                    autoComplete="off"
                    defaultValue={action === "Add" ? "" : selectedRow["Owner"]}
                    onChange={e => accountChange(e, "Owner")}
                  />
                  <datalist id="Owners">
                    {ownerData.map(item => (
                      <option value={item} />
                    ))}
                  </datalist>
                </div>


              </div>

              <div className="row">
                <div className={"col form-group"} style={{ marginTop: "1%" }}>
                  <label htmlFor={"ServiceType"}>Service Type</label>
                  <Dropdown
                    id={"ServiceType"}
                    options={serviceTypeDropdownValues}
                    value={
                      action === "Add"
                        ? serviceTypeDropdownValues[0]
                        : selectedRow["ServiceType"]
                    }
                    onChange={e => dropDownChange(e, "ServiceType")}
                  />
                </div>
                <div className={"col form-group"} style={{ marginTop: "1%" }}>
                  <label htmlFor={"StatusValue"}>Status</label>
                  <Dropdown
                    id={"StatusValue"}
                    options={activeStatus}
                    value={activeStatus[0]}
                    onChange={e => dropDownChange(e, "StatusValue")}
                  />
                </div>
                <div className={"col form-group"} style={{ marginTop: "1%" }}>
                  <label htmlFor={"Est.CalibrationCost"}>Est. Calibration Cost</label>
                  <span class="input-group-addon">$</span>
                  <input type="number" id={"Est.CalibrationCost"}
                    name={"Est.CalibrationCost"} min="0" 
                    step="0.01" data-number-to-fixed="2" 
                    data-number-stepfactor="100" class="form-control currency" 
                    onChange={onChange} 
                    defaultValue={action === "Add" ? "" : selectedRow["Est.CalibrationCost"]}/>
                </div>
              </div>
              <div class="row">
                <div className={"col form-group"} style={{ marginTop: "1%" }}>
                  <label htmlFor={"Est.AMC"}>Est. AMC</label>
                  <span class="input-group-addon">$</span>
                  <input type="number" id={"Est.AMC"}
                    name={"Est.AMC"} min="0" 
                    step="0.01" data-number-to-fixed="2" 
                    data-number-stepfactor="100" class="form-control currency" 
                    onChange={onChange} 
                    defaultValue={action === "Add" ? "" : selectedRow["Est.AMC"]}/>
                </div>
                <div className={"col form-group"} style={{ marginTop: "1%" }}>
                  <label htmlFor={"Remarks"}>Remarks</label>
                  <textarea id={"Remarks"}
                    name={"Remarks"} class="md-textarea form-control" rows="1" onChange={onChange} defaultValue={action === "Add" ? "" : selectedRow["Remarks"]}></textarea>
                </div>
              </div>
              <div className="text-end text-bottom" >
                <button className="btn btn-primary m-2" type="submit" onSubmit={submitData}>
                  Save
                </button>
                <button className="btn btn-primary" onClick={cancelData}>
                  Cancel
                </button>
              </div>
            </div>
        }

      </form>
    </React.Fragment>
  )
}