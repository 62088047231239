import React, { useState,useRef } from 'react'
import {  Col , CardBody, Card , Form , Row  } from 'reactstrap';
import { sendSupportMessage } from 'services/slackService';
const CustomerSupport = ( {toggleModal , user}) => {
    const descriptionRef = useRef(null);
    const [selectedOption, setSelectedOption] = useState('Bug');
    const handleSelectChange = event => setSelectedOption(event.target.value);

    async function handleSubmit(event){
        event.preventDefault();
        const descriptionValue = descriptionRef.current.value;
        const supportObj = {
            name : user.nickname,
            email : user.email,
            category : selectedOption,
            message : descriptionValue
        }
        toggleModal()
        await sendSupportMessage(supportObj)
    }
    return (
        <>
            <Card>
                <CardBody>
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col xs={12}>
                                    <div className="form-floating mb-3">
                                        <select defaultValue="Bug" className="form-select" onChange={handleSelectChange}>
                                            <option value="Bug">Bug</option>
                                            <option value="Feature">Feature</option>
                                            <option value="Improvement">Improvement</option>
                                        </select>
                                        <label htmlFor="floatingSelectGrid">Select your category</label>
                                    </div>
                                </Col>
                            </Row>
                            <div className="form-floating mb-2">
                                <textarea type="text" className="form-control" id="floatingnameInput" placeholder="Enter description" rows={5} ref={descriptionRef}></textarea>
                                <label htmlFor="floatingnameInput">Description</label>
                            </div>
                            <div>
                                <button type="submit" className="btn btn-primary w-md">Submit</button>
                            </div>
                        </Form>
                    </CardBody>
                </Card>
        </>
    )
}

export default CustomerSupport