import { useAuth0 } from '@auth0/auth0-react';
import { Button, Popconfirm } from 'antd'
import React from 'react'
import { toast } from 'react-hot-toast';
import { finalQuoteUpdate } from 'services/assetownerService';

const UpdateQuoteStatus = ({record}) => {
    const apiurl = process.env.REACT_APP_AUTH0_API_URL
    const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()
    
    const getAccessToken = async (audience, claim) => {
      let token
      try {
        token = await getAccessTokenSilently({
          audience,
          claim,
        })
      } catch (exception) {
        try {
          token = await getAccessTokenWithPopup({ audience, claim })
        } catch (ex) {
          token = ""
        }
      }
      return token
    }

    const confirmReject = async(e) => {
        const jwtToken = await getAccessToken(apiurl , 'edit:assets')
        try {
            await finalQuoteUpdate(jwtToken , record.pk , record.sk , "Rejected")
            toast.success("Quote Rejected")
        } catch (error) {
            toast.error("Failed to reject quote")
        }
    };
    const confirmAccept = async(e) => {
         const jwtToken = await getAccessToken(apiurl , 'edit:assets')
         try {
            await finalQuoteUpdate(jwtToken , record.pk , record.sk , "Accepted") 
            toast.success("Quote Accepted")
         } catch (error) {
            toast.error("Failed to accept quote")
         }
        }
    const cancel = (e) => {null};
  return (
    <div style={{display:'flex'  , gap:'1rem'}}> 
    <Popconfirm
        title="Reject Quote"
        description="Are you sure to reject this quote?"
        onConfirm={confirmReject}
        onCancel={cancel}
        okText="Yes"
        cancelText="No"
    >
        <Button danger>Reject</Button>
    </Popconfirm>
    <Popconfirm
        title="Accept Quote"
        description="Are you sure to accept this quote?"
        onConfirm={confirmAccept}
        onCancel={cancel}
        okText="Yes"
        cancelText="No"
    >
        <Button>Accept</Button>
    </Popconfirm>
    </div>
  )
}

export default UpdateQuoteStatus