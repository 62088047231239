import { useEffect, useState } from "react"
import { getAssetLocation } from "services/assetService"


const LocationForAssetTracking = ({location}) => {
  const [loct , setLoct] = useState("")
  const lat = (location[0]?.lat)
  const lng = (location[0]?.lng)
    useEffect(async() => {
      const result = await getAssetLocation(lat , lng)
      setLoct(result)
    }, [lat, lng])
    
  return (
    <>
       <span style={{fontWeight:"bold"}}>{loct}</span>
    </>
  )
}

export default LocationForAssetTracking