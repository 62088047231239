import React, { useState, useReducer } from "react"
import { Modal, ModalHeader, ModalBody } from "reactstrap"
import Dropdown from "react-dropdown"
import { useAuth0 } from "@auth0/auth0-react"
import { useToasts, ToastProvider } from "react-toast-notifications"
import "react-toastify/dist/ReactToastify.css"

import { dataReducer } from "../reducer/reducers"
import { decryptKeyValues } from "../../../services/commonService"
import { getServiceProviderById } from "../../../services//serviceProvider"
import { getServiceProviderData, getServiceProviderDetailsFromMetquay, getAssetOwnerDetailsFromMetquay } from "../../../services/dashboard-services"

import "../css/modal-style.css"
import "react-dropdown/style.css"

import { assetColumnsForPreference } from "../data/asset-fields"

/**
 * active dropdown values
 */
const activeStatus = ["Active", "Inactive"]

export const ServiceProviderModal = props => {
  const [count, setCount] = useState(0)
  const [data, dispatchData] = useReducer(dataReducer, [])
  const { addToast } = useToasts()
  const apiurl = process.env.REACT_APP_AUTH0_API_URL
  const { getAccessTokenSilently, getAccessTokenWithPopup, user } = useAuth0()
  const [error, setError] = useState(true)
  const [emailError, setEmailError] = useState(null)
  const [phoneNumberError, setPhoneNumberError] = useState(null)
  const [websiteError, setWebsiteError] = useState(null)

  const {
    updateRecords,
    action,
    selectedRow,
    open,
    toggle,
    componentType,
    modalheader,
    isDashboard,
    title,
    userMetadata,
  } = props
  const [dataFromMetquay, setDataFromMetquay] = useState(false)
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [website, setWebsite] = useState("")
  const [addressLine1, setAddressLine1] = useState("")
  const [addressLine2, setAddressLine2] = useState("")
  const [city, setCity] = useState("")
  const [state, setState] = useState("")
  const [zipCode, setZipcode] = useState("")
  const [country, setCountry] = useState("")


  /**
   * setDataForEdit method is used to set default values in edit mode.
   */
  const setDataForEdit = () => {
    if (action === "Edit" && count === 0) {
      dispatchData({ type: "setRecords", data: selectedRow })
      setCount(count + 1)
    }
  }

  const getAccessToken = async (audience, claim) => {
    let token
    try {
      token = await getAccessTokenSilently({
        audience,
        claim,
      })
    } catch (exception) {
      token = await getAccessTokenWithPopup({ audience, claim })
    }
    return token
  }

  const setDataFromMetquayField = (field, data) => {
    dispatchData({
      type: "setDataToModalonChange",
      data: { id: field, value: data },
    })
  }

  const onValidate = (field, value) => {
    if (field === 'email') {
      const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (value.match(validRegex)) {
        setEmailError(null)
        return false;
      } else {
        setEmailError('Please provide a valid email')
        return true;
      }
    } 
    // else if (field === 'phone') {
    //   // const validRegex = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
    //   // const validRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
    //   // const validRegex = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g
    //   // const validRegex1 = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g
    //   // const validRegex = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/g
    //   if (value.match(validRegex) || value.match(validRegex1)) {
    //     setPhoneNumberError(null)
    //     return false;
    //   } else {
    //     setPhoneNumberError('Please provide a valid phone number')
    //     return true;
    //   }
    // } else if (field === 'website') {
    //   const validRegex = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    //   if (value.match(validRegex)) {
    //     setWebsiteError(null)
    //     return false;
    //   } else {
    //     setWebsiteError('Please provide a valid website')
    //     return true;
    //   }
    // }
    else {
      return false
    }

  }

  const fetchDetailsFromMetquay = (details) => {
    if (details === undefined || details === null) {
      setName("")
      setEmail("")
      setWebsite("")
      setPhone("")
      setCity("")
      setState("")
      setCountry("")
      setZipcode("")
      setAddressLine1("")
      setAddressLine2("")
    } else {
      setDataFromMetquayField('name', details.name)
      setDataFromMetquayField('email', details.email)
      setDataFromMetquayField('phone', details.phone)
      setDataFromMetquayField('website', details.website)
      setDataFromMetquayField('zipCode', details.zip)
      setDataFromMetquayField('state', details.state)
      setDataFromMetquayField('city', details.city)
      setDataFromMetquayField('countryRegion', details.country)
      setDataFromMetquayField('addressLine1', details.addressLine1)
      setDataFromMetquayField('addressLine2', details.addressLine2)
      setName(details.name)
      setEmail(details.email)
      setWebsite(details.website)
      setPhone(details.phone)
      setCity(details.city)
      setState(details.state)
      setCountry(details.country)
      setZipcode(details.zip)
      setAddressLine1(details.addressLine1)
      setAddressLine2(details.addressLine2)
    }

  }

  /**
   * onChange method is used to map the data to the field on changing the value in modal.
   * @param {object} e - data
   * @param {string} header - field header
   * @param {string} type - type of the field changed
   */
  const onChange = async (e, header, type) => {
    setDataForEdit()
    if (!onValidate(e.target.id, e.target.value)) {
      dispatchData({
        type: "setDataToModalonChange",
        data: { id: e.target.id, value: e.target.value },
      })

      if (
        e.target.id === "metquaySecretKey" &&
        title === "Service Provider"
      ) {
        // if(data['mode'] === 'Add'){
          const jwtToken = await getAccessToken(apiurl, "add:asset")
          if (data["metquayurl"] !== undefined && data["metquayurl"] !== null) {
            await getServiceProviderDetailsFromMetquay(jwtToken, data["metquayurl"], e.target.value).then(
              result => {
                // console.log(result.data.resultData)
                let resultData = result.data.resultData
                fetchDetailsFromMetquay(resultData)
  
                setDataFromMetquay(true)
              }
            ).catch(err => {
              fetchDetailsFromMetquay(null)
              setDataFromMetquay(false)
            })
          }
        // }
      }

      if (
        e.target.id === "metquayurl" &&
        title === "Service Provider"
      ) {
        //If the Tracefii UID is updated then it won't fetch the data from metquay.
        // if(data['mode'] === 'Add'){
        const jwtToken = await getAccessToken(apiurl, "add:asset")
        if (data["metquaySecretKey"] !== undefined && data["metquaySecretKey"] !== null) {
          await getServiceProviderDetailsFromMetquay(jwtToken, e.target.value, data["metquaySecretKey"],).then(
            result => {
              // console.log(result.data.resultData)
              let resultData = result.data.resultData
              fetchDetailsFromMetquay(resultData)
              setDataFromMetquay(true)
            }
          ).catch(err => {
            fetchDetailsFromMetquay(null)
            setDataFromMetquay(false)
          })
        }
      // }

      }


      if (
        e.target.id === "metquayAssetOwnerKey" &&
        title === "Asset Owner" && userMetadata.role === "Admin"
      ) {
        const jwtToken = await getAccessToken(apiurl, "add:asset")
        if (data["metquayurl"] !== undefined && data["metquayurl"] !== null && data["metquaySecretKey"] !== undefined && data["metquaySecretKey"] !== null) {
          await getAssetOwnerDetailsFromMetquay(jwtToken, metquayUrl, metquaySecretKey, e.target.value).then(
            result => {
              // console.log(result.data.resultData)
              let resultData = result.data.resultData
              fetchDetailsFromMetquay(resultData)

              setDataFromMetquay(true)
            }
          ).catch(err => {
            fetchDetailsFromMetquay(null)
            setDataFromMetquay(false)
          })
        }

      }

      if (
        e.target.id === "metquayAssetOwnerKey" &&
        userMetadata.role.startsWith("Service Provider")
      ) {
        const primaryKeyValue = decryptKeyValues(userMetadata.pk)
        const jwtToken = await getAccessToken(apiurl, "add:asset")
        let metquayUrl = ""
        let metquaySecretKey = ""
        await getServiceProviderById(jwtToken, null, primaryKeyValue).then(
          result => {
            // console.log(result)
            metquayUrl = result[0].attributes.metquayurl
            metquaySecretKey = result[0].attributes.metquaySecretKey
          }
        )
        dispatchData({
          type: "setDataToModalonChange",
          data: { id: "metquaySecretKey", value: metquaySecretKey },
        })
        dispatchData({
          type: "setDataToModalonChange",
          data: { id: "metquayurl", value: metquayUrl },
        })
        await getAssetOwnerDetailsFromMetquay(jwtToken, metquayUrl, metquaySecretKey, e.target.value).then(
          result => {
            // console.log(result.data.resultData)
            let resultData = result.data.resultData
            fetchDetailsFromMetquay(resultData)

            setDataFromMetquay(true)
          }
        ).catch(err => {
          fetchDetailsFromMetquay(null)
          setDataFromMetquay(false)
        })

      }
    }
  }



  /**
   *
   * @param {event} e
   * @param {string} dataField
   */
  const dropDownChange = async (e, dataField) => {
    setDataForEdit()
    dispatchData({
      type: "setDataToModalonChange",
      data: { id: dataField, value: e.value },
    })
  }

  const accountChange = (e, dataField) => {
    setDataForEdit()
    dispatchData({
      type: "setDataToModalonChange",
      data: { id: dataField, value: e.target.value },
    })
  }

  /**
   * submitData method is called when we click the ok button in modal.
   * @param {object} e
   */
  const submitData = async e => {
    e.preventDefault()
    data["mode"] = action
    if (title === "Asset Owner") {
      const totalFields = [...assetColumnsForPreference]
      let importFields = []
      const mainColumns = []
      const expandableColumns = []
      let floatPosition = true
      let dateCount = 1
      let dateFields = {}
      totalFields.map((newField, value) => {
        importFields.push(newField.text)
        if(newField.type === 'Date' || newField.type === 'date'){
          dateFields['Date'+dateCount] = newField.text
          dateCount ++;
        }
        if (value < (totalFields.length / 2)) {
          mainColumns.push(newField)
        } else {
          expandableColumns.push(newField)
        }
        if (newField.dataField !== 'Report') {
          if (floatPosition === true) {
            newField.float = 'right'
          } else {
            newField.float = 'left'
          }
          floatPosition = !floatPosition
        }
      })
      totalFields.map((field, value) => {
        if (field.dataField === 'Report') {
          totalFields.splice(value, 1)
        }
      })

      const preferences = {
        mainColumns: mainColumns,
        //columns to be be shown while expanding
        expandableColumns: expandableColumns,
        //list of all fields to be shown in modal
        totalFields: totalFields,
        //list of field names to set data while importing
        importFields: importFields,
        // date fields to set email alert
        dateFields: dateFields,
      }
      data.preferences = preferences
    }
    if (componentType === "userComponent") {
      data["componentType"] = "user"
    }
    if (data["statusValue"] === undefined) {
      data["statusValue"] = "Active"
    }
    let metquayIntegrationError = false
    let metquayIntegrationErrorMessage = ""
    if (data["metquayurl"] !== undefined && data["metquayurl"] !== null) {
      const jwtToken = await getAccessToken(apiurl, "add:asset")
      let metquayAssetOwnerKey = data["metquayAssetOwnerKey"] !== undefined && data["metquayAssetOwnerKey"] !== null
        ? data["metquayAssetOwnerKey"]
        : " "
      let metquaySecretKey = data["metquaySecretKey"] !== undefined && data["metquaySecretKey"] !== null
        ? data["metquaySecretKey"]
        : " "
      let isAssetOwner = title === "Asset Owner" ? true : false

        await getServiceProviderData(jwtToken, data["metquayurl"], metquaySecretKey, "noOfAssets", metquayAssetOwnerKey, isAssetOwner)
        .then(result => { })
        .catch(error => {
          metquayIntegrationError = true
          metquayIntegrationErrorMessage = error.response.data
        })  
    }
    if (metquayIntegrationError === true) {
      addToast(metquayIntegrationErrorMessage, {
        appearance: "error",
        autoDismiss: true,
        placement: "bottom-left",
      })
    } else {
      if (action === "Add" && (data['freeTrialDays'] === undefined || data['freeTrialDays'] === null)) {
        data['freeTrialDays'] = 15
      }
      updateRecords(data)
      setEmailError(null)
      toggle("submit")
    }

    // updateRecords(data)
    // toggle("submit")
  }

  /**
   * cancelData function will be called when the cancel button is clicked
   * @param {event} e
   */
  const cancelData = e => {
    e.preventDefault()
    dispatchData({ type: "setRecords", data: {} })
    setName("")
    setEmail("")
    setWebsite("")
    setPhone("")
    setCity("")
    setState("")
    setCountry("")
    setDataFromMetquay(false)
    setEmailError(null)
    toggle()
  }

  /**
   * onModalClosed function is used to do the chnages when a modal is closed using close icon.
   */
  const onModalClosed = () => {
    dispatchData({ type: "setRecords", data: {} })
    setName("")
    setEmail("")
    setWebsite("")
    setPhone("")
    setCity("")
    setState("")
    setCountry("")
    setDataFromMetquay(false)
    setEmailError(null)
  }

  return (
    <React.Fragment>
      <ToastProvider placement="bottom-right">
        <Modal size="xl" isOpen={open} onClosed={e => onModalClosed()}>
          <ModalHeader toggle={toggle}>{modalheader}</ModalHeader>
          <form className="pl-3 pr-3 " onSubmit={submitData}>
            {
              //using bootstrap-grid
              <ModalBody>
                <div class="row">
                  <div className={"col form-group "}>
                    <label htmlFor="Name">
                      Name
                      <span className="required-field"></span>
                    </label>
                    <input
                      id="name"
                      name="Name"
                      className="form-control"
                      defaultValue={action === "Add" ? dataFromMetquay === true ? name : "" : selectedRow["name"]}
                      onChange={e => onChange(e, "name")}
                      required
                      data-error="This is a required field."
                    ></input>
                  </div>

                  <div className={"col form-group "}>
                    <label htmlFor="Email">
                      Email
                      <span className="required-field"></span>
                      {
                        emailError !== null ? <span className="text-danger">{' Invalid Email'}</span> : ''
                      }
                    </label>
                    <input
                      id="email"
                      name="Email"
                      className="form-control"
                      disabled={ action === "Edit" ? true : isDashboard}
                      defaultValue={
                        action === "Add" ? dataFromMetquay === true ? email : "" : selectedRow["email"]
                      }
                      onChange={e => onChange(e, "email")}
                      required
                      data-error="This is a required field."
                    ></input>
                  </div>

                  <div className={"col form-group "}>
                    <label htmlFor="Phone">
                      Phone
                      {
                        phoneNumberError !== null ? <span className="text-danger">{' Invalid Phone Number'}</span> : ''
                      }
                    </label>
                    <input
                      id="phone"
                      name="Phone"
                      className="form-control"
                      defaultValue={
                        action === "Add" ? dataFromMetquay === true ? phone : "" : selectedRow["phone"]
                      }
                      onChange={e => onChange(e, "phone")}
                    ></input>
                  </div>
                </div>

                <div class="row">
                  <div
                    className={"col form-group "}
                    style={{ marginTop: "1%" }}
                  >
                    <label htmlFor="Website">
                      Website
                      {
                        websiteError !== null ? <span className="text-danger">{' Invalid Website'}</span> : ''
                      }
                    </label>
                    <input
                      id="website"
                      name="Website"
                      className="form-control"
                      defaultValue={
                        action === "Add" ? dataFromMetquay === true ? website : "" : selectedRow["website"]
                      }
                      onChange={e => onChange(e, "website")}
                    ></input>
                  </div>
                  <div className={"col form-group"} style={{ marginTop: "1%" }}>
                    <label htmlFor="countryRegion">
                      Country/Region
                    </label>
                    <input
                      className="form-control"
                      name="Country/Region"
                      id="countryRegion"
                      defaultValue={
                        action === "Add" ? dataFromMetquay === true ? country : "" : selectedRow["countryRegion"]
                      }
                      onChange={e => onChange(e, "countryRegion")}
                    />
                  </div>
                  <div
                    className={"col form-group "}
                    style={{ marginTop: "1%" }}
                  >
                    <label htmlFor="AddressLine1">
                      AddressLine1
                    </label>
                    <input
                      id="addressLine1"
                      name="AddressLine1"
                      className="form-control"
                      defaultValue={
                        action === "Add" ? dataFromMetquay === true ? addressLine1 : "" : selectedRow["addressLine1"]
                        // action === "Add" ? "" : selectedRow["addressLine1"]
                      }
                      onChange={e => onChange(e, "addressLine1")}
                    ></input>
                  </div>
                </div>

                <div class="row">
                  <div
                    className={"col form-group "}
                    style={{ marginTop: "1%" }}
                  >
                    <label htmlFor="addressLine2">
                      AddressLine2
                    </label>
                    <input
                      id="addressLine2"
                      name="AddressLine2"
                      className="form-control"
                      defaultValue={
                        action === "Add" ? dataFromMetquay === true ? addressLine2 : "" : selectedRow["addressLine2"]
                        // action === "Add" ? "" : selectedRow["addressLine2"]
                      }
                      onChange={e => onChange(e, "addressLine2")}
                    ></input>
                  </div>
                  <div
                    className={"col form-group "}
                    style={{ marginTop: "1%" }}
                  >
                    <label htmlFor="city">
                      City
                    </label>
                    <input
                      id="city"
                      name="City"
                      className="form-control"
                      defaultValue={action === "Add" ? dataFromMetquay === true ? city : "" : selectedRow["city"]}
                      onChange={e => onChange(e, "city")}
                    ></input>
                  </div>
                  <div className={"col form-group"} style={{ marginTop: "1%" }}>
                    <label htmlFor="state">
                      State
                    </label>
                    <input
                      className="form-control"
                      list="state"
                      name="State"
                      id="state"
                      defaultValue={
                        action === "Add" ? dataFromMetquay === true ? state : "" : selectedRow["state"]
                      }
                      onChange={e => onChange(e, "state")}
                    />
                  </div>
                </div>

                <div class="row">
                  <div
                    className={"col form-group "}
                    style={{ marginTop: "1%" }}
                  >
                    <label htmlFor="zipCode">
                      ZipCode
                    </label>
                    <input
                      id="zipCode"
                      name="ZipCode"
                      className="form-control"
                      defaultValue={
                        action === "Add" ? dataFromMetquay === true ? zipCode : "" : selectedRow["zipCode"]
                      }
                      onChange={e => onChange(e, "zipCode")}
                    ></input>
                  </div>
                  <div
                    className={"col form-group "}
                    style={{ marginTop: "1%" }}
                  >
                    <label htmlFor="fax">
                      Fax
                    </label>
                    <input
                      id="fax"
                      name="Fax"
                      className="form-control"
                      defaultValue={action === "Add" ? "" : selectedRow["fax"]}
                      onChange={e => onChange(e, "fax")}
                    ></input>
                  </div>
                  {/* <div className={"col form-group"} style={{  marginTop: "1%" }}>
                  <label htmlFor="statusValue">Status</label>
                  <Dropdown
                    id="statusValue"
                    options={activeStatus}
                    value={activeStatus[0]}
                    onChange={e => dropDownChange(e, "statusValue")}
                  />
                </div> */}
                </div>
                {/* {isDashboard !== true ? (
                <div className={"col form-group"} style={{  marginTop: "1%" }}>
                <label htmlFor="statusValue">Status</label>
                <Dropdown
                  id="statusValue"
                  options={activeStatus}
                  value={activeStatus[0]}
                  onChange={e => dropDownChange(e, "statusValue")}
                />
              </div>
              ) : (
                ""
              )} */}

                {isDashboard !== true ? (
                  <div class="row">
                    <div
                      className={"col form-group"}
                      style={{ marginTop: "1%" }}
                    >
                      <label htmlFor="statusValue">Status</label>
                      <Dropdown
                        id="statusValue"
                        options={activeStatus}
                        value={activeStatus[0]}
                        onChange={e => dropDownChange(e, "statusValue")}
                      />
                    </div>
                    {title === "Service Provider" ? (
                      <div class="row">
                        <div
                          className={"col form-group"}
                          style={{ marginTop: "1%" }}
                        >
                          <label htmlFor="metquayurl">Metquay URL</label>
                          <input
                            className="form-control"
                            id="metquayurl"
                            onChange={e => onChange(e, "metquayurl")}
                            defaultValue={
                              action === "Add" ? "" : selectedRow["metquayurl"]
                            }
                          />
                        </div>
                        <div
                          className={"col form-group"}
                          style={{ marginTop: "1%" }}
                        >
                          <label htmlFor="metquaySecretKey">Tracefii UID</label>
                          <input
                            className="form-control"
                            id="metquaySecretKey"
                            onChange={e => onChange(e, "metquaySecretKey")}
                            defaultValue={
                              action === "Add"
                                ? ""
                                : selectedRow["metquaySecretKey"]
                            }
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {title === "Asset Owner" && userMetadata.role !== "Admin" ? (
                      <div
                        className={"col form-group"}
                        style={{ marginTop: "1%" }}
                      >
                        <label htmlFor="metquayAssetOwnerKey">
                          Asset Owner Key
                        </label>
                        <input
                          className="form-control"
                          id="metquayAssetOwnerKey"
                          onChange={e => onChange(e, "metquayAssetOwnerKey")}
                          defaultValue={
                            action === "Add"
                              ? ""
                              : selectedRow["metquayAssetOwnerKey"]
                          }
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {userMetadata.role === "Admin" ? (
                      <div
                        className={"col form-group"}
                        style={{ marginTop: "1%" }}
                      >
                        <label htmlFor="freeTrialDays"> Free Trial Days </label>
                        <input
                          className="form-control"
                          list="freeTrialDays"
                          name="freeTrialDays"
                          id="freeTrialDays"
                          defaultValue={
                            action === "Add" ? "" : selectedRow["freeTrialDays"]
                          }
                          onChange={e => onChange(e, "freeTrialDays")}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
              </ModalBody>
            }
            <div
              className="modal-footer form-group text-right"
              style={{ flex: 1, marginRight: "5%", float: "right" }}
            >
              <button
                className="btn btn-primary m-2"
                type="submit"
                onSubmit={submitData}
                disabled={emailError !== null || phoneNumberError !== null || websiteError !== null}
              >
                Save
              </button>
              <button
                className="btn btn-primary"
                type="submit"
                onClick={cancelData}
              >
                Cancel
              </button>
            </div>
            {isDashboard === true ? (
              <div
                className="form-group text-start"
                style={{ flex: 1, marginLeft: "5%", marginTop: "15%" }}
              >
                <label>
                  We have sent an email to your registered email address. Please
                  verify your email to proceed. Ignore if already verified.
                </label>
              </div>
            ) : (
              ""
            )}
          </form>
        </Modal>
      </ToastProvider>
    </React.Fragment>
  )
}