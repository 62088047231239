import http from "./httpService"

export async function getNoOfCertificatesForEachMonth(
  metquayUrl,
  metquaySecretKey
) {

  const serviceProvider = await http.get(
    `/dashboard/getServiceProviderData`, {
      params: { metquayUrl: metquayUrl, metquaySecretKey: metquaySecretKey, endPoint: 'noOfAssets'  }
   })
  // return data
  // console.log(serviceProvider)
}

export async function getServiceProviderData(jwtToken,
  metquayUrl,
  metquaySecretKey, endPoint, metquayAssetOwnerKey, isAssetOwner
) {
  http.setJwt(jwtToken)
  const noCustomerAssets = await http.get(
    `/dashboard/getServiceProviderData`, {
      params: { metquayUrl: metquayUrl, metquaySecretKey: metquaySecretKey, endPoint: endPoint, metquayAssetOwnerKey: metquayAssetOwnerKey, isAssetOwner: isAssetOwner }
   })
  // return data
  return noCustomerAssets
}

export async function getLiveUpdatesData(jwtToken,
  metquayUrl,
  metquaySecretKey,  first, metquayAssetOwnerKey, isAssetOwner
) {
  http.setJwt(jwtToken)
  const noCustomerAssets = await http.get(
    `/dashboard/getLiveUpdatesData`, {
      params: { metquayUrl: metquayUrl, metquaySecretKey: metquaySecretKey, first: first, metquayAssetOwnerKey: metquayAssetOwnerKey, isAssetOwner: isAssetOwner }
   })
  // return data
  return noCustomerAssets
}

export async function getAssetsFromMetquay(jwtToken,
  metquayUrl,
  metquaySecretKey, metquayAssetOwnerKey, first, limit
) {
  http.setJwt(jwtToken)
  const noCustomerAssets = await http.get(
    `/dashboard/getAssets`, {
      params: { metquayUrl: metquayUrl, metquaySecretKey: metquaySecretKey, first: first, metquayAssetOwnerKey: metquayAssetOwnerKey }
   })
  // return data
  return noCustomerAssets
}

/**
 * Return works created in last 30 days
 * @param {*} jwtToken 
 * @param {*} metquayUrl 
 * @param {*} metquaySecretKey 
 * @param {*} metquayAssetOwnerKey 
 * @param {*} first 
 * @param {*} limit 
 * @param {*} keyword 
 * @returns 
 */
export async function getWorksInLast30Days(jwtToken,
  metquayUrl,
  metquaySecretKey, metquayAssetOwnerKey, first, isAssetOwner,
  keyword
) {
  http.setJwt(jwtToken)
  const noCustomerAssets = await http.get(
    `/dashboard/createdInLast30days`, {
      params: { metquayUrl: metquayUrl, metquaySecretKey: metquaySecretKey, first: first, metquayAssetOwnerKey: metquayAssetOwnerKey, keyword:keyword, isAssetOwner:isAssetOwner }
   })
  // return data
  return noCustomerAssets
}

export async function getWorksFromMetquay(jwtToken,
  metquayUrl,
  metquaySecretKey, metquayAssetOwnerKey, first, isAssetOwner, keyword
) {
  http.setJwt(jwtToken)
  const noCustomerAssets = await http.get(
    `/dashboard/getWorks`, {
      params: { metquayUrl: metquayUrl, metquaySecretKey: metquaySecretKey, first: first, metquayAssetOwnerKey: metquayAssetOwnerKey, keyword:keyword, isAssetOwner:isAssetOwner }
   })
  // return data
  return noCustomerAssets
}

export async function getServiceProviderDetailsFromMetquay(jwtToken,
  metquayUrl,
  metquaySecretKey) {
  http.setJwt(jwtToken)
  
  const serviceProvdierDetails = await http.get(
    `/dashboard/getServiceProviderDetails`, {
      params: { metquayUrl: metquayUrl, metquaySecretKey: metquaySecretKey },
   })
  // return data
  return serviceProvdierDetails
}

export async function getAssetOwnerDetailsFromMetquay(jwtToken,
  metquayUrl,
  metquaySecretKey, metquayAssetOwnerKey) {
  http.setJwt(jwtToken)
  const getAssetOwnerDetails = await http.get(
    `/dashboard/getAssetOwnerDetails`, {
      params: { metquayUrl: metquayUrl, metquaySecretKey: metquaySecretKey, metquayAssetOwnerKey: metquayAssetOwnerKey }
   })
  // return data
  return getAssetOwnerDetails
}


export async function getFilteredAssetsFromMetquay(jwtToken,
  metquayUrl,
  metquaySecretKey, metquayAssetOwnerKey, first, keyword, filter
) {
  http.setJwt(jwtToken)
  const noCustomerAssets = await http.get(
    `/dashboard/getFilteredAssetsFromMetquay`, {
      params: { metquayUrl: metquayUrl, metquaySecretKey: metquaySecretKey, first: first, metquayAssetOwnerKey: metquayAssetOwnerKey, keyword:keyword, filter:filter }
   })
  // return data
  return noCustomerAssets
}


export async function getAssetOwnersList(jwtToken, metquayUrl, metquaySecretKey, activeInDays) {
  http.setJwt(jwtToken)
  const assetOwnersList = await http.get(
    `/dashboard/getAssetOwnersList`, {
      params: { metquayUrl: metquayUrl, metquaySecretKey: metquaySecretKey,activeInDays:activeInDays  }
   })
  return assetOwnersList
}

export async function updateActiveInDays( jwtToken , userId, activeInDays) {
  http.setJwt(jwtToken)
  const result = await http.post(
    `/dashboard/updateActiveInDays`, {
       userId: userId, activeInDays:activeInDays  
   })
  return result
}
