const tableData = [
  {
    id: "1",
    firstName: "Alex",
    emailAddress: "alex@gmail.com",
    phone: "123456789",
    lastName: "Miller",
    workPhone: "(202)-588-6500",
    mobilePhone: "+14845219747",
  },
  {
    id: "2",
    firstName: "John",
    emailAddress: "john@gamil.com",
    phone: "123456789",
    lastName: "Smith",
    workPhone: "(623)-853-7197",
    mobilePhone: "+16102448967",
  },
  {
    id: "3",
    firstName: "David",
    emailAddress: "alex@gamil.com",
    phone: "123456789",
    lastName: "Brown",
    workPhone: "(870)-931-0505",
    mobilePhone: "+14845211089",
  },
  {
    id: "4",
    firstName: "William",
    emailAddress: "alex@gamil.com",
    phone: "123456789",
    lastName: "Martinez",
    workPhone: "(603)-996-3829",
    mobilePhone: "+14845219770",
  },
  {
    id: "5",
    firstName: "Charles",
    emailAddress: "alex@gamil.com",
    phone: "123456789",
    lastName: "Taylor",
    workPhone: "217-826-3011",
    mobilePhone: "+15854380127",
  },
  {
    id: "6",
    firstName: "Alex",
    emailAddress: "alex@gamil.com",
    phone: "217-826-5960",
    lastName: "Kaggle",
    workPhone: "217-826-5960",
    mobilePhone: "+18143519367",
  },
  {
    id: "7",
    firstName: "Daniel",
    emailAddress: "alex@gamil.com",
    phone: "(304)-213-7297",
    lastName: "Moore",
    workPhone: "(304)-213-7297",
    mobilePhone: "+14845219764",
  },
  {
    id: "8",
    firstName: "Matthew",
    emailAddress: "alex@gamil.com",
    phone: "(509)-853-1947",
    lastName: "Lewis",
    workPhone: "(509)-853-1947",
    mobilePhone: "+15854380234",
  },
  {
    id: "9",
    firstName: "Mark",
    emailAddress: "alex@gamil.com",
    phone: "(206)-305-1454",
    lastName: "Wilson",
    workPhone: "(206)-305-1454",
    mobilePhone: "+14845219620",
  },
  {
    id: "10",
    firstName: "Scott",
    emailAddress: "alex@gamil.com",
    phone: "(724)-012-7537",
    lastName: "Hill",
    workPhone: "(724)-012-7537",
    mobilePhone: "+15854380161",
  },
  {
    id: "11",
    firstName: "Frank",
    emailAddress: "alex@gamil.com",
    phone: "(610)-853-1547",
    lastName: "Wright",
    workPhone: "(610)-853-1547",
    mobilePhone: "+14845211109",
  },
  {
    id: "12",
    firstName: "Brandon",
    emailAddress: "alex@gamil.com",
    phone: "(803)-315-1698",
    lastName: "Flores",
    workPhone: "(803)-315-1698",
    mobilePhone: "+15854380091",
  },
  {
    id: "13",
    firstName: "Paxton",
    emailAddress: "alex@gamil.com",
    phone: "(878)-774-0547",
    lastName: "Gross",
    workPhone: "(878)-774-0547",
    mobilePhone: "+14845219713",
  },
  {
    id: "14",
    firstName: "Adam",
    emailAddress: "alex@gamil.com",
    phone: "(865)-621-7197",
    lastName: "Zampa",
    workPhone: "(865)-621-7197",
    mobilePhone: "+14845219753",
  },
  {
    id: "15",
    firstName: "Alex",
    emailAddress: "alex@gamil.com",
    phone: "(623)-853-7412",
    lastName: "Torres",
    workPhone: "(623)-853-7412",
    mobilePhone: "+14845219663",
  },
  {
    id: "16",
    firstName: "Nathan",
    emailAddress: "alex@gamil.com",
    phone: "(605)-121-7415",
    lastName: "Courter-Nile",
    workPhone: "(605)-121-7415",
    mobilePhone: "+14845219638",
  },
  {
    id: "17",
    firstName: "Steve",
    emailAddress: "alex@gamil.com",
    phone: "(325)-853-1124",
    lastName: "Rodriguez",
    workPhone: "(325)-853-1124",
    mobilePhone: "+14842918652",
  },
  {
    id: "18",
    firstName: "Joan",
    emailAddress: "alex@gamil.com",
    phone: "(814)-853-1469",
    lastName: "Norris",
    workPhone: "(814)-853-1469",
    mobilePhone: "+16102458128",
  },
  {
    id: "19",
    firstName: "Ethan",
    emailAddress: "alex@gamil.com",
    phone: "(724)-963-7197",
    lastName: "Grubbs",
    workPhone: "(724)-963-7197",
    mobilePhone: "+16102458076",
  },
  {
    id: "20",
    firstName: "Oliver",
    emailAddress: "alex@gamil.com",
    phone: "(330)-512-9841",
    lastName: "Martinez",
    workPhone: "(330)-512-9841",
    mobilePhone: "+16102458017",
  },
  {
    id: "21",
    firstName: "Sean",
    emailAddress: "alex@gamil.com",
    phone: "(419)-645-1259",
    lastName: "Howard",
    workPhone: "(419)-645-1259",
    mobilePhone: "+16102458315",
  },
  {
    id: "22",
    firstName: "Noah",
    emailAddress: "alex@gamil.com",
    phone: "(440)-874-5548",
    lastName: "Gomez",
    workPhone: "(440)-874-5548",
    mobilePhone: "+16102458076",
  },
  {
    id: "23",
    firstName: "Billy",
    emailAddress: "alex@gamil.com",
    phone: "(406)-215-3654",
    lastName: "Kaggle",
    workPhone: "(406)-215-3654",
    mobilePhone: "+16102448911",
  },
  {
    id: "24",
    firstName: "Wayne",
    emailAddress: "alex@gamil.com",
    phone: "(308)-845-0451",
    lastName: "Harrison",
    workPhone: "(308)-845-0451",
    mobilePhone: 887,
  },
  {
    id: "25",
    firstName: "Charlotte",
    emailAddress: "alex@gamil.com",
    phone: "(402)-084-4848",
    lastName: "Collier",
    workPhone: "(402)-084-4848",
    mobilePhone: "+18143519435",
  },
  {
    id: "26",
    firstName: "Peter",
    emailAddress: "alex@gamil.com",
    phone: "(607)-854-7878",
    lastName: "James",
    workPhone: "(607)-854-7878",
    mobilePhone: "+18143519485",
  },
  {
    id: "27",
    firstName: "Arthur",
    emailAddress: "alex@gamil.com",
    phone: "(631)-567-9514",
    lastName: "Fisher",
    workPhone: "(631)-567-9514",
    mobilePhone: "+18143519479",
  },
  {
    id: "28",
    firstName: "Thomas",
    emailAddress: "alex@gamil.com",
    phone: "(676)-014-8520",
    lastName: "Miner",
    workPhone: "(676)-014-8520",
    mobilePhone: "+18143519465",
  },
  {
    id: "29",
    firstName: "Peter",
    emailAddress: "alex@gamil.com",
    phone: "(919)-330-1995",
    lastName: "McEnroe",
    workPhone: "(919)-330-1995",
    mobilePhone: "+18143519545",
  },
  {
    id: "30",
    firstName: "Gregory",
    emailAddress: "alex@gamil.com",
    phone: "(804)-190-2010",
    lastName: "Barner",
    workPhone: "(804)-190-2010",
    mobilePhone: "+18143519565",
  },
  {
    id: "31",
    firstName: "Larry",
    emailAddress: "alex@gamil.com",
    phone: "(910)-845-1997",
    lastName: "Samberg",
    workPhone: "(910)-845-1997",
    mobilePhone: "+18143519513",
  },
  {
    id: "32",
    firstName: "Eric",
    emailAddress: "alex@gamil.com",
    phone: "(330)-919-1947",
    lastName: "Stephens",
    workPhone: "(330)-919-1947",
    mobilePhone: "+18143519385",
  },
]

const userColumns = [
  {
    dataField: "firstName",
    text: "First Name",
    sort: true,
    float: "left",
    type: "string",
    required: true,
  },
  {
    dataField: "lastName",
    text: "Last Name",
    sort: true,
    float: "right",
    type: "string",
    //required: false,
  },
  {
    dataField: "emailAddress",
    text: "Email Address",
    sort: false,
    float: "left",
    type: "string",
    required: true,
  },
  {
    dataField: "phone",
    text: "Work Phone",
    sort: false,
    float: "left",
    type: "string",
    required: true,

  },
  {
    dataField: "role",
    text: "Role",
    sort: false,
    float: "right",
    type: "dropDown",
    required: true,
  },
  {
    dataField: "account",
    text: "Account",
    sort: false,
    float: "left",
    type: "dropDown",
    required: true,
  },
]

const modalColumns = [
  {
    dataField: "firstName",
    text: "First Name",
    sort: true,
    float: "left",
    type: "string",
    required: true,
  },
  {
    dataField: "lastName",
    text: "Last Name",
    sort: true,
    float: "right",
    type: "string",
    //required: true,
  },
  {
    dataField: "emailAddress",
    text: "Email Address",
    sort: false,
    float: "left",
    type: "string",
    required: true,
  },
  {
    dataField: "workPhone",
    text: "Work Phone",
    sort: true,
    float: "right",
    type: "string",
    //required: false,
  },
  
]

const dropDownOptions = ["Admin", "Service Provider", "Asset Owner"]

const spDropDownOptions = ["Service Provider", "Asset Owner"]

const accountOptions = ["Tracefii Admin"]

const defaultSorted = [
  {
    dataField: "id",
    order: "asc",
  },
]

export {
  userColumns,
  defaultSorted,
  tableData,
  dropDownOptions,
  spDropDownOptions,
  accountOptions,
  modalColumns,
}