import React, { useEffect, useState } from 'react'
import { useAuth0 } from "@auth0/auth0-react"
import { roleConfig, user_metadata } from 'nameSpaceConfig';
import CryptoJS from "crypto-js"
import { getQuotesForAssetOwner, getQuotesForServiceProvider } from 'services/assetService';
import AssetOwnerMetquayIntegratedQuotes from './AssetOwnerMI/AssetOwnerMetquayIntegratedQuotes';
import ServiceProviderMetquayIntegrated from './ServiceProvider/ServiceProviderMetquayIntegrated';

const QuotesOverview = () => {
    const apiurl = process.env.REACT_APP_AUTH0_API_URL
    const [quotes ,setQuotes] = useState([])
    const [assetsArr ,  setAssetsArr] = useState([])
    const { getAccessTokenSilently, getAccessTokenWithPopup, user } = useAuth0()
    const userMetadata = user[user_metadata]
    const role = userMetadata.role;
    console.log(role)
    const getAccessToken = async (audience, claim) => {
        let token
        try {
          token = await getAccessTokenSilently({
            audience,
            claim,
          })
        } catch (exception) {
          try {
            token = await getAccessTokenWithPopup({ audience, claim })
          } catch (ex) {
            token = ""
          }
        }
        return token
    }
    useEffect(async() => {
        if(userMetadata !== undefined){
            var userPk =  CryptoJS.enc.Base64.parse(userMetadata.pk).toString(CryptoJS.enc.Utf8)
            var userSk = CryptoJS.enc.Base64.parse(userMetadata.sk).toString(CryptoJS.enc.Utf8)
          }
        const jwtToken = await getAccessToken(apiurl, 'add:asset')
        if(role.startsWith('Asset Owner')){
            const quotes = await getQuotesForAssetOwner(jwtToken , userSk , userPk )
            const assetsArray = quotes.flatMap(obj => obj.attributes.assets);
            setAssetsArr(assetsArr)
            setQuotes(quotes)
        }else{
            const quotes = await getQuotesForServiceProvider(jwtToken , userPk)
            const assetsArray = quotes.flatMap(obj => obj.attributes.assets);
            setQuotes(quotes)
            setAssetsArr(assetsArray)
        }
      }, [user])
  return (
    <div className="page-content">
    <div>
        {role.startsWith('Asset Owner') ? (<h4>Request For Quotes</h4>) : (<h4>Request For Quotes</h4>)}
        {role.startsWith('Asset Owner') ? (<AssetOwnerMetquayIntegratedQuotes quotes={quotes} user={userMetadata} />) : 
        (<ServiceProviderMetquayIntegrated quotes={quotes} user={userMetadata}  setQuotes={setQuotes} assetsArray={assetsArr} userId ={user.sub}/> )}
      </div>
    </div>
  )
}

export default QuotesOverview