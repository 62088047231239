import UpdateQuoteStatus from "./UpdateQuoteStatus"

export const columnsForAssetOwnerQuotes = [
    {
      title: 'Asset ID',
      dataIndex: 'tagNo',
    },
    {
        title: 'Customer',
        dataIndex: 'companyName',
      },
      {
        title: 'Instrument Name',
        dataIndex: 'instrumentName',
      },
      {
        title: 'Instrument Category',
        dataIndex: 'instrumentCategory',
      },
      {
        title: 'Type',
        dataIndex: 'itemType',
      },
      {
        title: 'Seriel No',
        dataIndex: 'serialNo',
      },
      {
        title : 'Price',
        dataIndex : 'price'
      },
      {
        title : 'Turnaround',
        dataIndex : 'turnaround'
      }
]

export const columnsForAssetOwnerInquiry = [
  {
    title: 'RequestId',
    dataIndex: 'quoteId',
  },
  {
    title : 'Total Assets',
    dataIndex : 'totalAssets'
  },
  {
    title : 'Date',
    dataIndex : 'date'
  },
  {
    title : 'Status',
    dataIndex : 'status',
    render : (_ ,record) =>{
      return(<>
      {record.status === 'Awaiting Confirmation' ? (<><UpdateQuoteStatus record={record}/> </>) : (<p> {record.status} </p>) }
      </>)
    }
  }
]