import { useEffect, useState } from "react"
import { Row, Input } from "reactstrap"
import { useAuth0 } from "@auth0/auth0-react"

//service files
import {
  getProductDetailById,
  getPriceDetailsById,
} from "services/paymentService"
import {
  convertUSCentToUSD,
  convertTimeStamptoDateString,
} from "services/commonService"

export const ProductDetails = ({ productId }) => {
  const apiurl = process.env.REACT_APP_AUTH0_API_URL
  const { getAccessTokenSilently, getAccessTokenWithPopup, user } = useAuth0()
  const [productDetails, setProductDetails] = useState({})
  const [productPrice, setProductPrice] = useState(0)
  const [planCreatedDate, setPlanCreatedDate] = useState("")
  const [planLastUpdatedDate, setPlanLastUpdatedDate] = useState("")
  const [isPlanActive, setIsPlanActive] = useState("Active")

  /**
   * getAccessToken method is used to fecth jwt access token from auth0
   * @param {string} audience - the api URL
   * @param {string} claim - the permission
   */
  const getAccessToken = async (audience, claim) => {
    let token
    try {
      token = await getAccessTokenSilently({
        audience,
        claim,
      })
    } catch (exception) {
      token = await getAccessTokenWithPopup({ audience, claim })
    }
    return token
  }

  const getInitialData = async () => {
    let priceId = ""
    const jwtToken = await getAccessToken(apiurl, "edit:assets")
    await getProductDetailById(jwtToken, productId)
      .then(result => {
        priceId = result.data.default_price
        console.log(result)
        setProductDetails(result.data)
        setPlanCreatedDate(convertTimeStamptoDateString(result.data.created))
        setPlanLastUpdatedDate(
          convertTimeStamptoDateString(result.data.updated)
        )
        if (result.data.active) {
          setIsPlanActive("Active")
        } else {
          setIsPlanActive("Inactive")
        }
      })
      .catch(error => {})

    await getPriceDetailsById(jwtToken, priceId)
      .then(result => {
        setProductPrice(convertUSCentToUSD(result.data.unit_amount))
      })
      .catch(error => {})
  }
  useEffect(async () => {
    getInitialData()
  }, [])
  return (
    <>
      <Row>
        <form>
          <div className="form-group row mt-3">
            <div className={"col form-group xs-2"}>
              <label htmlFor="ProductName">Product Name</label>
              <Input
                className="form-control xs-2"
                list="ProductName"
                name="ProductName"
                id="productName"
                disabled
                value={productDetails.name}
              />
            </div>
            <div className={"col form-group xs-2"}>
              <label htmlFor="ProductDescription">Description</label>
              <textarea
                class="form-control xs-2"
                id="productDescription"
                rows="2"
                disabled
                value={productDetails.description}
              ></textarea>
            </div>
          </div>
          <div className="form-group row mt-3">
            <div className={"col form-group xs-2"}>
              <label htmlFor="Price">Price</label>
              <Input
                className="form-control xs-2"
                list="ProductPrice"
                name="ProductPrice"
                id="productPrice"
                disabled
                value={productPrice}
              />
            </div>
            <div className={"col form-group xs-2"}>
              <label htmlFor="ProductStatus">Status</label>
              <Input
                className="form-control xs-2"
                list="ProductStatus"
                name="ProductStatus"
                id="productStatus"
                disabled
                value={isPlanActive}
              />
            </div>
          </div>
          <div className="form-group row mt-3">
            <div className={"col form-group xs-2"}>
              <label htmlFor="CreatedAt">Created At</label>
              <Input
                className="form-control xs-2"
                list="CreatedAt"
                name="CreatedAs"
                id="createdAt"
                disabled
                value={planCreatedDate}
              />
            </div>
            <div className={"col form-group xs-2"}>
              <label htmlFor="LastUpdatedAt">Last Updated At</label>
              <Input
                className="form-control xs-2"
                list="LastUpdatedAt"
                name="LastUpdatedAt"
                id="lastUpdatedAt"
                disabled
                value={planLastUpdatedDate}
              />
            </div>
          </div>
        </form>
      </Row>
    </>
  )
}
