import React, { useReducer, useEffect, useState } from "react"
import {
  Button, Input, Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import { useAuth0 } from "@auth0/auth0-react"
import Dropdown from "react-dropdown"
import axios from "axios"
import { useToasts } from "react-toast-notifications"
import _ from "lodash"

//Import Components
import { TableForCustomFields } from "./TableForCustomFields"

//reducers
import { customDropDownFieldReducer, multiplerecordsreducer } from "../reducer/reducers"


//Import columns
import { addedCustomFieldColumns } from "../data/asset-fields"

//Service files
import {
  getAssetOwner,
} from "../../../services/assetownerService"
import { getServiceProviderById } from "../../../services/serviceProvider"
import { decryptKeyValues } from "../../../services/commonService"
import { user_metadata } from "../../../nameSpaceConfig"


const fieldTypes = ["Text", "Number", "Dropdown", "Date"]

const CustomFieldsTabComponent = props => {
  const { updateCustomFields } = props
  const apiurl = process.env.REACT_APP_AUTH0_API_URL
  const { getAccessTokenSilently, getAccessTokenWithPopup, user } = useAuth0()
  const { addToast } = useToasts()
  const userMetadata = user[user_metadata]
  const [openAddCustomFieldModal, setOpenAddCustomFieldModal] = useState(false)
  const [data, dispatchData] = useReducer(customDropDownFieldReducer, [])


  //new file 
  const [count, setCount] = useState(0)
  const [selectedFieldType, setSelectedFieldType] = useState("Text")
  const [customFields, dispatchCustomFields] = useReducer(customDropDownFieldReducer, { list: [] })
  const [selectRow, setSelectRow] = useState([])
  const [
    multipleRecords,
    dispatchMultipleRecords,
  ] = useReducer(multiplerecordsreducer, { list: [] })
  const [mode, setMode] = useState('Add')
  const [updateButtonEnable, setUpdateButtonEnable] = useState(true)
  const [dateFields, setDateFields] = useState({})

  /**
   * toggleAddCustomFieldsModal function is used to toggle Add/Edit modal
   */
  const toggleAddCustomFieldsModal = () => {
    setOpenAddCustomFieldModal(!openAddCustomFieldModal);
  };


  /**
   * getAccessToken method is used to fecth jwt access token from auth0
   * @param {string} audience - the api URL
   * @param {string} claim - the permission
   */
  const getAccessToken = async (audience, claim) => {
    let token
    try {
      token = await getAccessTokenSilently({
        audience,
        claim,
      })
    } catch (exception) {
      token = await getAccessTokenWithPopup({ audience, claim })
    }
    return token
  }

  /**
   * selectedRow function is used to set the selected row value
   * @param {object} row 
   * @param {boolean} isSelected 
   */
  const selectedRow = (row, isSelected) => {
    if (isSelected) {
      setSelectRow(row)
    } else {
      setSelectRow([])
    }
  }

  /**
  * multipleRows method is used when all rows are selected.
  * @param {list} rows - list of selected rows
  */
  const multipleRows = rows => {
    rows.map(row => {
      selectedRow(row, row.isSelected)
    })
    dispatchMultipleRecords(rows)
  }

  /**
   * isButtonsDisabled function is used to enable/ disabled add, edit, disable and delete buttons based on row selection
   * @param {string} action 
   * @returns 
   */
  // const isButtonsDisabled = action => {
  //   let disabled = true
  //   let selectedRowCount = 0
  //   customFields.list.map(record => {
  //     if (record.isSelected) {
  //       disabled = false
  //       selectedRowCount += 1
  //     }
  //   })
  //   if (action === "Edit") {
  //     if (selectedRowCount === 1) {
  //       return false
  //     } else {
  //       return true
  //     }
  //   }
  //   return disabled
  // }
  const isButtonsDisabled = action => {

    if (action === "Add") {
      let disabled = false
      customFields.list.map(record => {
        if (record.isSelected) {
          disabled = true
        }
      })
      return disabled
    } if (action === "Edit") {

      let selectedRowCount = 0
      customFields.list.map(record => {
        if (record.isSelected) {
          selectedRowCount += 1
        }
      })
      if (selectedRowCount === 1) {
        return false
      } else {
        return true
      }
    }
    if (action === "Delete") {
      let disabled = true
      customFields.list.map(record => {
        if (record.isSelected) {
          disabled = false
        }
      })
      return disabled
    }
    // let disabled = true
    // let selectedRowCount = 0
    // customFields.list.map(record => {
    //   if (record.isSelected) {
    //     disabled = false
    //     selectedRowCount += 1
    //   }
    // })
    // if (action === "Edit") {

    // }
    // return disabled
  }

  /**
   * setCustomFieldsValues function is used to set the data values for custom fields
   * @param {string} field 
   * @param {string} dataValue 
   */
  const setCustomFieldsValues = (field, dataValue) => {
    dispatchData({
      type: "setDataOnFieldChange",
      data: { id: field, value: dataValue },
    })
  }


  /**
   * generateId method is used to generate an id to uniquely identify each row
   * @returns uuid string
   */
  const generateId = () => {
    let dt = new Date().getTime();
    let uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        let r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return uuid;
  }

  /**
   * onPlusButtonClick is called while clicking add/ edit/ disable or delete buttons
   * @param {event} e 
   * @param {string} mode 
   */
  const onPlusButtonClick = (e, mode) => {
    setMode(mode)
    if (mode === 'Delete') {
      onDeleteButtonClick()
    } else if (mode === 'Disable') {
      onDisableButtonClick()
    } else if (mode === 'Add') {
      setCustomFieldsValues('id', generateId())
      setCustomFieldsValues('dataField', '')
      setCustomFieldsValues('type', '')
      setCustomFieldsValues('required', false)
      setCustomFieldsValues('dropdownValues', '')
      toggleAddCustomFieldsModal()
    } else {
      toggleAddCustomFieldsModal()
    }

  }

  // const onAddButtonClick = async (e) =>{
  //   e.preventDefault()
  //   setCustomFieldsValues('id', generateId())
  //   setCustomFieldsValues('dataField', '')
  //   setCustomFieldsValues('type', '')
  //   setCustomFieldsValues('required', false)
  //   setCustomFieldsValues('dropdownValues', '')
  //   toggleAddCustomFieldsModal()
  // }

  // const onEditButtonClick = async (e) => {

  // } 




  /**
   * onSaveButtonClick function will be called when the save button is clicked
   * @param {event} e 
   */
  const onSaveButtonClick = async (e) => {
    e.preventDefault()
    if (mode === 'Add') {
      if (data['text'] !== undefined && data['text'] !== null && data['text'] !== '') {
        if (data['dataField'] !== undefined && data['dataField'] !== null && data['dataField'] !== '') {
          if (data['type'] === undefined || data['type'] === null || data['type'] === '') {
            data['type'] = 'Text'
          }
          if (data['type'] === 'Date' && Object.keys(dateFields).length > 4) {
            addToast('Only 4 date fields can be added', {
              appearance: 'error',
              autoDismiss: true,
            })
          } else {
            setCustomFieldsValues('isDisabled', false)
            dispatchCustomFields({
              type: "setCustomFields",
              data: { id: 'customValues', value: data, mode: mode },
            })
            setSelectedFieldType("")
            setUpdateButtonEnable(false)
            // updateCustomFields(customFields)
            updateCustomFields(data, mode)
            toggleAddCustomFieldsModal()
          }

        }
      }
      else {
        addToast('All fields are mandatory', {
          appearance: 'error',
          autoDismiss: true,
        })
      }

    } else if (mode === 'Edit') {
      // updateCustomFields(customFields)
      dispatchCustomFields({
        type: "setCustomFields",
        data: { id: 'customValues', value: data, mode: mode },
      })
      updateCustomFields(data, mode)
      toggleAddCustomFieldsModal()
    } else if (mode === 'Delete') {

    } else {

    }

  }

  /**
   * setDataForEdit function is sued to set data in custom fields modal while clicking edit button
   */
  const setDataForEdit = () => {
    if (mode === "Edit" && count === 0) {
      dispatchData({
        type: "setRecords",
        data: selectRow,
      })
      setCount(count + 1)
    }
  }

  /**
   * onDeleteButtonClick function will be called whenn delete button is clicked
   */
  const onDeleteButtonClick = () => {
    multipleRecords.list.map(record => {
      dispatchCustomFields({
        type: "setCustomFields",
        data: { id: 'customValues', value: record, mode: 'Delete' },
      })
    })
    updateCustomFields(multipleRecords.list, 'Delete')
  }

  /**
   * onDisableButtonClick function will be called whe disable/enable button is clicked
   */
  const onDisableButtonClick = () => {
    for (let i = 0; i < multipleRecords.list.length; i++) {
      customFields.list.map(field => {
        const isDisabled = multipleRecords.list[i].isDisabled !== undefined ? !multipleRecords.list[i].isDisabled : true
        if (field.dataField === multipleRecords.list[i].dataField) {
          field['isDisabled'] = isDisabled
        }
      })
      setUpdateButtonEnable(false)
    }
    updateCustomFields(customFields, "EnableDisable")
    // console.log(customFields)
  }

  /**
   * onUpdateButtonClick is sued to update the custom fields details in asset owner data in db
   */
  const onUpdateButtonClick = () => {
    updateCustomFields(customFields)
    // console.log(customFields)
  }

  /**
   * onInputFieldChange function will be called when the value is dynamic input fields are changed
   * @param {event} e 
   * @param {string} field 
   * @param {number} index 
   */
  const onInputFieldChange = (e, field) => {
    setDataForEdit()
    let value = ''
    if (field === "type") {
      value = e.value
      if (e.value === 'Dropdown') {
        setSelectedFieldType("Dropdown")
      } else {
        setCustomFieldsValues('dropdownValues', '')
        setSelectedFieldType("")
      }
    } else if (field === "text") {
      value = e.target.value
      const dataFieldValue = value.replaceAll(' ', '').trim()
      setCustomFieldsValues('dataField', dataFieldValue)
    } else if (field === 'required') {
      value = e.target.checked
    } else if (field === 'dropdownValues') {
      const values = e.target.value
      let valueList = values.split(",")
      value = valueList
    }
    setCustomFieldsValues(field, value)
  }

  /**
   * getInitialData function will be called when the page is rendered. 
   */
  const getInitialData = async () => {
    let deData = null
    let customFields = []
    try {
      const jwtToken = await getAccessToken(apiurl, "edit:customer")
      if (userMetadata !== undefined && userMetadata.pk !== undefined) {
        deData = decryptKeyValues(userMetadata.pk)
      }
      if (userMetadata.role.startsWith("Asset Owner")) {
        await getAssetOwner(jwtToken, "", deData).then(result => {
          if (result[0].attributes.preferences !== undefined && result[0].attributes.preferences !== null) {
            customFields = result[0].attributes.preferences.customFields
            setDateFields(result[0].attributes.preferences.dateFields)
          }
        })
      } else {
        await getServiceProviderById(jwtToken, "", deData)
          .then(result => {
            if (result[0].attributes.preferences !== undefined && result[0].attributes.preferences !== null) {
              customFields = result[0].attributes.preferences.customFields
              setDateFields(result[0].attributes.preferences.dateFields)
            }
          })
          .catch(error => {
          })
      }
      if (customFields !== undefined) {
        customFields.map(field => {
          dispatchCustomFields({
            type: "setCustomFields",
            data: { id: 'customValues', value: field, mode: 'setInitialData' },
          })
        })
      }

    } catch (error) {
      if (axios.isCancel(error)) {
      } else {
        throw error
      }
    }
  }

  useEffect(() => {
    getInitialData()
  }, [])

  return (
    <React.Fragment>
      <div className="text-end">
        <Button color="primary" className="mb-2 me-2" onClick={e => onPlusButtonClick(e, "Add")} disabled={isButtonsDisabled("Add")}> Add </Button>
        <Button color="primary" className="mb-2 me-2" onClick={e => onPlusButtonClick(e, "Edit")} disabled={isButtonsDisabled("Edit")}> Edit </Button>
        <Button color="danger" className="mb-2 me-2" onClick={e => onPlusButtonClick(e, "Delete")} disabled={isButtonsDisabled("Delete")}> Delete </Button>
        <Button color="warning" className="mb-2 me-2" onClick={e => onPlusButtonClick(e, "Disable")} disabled={isButtonsDisabled("Delete")}> Enable/ Disable</Button>
        <Modal size="s" isOpen={openAddCustomFieldModal}>
          <ModalHeader toggle={toggleAddCustomFieldsModal}>{'Custom Fields'}</ModalHeader>
          <ModalBody>
            <form className="pl-3 pr-3 ">
              <div class="row">
                <div className={"form-group"} style={{ marginTop: "1%" }}>
                  <label id={"fieldName"} >Enter field name
                    <span className="required-field"></span>
                  </label>
                  <Input id={"fieldName"} type="text" onChange={e => onInputFieldChange(e, "text")} defaultValue={selectRow['text']} />
                </div>
                <div className={"form-group"} style={{ marginTop: "1%" }}>
                  <label id={"fieldType"} >Enter field type
                    <span className="required-field"></span>
                  </label>
                  <Dropdown
                    id={"fieldType"}
                    options={fieldTypes}
                    value={selectRow === undefined ? fieldTypes[0] : selectRow['type']}
                    onChange={e => onInputFieldChange(e, "type")}
                    defaultValue={"gag"}
                  />
                </div>
                {
                  selectedFieldType === 'Dropdown' || selectRow['type'] === 'Dropdown' ?
                    <div className={"form-group"} style={{ marginTop: "1%" }}>
                      <label id={"dataField"} >Enter Dropdown Values</label>
                      <Input type="text" onChange={e => onInputFieldChange(e, "dropdownValues")} placeholder='Enter comma separated values' defaultValue={selectRow === undefined ? 'Text' : selectRow['dropdownValues']} />
                    </div>
                    : ''
                }
                <div className={"form-group"} style={{ marginTop: "1%" }}>
                  <label id={"isMandatory"} >Is mandatory</label>
                  <Input type="checkbox" id={"isMandatory"} style={{ marginTop: "1%", marginLeft: "1%" }} defaultChecked={selectRow === undefined ? false : selectRow['required']} onChange={e => onInputFieldChange(e, "required")} />
                </div>
              </div>
              <div className="text-end">
                <Button color="primary" className="btn btn-primary mt-2" onClick={onSaveButtonClick}>Save</Button>
              </div>
            </form>
          </ModalBody>
        </Modal>
      </div>
      <div className="row">
        <TableForCustomFields customColumns={addedCustomFieldColumns} customFields={customFields.list} multipleRows={multipleRows} />
      </div>
      {/* <div className="text-end">
        <Button color="primary" className="btn btn-primary m-2" disabled={updateButtonEnable} onClick={onUpdateButtonClick} >Update</Button>
      </div> */}
    </React.Fragment>
  )
}

export default CustomFieldsTabComponent