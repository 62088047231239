import React, { useState } from "react"
import { Row, Col, Card, CardBody, Button, Input, Badge, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit"
import { useToasts } from "react-toast-notifications"

import Dropzone from "react-dropzone"
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css"

import S3FileUpload from "react-s3"

// import {conditionColumnFormatter} from "./"
import { demoQrCode, qrCodeColumnFormatter } from "./QRCodeGenerator"

//columns
import {
  assetColumnsForAssetOwnerLogin,
  expandableColumns,
} from "../data/asset-fields"
import "../css/modal-style.css"

//service
import { decryptKeyValues } from "../../../services/commonService"

/**
 * conditionColumnFormatter is sued to format condition column data bsed on cal due date.
 * @param {object} cell
 * @param {object} row
 * @returns react component
 */
export const conditionColumnFormatter = (cell, row) => {
  let result = new Date()
  result.setDate(result.getDate() + 90)
  const badgeColour =
    new Date(row.DueDate) < new Date()
      ? "danger"
      : new Date(row.DueDate) < new Date(result)
        ? "warning"
        : "success"
  return (
    <Badge className={"rounded-pill  font-size-12 badge-soft-" + badgeColour} >
      {badgeColour === "danger"
        ? "Danger"
        : badgeColour === "success"
          ? "Good"
          : "Warning"}
    </Badge>
  )
}

export const TableWithRowExpand = props => {
  const {
    // columnsCustomer,
    mainRowFields,
    expandableRowFields,
    records,
    multipleRows,
    userData,
    getNextAssetData,
    getPreviousAssetData,
    firstKey,
    lastEvaluatedKey,
    onFilter,
    onSearchKeyChange,
    isGood,
    isWarning,
    isDanger,
    count,
    searchKey,
    totalFieldsForModal,
  } = props
  const [goodToggle, setGoodToggle] = useState(isGood)
  const [warningToggle, setWarningToggle] = useState(isWarning)
  const [dangerToggle, setDangerToggle] = useState(isDanger)
  const [prevCondition, setPrevCondition] = useState("")
  const { addToast } = useToasts()
  const { ExportCSVButton } = CSVExport
  const [drp_link, setdrp_link] = useState(false);
  if (mainRowFields[6] !== undefined) {
    mainRowFields[6].formatter = demoQrCode
  }
  if (mainRowFields[0] !== undefined) {
    mainRowFields[0].formatter = conditionColumnFormatter
  }
  for(let i=0; i < expandableRowFields.length; i++){
    if(expandableRowFields[i].dataField === 'Report'){
      expandableRowFields[i].formatter = qrCodeColumnFormatter
    }
  }
  for(let i=0; i < mainRowFields.length; i++){
    if(mainRowFields[i].dataField === 'Report'){
      mainRowFields[i].formatter = qrCodeColumnFormatter
    }
  }

  /**
   * assignToggleValues is a common method used to toggle and untoggle(previously selected) buttons based on selected condition
   * @param {boolean} toggleValueForGood
   * @param {boolean} toggleValueForWarning
   * @param {boolean} toggleValueForDanger
   */
  const assignToggleValues = (
    toggleValueForGood,
    toggleValueForWarning,
    toggleValueForDanger
  ) => {
    setGoodToggle(toggleValueForGood)
    setWarningToggle(toggleValueForWarning)
    setDangerToggle(toggleValueForDanger)
  }

  /**
   * onToggle method is call the filter method based on selected condition
   * @param {event} e
   * @param {string} condition
   */
  const onToggle = async (e, condition) => {
    if (prevCondition === condition) {
      assignToggleValues(false, false, false)
      onFilter(e, "")
      setPrevCondition("", false, false, false)
    } else {
      if (condition == "good") {
        assignToggleValues(true, false, false)
        onFilter(e, condition, true, false, false)
      } else if (condition == "warning") {
        assignToggleValues(false, true, false)
        onFilter(e, condition, false, true, false)
      } else if (condition == "danger") {
        assignToggleValues(false, false, true)
        onFilter(e, condition, false, false, true)
      }

      setPrevCondition(condition)
    }
  }

  /**
   * expandRow function is provide inner row details of the table
   */
  const expandRow = {
    renderer: row => (
      <div>
        <BootstrapTable
          bootstrap4
          {...props.baseProps}
          keyField="id"
          bordered={false}
          data={[row]}
          // columns={expandableColumns}
          columns={expandableRowFields === undefined || expandableRowFields.length === 0 ? expandableColumns : expandableRowFields}
          wrapperClasses="table-responsive"
        ></BootstrapTable>
      </div>
    ),
    showExpandColumn: true,
    onlyOneExpanding: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      return isAnyExpands ? (
        <i className="dripicons-minus" />
      ) : (
        <i className="dripicons-plus" />
      )
    },
    expandColumnRenderer: ({ expanded }) => {
      return expanded ? (
        <i className="dripicons-minus" />
      ) : (
        <i className="dripicons-plus" />
      )
    },
  }

  /**
   * handleFiles function will be called when a file uploaded for import
   * @param {file} files 
   */
  const handleFiles = files => {
    const primaryKeyvalue = decryptKeyValues(userData.pk)
    const sortKeyValue = decryptKeyValues(userData.sk)
    let fileNameWithAssetOwner = ""
    let directoryName = ""
    if (sortKeyValue.startsWith("USER")) {
      fileNameWithAssetOwner = sortKeyValue
      directoryName = primaryKeyvalue + "/" + sortKeyValue
    } else {
      fileNameWithAssetOwner = primaryKeyvalue
      directoryName = primaryKeyvalue + "/" + primaryKeyvalue
    }
    let file = files[0]
    if (file !== undefined) {
      const fileNameArray = file.name.split(".")
      const fileType = fileNameArray[fileNameArray.length - 1]
      if (fileType === "csv") {
        const fileName = fileNameWithAssetOwner + ".csv"
        Object.defineProperty(file, "name", { value: fileName })

        const config = {
          bucketName: process.env.REACT_APP_ASSET_IMPORT_BUCKET_NAME,

          dirName: directoryName /* optional*/,
          region: process.env.REACT_APP_REGION_IMPORT_TEST,
          accessKeyId: process.env.REACT_APP_ACCESS_ID,
          secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
        }

        S3FileUpload.uploadFile(file, config)
          .then(data => {
            addToast(
              "Your file has been uploaded. We will send you an email after completion of import.",
              {
                appearance: "info",
                autoDismiss: true,
              }
            )
          })
          .catch(err => { }
            // console.error(err)
          )
      } else {
        addToast("Please select a CSV file.", {
          appearance: "error",
          autoDismiss: true,
        })
      }
    }
  }

  const handleCheckboxClick = (e, rowIndex, checked) => {
    let rows = []
    records[rowIndex].isSelected = checked
    rows.push(records[rowIndex])
    multipleRows(rows)
  }

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    onSelect: (row, isSelect, rowIndex, e) => {
      let rows = []
      row.isSelected = isSelect
      rows.push(row)
      multipleRows(rows)
    },
    onSelectAll: (isSelect, rows, e) => {
      rows.map(row => {
        row.isSelected = isSelect
      })
      multipleRows(rows)
    },
    selectionRenderer: ({ checked, disabled, rowIndex }) => {
      return (
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            checked={checked}
            disabled={disabled}
            onChange={() => { }}
          />
          <label
            className="custom-control-label"
            onClick={e => {
              e.preventDefault()
              handleCheckboxClick(e, rowIndex, checked)
            }}
          ></label>
        </div>
      )
    },
    selectionHeaderRenderer: ({ indeterminate, ...rest }) => {
      return (
        <div className="custom-control custom-checkbox select-header">
          <input
            type="checkbox"
            className="custom-control-input"
            id="customCheck1"
            ref={input => {
              if (input) input.indeterminate = indeterminate
            }}
            {...rest}
            onChange={() => { }}
          />
          <label
            className="custom-control-label"
            htmlFor="customCheck1"
            onClick={e => {
              e.preventDefault()
            }}
          ></label>
        </div>
      )
    },
  }

  return (
    <Card>
      <CardBody>
        <ToolkitProvider
          bootstrap4
          keyField="id"
          data={records}
          //columns to be exported to a csv file
          columns={totalFieldsForModal}
          search
          exportCSV={{ onlyExportFiltered: true, exportAll: false }}
        >
          {props => (
            <React.Fragment>
              <Row>
                <div className="col-md-12 text-end">
                  <Row>
                    <Col className="text-start">

                      <div
                        className="form-check form-switch good-color-style"
                        style={{ flex: 1, marginLeft: "3%", float: "left" }}
                      >
                        <input
                          type="checkbox"
                          className="form-check-input good-color-style"
                          id="customSwitch1"
                          checked={goodToggle}
                          onChange={e => {
                            onToggle(e, "good")
                          }}
                        />
                        <label
                          className="form-check-label good-color-style"
                          color="primary"
                          htmlFor="customSwitch1"
                        >
                          Good (
                          {count !== undefined && count.ALLGOODASSETSCOUNT !== undefined
                            ? count.ALLGOODASSETSCOUNT
                            : ""}
                          )
                        </label>
                      </div>
                      <div
                        className="form-check form-switch good-color-style"
                        style={{ flex: 1, marginLeft: "3%", float: "left" }}
                      >
                        <input
                          type="checkbox"
                          className="form-check-input warning-color-style"
                          id="customSwitch2"
                          checked={warningToggle}
                          onChange={e => {
                            onToggle(e, "warning")
                          }}
                        />
                        <label class="form-check-label" htmlFor="customSwitch2">
                          Warning (
                          {count !== undefined && count.ALLWARNINGASSETSCOUNT !== undefined
                            ? count.ALLWARNINGASSETSCOUNT
                            : ""}
                          )
                        </label>
                      </div>
                      <div
                        class="form-check form-switch good-color-style"
                        style={{ flex: 1, marginLeft: "3%", float: "left" }}
                      >
                        <input
                          type="checkbox"
                          class="form-check-input danger-color-style"
                          id="customSwitch3"
                          checked={dangerToggle}
                          onChange={e => {
                            onToggle(e, "danger")
                          }}
                        />
                        <label class="form-check-label" htmlFor="customSwitch3">
                          Danger (
                          {count !== undefined && count.ALLDANGERASSETSCOUNT !== undefined
                            ? count.ALLDANGERASSETSCOUNT
                            : ""}
                          )
                        </label>
                      </div>
                    </Col>

                    <Col className="text-end">
                      <div
                        style={{ flex: 3, float: "right" }}
                      >
                        <ExportCSVButton
                          {...props.csvProps}
                          className="btn btn-primary mb-2 me-2"
                        >
                          Export
                        </ExportCSVButton>
                      </div>
                      {userData.role.startsWith("Asset Owner") ? <div
                        style={{ flex: 2, float: "right", marginRight: "1%" }}
                      >
                        <Dropzone onDrop={handleFiles}>
                          {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <Button className="mb-2 me-2">Import</Button>
                            </div>
                          )}
                        </Dropzone>
                        
                      </div> 
                      : ""}

                      <div
                        style={{ flex: 1, float: "right", marginRight: "1%" }}
                      >
                        <Input
                          placeholder="Search"
                          type="search"
                          className="mb-2 me-2 "
                          defaultValue={searchKey}
                          onChange={({ target: { value } }) =>
                            onSearchKeyChange(value)
                          }
                        />
                      </div>


                    </Col>
                    {/* <Col>
                    <div>
                    <Input
                      style={{ flex: 1, marginRight: "1%", float: "left" }}
                      placeholder="Search"
                      type="search"
                      className="nm-3 col-lg-4"
                      defaultValue={searchKey}
                      onChange={({ target: { value } }) =>
                        onSearchKeyChange(value)
                      }
                    />
                    <Dropzone onDrop={handleFiles}>
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <Button className="mb-2 me-2">Import</Button>
                        </div>
                      )}
                    </Dropzone>

                    <ExportCSVButton
                      {...props.csvProps}
                      className="btn btn-primary mb-2 me-2"
                    >
                      Export
                    </ExportCSVButton>
                    </div>
                    </Col> */}
                  </Row>
                </div>
              </Row>

              <BootstrapTable
                bootstrap4
                {...props.baseProps}
                keyField="id"
                bordered={false}
                selectRow={selectRow}
                data={records}
                // columns={columnsCustomer}
                columns={mainRowFields === undefined || mainRowFields.length === 0 ? assetColumnsForAssetOwnerLogin : mainRowFields}
                // pagination={paginationFactory({ sizePerPage: 50 })}
                expandRow={expandRow}
                wrapperClasses="table-responsive"
              />
              <div className="col-md-12">
                <Row>
                  <Col className=" text-start">
                    <Badge className={"rounded-pill  font-size-12 badge-soft-success"} pill>
                      Good{" "}
                    </Badge>
                    <label className="mb-2 me-2">{" (Due>90 days), "}</label>
                    <Badge className={"rounded-pill  font-size-12 badge-soft-warning"} pill>
                      Warning{" "}
                    </Badge>
                    <label className="mb-2 me-2">{" (Due<90 days),  "}</label>
                    <Badge className={"rounded-pill  font-size-12 badge-soft-danger"} pill>
                      Danger{" "}
                    </Badge>
                    <label className="mb-2 me-2">{" ( Past Due)"}</label>
                  </Col>
                  <Col className="text-end">
                    <Button
                      className="mb-2 me-2"
                      color="primary"
                      onClick={getPreviousAssetData}
                      disabled={firstKey === undefined || firstKey.pk === undefined}
                    >
                      Prev
                    </Button>

                    <Button
                      className="mb-2 me-2"
                      color="primary"
                      onClick={getNextAssetData}
                      disabled={
                        lastEvaluatedKey === undefined ||
                        lastEvaluatedKey.pk === undefined
                      }
                    >
                      Next
                    </Button>
                  </Col>
                </Row>
              </div>
              <div className="col-md-12">
                {records.length} record(s) per page
              </div>
            </React.Fragment>
          )}
        </ToolkitProvider>
      </CardBody>
    </Card>
  )
}
