import PropTypes from "prop-types";
import React, { useEffect, useState }  from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react"
import { user_metadata } from "../../nameSpaceConfig"

//Import CryptoJS to decrypt primary key value
import CryptoJS from "crypto-js"

import SidebarContent from "./SidebarContent";

import {
  decryptKeyValues
} from "../../services/commonService"
import {getAssetOwner} from "../../services/assetownerService"
import {getServiceProviderById} from "../../services/serviceProvider"

import { Link } from "react-router-dom";

import traceFiiLogo from '../../assets/images/tracefii.png'
import { withTranslation } from "react-i18next";

const Sidebar = props => {
  const apiurl = process.env.REACT_APP_AUTH0_API_URL
  const { getAccessTokenSilently, getAccessTokenWithPopup, user } = useAuth0()
  const userMetadata = user[user_metadata]
  const [metquayAssetOwnerKey, setMetquayAssetOwnerKey] = useState(" ")
  const [metquaySecretKey, setMetquaySecretKey] = useState(" ")
  const [metquayUrl, setMetquayUrl] = useState(" ")
  let sortKey = ""
  if(userMetadata.sk !== null && userMetadata.sk !==undefined){
    sortKey = CryptoJS.enc.Base64.parse(userMetadata.sk).toString(CryptoJS.enc.Utf8)
  }

  /**
   * getAccessToken method is used to fecth jwt access token from auth0
   * @param {string} audience - the api URL
   * @param {string} claim - the permission
   */
   const getAccessToken = async (audience, claim) => {
    let token
    try {
      token = await getAccessTokenSilently({
        audience,
        claim,
      })
    } catch (exception) {
      token = await getAccessTokenWithPopup({ audience, claim })
    }
    return token
  }

  useEffect(() => {
    const initMenu = async () => {
      //
      if (userMetadata.role.startsWith("Asset Owner")) {
        const decodedPrimaryKey = decryptKeyValues(userMetadata.pk)
        const jwtToken = await getAccessToken(apiurl, "add:asset")
        await getAssetOwner(jwtToken, null, decodedPrimaryKey)
              .then(result => {
              //  console.log("666666666666666666^")
              //  console.log(result)
               setMetquayAssetOwnerKey(result[0].attributes.metquayAssetOwnerKey)
               setMetquayUrl(result[0].attributes.metquayurl)
              })
              .catch(error => {
               
              })
      }else if (userMetadata.role.startsWith("Service Provider")) {
        const decodedPrimaryKey = decryptKeyValues(userMetadata.pk)
        const jwtToken = await getAccessToken(apiurl, "add:asset")
        await getServiceProviderById(jwtToken, "", decodedPrimaryKey)
        .then(result => {
          setMetquaySecretKey(result[0].attributes.metquaySecretKey)
          setMetquayUrl(result[0].attributes.metquayurl)
        })
        .catch(error => {
        })
      }
    }
    initMenu()
  }, [])

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
        <Link to="/" className="logo ">
          <img src={traceFiiLogo} alt="" height="30" />
        </Link>
        </div>
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? <SidebarContent  metquayAssetOwnerKey = {metquayAssetOwnerKey} metquayUrl={metquayUrl}/> : <SidebarContent />}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  }
}
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)))
