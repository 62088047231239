import { QRCodeGeneratorForWorks } from "../Components/QRCodeGenerator"
import {statusColumnFormatter} from "../../Dashboard/LiveUpdates"

const metquayWorksFieldForServiceProvider = [
  {
    dataField: "status",
    text: "Status",
    sort: false,
    float: "left",
    type: "string",
    formatter: statusColumnFormatter,
  },
    {
      dataField: "assetOwner",
      text: "Asset Owner",
      sort: true,
      float: "left",
      type: "string",
    //   formatter: conditionColumnFormatter,
    },
    {
      dataField: "workNo",
      text: "Work#",
      sort: true,
      float: "right",
      type: "number",
      maxlength: 15,
    },
   
    // {
    //   dataField: "status",
    //   text: "Status",
    //   sort: false,
    //   float: "left",
    //   type: "string",
    // },
    {
      dataField: "instrumentCategory",
      text: "Instrument Category",
      sort: true,
      float: "right",
      type: "string",
    },
    {
      dataField: "instrumentDescription",
      text: "Instrument Description",
      sort: false,
      float: "left",
      type: "string",
    },
    {
      dataField: "serialNo",
      text: "Serial#",
      sort: true,
      float: "right",
      type: "string",
      maxlength: 50,
    },
    
    {
      dataField: "tagNo",
      text: "Tag#",
      sort: false,
      float: "left",
      type: "date",
    },
    {
      dataField: "instrumentUniqueNo",
      text: "Instrument Unique#",
      sort: true,
      float: "right",
      type: "date",
    },
    {
      dataField: "salesOrderNo",
      text: "Sales Order#",
      sort: true,
      float: "left",
      type: "date",
    },
    // {
    //   dataField: "reportType",
    //   text: "Report Type",
    //   sort: true,
    //   float: "left",
    //   type: "date",
    // },
    // {
    //     dataField: "deliveryDate",
    //     text: "Delivery Date",
    //     sort: true,
    //     float: "left",
    //     type: "date",
    //   },
    //   {
    //     dataField: "createdDate",
    //     text: "Created Date",
    //     sort: true,
    //     float: "left",
    //     type: "date",
    //   },
    //   {
    //     dataField: "finishedDate",
    //     text: "Finished Date",
    //     sort: true,
    //     float: "left",
    //     type: "date",
    //   },
    //   {
    //     dataField: "certificateUrl",
    //     text: "Certificate Url",
    //     sort: true,
    //     float: "left",
    //     type: "date",
    //   },

  ]

  const metquayWorksExpandableFieldForServiceProvider = [
    
    {
      dataField: "certificateNo",
      text: "Certificate#",
      sort: true,
      float: "left",
      type: "string",
      maxlength: 15,
    },
    {
      dataField: "reportType",
      text: "Report Type",
      sort: true,
      float: "left",
      type: "date",
    },
    {
        dataField: "deliveryDate",
        text: "Delivery Date",
        sort: true,
        float: "left",
        type: "date",
      },
      {
        dataField: "createdDate",
        text: "Created Date",
        sort: true,
        float: "left",
        type: "date",
      },
      {
        dataField: "finishedDate",
        text: "Finished Date",
        sort: true,
        float: "left",
        type: "date",
      },
      {
        dataField: "certificateUrl",
        text: "QR Code",
        sort: true,
        float: "left",
        type: "date",
        formatter: QRCodeGeneratorForWorks,
      },
      {
        dataField : "customerPoNo",
        text : "Customer Po No.",
        sort : true,
        float : "left",
        type : "date",
      }
  ]
  export {
    metquayWorksFieldForServiceProvider, metquayWorksExpandableFieldForServiceProvider
  }