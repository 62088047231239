import React, { useEffect, useState , useRef } from 'react'
import {
  Col,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  CardBody,
  Card,
} from "reactstrap"
import { GoogleMap, useLoadScript, Marker  } from '@react-google-maps/api'
import { getAllLocations, getLocationOfAsset } from 'services/assetService'
import { useAuth0 } from "@auth0/auth0-react"
import { user_metadata } from 'nameSpaceConfig';
import { Hint } from 'react-autocomplete-hint';
import CryptoJS from "crypto-js"
import AssetTracking from './AssetTracking'
import LocationForAssetTracking from './Asset-management/Components/LocationForAssetTracking'
import toast, { Toaster } from 'react-hot-toast';
const apiurl = process.env.REACT_APP_AUTH0_API_URL
const AssetLocation = () => {
    const { getAccessTokenSilently, getAccessTokenWithPopup, user } = useAuth0()
    const [hintData, setHintData] = useState([])
    const userMetadata = user[user_metadata]
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLEMAPS_API
      })
    const [assetData , setAssetData] = useState({})
    const [markers , setMarkers] = useState([])
    const inputRef = useRef(null);
    const getAccessToken = async (audience, claim) => {
        let token
        try {
          token = await getAccessTokenSilently({
            audience,
            claim,
          })
        } catch (exception) {
          try {
            token = await getAccessTokenWithPopup({ audience, claim })
          } catch (ex) {
            token = ""
          }
        }
        return token
    }

    const onLoad = (map) => {
        console.log('map: ', map);
      };
      const options = {
        disableDefaultUI: true,
        zoomControl: true
      };
      const center = {
        lat: 37.7749,
        lng: -122.4194
      };
      const containerStyle = {
        width: '100%',
        height: '400px'
      };


      const handleAssetLocation = async(e , locationData)=>{
        e.preventDefault();
        if(userMetadata !== undefined){
            var assetOwnerPk =  CryptoJS.enc.Base64.parse(userMetadata.pk).toString(CryptoJS.enc.Utf8)
          }
          if(inputRef.current.value.length > 0){
            if(hintData.includes(inputRef.current.value)){
              const jwtToken = await getAccessToken(apiurl, 'add:asset')
              const locations = await getLocationOfAsset(jwtToken , inputRef.current.value ? inputRef.current.value : locationData , assetOwnerPk)
              setAssetData(locations[0])
              setMarkers(locations[0].attributes?.locations)
              }else {
                toast.error("Asset not found , Please check cases as well")
              }
          }else {
            const jwtToken = await getAccessToken(apiurl, 'add:asset')
            const locations = await getLocationOfAsset(jwtToken , locationData , assetOwnerPk)
            setAssetData(locations[0])
            setMarkers(locations[0].attributes?.locations)
          }
          
      }
      useEffect(async() => {
        if(userMetadata !== undefined){
            var assetOwnerPk =  CryptoJS.enc.Base64.parse(userMetadata.pk).toString(CryptoJS.enc.Utf8)
          }
        const jwtToken = await getAccessToken(apiurl, 'add:asset')
        const allAssets = await getAllLocations(jwtToken , assetOwnerPk)
        const namesOfAsset = allAssets.map(item => item.pk);
        setHintData(namesOfAsset)
      }, [])
      const [modal, setModal] = useState(false);
      const toggle = () => setModal(!modal);
  return (

    <div className="page-content">
            <Modal isOpen={modal} toggle={toggle} size="xl">
            <ModalHeader toggle={toggle}>Scan Asset</ModalHeader>
            <ModalBody>
              <AssetTracking  
              modal = {toggle}
              handleAssetLocation = {handleAssetLocation}
              />
            </ModalBody>
          </Modal>


      <div style={{display:"flex" , justifyContent:"space-between"}}>
      <h3>Asset Location</h3>
      </div>
      <Row>
        <div style={{margin:"1rem 0rem 0rem 0rem"}}>
            <form action="submit" style={{display:"flex" , gap:"1rem" , outline:"1px"}}>
            <Hint options={hintData} allowTabFill>
                <input type="text" placeholder='Asset Seriel Number' className = 'form-control' ref={inputRef} />
            </Hint>
                <Button size='sm' color='success' onClick={handleAssetLocation}> Check Location</Button>
                <Button size= 'md' color='danger' onClick={toggle}>Scan Asset</Button>
            </form>
        </div>
        <Col xs={12} md={7} style={{marginTop:"2rem" , marginRight:"2rem"}}>
          <Card>
          <CardBody>
        {
            isLoaded && 
            (
            <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={1}
            options={options}
            onLoad={onLoad}
            >
            {markers.map((marker, index) => (
            <Marker key={index} position={marker} />
            ))}
            </GoogleMap>
            )
        }
        </CardBody>
        </Card>
        </Col>
        <Col md={4} style={{marginTop:"2rem"}}>
        <React.Fragment>
        {" "}
        <Card outline color="primary" className="border">
          <CardBody>
            <h4 className="mb-4 card-title text-black"> <span style={{fontWeight:"lighter"}}>Asset No :</span> {assetData?.pk}</h4>
            <Row>
            </Row>
            <p className="text-black mb-3">
            Last Location : 
            <LocationForAssetTracking location = {(markers.slice(-1))}/>
            </p>
            <p className="text-black mb-3" >
            Last CheckIn Time : <span style={{fontWeight:"bold"}}>{assetData?.attributes?.lastLocation?.checkoutTime}</span> 
            </p>
          </CardBody>
        </Card>
      </React.Fragment>
        </Col>
      </Row>
      <Toaster 
          position="bottom-right"
          reverseOrder={false} />
    </div>
  )
}

export default AssetLocation