import http from "./httpService"

/**
 * getUsers method is used to fetch all users from db.
 * @param {string} cancelToken - token generated from axios.
 * @param {string} jwtToken - token from user
 */
export async function getUsers(cancelToken, jwtToken) {
  http.setJwt(jwtToken)
  const users = await http.get(`/users`)
  const data = users.data.map(item => (item = clean(item)))
  return data
}

/**
 * getUsersById method is used to fetch users from db based on id.
 * @param {*} cancelToken - token generated from axios.
 * @param {*} jwtToken - token from user
 * @param {*} pk - hash key
 */
export async function getUsersById(cancelToken, jwtToken, pk, role, assetOwnerSortKey) {
  http.setJwt(jwtToken)
  let params ={};
  params.pk= pk
  params.role= role
  params.assetOwnerSortKey= assetOwnerSortKey
  const headers = {
    pk: pk,
    role: role,
  }
  const users = await http.get(`/users/byId`, {
     params: { pk: pk,role:role, sk:assetOwnerSortKey }
  })
  const data = users.data.map(item => (item = clean(item)))
  return data
}

/**
 * addUserData method is used to add or update user data to the db.
 * @param {object} data - data to be added / updated
 * @param {string} action - action to be performed
 * @param {string} _id - id of selected data incase of update function
 */
export async function addUserData(data, action, jwtToken, _id) {
  const newData = clean(data)
  http.setJwt(jwtToken)
  if (action === "Add") {
    const user = await http.post(`/users`, newData)
    return user
  } else if (action === "Edit") {
    const user = await http.put(`/users`, newData)
    return user
  } else if (action === "Delete") {
    // let user
    // newData.map(async deleteData => {
    //   if (deleteData.isSelected) {
    //     const headers = {
    //       id: deleteData.id,
    //       pk: deleteData.pk,
    //       email: deleteData.emailAddress,
    //     }
    //     user = await http.delete(`/users/${_id}`, { headers, data })
    //   }
    // })

    // return user

    const headers = {}
    const assets = await http.delete(
      `/users`,
      { data },
      {
        headers,
      }
    )
    return assets
  }
}

export async function getUserNamesById( jwtToken, pk) {
  http.setJwt(jwtToken)
  let params ={};
  params.pk= pk
  const users = await http.get(`/users/getUserNameById`, {
     params: { pk: pk }
  })
  // console.log(users)
  return users
}

export async function getAuthUserDetails( jwtToken, userId) {
  http.setJwt(jwtToken)
  const users = await http.get(`/users/getAuthUser`, {
    params: { userId: userId }
 })
  // const data = users.data.map(item => (item = clean(item)))
  return users
}

export async function getAuthUserDetailsUsingEmailId( jwtToken, emailId) {
  http.setJwt(jwtToken)
  const users = await http.get(`/users/getAuthUserDetailsUsingEmailId`, {
    params: { emailId: emailId }
 })
  // const data = users.data.map(item => (item = clean(item)))
  return users.data
}

export async function getOwnerNameAndId( jwtToken, pk) {
  http.setJwt(jwtToken)
  let params ={};
  params.pk= pk
  const users = await http.get(`/users/getOwnerNameAndId`, {
     params: { pk: pk }
  })
  // console.log(users)
  return users.data
}

/**
 * getUsers method is used to fetch all users from db.
 * @param {string} cancelToken - token generated from axios.
 * @param {string} jwtToken - token from user
 */
 export async function getRoleDataForUserCreation(jwtToken, pk, type) {
  http.setJwt(jwtToken)
  const users = await http.get(`/users/getRoleDataForUserCreation`, {
    params: { pk: pk, type: type }
 })
  const data = users.data.map(item => (item = clean(item)))
  return data
}

/**
 * getAuthUserByEmail is used to fetch the user by Email
 * @param {string} jwtToken - token from user
 * @param {string} email - to get the data using the email
 */
export async function getAuthUserByEmail(jwtToken , email){
  // http.setJwt(jwtToken)
  const result = await http.get('/users/getAuthUserByEmail', {
    params:{email:email}
  })
  return result.data[0]
}


export async function sendVerificationMail(jwtToken, userData) {
  http.setJwt(jwtToken)
 const user = await http.post(`/users/sendVerificationMailToSignedUpUsers`, userData)
    return user
}

function clean(obj) {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === ""
    ) {
      delete obj[propName]
    }
  }
  return obj
}
