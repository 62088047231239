import  { useState } from 'react'
import { QrReader } from 'react-qr-reader';
import { Button, Col, Row } from 'reactstrap';
import { useLoadScript } from '@react-google-maps/api';
import { useAuth0 } from "@auth0/auth0-react"
import { user_metadata } from 'nameSpaceConfig';
import { putLocationInAsset } from 'services/assetService';
import CryptoJS from "crypto-js"
import toast, { Toaster } from 'react-hot-toast';
const apiurl = process.env.REACT_APP_AUTH0_API_URL
const AssetTracking = ({modal , handleAssetLocation}) => {
  const [data, setData] = useState({});
  const { getAccessTokenSilently, getAccessTokenWithPopup, user } = useAuth0()
  const userMetadata = user[user_metadata]
  const[currentPosition , setCurrentPosition] = useState({
    lat : null, lng : null
  })
  const getAccessToken = async (audience, claim) => {
    let token
    try {
      token = await getAccessTokenSilently({
        audience,
        claim,
      })
    } catch (exception) {
      try {
        token = await getAccessTokenWithPopup({ audience, claim })
      } catch (ex) {
        token = ""
      }
    }
    return token
  }
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyDCRgorAlAjM4D2JBESmZJ_5z0Gk7-VXEQ',
  });
  
  const handleUserLocation = async(e)=>{
    e.preventDefault();
    if(isLoaded){
      navigator.geolocation.getCurrentPosition(position=>
          setCurrentPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          })
      )}
      if(loadError){
        console.log("Create modal for failure")
      }
    }
    const handleUserSubmission = async(e)=>{
      e.preventDefault();
      if(userMetadata !== undefined){
        var assetOwnerPk =  CryptoJS.enc.Base64.parse(userMetadata.pk).toString(CryptoJS.enc.Utf8)
      }
      if ((currentPosition.lat || currentPosition.lng) === null || undefined) {
        toast.error("Location is required")
      }else {
        const jwtToken = await getAccessToken(apiurl, 'add:asset')
        const assetLocationObj = await putLocationInAsset(jwtToken , data.assetNo , assetOwnerPk , currentPosition)
        if(assetLocationObj){
          toast.success("Asset Location Added")
          modal()
          handleAssetLocation(e , data.assetNo)
        }else{
          toast.error("Error Occured while doing the operation")
        }}
    }

  return (
    <div className="page-content" style={{marginTop:"-5rem"}}>
      <Row>
        <Col xs={12} md={12}>
          <QrReader
            delay={300}
            onResult={(result, error) => {
              if (!!result) {
                setData(JSON.parse(result?.text));
              }

              if (!!error) {
                // toast.error("Failed to Scan")
              }
            }}
            style={{ width: '100%' }}
            constraints={{
              facingMode:"environment"
            }}
          />
        </Col>
        <Col xs={12} md={6}>
          <Col md={3}>
          <form action="submit">
           <div style={{backgroundColor:"whitesmoke" , borderRadius:"2rem" , width:"20rem" , height:"25rem" , margin:"2rem" ,marginTop:"5rem" , display:"flex" , flexDirection:"column" , alignItems:"center" }}>
            <div style={{display:"flex"}}>
            <h5 style={{alignSelf:"flex-start" , padding:"1rem" , textDecoration:"underline"}}>
              Asset Details
            </h5>
            <Button size='sm' color='warning' style={{alignSelf:"flex-start" , margin:"1rem"}} onClick={handleUserLocation}>Get Current Location</Button>
            </div>
            <p>Asset Number : {data.assetNo}</p>
            <p style={{textDecoration:"underline"}}>Location</p> 
            <input type="text" disabled={true} value={JSON.stringify(currentPosition)}  />
            <Button size='md' color="success" style={{marginTop:"1rem"}} onClick={handleUserSubmission}>Confirm Asset and Location</Button>
           </div>
           </form>
          </Col>
        </Col>
      </Row>
      <Toaster 
         position="bottom-right"
         reverseOrder={false} />
    </div>
  )
}

export default AssetTracking