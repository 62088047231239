import React from "react";
import { Card, CardBody, Badge, Label } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search, CSVExport, } from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

export const deleteFieldFormatter = (cell, row) => {
  return (
    row.toBeRemoved === true ?
      <Badge className={"rounded-pill  font-size-12 badge-soft-danger"} rounded-pill >
        Disabled
      </Badge> : 
      <Badge className={"rounded-pill  font-size-12 badge-soft-success"} rounded-pill >
        Enabled
      </Badge> 
  )
    
  
}

export const mandatoryFieldFormatter = (cell, row) => {
  return (
    row.required === true ?
      <Badge className={"rounded-pill  font-size-12 badge-soft-success"} rounded-pill >
        Yes
      </Badge> : ''
  )
}

export const typeFieldFormatter = (cell, row) => {
  let typeValue = "Text"
  typeValue = (row.type === 'string' || row.type === 'text' || row.type === 'Text') ? 'Text':  row.type === 'date' ? 'Date': (row.type === 'Number' || row.type === 'number')? 'Numeric' : (row.type === 'Dropdown' || row.type === 'dropdown' || row.type === 'select') ? 'Dropdown/ String' : ''
  return (
      <Label  >
        {typeValue}
      </Label> 
  )
}

export const dropDownFieldFormatter = (cell, row) => {
  let dropdownValues = row.dropdownValues !== undefined && row.dropdownValues !== null ? row.dropdownValues : ''
  return (
    row.dropdownValues !== undefined ? 
      <Label  >
        {row.dropdownValues.join(",")}
      </Label>  : ''
  )
}

export const TableForExistingColumns = (props) => {
  const {
    customColumns,
    existingFields,
    updateCsv,
    multipleRows,
  } = props;
  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;



  /**
   * handleCheckboxClick will be called when the check box is clicked
   * @param {event} e 
   * @param {number} rowIndex 
   * @param {boolean} checked 
   */
  const handleCheckboxClick = (e, rowIndex, checked) => {
    let rows = [];
    existingFields[rowIndex].isSelected = checked;
    rows.push(existingFields[rowIndex]);
    multipleRows(rows);
  };

  /**
   *selectRow method will be called when a row is selected 
   */
  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    onSelect: (row, isSelect, rowIndex, e) => {
      let rows = [];
      row.isSelected = isSelect;
      rows.push(row);
      multipleRows(rows);
    },
    onSelectAll: (isSelect, rows, e) => {
      rows.map((row) => {
        row.isSelected = isSelect;
      });
      multipleRows(rows);
    },
    selectionRenderer: ({ checked, disabled, rowIndex }) => {
      return (
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            checked={checked}
            disabled={disabled}
            onChange={() => { }}
          />
          <label
            className="custom-control-label"
            onClick={(e) => {
              e.preventDefault();
              handleCheckboxClick(e, rowIndex, checked);
            }}
          ></label>
        </div>
      );
    },
    selectionHeaderRenderer: ({ indeterminate, ...rest }) => {
      return (
        <div className="custom-control custom-checkbox select-header">
          <input
            type="checkbox"
            className="custom-control-input"
            id="customCheck1"
            ref={(input) => {
              if (input) input.indeterminate = indeterminate;
            }}
            {...rest}
            onChange={() => { }}
          />
          <label
            className="custom-control-label"
            htmlFor="customCheck1"
            onClick={(e) => {
              e.preventDefault();
            }}
          ></label>
        </div>
      );
    },
  };

  const paginationOptions = {
    sizePerPage: 10,
  };

  return (
    <Card>
      <CardBody >
        <ToolkitProvider
          style={{ textDecorationLine: 'line-through' }}
          bootstrap4
          keyField="dataField"
          data={existingFields}
          columns={customColumns}
          search
        >
          {(props) => (
            <React.Fragment>

              <BootstrapTable
                {...props.baseProps}
                className="strikeout"
                keyField="dataField"
                bordered={false}
                selectRow={selectRow}
                pagination={paginationFactory(paginationOptions)}
                wrapperClasses="table-responsive"
              />
            </React.Fragment>
          )}
        </ToolkitProvider>
      </CardBody>
    </Card>
  );
};

