import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
  Table,
} from "reactstrap";
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import ScaleLoader from "react-spinners/ScaleLoader";
import { getAuthUserByEmail } from 'services/userService';
import { useAuth0 } from "@auth0/auth0-react"
import { decryptKeyValues } from 'services/commonService';
import { getServiceProviderById, updateVideoUrlForServiceProvider } from 'services/serviceProvider'
import { updateActiveInDays } from 'services/dashboard-services';
import emailLogsTableData from './data/emailLogs-table-fields';
import { allEmailAlertLogs } from 'services/serviceHistoryService';
import { getAssetOwner } from 'services/assetownerService';
import ResendInvitation from 'pages/Asset-management/Components/ResendInvitation';
import { toast } from 'react-hot-toast';
const apiurl = process.env.REACT_APP_AUTH0_API_URL

const UserTrack = () => {
  const [user, setUser] = useState({})
  const [userFromDb, setUserFromDb] = useState({})
  const [lastLogin, setLastLogin] = useState('')
  const [loading, SetLoading] = useState(true)
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()
  const [value, setValue] = useState(0);
  const [userId, setUserId] = useState("")
  const [emailLogs, setEmailLogs] = useState([])
  const [pk, setPk] = useState("")
  const [sk , setSk] = useState("")
  const [videoUrl , setVideoUrl] = useState("")
  const getAccessToken = async (audience, claim) => {
    let token
    try {
      token = await getAccessTokenSilently({
        audience,
        claim,
      })
    } catch (exception) {
      try {
        token = await getAccessTokenWithPopup({ audience, claim })
      } catch (ex) {
        token = ""
      }
    }
    return token
  }
  var user_email;
  const { id } = useParams()
  user_email = id;
  const handleSubmitActiveInDays = async (event) => {
    event.preventDefault();
    const jwtToken = await getAccessToken(apiurl, 'read:assetOwner')
    await updateActiveInDays(jwtToken, userId, value)
    setValue(0)
    toast.success("Updated Successfully")
  };
  const handleSubmitVideoUrl = async (event) => {
    event.preventDefault();
    const jwtToken = await getAccessToken(apiurl, 'read:assetOwner')
    await updateVideoUrlForServiceProvider(jwtToken, pk,sk, videoUrl)
    setVideoUrl("")
    toast.success("Updated Successfully")
  };
  useEffect(async () => {
    SetLoading(true)
    const jwtToken = await getAccessToken(apiurl, 'add:serviceProvider')
    if (user_email) {
      const emailLogsList = await allEmailAlertLogs(jwtToken, user_email)
      if (emailLogsList !== undefined) {
        setEmailLogs(emailLogsList.data)
      } else {
        setEmailLogs([])
      }
      const userData = await getAuthUserByEmail(jwtToken, user_email)
      setUser(userData)
      setUserId(userData.user_id)
      var timestamp = userData.last_login
      var date = new Date(timestamp);
      var lastLogin = date.toLocaleString()
      setLastLogin(lastLogin)
      var decryptedPk = decryptKeyValues(userData.user_metadata.pk)
      setPk(decryptedPk)
      var decryptedSk = decryptKeyValues(userData.user_metadata.sk)
      setSk(decryptedSk)
      var userFromDb;
      if (userData.user_metadata.role.startsWith('Service Provider')) {
        userFromDb = await getServiceProviderById(jwtToken, null, decryptedPk)
        setUserFromDb(userFromDb)
      } else {
        userFromDb = await getAssetOwner(jwtToken, null, decryptedPk)
        setUserFromDb(userFromDb)
      }
    } else {
      //toaster
    }

    SetLoading(false)
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {
            loading ? (
              <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', marginTop: "20px" }}>
                <ScaleLoader color="#36d7b7" />
              </div>
            ) : (
              <>
                <Row>
                  <Col xl="4">
                    <Card className="overflow-hidden">
                      <div className="bg-primary bg-soft">
                        <Row>
                          <Col xs="12">
                            <div className="text-primary p-2">
                              <h5 className="text-primary"> {user.user_metadata.firstName}</h5>
                              <p>{user.nickname}</p>
                              {
                                user.email_verified ? (<p>Email Verified</p>) : <p>Email Not verified</p>
                              }

                            </div>
                          </Col>
                        </Row>
                      </div>
                      <CardBody className="pt-0">
                        <Row>
                          <Col sm={12}>
                            <div className="pt-2">
                              <Row>
                                <Col xs="6">
                                  <h5 className="font-size-15">
                                    Logins Count
                                  </h5>
                                  <p className="text-muted mb-0">{user.logins_count ? user.logins_count : 0}</p>
                                </Col>
                                <Col xs="6">
                                  <h5 className="font-size-15">
                                    Last Login
                                  </h5>
                                  <p className="text-muted mb-0">{lastLogin !== 'Invalid Date' ? lastLogin : (<p>Did not Login</p>)}</p>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-3 h4">
                          Personal Information
                        </CardTitle>
                        <p className="text-muted mb-4">
                          {userFromDb[0].attributes.metquayurl} <br />
                          {userFromDb[0].attributes.metquaySecretKey}
                        </p>
                        <div className="table-responsive">
                          <Table className="table-nowrap mb-0">
                            <tbody>
                              <tr>
                                <th scope="row">Free Trail Days :</th>
                                <td>{userFromDb[0].attributes.freeTrialDays}</td>
                              </tr>
                              <tr>
                                <th scope="row">Mobile :</th>
                                <td>{userFromDb[0].attributes.phone}</td>
                              </tr>
                              <tr>
                                <th scope="row">E-mail :</th>
                                <td>{userFromDb[0].attributes.email}</td>
                              </tr>
                              <tr>
                                <th scope="row">Address : </th>
                                <td>{userFromDb[0].attributes.addressLine1 + userFromDb[0].attributes.addressLine2 + ',' + userFromDb[0].attributes.city + ',' + userFromDb[0].attributes.state + ',' + userFromDb[0].attributes.countryRegion}</td>
                              </tr>
                              <tr>
                                <th scope="row">Premium User</th>
                                <td>{userFromDb[0].attributes.customerId ? userFromDb[0].attributes.customerId : "Not Premium User"}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </CardBody>
                    </Card>
                    {user.user_metadata.role.startsWith('Service Provider') && (
                      <>
                        <Card>
                          <CardBody>
                            <Card className="overflow-hidden"  >
                              <div className="bg-soft-primary">
                                <Row>
                                  <Col xs="12">
                                    <div className="text-primary">
                                      <h5 className="text-primary">Update the Active In days</h5>
                                      <Form onSubmit={handleSubmitActiveInDays}>
                                        <FormGroup>
                                          <div style={{ display: "flex", gap: 5 }}>
                                            <Input
                                              type="number"
                                              name="input"
                                              id="exampleInput"
                                              placeholder="Enter activeInDays value"
                                              value={value}
                                              onChange={(event) => setValue(event.target.value)}
                                            />
                                            <Button type="submit">Submit</Button>
                                          </div>
                                        </FormGroup>
                                      </Form>
                                    </div>

                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          </CardBody>
                        </Card>

                        <Card>
                          <CardBody>
                            <Card className="overflow-hidden"  >
                              <div className="bg-soft-primary">
                                <Row>
                                  <Col xs="12">
                                    <div className="text-primary">
                                      <h5 className="text-primary">Update the guide video of the asset owner</h5>
                                      <Form onSubmit={handleSubmitVideoUrl}>
                                        <FormGroup>
                                          <div style={{ display: "flex", gap: 5 }}>
                                            <Input
                                              type="text"
                                              name="input"
                                              id="exampleInput"
                                              placeholder="Enter video link url"
                                              value={videoUrl}
                                              onChange={(event) => setVideoUrl(event.target.value)}
                                            />
                                            <Button type="submit">Submit</Button>
                                          </div>
                                        </FormGroup>
                                      </Form>
                                    </div>

                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          </CardBody>
                        </Card>
                      </>
                    )}

                  </Col>

                  <Col xl="8">
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4 h4">Email Logs</CardTitle>
                        <ToolkitProvider
                          keyField="data.timestamp"
                          data={emailLogs}
                          columns={emailLogsTableData()}
                          bootstrap4
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row>
                                <Col md="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      responsive
                                      remote
                                      bordered={false}
                                      striped={false}
                                      classes={
                                        "table table-nowrap table-hover mb-0"
                                      }
                                      headerWrapperClasses={"tbody-light"}
                                      {...toolkitProps.baseProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                {user.user_metadata.role.startsWith('Service Provider') && (
                  <Row>
                    <ResendInvitation
                      pk={pk}
                    />
                  </Row>
                )}
              </>
            )}

        </Container>
      </div>
    </React.Fragment>
  )
}

export default UserTrack