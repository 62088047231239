import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { useAuth0 } from "@auth0/auth0-react"
import { user_metadata } from "../../nameSpaceConfig"
//services

const SidebarContent = props => {
  const {metquayAssetOwnerKey, metquayUrl} = props
  const { user } = useAuth0()
  const userMetadata = user[user_metadata]
  const ref = useRef();
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = async () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false
    }
    scrollElement(item);
    return false
  }
  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>
            <li>
              <Link to="/#" className="">
              <i className="bx bx-home-circle"></i>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>
            <li className="menu-title">{props.t("Apps")}</li>
            <li>
              <Link to="/#" >
                <i className="bx bx-store"></i>
                <span>{props.t("Asset Management")}</span>
              </Link>           
              {user.email_verified  ?
              <ul className="sub-menu" aria-aria-expanded = 'true'>
              <li>
              {userMetadata.role !== "Admin" ?(
                  <Link to="/assetManagement-assets">{props.t("Assets")}</Link>
                ) : (
                  ""
                )}               
              </li>
              <li>
                {userMetadata.role.startsWith("Asset Owner") && (metquayAssetOwnerKey !== undefined && metquayAssetOwnerKey !== " " && metquayAssetOwnerKey !== null)?(
                  <Link to="/assetManagement-metquayAssets">
                    {props.t("Managed Assets")}
                  </Link>
                ) : (
                  " "
                )}
              </li>
              <li>
                {(userMetadata.role.startsWith("Asset Owner") || userMetadata.role.startsWith("Service Provider")) && (metquayUrl !== undefined && metquayUrl !== " " && metquayUrl !== null)?(
                  <Link to="/assetManagement-metquayWorks">
                    {props.t("Works")}
                  </Link>
                ) : (
                  " "
                )}
              </li>
              <li>
                {userMetadata.role === "Admin" ||
                userMetadata.role.startsWith("Service Provider") ? (
                  <Link to="/assetManagement-assetOwners">
                    {props.t("Asset Owners")}
                  </Link>
                ) : (
                  " "
                )}
              </li>
              <li>
                {userMetadata.role === "Admin" ? (
                  <Link to="/assetManagement-serviceproviders">
                    {props.t("Service Providers")}
                  </Link>
                ) : (
                  " "
                )}
              </li>
              <li>
                <Link to="/assetManagement-users">{props.t("Users")}</Link>
              </li>
              {userMetadata.role === "Admin" ? (
              <li>
                <Link to="/asset-management-plans">{props.t("Plans")}</Link>
              </li> ) : null
                }
                 {userMetadata.sk === 'QVNTRVRPV05FUg==' ? (
              <li>
                 <Link to="/asset-track">{props.t("Asset Tracker")}
                 <span className="badge rounded-pill bg-danger float-end" key="t-new">Premium</span>
                 </Link>
              </li>
            ) : null}

            {userMetadata.sk !== 'QVNTRVRPV05FUg==' ? (
              <li>
                 <Link to="/asset-quote">{props.t("Request For Quotes")}
                 {/* <span className="badge rounded-pill bg-danger float-end" key="t-new">Premium</span> */}
                 </Link>
              </li>
            ) : null}
            </ul>
              : (
                    " "
                  )}
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
