import React, { useState, useEffect } from "react"
import { Row, Col, Card, CardBody, Button,  } from "reactstrap"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { useAuth0 } from "@auth0/auth0-react"
//Columns
import { userFieldListForPlan } from "../data/plan-detail-fields"
//service files
import { getCustomersFromStripe } from "services/paymentService"
import { mapCustomerFromStripe } from "services/commonService"

export const UserListForPlan = ({ productId }) => {
  const apiurl = process.env.REACT_APP_AUTH0_API_URL
  const { getAccessTokenSilently, getAccessTokenWithPopup, user } = useAuth0()
  const [userList, setUserList] = useState([])
  const [firstCustomerId, setFirstCustomerId] = useState("")
  const [lastCustomerId, setLastCustomerId] = useState("")
  const [firstPage, setFirstPage] = useState(0)
  const [lastPage, setLastPage] = useState('')
  const { SearchBar } = Search

  /**
   * getAccessToken method is used to fecth jwt access token from auth0
   * @param {string} audience - the api URL
   * @param {string} claim - the permission
   */
  const getAccessToken = async (audience, claim) => {
    let token
    try {
      token = await getAccessTokenSilently({
        audience,
        claim,
      })
    } catch (exception) {
      token = await getAccessTokenWithPopup({ audience, claim })
    }
    return token
  }

  const fetchCustomers = async (e, isPrev, isNext) => {
    const jwtToken = await getAccessToken(apiurl, "edit:assets")
    let startFrom = ""
    if (isPrev !== null) {
      setFirstPage(firstPage - 1)
      startFrom = firstCustomerId
    } else {
      setFirstPage(firstPage + 1)
      setLastPage(lastPage + 1)
      startFrom = lastCustomerId
    }
    await getCustomersFromStripe(jwtToken, startFrom)
      .then(result => {
        console.log(result)
        setFirstCustomerId(result.data[0].id)
        setLastCustomerId(result.data[19].id)
        const list = mapCustomerFromStripe(result.data, productId)
        setUserList(list)
      })
      .catch(error => {
        console.error(error)
      })
  }

  const getInitialData = async () => {
    const jwtToken = await getAccessToken(apiurl, "edit:assets")
    await getCustomersFromStripe(jwtToken)
      .then(result => {
        const list = mapCustomerFromStripe(result.data, productId)
        console.log(list)
        setUserList(list)
        setLastCustomerId(result.data[19].id)
        // setFirstCustomerId()
      })
      .catch(error => {
        console.error(error)
      })
  }
  useEffect(() => {
    getInitialData()
  }, [])
  return (
    <>
      <Card>
        <CardBody>
          <ToolkitProvider
            bootstrap4
            keyField="dataField"
            columns={userFieldListForPlan}
            data={userList}
            search
          >
            {props => (
              <React.Fragment>
                <Row>
                  <div className="col-md-12 text-right">
                    <Row>
                      <Col>
                        <SearchBar {...props.searchProps} />
                      </Col>
                    </Row>
                  </div>
                </Row>
                <BootstrapTable
                  {...props.baseProps}
                  keyField="id"
                  bordered={false}
                  wrapperClasses="table-responsive"
                />
              </React.Fragment>
            )}
          </ToolkitProvider>
        </CardBody>
      </Card>
      <div className="text-end">
        <Button
          className="mb-2 me-2"
          color="primary"
          // disabled={firstPage === 0 ? true : false}
          onClick={e => {
            fetchCustomers(e, true, null)
          }}
        >
          Prev
        </Button>
        <Button
          className="mb-2 me-2"
          color="primary"
          onClick={e => {
            fetchCustomers(e, null, true)
          }}
        >
          Next
        </Button>
      </div>
    </>
  )
}