import {conditionColumnFormatter} from "../Components/TableWithRowExpand"
import { conditionColumnFormatterForMetquayAssets } from "../Components/TableForManagedAssets"
import { qrCodeColumnFormatter, QRCodeGeneratorForWorks } from "../Components/QRCodeGenerator"
import  {detailButtonFormatter}  from "../Components/QRCodeGenerator"
import {statusColumnFormatter} from "../../Dashboard/LiveUpdates"
import { deleteFieldFormatter, mandatoryFieldFormatter, typeFieldFormatter, dropDownFieldFormatter } from "../PreferenceComponents/TableForExistingColumns"
import { disableRowFormatter } from "../PreferenceComponents/TableForCustomFields"
import { emailDisableRowFormatter } from "../PreferenceComponents/TableForEmailAlert"

const columns = [
  // {
  //   dataField: "assetdValue",
  //   text: "Asset Id",
  //   sort: true,
  //   float: "left",
  //   type: "string",
  // },
  {
    dataField: "assetOwnerName",
    text: "Asset Owner",
    sort: true,
    float: "left",
    type: "string",
  },
  {
    dataField: "serviceProviderName",
    text: "Service Provider",
    sort: true,
    float: "right",
    type: "string",
  },
  {
    dataField: "category",
    text: "Category",
    sort: false,
    float: "left",
    type: "string",
  },
  {
    dataField: "manufacturer",
    text: "Manufacturer",
    sort: true,
    float: "right",
    type: "string",
  },
  {
    dataField: "model",
    text: "Model",
    sort: false,
    float: "left",
    type: "string",
  },
  {
    dataField: "description",
    text: "Description",
    sort: true,
    float: "right",
    type: "string",
    maxlength: 50,
  },
  {
    dataField: "serialNumber",
    text: "Serial Number",
    sort: true,
    float: "left",
    type: "number",
    maxlength: 15,
  },
  {
    dataField: "assetNumber",
    text: "Asset Number",
    sort: true,
    float: "right",
    type: "string",
    maxlength: 15,
  },
  {
    dataField: "serviceDate",
    text: "Last Cal Date",
    sort: false,
    float: "left",
    type: "date",
  },
  {
    dataField: "dueDate",
    text: "Cal Due Date",
    sort: true,
    float: "right",
    type: "date",
  },
]

const expandableColumns = [
  {
    dataField: "oemAccuracy",
    text: "OEM Accuracy",
    sort: true,
    float: "left",
    type: "string",
  },
  {
    dataField: "range",
    text: "Range",
    sort: true,
    float: "left",
    type: "string",
  },
  {
    dataField: "unitOfMeasurement",
    text: "Unit Of Measurement",
    sort: true,
    float: "left",
    type: "string",
  },
  {
    dataField: "serviceFrequency",
    text: "Service Frequency (Days)",
    sort: true,
    float: "left",
    type: "string",
  },
  {
    dataField: "reportNumber",
    text: "Report",
    sort: true,
    float: "right",
    type: "string",
  },
  {
    dataField: "createdDate",
    text: "Added Date",
    sort: true,
    float: "left",
    type: "string",
    maxlength: 15,
  },
  {
    dataField: "lastModifiedDate",
    text: "Last Modified Date",
    sort: false,
    float: "left",
    type: "string",
  },
  {
    dataField: "createdBy",
    text: "Added By",
    sort: true,
    float: "right",
    type: "string",
  },
  {
    dataField: "lastModifiedBy",
    text: "Last Modified By",
    sort: false,
    float: "left",
    type: "string",
  },
  {
    dataField: "certificateUrl",
    text: "QR Code",
    sort: true,
    float: "right",
    type: "string",
    maxlength: 50,
    formatter: qrCodeColumnFormatter,
  },
  
  {
    dataField: "ownerName",
    text: "Owner",
    sort: false,
    float: "left",
    type: "date",
  },
  {
    dataField: "serviceType",
    text: "Service Type",
    sort: true,
    float: "right",
    type: "string",
    maxlength: 50,
  },
  
  {
    dataField: "serviceProviderName",
    text: "Service Provider",
    sort: false,
    float: "left",
    type: "date",
  },
  {
    dataField: "estCalCost",
    text: "Est. Calibration Cost",
    sort: false,
    float: "left",
    type: "date",
  },
  {
    dataField: "estAMC",
    text: "Est. AMC",
    sort: false,
    float: "left",
    type: "date",
  },

  {
    dataField: "remarks",
    text: "Remarks",
    sort: false,
    float: "left",
    type: "date",
  },
]

// const expandableColumns = [
//   {
//     dataField: "assetOwnerDetails",
//     text: "Asset Owner Details",
//     sort: false,
//     float: "right",
//     type: "string",
//   },
//   {
//     dataField: "spDetails",
//     text: "Servcie Provider Details",
//     sort: false,
//     float: "left",
//     type: "string",
//   },
//   {
//     dataField: "createdDate",
//     text: "Created Date",
//     sort: false,
//     float: "right",
//     type: "date",
//   },
//   {
//     dataField: "lastModifiedDate",
//     text: "Modified Date",
//     sort: true,
//     float: "left",
//     type: "date",
//   },

//   {
//     dataField: "createdBy",
//     text: "Created By",
//     sort: true,
//     float: "right",
//     type: "string",
//   },
//   {
//     dataField: "lastModifiedBy",
//     text: "Last Modified By",
//     sort: true,
//     float: "left",
//     type: "string",
//   },
//   {
//     dataField: "status",
//     text: "Status",
//     sort: true,
//     float: "right",
//     type: "string",
//   },
//   {
//     dataField: "qrCode",
//     text: "QR Code1",
//     sort: true,
//     float: "left",
//     type: "string",
//     formatter: qrCodeColumnFormatter,
//   },
// ]

const totalColumns = [
  // {
  //   dataField: "assetOwnerName",
  //   text: "Asset Owner",
  //   sort: true,
  //   float: "left",
  //   type: "autoSuggest",
  //   required: true,
  // },
  // {
  //   dataField: "serviceProviderName",
  //   text: "Service Provider",
  //   sort: true,
  //   float: "right",
  //   type: "autoSuggest",
  //   required: true,
  // // },
  // {
  //   dataField: "category",
  //   text: "Category",
  //   sort: false,
  //   float: "left",
  //   type: "string",
  //   required: true,
  // },
  //  {
  //   dataField: "manufacturer",
  //   text: "Manufacturer",
  //   sort: true,
  //   float: "right",
  //   type: "string",
  //   required: true,
  // },
  //{
  //   dataField: "model",
  //   text: "Model",
  //   sort: false,
  //   float: "left",
  //   type: "string",
  //   required: false,
  // },
  {
    dataField: "description",
    text: "Description",
    sort: true,
    float: "right",
    type: "string",
    required: false,
    maxlength: 50,
  },
  {
    dataField: "serialNumber",
    text: "Serial Number",
    sort: true,
    float: "left",
    type: "number",
    required: false,
    maxlength: 15,
  },
  {
    dataField: "assetNumber",
    text: "Asset Number",
    sort: true,
    float: "right",
    type: "number",
    maxlength: 15,
  },
  {
    dataField: "serviceDate",
    text: "Last Cal Date",
    sort: false,
    float: "left",
    type: "date",
    required: true,
  },
  {
    dataField: "dueDate",
    text: "Cal Due Date",
    sort: true,
    float: "right",
    type: "date",
    required: true,
  },
  // {
  //   dataField: "createdDate",
  //   text: "Created Date",
  //   sort: false,
  //   float: "right",
  //   type: "date",
  //   required: false,
  // },
  // {
  //   dataField: "lastModifiedDate",
  //   text: "Modified Date",
  //   sort: true,
  //   float: "left",
  //   type: "date",
  //   required: false,
  // },

  // {
  //   dataField: "createdBy",
  //   text: "Created By",
  //   sort: true,
  //   float: "right",
  //   type: "string",
  //   required: false,
  // },
  // {
  //   dataField: "lastModifiedBy",
  //   text: "Last Modified By",
  //   sort: true,
  //   float: "left",
  //   type: "string",
  //   required: false,
  // },
  {
    dataField: "status",
    text: "Status",
    sort: true,
    float: "right",
    type: "dropDown",
    required: false,
  },
]

const exportableColumns = [
  {
    dataField: "assetOwnerName",
    text: "Asset Owner",
    sort: true,
    float: "left",
    type: "autoSuggest",
    required: true,
  },
  {
    dataField: "serviceProviderName",
    text: "Service Provider",
    sort: true,
    float: "right",
    type: "autoSuggest",
    required: true,
  },
  {
    dataField: "category",
    text: "Category",
    sort: false,
    float: "left",
    type: "string",
    required: true,
  },
  {
    dataField: "manufacturer",
    text: "Manufacturer",
    sort: true,
    float: "right",
    type: "string",
    required: true,
  },
  {
    dataField: "model",
    text: "Model",
    sort: false,
    float: "left",
    type: "string",
    required: false,
  },
  {
    dataField: "description",
    text: "Description",
    sort: true,
    float: "right",
    type: "string",
    required: false,
  },
  {
    dataField: "serialNumber",
    text: "Serial Number",
    sort: true,
    float: "left",
    type: "number",
    required: false,
  },
  {
    dataField: "assetNumber",
    text: "Asset Number",
    sort: true,
    float: "right",
    type: "number",
  },
  {
    dataField: "serviceDate",
    text: "Last Cal Date",
    sort: false,
    float: "left",
    type: "date",
    required: true,
  },
  {
    dataField: "dueDate",
    text: "Cal Due Date",
    sort: true,
    float: "right",
    type: "date",
    required: true,
  },
  {
    dataField: "assetOwnerDetails",
    text: "Asset Owner Details",
    sort: false,
    float: "right",
    type: "string",
  },
  {
    dataField: "spDetails",
    text: "Servcie Provider Details",
    sort: false,
    float: "left",
    type: "string",
  },
  {
    dataField: "createdDate",
    text: "Created Date",
    sort: false,
    float: "right",
    type: "date",
  },
  {
    dataField: "lastModifiedDate",
    text: "Modified Date",
    sort: true,
    float: "left",
    type: "date",
  },

  {
    dataField: "createdBy",
    text: "Created By",
    sort: true,
    float: "right",
    type: "string",
  },
  {
    dataField: "lastModifiedBy",
    text: "Last Modified By",
    sort: true,
    float: "left",
    type: "string",
  },
  {
    dataField: "status",
    text: "Status",
    sort: true,
    float: "right",
    type: "string",
  },
  {
    dataField: "serviceProviderId",
    text: "Service Provider Id",
    sort: true,
    float: "right",
    type: "string",
  },
  {
    dataField: "assetOwnerId",
    text: "Asset Owner Id",
    sort: true,
    float: "right",
    type: "string",
  },
]
const importColumns = [
  "Asset Owner",
  "Asset Owner Id",
  "Service Provider",
  "Service Provider Id",
  "Category",
  "Manufacturer",
  "Model",
  "Description",
  "Serial Number",
  "Asset Number",
  "Last Cal Date",
  "Cal Due Date",
  "Created Date",
  "Modified Date",
  "Status",
  "Created By",
  "Last Modified By",
  "Asset Owner Details",
  "Servcie Provider Details",
]

const csvColumns = {
  "Asset Owner": "assetOwnerName",
  "Asset Owner Id": "assetOwnerId",
  "Service Provider": "serviceProviderName",
  "Service Provider Id": "serviceProviderId",
  Category: "category",
  Manufacturer: "manufacturer",
  Model: "model",
  Description: "description",
  "Serial Number": "serialNumber",
  "Asset Number": "assetNumber",
  "Last Cal Date": "serviceDate",
  "Cal Due Date": "dueDate",
  "Created Date": "createdDate",
  "Modified Date": "lastModifiedDate",
  Status: "status",
  "Created By": "createdBy",
  "Last Modified By": "lastModifiedBy",
  "Asset Owner Details": "assetOwnerDetails",
  "Servcie Provider Details": "spDetails",
}

const defaultSorted = [
  {
    dataField: "id",
    order: "asc",
  },
]

const categoryValues = [
  "Caliper",
  "Temperature",
  "Multimeter",
  "Printer",
  "Processor",
  "Scale",
  "Multimeter",
  "Clamp Meter",
  "Flow",
  "Weighing Scale",
  "Digital Multimeter",
  "Fluke Multimeter",
  "Multimeter - DC Voltage Measuring",
  "DC Voltage Measuring Equipment",
  "Weighing Set",
  "True RMS Multimeter",
]

const manufacturerValues = [
  "Fluke",
  "WIKA",
  "Mittutoyo",
  "M10",
  "FLUKE M/N 718 100G  S/N 7373058  0-100 PSI WV-40  W/ YELLOW HOLSTER",
  "E+",
  "Testo",
  "MK01",
  "Clamp Meter",
  "Dwyer",
  "Mettler-toledo",
]

const modelValues = [
  "Compact True RMS",
  "5520",
  "MD01",
  "MD-01",
  "PROWIRL 72",
  "MT100",
  "MK01",
  "V11P3",
  "176T2",
  "PUA574",
  "8265 ngw",
  "GW5-019C",
  "TFT-LCD",
]


const assetColumnsForAssetOwnerLogin = [
  {
    dataField: "condition",
    text: "Condition",
    sort: true,
    float: "left",
    type: "string",
    formatter: conditionColumnFormatter,
  },
  {
    dataField: "serialNumber",
    text: "Serial#",
    sort: true,
    float: "right",
    type: "text",
    maxlength: 15,
    toBeRemoved: false,
  },
  {
    dataField: "assetNumber",
    text: "Asset#",
    sort: true,
    float: "left",
    type: "string",
    maxlength: 15,
    toBeRemoved: false,
  },
  {
    dataField: "category",
    text: "Category",
    sort: false,
    float: "left",
    type: "string",
    toBeRemoved: false,
  },
  {
    dataField: "manufacturer",
    text: "Make",
    sort: true,
    float: "right",
    type: "string",
    toBeRemoved: false,
  },
  {
    dataField: "model",
    text: "Model",
    sort: false,
    float: "left",
    type: "string",
    toBeRemoved: false,
  },
  {
    dataField: "description",
    text: "Description",
    sort: true,
    float: "right",
    type: "string",
    maxlength: 50,
    toBeRemoved: false,
  },
  
  {
    dataField: "serviceDate",
    text: "Serviced Date",
    sort: false,
    float: "left",
    type: "date",
    toBeRemoved: false,
  },
  {
    dataField: "dueDate",
    text: "Due Date",
    sort: true,
    float: "right",
    type: "date",
    toBeRemoved: false,
  },
  {
    dataField: "location",
    text: "Location",
    sort: true,
    float: "left",
    type: "text",
    toBeRemoved: false,
  },
  {
    dataField: "statusValue",
    text: "Status",
    sort: true,
    float: "left",
    type: "text",
    toBeRemoved: false,
  },
]

const assetExpandableColumnsForAssetOwnerLogin = [
  {
    dataField: "serviceFrequency",
    text: "Service Frequency (Days)",
    sort: true,
    float: "left",
    type: "string",
  },
  {
    dataField: "reportNumber",
    text: "Report",
    sort: true,
    float: "right",
    type: "string",
  },
  {
    dataField: "createdDate",
    text: "Added Date",
    sort: true,
    float: "left",
    type: "string",
    maxlength: 15,
  },
  {
    dataField: "lastModifiedDate",
    text: "Last Modified Date",
    sort: false,
    float: "left",
    type: "string",
  },
  {
    dataField: "createdBy",
    text: "Added By",
    sort: true,
    float: "right",
    type: "string",
  },
  {
    dataField: "lastModifiedBy",
    text: "Last Modified By",
    sort: false,
    float: "left",
    type: "string",
  },
  {
    dataField: "certificateUrl",
    text: "QR Code",
    sort: true,
    float: "right",
    type: "string",
    maxlength: 50,
    formatter: QRCodeGeneratorForWorks,
  },
  
  {
    dataField: "ownerName",
    text: "Owner",
    sort: false,
    float: "left",
    type: "date",
  },
  {
    dataField: "serviceType",
    text: "Service Type",
    sort: true,
    float: "right",
    type: "string",
    maxlength: 50,
  },
  
  {
    dataField: "serviceProviderName",
    text: "Service Provider",
    sort: false,
    float: "left",
    type: "date",
  },
]

const totalAssetColumnsForAssetOwnerLogin = [
  {
    dataField: "serialNumber",
    text: "Serial#",
    sort: true,
    float: "right",
    type: "number",
    maxlength: 15,
  },
  {
    dataField: "assetNumber",
    text: "Asset#",
    sort: true,
    float: "left",
    type: "string",
    maxlength: 15,
  },
  {
    dataField: "category",
    text: "Category",
    sort: false,
    float: "left",
    type: "string",
  },
  {
    dataField: "manufacturer",
    text: "Make",
    sort: true,
    float: "right",
    type: "string",
  },
  {
    dataField: "model",
    text: "Model",
    sort: false,
    float: "left",
    type: "string",
  },
  {
    dataField: "description",
    text: "Description",
    sort: true,
    float: "right",
    type: "string",
    maxlength: 50,
  },
  
  {
    dataField: "serviceDate",
    text: "Serviced Date",
    sort: false,
    float: "left",
    type: "date",
  },
  {
    dataField: "dueDate",
    text: "Due Date",
    sort: true,
    float: "right",
    type: "date",
  },
  {
    dataField: "location",
    text: "Location",
    sort: true,
    float: "left",
    type: "text",
  },
  {
    dataField: "status",
    text: "Status",
    sort: true,
    float: "left",
    type: "text",
  },
  {
    dataField: "serviceFrequency",
    text: "Service Frequency (Days)",
    sort: true,
    float: "left",
    type: "string",
  },  
  {
    dataField: "ownerName",
    text: "Owner",
    sort: false,
    float: "left",
    type: "date",
  },
  {
    dataField: "serviceType",
    text: "Service Type",
    sort: true,
    float: "right",
    type: "string",
    maxlength: 50,
  },
  
  {
    dataField: "serviceProviderName",
    text: "Service Provider",
    sort: false,
    float: "left",
    type: "date",
  },
]

// const dashboardAssetTableColumns = [
//   {
//     dataField: "assetOwnerName",
//     text: "Asset Owner",
//     sort: true,
//     float: "left",
//     type: "string",
//   },
//   {
//     dataField: "category",
//     text: "Category",
//     sort: false,
//     float: "left",
//     type: "string",
//   },
//   {
//     dataField: "manufacturer",
//     text: "Manufacturer",
//     sort: true,
//     float: "right",
//     type: "string",
//   },
//   {
//     dataField: "model",
//     text: "Model",
//     sort: false,
//     float: "left",
//     type: "string",
//   },
//   {
//     dataField: "description",
//     text: "Description",
//     sort: true,
//     float: "right",
//     type: "string",
//     maxlength: 50,
//   },
//   {
//     dataField: "serialNumber",
//     text: "Serial Number",
//     sort: true,
//     float: "left",
//     type: "number",
//     maxlength: 15,
//   },
//   {
//     dataField: "assetNumber",
//     text: "Asset Number",
//     sort: true,
//     float: "right",
//     type: "string",
//     maxlength: 15,
//   },{
//     dataField: "details",
//     text: "Condition",
//     sort: true,
//     float: "right",
//     type: "string",
//     formatter: conditionColumnFormatter,
//   },
//   {
//     dataField: "dueDate",
//     text: "Due Date",
//     sort: true,
//     float: "right",
//     type: "date",
//   },
// ]

const dashboardAssetTableColumns = [
  {
    // dataField: "assetOwnerName",

    dataField: "Owner",
    text: "Asset Owner",
    sort: true,
    float: "left",
    type: "string",
  },
  {
    dataField: "Category",
    text: "Category",
    sort: false,
    float: "left",
    type: "string",
  },
  {
    dataField: "Make",
    text: "Manufacturer",
    sort: true,
    float: "right",
    type: "string",
  },
  {
    dataField: "Model",
    text: "Model",
    sort: false,
    float: "left",
    type: "string",
  },
  {
    dataField: "Description",
    text: "Description",
    sort: true,
    float: "right",
    type: "string",
    maxlength: 50,
  },
  {
    dataField: "Serial#",
    text: "Serial Number",
    sort: true,
    float: "left",
    type: "number",
    maxlength: 15,
  },
  {
    dataField: "Asset#",
    text: "Asset Number",
    sort: true,
    float: "right",
    type: "string",
    maxlength: 15,
  },{
    dataField: "details",
    text: "Condition",
    sort: true,
    float: "right",
    type: "string",
    formatter: conditionColumnFormatter,
  },
  {
    dataField: "DueDate",
    text: "Due Date",
    sort: true,
    float: "right",
    type: "date",
  },
]


const liveUpdatesColumns = [
  {
    dataField: "salesOrderNo",
    text: "Sales Order#",
    sort: true,
    float: "right",
    type: "string",
  },
  {
    dataField: "assetOwner",
    text: "Asset Owner",
    sort: true,
    float: "left",
    type: "string",
  },
  {
    dataField: "workNo",
    text: "Work No",
    sort: false,
    float: "left",
    type: "string",
  },
  {
    dataField: "certificateNo",
    text: "Certificate No",
    sort: true,
    float: "right",
    type: "string",
  },
  {
    dataField: "status",
    text: "Status",
    sort: false,
    float: "left",
    type: "string",
    formatter: statusColumnFormatter,
  },
  {
    dataField: "instrumentCategory",
    text: "Instrument Category",
    sort: true,
    float: "right",
    type: "string",
    maxlength: 50,
  },
  {
    dataField: "instrumentDescription",
    text: "Instrument Description",
    sort: true,
    float: "left",
    type: "number",
    maxlength: 15,
  },
  {
    dataField: "serialNo",
    text: "Serial No",
    sort: true,
    float: "right",
    type: "string",
    maxlength: 15,
  },{
    dataField: "tagNo",
    text: "Tag No",
    sort: true,
    float: "right",
    type: "string",
  },
  {
    dataField: "instrumentUniqueNo",
    text: "Instrument Unique No",
    sort: true,
    float: "right",
    type: "string",
  },
  
  {
    dataField: "reportType",
    text: "Report Type",
    sort: true,
    float: "right",
    type: "string",
  },
  {
    dataField: "deliveryDate",
    text: "Delivery Date",
    sort: true,
    float: "right",
    type: "string",
  },
  {
    dataField: "finishedDate",
    text: "Finished Date",
    sort: true,
    float: "right",
    type: "string",
  },
]

const metquayAssetsColumn = [
  {
    dataField: "condition",
    text: "Condition",
    sort: true,
    float: "left",
    type: "string",
    formatter: conditionColumnFormatterForMetquayAssets,
  },
  {
    dataField: "serialNo",
    text: "Serial No.",
    sort: true,
    float: "right",
    type: "number",
    maxlength: 15,
  },
  {
    dataField: "tagNo",
    text: "Asset No.",
    sort: true,
    float: "left",
    type: "string",
    maxlength: 15,
  },
  {
    dataField: "instrumentCategory",
    text: "Category",
    sort: false,
    float: "left",
    type: "string",
  },
  {
    dataField: "itemMake",
    text: "Make",
    sort: true,
    float: "right",
    type: "string",
  },
  {
    dataField: "itemModel",
    text: "Model",
    sort: false,
    float: "left",
    type: "string",
  },
  {
    dataField: "description",
    text: "Description",
    sort: true,
    float: "right",
    type: "string",
    maxlength: 50,
  },
  
  {
    dataField: "calibratedDate",
    text: "Serviced Date",
    sort: false,
    float: "left",
    type: "date",
  },
  {
    dataField: "dueDate",
    text: "Due Date",
    sort: true,
    float: "right",
    type: "date",
  },
  {
    dataField: "location",
    text: "Location",
    sort: true,
    float: "left",
    type: "text",
  },
  {
    dataField: "status",
    text: "Status",
    sort: true,
    float: "left",
    type: "text",
  },
]

const customColumns = [
  {
    dataField: "assetOwnerName",
    text: "Asset Owner",
    sort: true,
    float: "left",
    type: "text",
  },
  {
    dataField: "serviceProviderName",
    text: "Service Provider",
    sort: true,
    float: "right",
    type: "text",
  },
  {
    dataField: "category",
    text: "Category",
    sort: false,
    float: "left",
    type: "password",
  },
  {
    dataField: "manufacturer",
    text: "Manufacturer",
    sort: true,
    float: "right",
    type: "select",
  },
  {
    dataField: "model",
    text: "Model",
    sort: false,
    float: "left",
    type: "string",
  },
  {
    dataField: "description",
    text: "Description",
    sort: true,
    float: "right",
    type: "string",
    maxlength: 50,
  },
  {
    dataField: "serialNumber",
    text: "Serial Number",
    sort: true,
    float: "left",
    type: "number",
    maxlength: 15,
  },
  {
    dataField: "assetNumber",
    text: "Asset Number",
    sort: true,
    float: "right",
    type: "string",
    maxlength: 15,
  },
  {
    dataField: "serviceDate",
    text: "Last Cal Date",
    sort: false,
    float: "left",
    type: "date",
  },
]

const addedCustomFieldColumns = [
  {
    dataField: "text",
    text: "Field Name",
    sort: true,
    float: "left",
    type: "text",
  },
  {
    dataField: "type",
    text: "Field Type",
    sort: true,
    float: "right",
    type: "text",
  },
  {
    dataField: "dropdownValues",
    text: "Dropdown Values",
    sort: false,
    float: "left",
    type: "password",
  },
  {
    dataField: "required",
    text: "Is Mandatory",
    sort: true,
    float: "right",
    type: "select",
    formatter: disableRowFormatter,
  },  
]

const existingFieldColumns = [
  {
    dataField: "isDeleted",
    text: "Enable/ Disable",
    sort: true,
    float: "right",
    type: "select",
    formatter: deleteFieldFormatter,
  },
  {
    dataField: "text",
    text: "Field Name",
    sort: true,
    float: "left",
    type: "text",
  },
  {
    dataField: "type",
    text: "Field Type",
    sort: true,
    float: "right",
    type: "text",
    formatter: typeFieldFormatter,
  },
  {
    dataField: "dropdownValues",
    text: "Dropdown Values",
    sort: false,
    float: "left",
    type: "text",
    formatter: dropDownFieldFormatter,
  },
  {
    dataField: "required",
    text: "Is Mandatory",
    sort: true,
    float: "right",
    type: "select",
    formatter: mandatoryFieldFormatter,
  },
  
]

const assetColumnsForPreference = [
  {
    dataField: "Condition",
    text: "Condition",
    sort: true,
    float: "left",
    type: "string",
    formatter: conditionColumnFormatter,
  },
  {
    dataField: "Serial#",
    text: "Serial#",
    sort: true,
    float: "right",
    type: "text",
    maxlength: 15,
    toBeRemoved: false,
  },
  {
    dataField: "Asset#",
    text: "Asset#",
    sort: true,
    float: "left",
    type: "string",
    maxlength: 15,
    toBeRemoved: false,
    required: true
  },
  {
    dataField: "Category",
    text: "Category",
    sort: false,
    float: "right",
    type: "Dropdown",
    toBeRemoved: false,
    // dropdownValues: categoryValues,
  },
  {
    dataField: "Make",
    text: "Make",
    sort: true,
    float: "left",
    type: "Dropdown",
    toBeRemoved: false,
    // dropdownValues: manufacturerValues,
  },
  {
    dataField: "Model",
    text: "Model",
    sort: false,
    float: "right",
    type: "Dropdown",
    toBeRemoved: false,
    // dropdownValues: modelValues,
  },
  {
    dataField: "Description",
    text: "Description",
    sort: true,
    float: "left",
    type: "string",
    maxlength: 50,
    toBeRemoved: false,
  },
  
  {
    dataField: "ServicedDate",
    text: "Serviced Date",
    sort: false,
    float: "right",
    type: "date",
    toBeRemoved: false,
    required: true
  },
  {
    dataField: "DueDate",
    text: "Due Date",
    sort: true,
    float: "left",
    type: "date",
    toBeRemoved: false,
    required: true
  },
  {
    dataField: "Location",
    text: "Location",
    sort: true,
    float: "right",
    type: "text",
    toBeRemoved: false,
  },
  {
    dataField: "OEMAccuracy",
    text: "OEM Accuracy",
    sort: true,
    float: "left",
    type: "number",
    toBeRemoved: false,
  },
  {
    dataField: "Range",
    text: "Range",
    sort: true,
    float: "right",
    type: "text",
    toBeRemoved: false,
  },

  {
    dataField: "UnitofMeasurement",
    text: "Unit of Measurement",
    sort: true,
    float: "left",
    type: "Text",
    toBeRemoved: false,
  },
  {
    dataField: "ServiceFrequency(Days)",
    text: "Service Frequency(Days)",
    sort: true,
    float: "right",
    type: "number",
    toBeRemoved: false,
  },
  {
    dataField: "Owner",
    text: "Owner",
    sort: true,
    float: "left",
    type: "Dropdown",
    toBeRemoved: false,
    dropdownValues: [],
  },
  {
    dataField: "Report",
    text: "Report",
    sort: true,
    float: "right",
    // type: "string",
    maxlength: 50,
    formatter: qrCodeColumnFormatter,
  },
  {
    dataField: "ServiceType",
    text: "Service Type",
    sort: true,
    float: "right",
    type: "Dropdown",
    toBeRemoved: false,
    dropdownValues: ["Calibration", "PM", "Calibration & PM"]
  },

  {
    dataField: "StatusValue",
    text: "Status",
    sort: true,
    float: "left",
    type: "Dropdown",
    toBeRemoved: false,
    dropdownValues: ["Active", "Inactive"]
  },
  {
    dataField: "Est.CalibrationCost",
    text: "Est. Calibration Cost",
    sort: true,
    float: "right",
    type: "Number",
    toBeRemoved: false,
  },
  {
    dataField: "Est.AMC",
    text: "Est. AMC",
    sort: true,
    float: "left",
    type: "Number",
    toBeRemoved: false,
  },

  {
    dataField: "Remarks",
    text: "Remarks",
    sort: true,
    float: "right",
    type: "Text",
    toBeRemoved: false,
  },
]

const emailAlertColumns = [
  // {
  //   dataField: "isDeleted",
  //   text: "Enable/ Disable",
  //   sort: true,
  //   float: "right",
  //   type: "select",
  //   formatter: deleteFieldFormatter,
  // },
  {
    dataField: "emailAlertFrequency",
    text: "Email Alert Frequency",
    sort: true,
    float: "left",
    type: "text",
  },
  {
    dataField: "emailAlertType",
    text: "Email Alert Type",
    sort: true,
    float: "right",
    type: "text",
    // formatter: typeFieldFormatter,
  },
  {
    dataField: "emailAlertField",
    text: "Date Field",
    sort: false,
    float: "left",
    type: "text",
    // formatter: dropDownFieldFormatter,
  },
  {
    dataField: "LastEmailSentDate",
    text: "Last Email Sent Date",
    sort: true,
    float: "right",
    type: "select",
    // formatter: mandatoryFieldFormatter,
  },
  {
    dataField: "ActiveInactive",
    text: "Active/ Inactive",
    sort: true,
    float: "right",
    type: "select",
    formatter: emailDisableRowFormatter,
  },
]
export {
  columns,
  defaultSorted,
  expandableColumns,
  totalColumns,
  csvColumns,
  importColumns,
  categoryValues,
  manufacturerValues,
  modelValues,
  exportableColumns,
  assetColumnsForAssetOwnerLogin,
  assetExpandableColumnsForAssetOwnerLogin,
  totalAssetColumnsForAssetOwnerLogin,
  dashboardAssetTableColumns,
  liveUpdatesColumns,
  metquayAssetsColumn,
  customColumns,
  addedCustomFieldColumns,
  existingFieldColumns,
  assetColumnsForPreference,
  emailAlertColumns,
}