import React, { useState, useReducer, useEffect } from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import Dropdown from "react-dropdown"
import CryptoJS from "crypto-js"
import { useToasts } from "react-toast-notifications"
import { useAuth0 } from "@auth0/auth0-react"


import { dataReducer } from "../reducer/reducers"
import {
  dropDownOptions,
  accountOptions,
  spDropDownOptions,
  modalColumns,
} from "../data/user-fields"
import {
  getServiceProviderNames,
} from "../../../services/serviceProvider"
import {
  getAssetOwnerNames,
} from "../../../services/assetownerService"

import "../css/modal-style.css"
import "../css/modal-style.css"
import "react-dropdown/style.css"

export const UserModal = props => {
  const { addToast } = useToasts()
  const apiurl = process.env.REACT_APP_AUTH0_API_URL
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()
  const [count, setCount] = useState(0)
  const [data, dispatchData] = useReducer(dataReducer, [])
  // const [accountsData, setAccountsData] = useState(accountOptions);
  const [accountsData, setAccountsData] = useState([])
  const [keyValueMap, setKeyValueMap] = useState({})
  const [phoneNumberError, setPhoneNumberError] = useState(null)
  const [emailError, setEmailError] = useState(null)
  const [workPhoneError, setWorkPhoneError] = useState(null)
  const [accountMap, setAccountMap] = useState({})
  const [role, setRole] = useState("")
  const [isEdited,setIsedited ] = useState(false)

  const {
    updateRecords,
    action,
    selectedRow,
    editable,
    open,
    toggle,
    modalheader,
    userMetadata,
  } = props

  /**
   * getAccessToken method is used to fecth jwt access token from auth0
   * @param {string} audience - the api URL
   * @param {string} claim - the permission
   */
  const getAccessToken = async (audience, claim) => {
    let token
    try {
      token = await getAccessTokenSilently({
        audience,
        claim,
      })
    } catch (exception) {
      token = await getAccessTokenWithPopup({ audience, claim })
    }
    return token
  }

  // const onValidate = (field, value) => {
  //   if (field === 'emailAddress') {
  //     const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  //     if (value.match(validRegex)) {
  //       setEmailError(null)
  //       return false;
  //     } else {
  //       setEmailError('Please provide a valid email')
  //       return true;
  //     }
  //   } else if (field === 'mobilePhone') {
  //     // const validRegex = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
  //     // const validRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
  //     const validRegex = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g
  //     if (!value.match(validRegex) || value.length > 10) {
  //       setPhoneNumberError('Please provide a valid phone number')
  //       return true;
  //     } else {
  //       setPhoneNumberError(null)
  //       return false;
  //     }
  //   } 
  // }

  /**
   * onChange method is used to map the data to the field on changing the value in modal.
   * @param {object} e - data
   * @param {string} header - field header
   * @param {string} type - type of the field changed
   */
  const onChange = (e, header, type) => {

    if (action === "Edit" && count === 0) {
      dispatchData({ type: "setRecords", data: selectedRow })
      setCount(count + 1)
    }
    if (!onValidate(e.target.id, e.target.value)) {
      setIsedited(true);
      dispatchData({
        type: "setDataToModalonChange",
        data: { id: e.target.id, value: e.target.value },
      })
    }

    

    // dispatchData({
    //   type: "setDataToModalonChange",
    //   data: { id: e.target.id, value: e.target.value },
    // })
  }
  const onValidate = (field, value) => {
    if (field === 'emailAddress') {
      const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (value.match(validRegex)) {
        setEmailError(null)
        return false;
      } else {
        setEmailError('Please provide a valid email')
        return true;
      }
    } else if (field === 'mobilePhone') {
      // const validRegex1 = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g
      const validRegex1 = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/g
      const validRegex2 = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
      if (value.match(validRegex2) || value.match(validRegex1)) {
        setPhoneNumberError(null)
        return false;
      } else {
        setPhoneNumberError('Please provide a valid phone number')
        return true;
      }
    } else if (field === 'workPhone') {
      // const validRegex1 = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g
      const validRegex1 = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/g
      const validRegex2 = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
      if (value.match(validRegex2) || value.match(validRegex1)) {
        setWorkPhoneError(null)
        return false;
      } else {
        setWorkPhoneError('Please provide a valid phone number')
        return true;
      }
    }

  }

  const dropDownChange = async (e, dataField) => {
    if (dataField === "role") {
      setIsedited(true)
      dispatchData({
        type: "setDataToModalonChange",
        data: { id: dataField, value: e.value },
      })
      const jwtToken = await getAccessToken(apiurl, "edit:customer")
      if (e.value === "Service Provider") {
        if (userMetadata.role === "Admin") {
          await getServiceProviderNames(jwtToken)
            .then(result => {
              dispatchData({
                type: "setDataToModalonChange",
                data: { id: dataField, value: e.value },
              })
              setAccountsData(result.names)
              setKeyValueMap(result.values)
            })
            .catch(error => {
              addToast("You are not authorised to view this data.", {
                appearance: "error",
                autoDismiss: true,
              })
            })
        }
      } else if (e.value === "Asset Owner") {
        if (userMetadata.role === "Admin") {
          await getAssetOwnerNames(jwtToken)
            .then(result => {
              dispatchData({
                type: "setDataToModalonChange",
                data: { id: dataField, value: e.value },
              })
              setAccountsData(result.names)
              setKeyValueMap(result.values)
            })
            .catch(error => {
              addToast("You are not authorised to view this data.", {
                appearance: "error",
                autoDismiss: true,
              })
            })
        }
      } else {
        setAccountsData(accountOptions)
      }
    } else {
      const pk = keyValueMap[e.value]
      dispatchData({
        type: "setDataToModalonChange",
        data: { id: "pk", value: pk },
      })
      dispatchData({
        type: "setDataToModalonChange",
        data: { id: dataField, value: e.value },
      })
    }
  }

  /**
   * accountChange method is called when we select an account for the role.
   */
  const accountChange = e => {
    let pk
    dispatchData({
      type: "setDataToModalonChange",
      data: { id: "account", value: e.target.value },
    })
    if (userMetadata.role === "Admin") {
      pk = keyValueMap[e.target.value]
    }
    else {
      pk = CryptoJS.enc.Base64.parse(userMetadata.pk).toString(
        CryptoJS.enc.Utf8
      )
    }
    dispatchData({
      type: "setDataToModalonChange",
      data: { id: "pk", value: pk },
    })
  }

  const getKey = (val, map) => {
    return Object.keys(map).find(key => map[key] === val)
  }

  /**
   * submitData method is called when we click the ok button in modal.
   * @param {object} e
   */
  const submitData = async e => {
    e.preventDefault()
    if ( userMetadata.role == "Asset Owner Default User" || userMetadata.role == "Service Provider Default User") {
      const pk = CryptoJS.enc.Base64.parse(userMetadata.pk).toString(CryptoJS.enc.Utf8)
      // const jwtToken = await getAccessToken(apiurl, "edit:customer")
      // let accountMap = {}
      // let role = ""
      // if (userMetadata.role == "Asset Owner Default User") {
      //   await getAssetOwnerNames(jwtToken).then(result => {
      //     accountMap = result.values
      //   })
      //   role = "Asset Owner Regular User"
      // } else if (userMetadata.role == "Service Provider Default User") {
      //   await getServiceProviderNames(jwtToken).then(result => {
      //     accountMap = result.values
      //   })
      //   role = "Service Provider Regular User"
      // } else {
      //   await getServiceProviderNames(jwtToken).then(result => {
      //     accountMap = result.values
      //   })
      //   role = userMetadata.role
      // }
      data["pk"] = pk
      if(action === "Add"){
        data["role"] = role
      }
      const accountDetails = getKey(pk, accountMap)
      let userAccount = accountMap[pk];
      let separatedArray = userAccount.split(', ');
      let newAccountData=[];
      separatedArray.forEach(accountResult => {
        if(accountResult !== "undefined"){
          newAccountData.push(accountResult);
        }
      });
      let accountString = newAccountData.join(',');
      data["account"] = accountString;
    } else if( userMetadata.role == "Admin"){
      data["role"] = role
    }
    data["mode"] = action
    updateRecords(data)
    setAccountsData(accountOptions)
    toggle()
  }

  const cancelData = e => {
    e.preventDefault()
    setIsedited(false)
    setPhoneNumberError(null)
    setWorkPhoneError(null)
    setEmailError(null)
    toggle()
  }

  useEffect(() => {
    let data;
    let deData;
    /**
     * getInitialData method is used to fetch all the records from db and display in table.
     */
    const getInitialData = async () => {
      let account = {}
      let userRole = ""
      const jwtToken = await getAccessToken(apiurl, "edit:customer")
      if (userMetadata.role == "Asset Owner Default User") {
        await getAssetOwnerNames(jwtToken).then(result => {
          account = result.values
        })
        userRole = "Asset Owner Regular User"
      } else if (userMetadata.role == "Service Provider Default User") {
        await getServiceProviderNames(jwtToken).then(result => {
          account = result.values
        })
        userRole = "Service Provider Regular User"
      } else {
        // await getServiceProviderNames(jwtToken).then(result => {
        //   accountMap = result.values
        // })
        userRole = userMetadata.role
      }
      setAccountMap(account)
      setRole(userRole)
    };
    getInitialData();

  
  }, []);

  return (
    <React.Fragment>
      <Modal isOpen={open}>
        <ModalHeader toggle={toggle}>{modalheader}</ModalHeader>
        <form className="pl-3 pr-3 " onSubmit={submitData}>
          <ModalBody>
            {modalColumns.map(column => (
              <div key={column.dataField}>
                <div className={"form-group "} style={{  marginTop: "1%" }}>
                  <label htmlFor={column.text}>
                    {column.text}
                    {column.required ? (
                      <span className="required-field"></span>
                      
                    ) : (
                      ""

                    )}
                     {
                        emailError !== null && column.dataField === "emailAddress" ? <span className="text-danger m-2">{emailError}</span> : ''
                      }
                      {
                        workPhoneError !== null && column.dataField === "workPhone" ? <span className="text-danger m-2">{workPhoneError}</span> : ''
                      }
                  </label>
                  <input
                    id={column.dataField}
                    name={column.text}
                    className="form-control"
                    defaultValue={
                      action === "Add" ? "" : selectedRow[column.dataField]
                    }
                    onChange={onChange}
                    readOnly={
                      action === "Edit"
                        ? column.dataField === "emailAddress"
                          ? true
                          : false
                        : editable
                    }
                    required={column.required}
                    // data-error="This is a required fields."
                    
                  ></input>
                </div>
              </div>
            ))}
            {userMetadata.role == "Admin" ? (
              <div className={" form-group"} style={{  marginTop: "1%" }}>
                <label htmlFor="role">Role</label>
                <Dropdown
                  id="role"
                  options={
                    userMetadata.role == "Service Provider"
                      ? spDropDownOptions
                      : dropDownOptions
                  }
                  value={action === "Add" ? "" : selectedRow["role"]}
                  
                  onChange={e => dropDownChange(e, "role")}
                />
              </div>
            ) : (
              ""
            )}
            {userMetadata.role == "Admin" ? (
              <div className={" form-group"}>
                <label htmlFor="account">Account</label>
                <input
                  className="form-control"
                  list="accounts"
                  name="accounts"
                  id="account"
                  autoComplete="off"
                  defaultValue={action === "Add" ? "" : selectedRow["account"]}
                  onChange={accountChange}
                />
                <datalist id="accounts">
                  {accountsData.map(item => (
                    <option value={item} />
                  ))}
                </datalist>
              </div>
            ) : (
              ""
            )}
          </ModalBody>
          <ModalFooter>
            <div className="form-group text-right">
              <button
                className="btn btn-primary m-2"
                type="submit"
                onSubmit={submitData}
                disabled={isEdited == false}
              >
                Save
              </button>
              <button
                className="btn btn-primary"
                type="submit"
                onClick={cancelData}
              >
                Cancel
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
    </React.Fragment>
  )
}
