import React, {useEffect, useState } from "react"
import { useToasts } from "react-toast-notifications"
import { useAuth0 } from "@auth0/auth0-react"
import axios from "axios"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
//Import Table
import { PlanDetailTable } from "./Components/PlanDetailTable"
//import services
import {
  getProductDetails,
} from "../../services/paymentService"
import { user_metadata } from "../../nameSpaceConfig"

const PlanList = props => {
  const apiurl = process.env.REACT_APP_AUTH0_API_URL
  const { getAccessTokenSilently, getAccessTokenWithPopup, user } = useAuth0()
  const [productsList, setProductsList] = useState([])

  /**
   * getAccessToken method is used to fecth jwt access token from auth0
   * @param {string} audience - the api URL
   * @param {string} claim - the permission
   */
  const getAccessToken = async (audience, claim) => {
    let token
    try {
      token = await getAccessTokenSilently({
        audience,
        claim,
      })
    } catch (exception) {
      token = await getAccessTokenWithPopup({ audience, claim })
    }
    return token
  }

  const getInitialData = async () => {
    const jwtToken = await getAccessToken(apiurl, "edit:assets")
    let productListArray = []
    await getProductDetails(jwtToken).then(result => {
      result.data.data.map(product => {
        if (product.active === true) {
          productListArray.push(product)
        }
      })
    })
    setProductsList(productListArray)
  }

  useEffect(() => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source() 
    getInitialData()
    return () => {
      source.cancel()
    }
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Asset Management"
            breadcrumbItem="Plans Available"
          />
          <PlanDetailTable productsList={productsList} />
        </div>
      </div>
    </React.Fragment>
  )
}

export default PlanList
