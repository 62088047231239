import { Input, Select, Tag } from "antd";
import ServiceProviderQuoteModal from "./QuoteModal/ServiceProviderQuoteModal";


export const columnsForServiceProviderQuotes = [
    {
        title: 'Seriel No',
        dataIndex: 'serialNo',
    },
    {
      title: 'Asset ID',
      dataIndex: 'tagNo',
    },
      {
        title: 'Instrument Name',
        dataIndex: 'instrumentName',
      },
      {
        title: 'Instrument Category',
        dataIndex: 'instrumentCategory',
      },
      {
        title: 'Type',
        dataIndex: 'itemType',
      },
      {
        title :'Remarks',
        dataIndex : 'comment'
      }
]


export const columnsForServiceProviderInquiry = [
  {
    title: 'Customer',
    dataIndex: 'name',
  },
  {
    title : 'Quote Id',
    dataIndex : 'quoteId'
  },
  {
    title : 'Total Assets',
    dataIndex : 'totalAssets'
  },
  {
    title : 'Date',
    dataIndex : 'date'
  },
  {
    title : 'Status',
    dataIndex : 'status',
    render: (_, record) => {

      if(record.status === 'Awaiting Review'){
      return(
      <ServiceProviderQuoteModal inquiryData={record} />
    )}else if (record.status === 'Accepted'){
      return(
      <>
      <Tag bordered={false} color="success">
        Accepted
      </Tag>
    </>
      )
    }else {
      return(<>
      <Tag bordered={false} color="error">
        Rejected
      </Tag>
      </>)
    }
    }
  }
]


export const columnsForServiceProviderSentQuote = [
  {
    title: 'Seriel No',
    dataIndex: 'serialNo',
},
{
  title: 'Asset ID',
  dataIndex: 'tagNo',
},
  {
    title: 'Instrument Name',
    dataIndex: 'instrumentName',
  },
  {
    title: 'Instrument Category',
    dataIndex: 'instrumentCategory',
  },
  {
    title: 'Type',
    dataIndex: 'itemType',
  },
  {
    title :'Remarks',
    dataIndex : 'comment'
  },
  {
    title : 'Price',
    dataIndex : 'price'
  },
  {
    title : 'Turnaround',
    dataIndex : 'turnaround'
  }
]