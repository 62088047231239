import React, { useState } from 'react';
import { Divider, Input, Modal,  Table } from 'antd';
import { Button } from 'antd';
import { useAuth0 } from '@auth0/auth0-react';
import { user_metadata } from 'nameSpaceConfig';
import { toast } from 'react-hot-toast';
import { createQuoteOnInquiry } from 'services/serviceProvider';


const ServiceProviderQuoteModal = ({  inquiryData }) => {
  const apiurl = process.env.REACT_APP_AUTH0_API_URL
  const { getAccessTokenSilently, getAccessTokenWithPopup , user} = useAuth0()
  
  const getAccessToken = async (audience, claim) => {
    let token
    try {
      token = await getAccessTokenSilently({
        audience,
        claim,
      })
    } catch (exception) {
      try {
        token = await getAccessTokenWithPopup({ audience, claim })
      } catch (ex) {
        token = ""
      }
    }
    return token
  }
  const currency = user[user_metadata].currency ? user[user_metadata].currency : "$"
    const [finalAssetList , setFinalAssetList] = useState(Array.from(inquiryData.attributes.assets))

    const columnsForServiceProviderInquiryPricing = [
        {
          title: 'Asset ID',
          dataIndex: 'tagNo',
        },
          {
            title: 'Instrument Name',
            dataIndex: 'instrumentName',
          },
          {
            title: 'Instrument Category',
            dataIndex: 'instrumentCategory',
          },
          {
            title: 'Type',
            dataIndex: 'itemType',
          },
          {
            title :'Remarks',
            dataIndex : 'comment'
          },
          {
            title : 'Pricing',
            dataIndex : 'tagNo',
            render : (_,record) =>{
            const tagNo = record.tagNo
              return (<>
            <Input 
                placeholder='Price'
                // defaultValue={0}
                onChange={(e) => handlePriceChange(tagNo, e.target?.value ? e?.target?.value : 0)}
            />
              </>)
            } 
          },
          {
            title : 'Turnaround',
            dataIndex : 'tagNo',
            render : (_,record)=>{
              const tagNo = record.tagNo
              return(<>
              <Input 
                placeholder='Days'
                // defaultValue={0}
                onChange={(e) => handleTurnaroundChange(tagNo , e?.target?.value ? e?.target?.value : 0)}
                />
              </>)
            }
          }
      ]
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpen = () => {setIsModalOpen(true)};
  
    const handleOk = async() => {
      const jwtToken = await getAccessToken(apiurl , 'edit:assets')
      const inquiryId = inquiryData.pk
      const data = inquiryData.attributes
      data.assets = finalAssetList
      const getTagNosWithEmptyFieldsOrNoQuoteTurnaround = (arr) =>
      arr
      .filter(
      (item) =>
        item.turnaround === "" ||
        item.price === "" ||
        !("price" in item) ||
        !("turnaround" in item)
      )
      .map((item) => item.tagNo);
      const result = getTagNosWithEmptyFieldsOrNoQuoteTurnaround(finalAssetList);
      console.log(result);
      if(result.length > 0){
        toast.error("Some of the assets do not have price or turnaround")
      }else{
      try {
        await createQuoteOnInquiry(jwtToken ,inquiryId ,  data , "Awaiting Confirmation")
        toast.success("Quote Sent")
      } catch (error) {
        toast.error("Could not send quote")
      }
      setIsModalOpen(false)
      }
    };
  
    const handleCancel = () => {setIsModalOpen(false)};

    const handleTurnaroundChange = (assetId , days)=>{
      var updatedAssets = finalAssetList.map((asset)=>
      asset.tagNo === assetId ? {...asset , turnaround : days} : asset
      )
      setFinalAssetList(updatedAssets)
    }
    const handlePriceChange = (assetId, price) => {
        const updatedAssets = finalAssetList.map((asset) =>
        asset.tagNo === assetId ? { ...asset, price : `${currency}${price}` } : asset
        );
      setFinalAssetList(updatedAssets);
    };
  return (
    <div>
    <Button onClick={handleOpen}>Send Quote</Button>
      <Modal title="Enter Quote Details" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} width={'1000px'} okText={'Send Quote'}>
      <Divider />
      <div style={{display:'flex' , gap:'1rem'}}>
      </div>
        <Table columns={columnsForServiceProviderInquiryPricing} dataSource={inquiryData.attributes.assets} />
      </Modal>
    </div>
  );
};

export default ServiceProviderQuoteModal;