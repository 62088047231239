import React, { useReducer, useEffect, useState } from "react"
import {
    Button, Modal,
    ModalHeader,
    ModalBody,
} from "reactstrap"
import { useAuth0 } from "@auth0/auth0-react"
import Dropdown from "react-dropdown"
import axios from "axios"
import { useToasts } from "react-toast-notifications"
import _ from "lodash"

//Import Components
import { TableForEmailAlert } from "./TableForEmailAlert"

//reducers
import { customDropDownFieldReducer, multiplerecordsreducer, emailAlertReducer } from "../reducer/reducers"


//Import columns
import { emailAlertColumns } from "../data/asset-fields"

//Service files
import {
    getAssetOwner,
} from "../../../services/assetownerService"
import { getServiceProviderById } from "../../../services/serviceProvider"
import { decryptKeyValues } from "../../../services/commonService"
import { user_metadata } from "../../../nameSpaceConfig"


const emailAlertType = ["Summary", "Detailed Report"]
const emailAlertFrequencyForSummary = ["Monthly", "Bi-Weekly", "Weekly", "Daily"]

const EmailAlertComponent = props => {
    const { updateEmailAlertSettings } = props
    const apiurl = process.env.REACT_APP_AUTH0_API_URL
    const { getAccessTokenSilently, getAccessTokenWithPopup, user } = useAuth0()
    const { addToast } = useToasts()
    const userMetadata = user[user_metadata]
    const [openAddCustomFieldModal, setOpenAddCustomFieldModal] = useState(false)
    const [data, dispatchData] = useReducer(customDropDownFieldReducer, [])


    //new file 
    const [count, setCount] = useState(0)
    const [selectedFieldType, setSelectedFieldType] = useState("Text")
    const [customFields, dispatchCustomFields] = useReducer(customDropDownFieldReducer, { list: [] })
    const [selectRow, setSelectRow] = useState([])
    const [
        multipleRecords,
        dispatchMultipleRecords,
    ] = useReducer(multiplerecordsreducer, { list: [] })
    const [mode, setMode] = useState('Add')
    const [updateButtonEnable, setUpdateButtonEnable] = useState(true)
    const [emailAlertField, setEmailAlertField] = useState([])
    const [emailAlertSettings, setEmailAlertSettings] = useState({})
    const [currentEmailType, setCurrentEmailType] = useState('')
    const [currentEmailFrequency, setCurrentEmailFrequency] = useState('')
    const fieldTypes = ["Text", "Number", "Dropdown", "Date"]
    // const [emailAlertList, setEmailAlertList] = useState([])
    const [emailAlertList, dispatchEmailAlertList] = useReducer(emailAlertReducer, { list: [] })
    const [duplicateAlertError, setDuplicateAlertError] = useState(false)


    /**
     * toggleAddCustomFieldsModal function is used to toggle Add/Edit modal
     */
    const toggleAddCustomFieldsModal = () => {
        setOpenAddCustomFieldModal(!openAddCustomFieldModal);
    };

    /**
   * generateId method is used to generate an id to uniquely identify each row
   * @returns uuid string
   */
    const generateId = () => {
        let dt = new Date().getTime();
        let uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
            /[xy]/g,
            function (c) {
                let r = (dt + Math.random() * 16) % 16 | 0;
                dt = Math.floor(dt / 16);
                return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
            }
        );
        return uuid;
    }


    /**
     * getAccessToken method is used to fecth jwt access token from auth0
     * @param {string} audience - the api URL
     * @param {string} claim - the permission
     */
    const getAccessToken = async (audience, claim) => {
        let token
        try {
            token = await getAccessTokenSilently({
                audience,
                claim,
            })
        } catch (exception) {
            token = await getAccessTokenWithPopup({ audience, claim })
        }
        return token
    }

    /**
     * selectedRow function is used to set the selected row value
     * @param {object} row 
     * @param {boolean} isSelected 
     */
    const selectedRow = (row, isSelected) => {
        if (isSelected) {
            setSelectRow(row)
            console.log(selectRow)
        } else {
            setSelectRow([])
        }
    }

    /**
    * multipleRows method is used when all rows are selected.
    * @param {list} rows - list of selected rows
    */
    const multipleRows = rows => {
        rows.map(row => {
            selectedRow(row, row.isSelected)
        })
        dispatchMultipleRecords(rows)
    }

    /**
     * isButtonsDisabled function is used to enable/ disabled add, edit, disable and delete buttons based on row selection
     * @param {string} action 
     * @returns 
     */
    const isButtonsDisabled = action => {

        if (action === "Add") {
            let disabled = false
            emailAlertList.list.map(record => {
                if (record.isSelected) {
                    disabled = true
                }
            })
            return disabled
        } if (action === "Edit") {

            let selectedRowCount = 0
            emailAlertList.list.map(record => {
                if (record.isSelected) {
                    selectedRowCount += 1
                }
            })
            if (selectedRowCount === 1) {
                return false
            } else {
                return true
            }
        }
        if (action === "Delete") {
            let disabled = true
            emailAlertList.list.map(record => {
                if (record.isSelected) {
                    disabled = false
                }
            })
            return disabled
        }
        // let disabled = true
        // let selectedRowCount = 0
        // emailAlertList.list.map(record => {
        //   if (record.isSelected) {
        //     disabled = false
        //     selectedRowCount += 1
        //   }
        // })
        // if (action === "Edit") {

        // }
        // return disabled
    }

    /**
     * setEmailAlertValues function is used to set the data values for custom fields
     * @param {string} field 
     * @param {string} dataValue 
     */
    const setEmailAlertValues = (field, dataValue) => {
        dispatchData({
            type: "setDataOnFieldChange",
            data: { id: field, value: dataValue },
        })
    }

    /**
     * onPlusButtonClick is called while clicking add/ edit/ disable or delete buttons
     * @param {event} e 
     * @param {string} mode 
     */
    const onPlusButtonClick = (e, mode) => {
        setDuplicateAlertError(false)
        setMode(mode)
        if (mode === 'Delete') {
            onDeleteButtonClick()
        } else if (mode === 'Disable') {
            onDisableButtonClick()
        } else if (mode === 'Add') {
            setEmailAlertValues('id', generateId())
            setEmailAlertValues('emailAlertFrequency', '')
            setEmailAlertValues('emailAlertType', '')
            setEmailAlertValues('emailAlertField', '')
            toggleAddCustomFieldsModal()
        } else {
            toggleAddCustomFieldsModal()
        }

    }


    /**
     * onAddButtonClick function will be called when the add button is clicked
     * @param {event} e 
     */
    const onAddButtonClick = async (e) => {
        e.preventDefault()
        if (mode === 'Add') {
            if (data.emailAlertType !== undefined && data.emailAlertFrequency !== undefined && data.emailAlertType !== null &&
                data.emailAlertFrequency !== null && data.emailAlertField !== undefined && data.emailAlertField !== null &&
                data.emailAlertType !== '' && data.emailAlertFrequency !== '' && data.emailAlertField !== '') {
                let isDuplicateAlert = false
                emailAlertList.list.map(alert => {
                    if (data.emailAlertType === alert.emailAlertType && data.emailAlertFrequency === alert.emailAlertFrequency && data.emailAlertField === alert.emailAlertField) {
                        isDuplicateAlert = true
                        setDuplicateAlertError(true)
                        return
                    }
                })
                if (isDuplicateAlert === true) {
                    addToast('This email alert have already been created', {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else {
                    setDuplicateAlertError(false)
                    setCurrentEmailFrequency(data.emailAlertFrequency)
                    setCurrentEmailType(data.emailAlertType)
                    dispatchEmailAlertList({
                        type: "setCustomFields",
                        data: { id: 'customValues', value: data, mode: mode },
                    })
                    toggleAddCustomFieldsModal()
                    updateEmailAlertSettings(data, mode)
                    // console.log(data)
                }

            } else {
                addToast('All fields are mandatory', {
                    appearance: 'error',
                    autoDismiss: true,
                })
            }
        } else if (mode === 'Edit') {
            dispatchEmailAlertList({
                type: "setCustomFields",
                data: { id: 'customValues', value: data, mode: mode },
            })
            //   
            toggleAddCustomFieldsModal()
            updateEmailAlertSettings(data, mode)
        }
    }

    /**
     * setDataForEdit function is sued to set data in custom fields modal while clicking edit button
     */
    const setDataForEdit = () => {
        if (mode === "Edit" && count === 0) {
            dispatchData({
                type: "setRecords",
                data: selectRow,
            })
            setCount(count + 1)
        }
    }

    /**
     * onDeleteButtonClick function will be called whenn delete button is clicked
     */
    const onDeleteButtonClick = () => {
        console.log(multipleRecords)
        multipleRecords.list.map(record => {
            dispatchEmailAlertList({
                type: "setCustomFields",
                data: { id: 'customValues', value: record, mode: 'Delete' },
            })
        })
        updateEmailAlertSettings(multipleRecords.list, 'Delete')
    }


    /**
     * onDisableButtonClick function will be called whe disable/enable button is clicked
     */

    const onDisableButtonClick = () => {
        for (let i = 0; i < multipleRecords.list.length; i++) {
            emailAlertList.list.map(field => {
                const isDisabled = multipleRecords.list[i].isDisabled !== undefined ? !multipleRecords.list[i].isDisabled : true
                if (field.id === multipleRecords.list[i].id) {
                    field['isDisabled'] = isDisabled
                }
            })
            setUpdateButtonEnable(false)
        }
        updateEmailAlertSettings(emailAlertList.list, "EnableDisable")
        // console.log(customFields)
    }



    /**
     * onInputFieldChange function will be called when the value is dynamic input fields are changed
     * @param {event} e 
     * @param {string} field 
     * @param {number} index 
     */
    const onInputFieldChange = (e, field) => {
        setDataForEdit()
        // setEmailAlertValues(field, e.value)
        dispatchData({
            type: "setDataOnFieldChange",
            data: { id: field, value: e.value },
        })
    }

    /**
     * getInitialData function will be called when the page is rendered. 
     */
    const getInitialData = async () => {
        let deData = null
        let emailAlert = []
        let customFields = []
        let totalFields = []
        try {
            const jwtToken = await getAccessToken(apiurl, "edit:customer")
            if (userMetadata !== undefined && userMetadata.pk !== undefined) {
                deData = decryptKeyValues(userMetadata.pk)
            }
            if (userMetadata.role.startsWith("Asset Owner")) {
                await getAssetOwner(jwtToken, "", deData).then(result => {
                    if (result[0].attributes.preferences !== undefined && result[0].attributes.preferences !== null) {
                        emailAlert = result[0].attributes.preferences.emailAlert
                        customFields = result[0].attributes.preferences.customFields
                        totalFields = result[0].attributes.preferences.totalFields
                    }
                })
            } else {
                await getServiceProviderById(jwtToken, "", deData)
                    .then(result => {
                        if (result[0].attributes.preferences !== undefined && result[0].attributes.preferences !== null) {
                            emailAlert = result[0].attributes.preferences.emailAlert
                            customFields = result[0].attributes.preferences.customFields
                            totalFields = result[0].attributes.preferences.totalFields
                        }
                    })
                    .catch(error => {
                    })
            }
            // setEmailAlertSettings(emailAlert)
            if (emailAlert !== undefined) {
                emailAlert.map(field => {
                    dispatchEmailAlertList({
                        type: "setCustomFields",
                        data: { id: 'customValues', value: field, mode: 'setInitialData' },
                    })
                })
            }
            let alertFields = []
            if (totalFields !== undefined) {
                totalFields.map(field => {
                    if (field.type === "Date" || field.type === "date") {
                        alertFields.push(field.text)
                    }
                    console.log(field)
                })
            }

            setEmailAlertField(alertFields)
        } catch (error) {
            if (axios.isCancel(error)) {
            } else {
                throw error
            }
        }
    }

    useEffect(() => {
        getInitialData()
    }, [])

    return (
        <React.Fragment>
            <div className="text-end">
                <Button color="primary" className="mb-2 me-2" onClick={e => onPlusButtonClick(e, "Add")} disabled={isButtonsDisabled("Add")}> Add </Button>
                <Button color="primary" className="mb-2 me-2" onClick={e => onPlusButtonClick(e, "Edit")} disabled={isButtonsDisabled("Edit")}> Edit </Button>
                <Button color="danger" className="mb-2 me-2" onClick={e => onPlusButtonClick(e, "Delete")} disabled={isButtonsDisabled("Delete")}> Delete </Button>
                <Button color="warning" className="mb-2 me-2" onClick={e => onPlusButtonClick(e, "Disable")} disabled={isButtonsDisabled("Delete")}> Active/Inactive</Button>
            </div>

            <Modal size="s" isOpen={openAddCustomFieldModal}>
                <ModalHeader toggle={toggleAddCustomFieldsModal}>{'Email Alert '}
                {duplicateAlertError === true ?<span className="text-danger">Duplicate Alert</span> : ''}
                </ModalHeader>
                <ModalBody>
                    <form className="pl-3 pr-3 ">
                        <div class="row">
                            <div className={"form-group"} style={{ marginTop: "1%" }}>
                                <label id={"emailAlertFrequency"} >Select Email Alert Frequency
                                    <span className="required-field"></span></label>
                                <Dropdown
                                    id={"emailAlertFrequency"}
                                    options={emailAlertFrequencyForSummary}
                                    value={selectRow === undefined ? '' : selectRow['emailAlertFrequency']}
                                    onChange={e => onInputFieldChange(e, "emailAlertFrequency")}
                                />
                            </div>
                            <div className={"form-group"} style={{ marginTop: "1%" }}>
                                <label id={"emailAlertType"} >Select Email Alert Type
                                    <span className="required-field"></span></label>
                                <Dropdown
                                    id={"emailAlertType"}
                                    // options={data.emailAlertFrequency === "Daily" ? emailAlertForDaily : emailAlertType}
                                    options={emailAlertType}
                                    value={selectRow === undefined ? '' : selectRow['emailAlertType']}
                                    onChange={e => onInputFieldChange(e, "emailAlertType")}
                                />
                            </div>

                            <div className={"form-group"} style={{ marginTop: "1%" }}>
                                <label id={"emailAlertField"} >Select a Date Field
                                    <span className="required-field"></span>
                                </label>
                                <Dropdown
                                    id={"emailAlertField"}
                                    options={emailAlertField}
                                    value={selectRow === undefined ? '' : selectRow['emailAlertField']}
                                    onChange={e => onInputFieldChange(e, "emailAlertField")}
                                />
                            </div>
                        </div>
                        <div className="text-end">
                            <Button color="primary" className="btn btn-primary mt-2" onClick={onAddButtonClick}>Save</Button>
                        </div>
                    </form>
                </ModalBody>
            </Modal>
            <div className="row">
                <TableForEmailAlert emailFields={emailAlertColumns} emailFieldsList={emailAlertList.list} multipleRows={multipleRows} />
            </div>
        </React.Fragment>
    )
}

export default EmailAlertComponent