import { csvColumns, importColumns } from "../data/asset-fields"

/**
 * convertDateFormat method is used to change the date to mm/dd/yyy format
 * @param {string} dateFromDb
 * @returns
 */
export const convertDateFormat = dateFromDb => {
  let date = new Date(dateFromDb)
  let year = date.getFullYear()
  if(isNaN(year)){
    if(dateFromDb !== undefined){
      date = new Date(dateFromDb.replace( /(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3") )
    }
    
    year = date.getFullYear()
  }
    let month = date.getMonth() + 1
    let dt = date.getDate()
  
    if (dt < 10) {
      dt = "0" + dt
    }
    if (month < 10) {
      month = "0" + month
    }
    return month + "/" + dt + "/" + year
  
}

/**
 * findCoditionOfAsset methos is used to check the filter condition of asset
 * @param {date} dueDate 
 * @returns string
 */
export const findCoditionOfAsset = dueDate => {
  let currentDate = new Date()
  currentDate.setDate(currentDate.getDate() + 90)
  const condition =
    new Date(dueDate) < new Date()
      ? "danger"
      : new Date(dueDate) < new Date(currentDate)
      ? "warning"
      : "good"
  return condition
}

export const assetRecordsReducer = (state, action) => {
  switch (action.type) {
    case "setRecords":
      return {
        ...state,
        list: action.payload.map(item => {
          // item.attributes.dueDate = convertDateFormat(item.attributes.dueDate)
          item.attributes.DueDate = convertDateFormat(item.attributes.DueDate)
          // item.attributes.serviceDate = convertDateFormat(
          //   item.attributes.serviceDate
          // )
          item.attributes.ServicedDate = convertDateFormat(
            item.attributes.ServicedDate
          )
          item.attributes.createdDate = convertDateFormat(
            item.attributes.createdDate
          )
          item.attributes.lastModifiedDate = convertDateFormat(
            item.attributes.lastModifiedDate
          )
          return {
            ...item.attributes,
            id: item.sk,
            pk: item.pk,
            version: item.version,
            isSelected: false,
          }
        }),
      }

    case "setCSVRecords": {
      try {
        action.payload.map(csvData => {
          let importdata = {}
          importColumns.map(column => {
            const fieldValue = csvData[column]
            const dataField = csvColumns[column]
            importdata[dataField] = fieldValue
          })
          action.dataToBeImported.push(importdata)
        })
        const totaldata = [...state.list, ...action.dataToBeImported]
        return {
          ...state,
          list: totaldata.map(item => {
            return { ...item, id: item.id, pk: item.pk, isSelected: false }
          }),
        }
      } catch (e) {
        // console.log(e)
      }
    }

    case "Edit": {
      const list = [...state.list]
      const record = action.payload.data
      const selectedFilter = action.payload.selectedFilter
      const recordExists = list.find(r => r.id === record.id)
      if (recordExists) {
        const index = list.indexOf(recordExists)
        const editedAssetCondition = findCoditionOfAsset(record.dueDate)
        if (selectedFilter === "" || selectedFilter === editedAssetCondition) {
          record.pk = action.payload.data.pk
          record.assetCondition = editedAssetCondition
          record.version = action.payload.data.version
          // record.dueDate = convertDateFormat(record.dueDate)
          // record.serviceDate = convertDateFormat(record.serviceDate)
          record.ServicedDate = convertDateFormat(record.ServicedDate)
          record.DueDate = convertDateFormat(record.DueDate)
          record.serviceDate = convertDateFormat(record.serviceDate)
          record.lastModifiedDate = convertDateFormat(record.lastModifiedDate)
          list.splice(index, 1, record)
        } else {
          list.splice(index, 1)
        }
        return { ...state, list: list }
      }
      list.push({ ...record })
      return { ...state, list: list }
    }
    case "Add": {
      const list = [...state.list]
      const selectedFilterList = []
      // if (action.payload.isGood) {
      //   selectedFilterList.push("good")
      // }
      // if (action.payload.isWarning) {
      //   selectedFilterList.push("warning")
      // }
      // if (action.payload.isDanger) {
      //   selectedFilterList.push("danger")
      // }
      
      const record = action.payload.data.attributes
      const selectedFilter = action.payload.selectedFilter
      const editedAssetCondition = findCoditionOfAsset(record.dueDate)
      if (selectedFilter === "" || selectedFilter === editedAssetCondition) {
        record.pk = action.payload.data.pk
      record.id = action.payload.data.sk
      record.assetCondition = editedAssetCondition
      record.DueDate = convertDateFormat(record.DueDate)
      record.ServicedDate = convertDateFormat(record.ServicedDate)
      record.lastModifiedDate = convertDateFormat(record.lastModifiedDate)
      record.createdDate = convertDateFormat(record.createdDate)
      record.version = action.payload.data.version
      list.push({ ...record })
      }
      return { ...state, list: list }
    }
    case "Delete": {
      const records = [...state.list]
      const dataToBeDeleted = [...state.list]
      dataToBeDeleted.map(data => {
        if (data.isSelected) {
          const index = records.indexOf(data)
          records.splice(index, 1)
        }
      })
      return { ...state, list: records }
    }
    default:
      return state
  }
}

export const dataReducer = (state, action) => {
  switch (action.type) {
    case "setDataToModalonChange":
      return {
        ...state,
        [action.data.id]: action.data.value,
      }

    case "setRecords":
      const data = action.data
      return { ...state, ...data }

    default:
      return state
  }
}

export const multiplerecordsreducer = (state, action) => {
  const totaldata = [...state.list, ...action]
  return {
    ...state,
    list: totaldata.map(item => {
      return { ...item, id: item.id }
    }),
  }
}

export const metquayAssetsreducer = (state, action) => {
  switch (action.type) {
    case "setRecords":
      return {
        // ...state,
        // list: action.payload.map(item => {
          
        //   return {
        //     ...item
        //   }
        // }),
        ...state,
        list: action.payload.map(item => {
         
          return {
            ...item,
            tagNo: item.barcode,
            description: item.itemName,
            createdDate: item.createdDate,
            lastModifiedDate: item.modifiedDate,
            createdBy: item.companyName,
            lastModifiedBy: item.companyName,
          }
        }),
      }


  
 
    default:
      return state
  }
}

export const metquayWorksreducer = (state, action) => {
  switch (action.type) {
    case "setRecords":
      return {
        // ...state,
        // list: action.payload.map(item => {
          
        //   return {
        //     ...item
        //   }
        // }),
        ...state,
        list: action.payload.map(item => {
         
          const randomNumber = Math.random();
          return {
            ...item,
            id: item.workNo+item.tagNo+item.instrumentUniqueNo+randomNumber,
            //tagNo: item.barcode,
            description: item.itemName,
            createdDate: item.createdDate,
            lastModifiedDate: item.modifiedDate,
            createdBy: item.companyName,
            lastModifiedBy: item.companyName,
          }
        }),
      }


  
 
    default:
      return state
  }
}
