import React from "react"
import { Route, Redirect, withRouter } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react"
import Spinner from "../components/Common/Spinner"
import { loadStripe } from "@stripe/stripe-js"

//service methods
import { getAuthUserDetails } from "../services/userService"
import {
  getSubscriptionDetails,
  createNewSessionForDeclinedCardFailuers,
  retrieveCheckoutSession,
  retrieveSetupIntent,
} from "../services/paymentService"
import {
  getAssetOwner,
  updateInvitationInAssetOwner,
  updateAssetsCountInDynamoDb
} from "../services/assetownerService"
import {
  getServiceProviderById,
} from "../services/serviceProvider"
import {checkLoggedInDays, decryptKeyValues} from  "../services/commonService"

import { user_metadata } from "../nameSpaceConfig"
import { sendSlackInvitaionMessage } from "services/slackService"

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => {
  const apiurl = process.env.REACT_APP_AUTH0_API_URL
  const {
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    user,
  } = useAuth0()
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

  /**
   * getAccessToken method is used to fecth jwt access token from auth0
   * @param {string} audience - the api URL
   * @param {string} claim - the permission
   */
  const getAccessToken = async (audience, claim) => {
    let token
    try {
      token = await getAccessTokenSilently({
        audience,
        claim,
      })
    } catch (exception) {
      token = await getAccessTokenWithPopup({ audience, claim })
    }
    return token
  }

  return (
    <Route
      {...rest}
      render={props => {
        if (isLoading) {
          return (
            <div className="p-4 position-relative">
              <Spinner />
            </div>
          )
        }

        if (user !== undefined ) {
          const userMetadata = user[user_metadata]
          let noOfFreeTrialDays = 0
          if(userMetadata.role.startsWith("Asset Owner")){
            if(userMetadata.sk === 'QVNTRVRPV05FUg=='){
            const getSubscriptionStatus = async () => {
              const jwtToken = await getAccessToken(apiurl, "edit:assets")
              const userMetadata = user[user_metadata]
              let sortKeyValue = ""
              let primaryKeyValue = ""
              if (userMetadata.role.startsWith("Asset Owner")) {
                primaryKeyValue = decryptKeyValues(userMetadata.pk)
                sortKeyValue = decryptKeyValues(userMetadata.sk)
              }
              var invitationStatus = false
              let assetOwnerDetails = {}
              var noOfAssets;
              await getAssetOwner(jwtToken, null, primaryKeyValue).then(result => {
                  assetOwnerDetails = result[0]
                  invitationStatus  = assetOwnerDetails.attributes.isInvitationAccepted
                  noOfAssets = assetOwnerDetails.attributes.noOfAssets
              })
              if(noOfAssets === undefined ){
                await updateAssetsCountInDynamoDb(jwtToken , primaryKeyValue)
              } 
              if(invitationStatus === undefined){
                if(user.email_verified === true){
                  await sendSlackInvitaionMessage(jwtToken ,user.email)
                  await updateInvitationInAssetOwner(jwtToken , primaryKeyValue , sortKeyValue)
                }
              }
              if(invitationStatus === false){
                if(user.email_verified === true){
                  await sendSlackInvitaionMessage(jwtToken ,user.email)
                  await updateInvitationInAssetOwner(jwtToken , primaryKeyValue , sortKeyValue)
                }
              }
              noOfFreeTrialDays = assetOwnerDetails.attributes.freeTrialDays
              if(assetOwnerDetails.attributes.subscriptionId !== undefined & assetOwnerDetails.attributes.subscriptionId !== null){
                await getSubscriptionDetails(jwtToken, assetOwnerDetails.attributes.subscriptionId).then(async (result) => {
                  productId = result.data.plan.product
                  if(result.data.status === 'active'){
                    return <Redirect to={{ pathname: "/dashboard", state: { from: props.location } }} />
                  }if(result.data.status === 'incomplete' ){
                    const stripe = await stripePromise
                    let sessionId = ""
                    let setupIntent = ""
                    await createNewSessionForDeclinedCardFailuers(jwtToken, assetOwnerDetails.attributes.subscriptionId, assetOwnerDetails.attributes.customerId).then(
                      async session => {
                        sessionId = session.data.id
                        await stripe.redirectToCheckout({ sessionId: session.data.id })
                      }
                    )
                      await retrieveCheckoutSession(jwtToken, sessionId).then(result =>{
                        setupIntent = result.data.object.setup_intent
                      })

                      await retrieveSetupIntent(jwtToken, setupIntent).then(result =>{
                        paymentId = result.payment_method.id
                      })

                  }else{
                    return <Redirect to={{ pathname: "/assetManagement-payment", state: { from: props.location } }} />
                  }
                })
              }else{
                getAuthUserDetails(jwtToken, user.sub).then(result => {
                  const differenceBetweenCreatedDateAndCurrentDate = checkLoggedInDays(result.data.created_at)
                  if (differenceBetweenCreatedDateAndCurrentDate <= noOfFreeTrialDays) {
                    return <Redirect to={{ pathname: "/dashboard", state: { from: props.location } }} />
                  } else {
                    return <Redirect to={{ pathname: "/assetManagement-payment", state: { from: props.location } }} />
                  }
                })
              }
            }
            getSubscriptionStatus()
          }else {}
          }else if(userMetadata.role.startsWith("Service Provider")){
            const getMetquayIntegrationStatus = async () =>{
              const jwtToken = await getAccessToken(apiurl, "edit:assets")
              const primaryKeyValue = decryptKeyValues(userMetadata.pk)
                await getServiceProviderById(jwtToken, null, primaryKeyValue)
              .then(result => {
                if(result[0].attributes.metquaySecretKey !== undefined) {
                }
              })
              

            }
            getMetquayIntegrationStatus()
          }
          
        }

        if (!isAuthenticated) return loginWithRedirect()

        return (
          <Layout>
            <Component {...props} />
          </Layout>
        )
      }}
    />
  )
}

export default withRouter(Authmiddleware)