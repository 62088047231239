import React from "react"
import { Redirect } from "react-router-dom"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
//Asset Management
import Assets from "../pages/Asset-management/Assets"
import AssetOwners from "../pages/Asset-management/AssetOwners"
import ServiceProviders from "../pages/Asset-management/ServiceProviders"
import Users from "../pages/Asset-management/User"
import MetquayAssets from "../pages/Asset-management/MetquayAssets"
import MetquayWorks from "../pages/Asset-management/MetquayWorks"


//Payment
import Payment from "../pages/Asset-management/Payment"
import Preferences from "../pages/Asset-management/Preferences"
import BulkInviteNew from "pages/Asset-management/Components/BulkInviteNew"
import ResendInvitation from "pages/Asset-management/Components/ResendInvitation"
import UserTrack from "pages/Dashboard/UserTrack"
import PlanList from "pages/Asset-management/PlanList"
import PlanDetailPage from "pages/Asset-management/PlanDetailPage"
import AssetLocation from "pages/AssetLocation"
import Logout from "pages/Authentication/Logout"
import QuotesOverview from "pages/RequestForQuotes/QuotesOverview"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  {path: "/bulk-invite" , component:BulkInviteNew},
  { path: "/resend-invitation", component: ResendInvitation },
  { path:"/user/:id" ,component:UserTrack},
  { path: "/asset-management-plans", component: PlanList },
  { path: "/asset-management-product", component: PlanDetailPage },
  {path : "/asset-track" , component: AssetLocation},
  {path : "/asset-quote" , component: QuotesOverview},
  //Asset management
  { path: "/assetManagement-assets", component: Assets },
  { path: "/assetManagement-assetOwners", component: AssetOwners },
  { path: "/assetManagement-serviceproviders", component: ServiceProviders },
  { path: "/assetManagement-users", component: Users },
  { path: "/assetManagement-metquayAssets", component: MetquayAssets },
  { path: "/assetManagement-metquayWorks", component: MetquayWorks },
  { path: "/assetManagement-payment", component: Payment },
  { path: "/assetManagement-preferences", component: Preferences },
  
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
]

export { publicRoutes, authProtectedRoutes }
