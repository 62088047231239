import { planNameAddFeatures } from "pages/Dashboard/LiveUpdates"
import { statusFieldFormatter } from "pages/Dashboard/LiveUpdates"

const planDetailFields = [
  {
    dataField: "name",
    text: "Plan Name",
    sort: false,
    float: "left",
    type: "string",
  },
  {
    dataField: "description",
    text: "Description",
    sort: false,
    float: "left",
    type: "string",
  },
  {
    dataField: "actions",
    text: "Actions",
    sort: false,
    float: "left",
    type: "string",
    formatter: planNameAddFeatures,
  },
]

const userFieldListForPlan =[
  {
    dataField: "userName",
    text: "User Name",
    sort: false,
    float: "left",
    type: "string",
  },
  {
    dataField: "userEmail",
    text: "Email Address",
    sort: false,
    float: "left",
    type: "string",
  },
  {
    dataField: "createdAt",
    text: "Created Date",
    sort: false,
    float: "left",
    type: "string",
  },
  {
    dataField: "noOfUsers",
    text: "No.of Users",
    sort: false,
    float: "left",
    type: "string",
  },
  {
    dataField: "subscriptionEndDate",
    text: "Subscription End date",
    sort: false,
    float: "left",
    type: "string",
  },
  {
    dataField: "isCanceled",
    text: "Status",
    sort: false,
    float: "left",
    type: "string",
    // formatter: statusFieldFormatter
  },
  {
    dataField: "canceledDate",
    text: "Canceled Date",
    sort: false,
    float: "left",
    type: "string",
  },
  // {
  //   dataField: "name",
  //   text: "Plan Name",
  //   sort: false,
  //   float: "left",
  //   type: "string",
  // },
]

export { planDetailFields, userFieldListForPlan }
