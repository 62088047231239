import React , { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, CardTitle, Button, Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import toast, { Toaster } from 'react-hot-toast';
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import '../css/datatables.scss'
import ScaleLoader from "react-spinners/ScaleLoader";
import { useAuth0 } from "@auth0/auth0-react"
const apiurl = process.env.REACT_APP_AUTH0_API_URL
import { getAssetOwnerById } from "services/assetownerService";
import { getAssetOwnersList } from "services/dashboard-services";
import { getServiceProviderById } from "services/serviceProvider";
import { user_metadata } from "nameSpaceConfig";
import { decryptKeyValues } from "services/commonService";
import { sendMessage } from "services/bulkInviteService";


const BulkInviteNew = () => {
    const { getAccessTokenSilently, getAccessTokenWithPopup, user } = useAuth0()
    const [selected, setSelected] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [modal, setModal] = useState(false);
    const [assetOwnerList, setAssetOwnerList] = useState([])
    const [loading, setLoading] = useState(true)
    const [days , setDays] = useState(30)
    const { SearchBar } = Search;
    const onSelect = (row, isSelect) => {
      if (isSelect) {
        setSelected([...selected, row]);
      } else {
        setSelected(selected.filter(x => x.id !== row.id));
      }
    }
    const onSelectAll = (isSelect, rows) => {
      setSelectAll(isSelect);
      setSelected(isSelect ? rows : []);
    }
  
    const getAccessToken = async (audience, claim) => {
      let token
      try {
        token = await getAccessTokenSilently({
          audience,
          claim,
        })
      } catch (exception) {
        token = await getAccessTokenWithPopup({ audience, claim })
      }
      return token
    }
    
    const columns = [
      // {
      //   dataField: "id",
      //   text: "Id",
      //   sort: true,
      // },
      {
        dataField: "name",
        text: "Name",
        sort: true,
      },
      {
        dataField: "email",
        text: "Email",
        sort: true,
      },
      {
        dataField: "companyCode",
        text: "Customer Code",
        sort: true,
      },
      {
        dataField: "metquayAssetOwnerKey",
        text: "Tracefii UID",
        sort: true,
      }
    ];
  
    const defaultSorted = [
      {
        dataField: "name",
        order: "asc",
      },
    ];
  
    const pageOptions = {
      sizePerPage: 25,
      totalSize: assetOwnerList.length, // replace later with size(customers),
      custom: true,
    };
  
    // Select All Button operation
    const selectRow = {
      mode: 'checkbox',
      clickToSelect: true,
      onSelect: onSelect,
      onSelectAll: onSelectAll
    };
  
    const handleBulkInvite = async () => {
      if (selected.length === 0) {
        toast.error('You have not selected any asset owners',
          {
            style: {
              borderRadius: '10px',
              background: '#333',
              color: '#fff',
            },
          });
  
      }
      else {
        setModal(!modal)
  
      }
    }
    var pk = decryptKeyValues(user[user_metadata].pk)
    const toggle = () => setModal(!modal);
  
    async function modalConfirmation() {
      let msk = '', mu = '', serviceProviderName = '', serviceProviderId = '',videoUrl = ''
      const jwtToken = await getAccessToken(apiurl, "add:asset")
      await getServiceProviderById(jwtToken, null, pk)
        .then(result => {
          serviceProviderId = (result[0].pk)
          serviceProviderName = (result[0].attributes.name)
          msk = (result[0].attributes.metquaySecretKey)
          mu = (result[0].attributes.metquayurl)
          videoUrl = result[0].attributes.videoUrl ? result[0].attributes.videoUrl : "https://vimeo.com/manage/videos/803330911";
          return result
        })
        .catch(error => {
          // console.log(error)
        })
      const assetOwners = selected.filter((item, index) => {
        return !selected.slice(index + 1).find(x => x.email === item.email);
      });
  
      await sendMessage(assetOwners, jwtToken, msk, mu, serviceProviderId, serviceProviderName,videoUrl)
      setSelected([])
      setModal(!modal)
      toast.success("Invitions sent might take some time, we will inform once the process is over",
        {
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        })
    }
    async function getAssetOwnerListfromDB(jwtToken , pk){
      var assetOwnerList = []
      await getAssetOwnerById(jwtToken, null, pk)
        .then(result => {
          assetOwnerList = result
          return result
        })
        .catch(error => {
          toast.error("Failed to Load Data")
          // console.log(error)
        })
        return assetOwnerList
    }
  
    async function getAssetOwnerListfromMetquay(jwtToken, metquayUrl, metquaySecretKey, activeInDays){
      var assetOwnerList = [] 
      var filteredAoList = []
      await getAssetOwnersList(jwtToken, metquayUrl, metquaySecretKey, activeInDays)
      .then(result => { 
        assetOwnerList = result.data.resultData
        return result.data.resultData
      })
      .catch(error => {
        toast.error("Failed to Load Data")
        // console.log(error)
      }) 
      filteredAoList =  assetOwnerList.map(obj => {
        obj.email = obj.email.split(',')[0].trim();
        return obj;
      });
      return filteredAoList
    }
    async function removeDuplicatesAndReturnFinalList(smallerArray, biggerArray) {
      const emailIds = new Set();
      smallerArray.forEach(item => emailIds.add(item.attributes.email));
      const filteredArray = await biggerArray.filter(item => !emailIds.has(item.email));
      return filteredArray.filter((item, index) => {
        return !filteredArray.slice(index + 1).find(x => x.email === item.email);
      });
    }
    useEffect(async ()=>{
      let pk, sk, metquayUrl, metquaySecretKey
      pk = decryptKeyValues(user[user_metadata].pk)
      const jwtToken = await getAccessToken(apiurl, "add:asset")
      setDays(user[user_metadata].activeInDays)
      await getServiceProviderById(jwtToken, null, pk)
        .then(result => {
          metquaySecretKey = (result[0].attributes.metquaySecretKey)
          metquayUrl = (result[0].attributes.metquayurl)
          return result
        })
        .catch(error => {
          // console.log(error)
        })
  
      const assetOwnersFromDB = await getAssetOwnerListfromDB(jwtToken , pk)
      const assetOwnersFromMetquay = await getAssetOwnerListfromMetquay(jwtToken , metquayUrl , metquaySecretKey , user[user_metadata].activeInDays)
      const finalArray = await removeDuplicatesAndReturnFinalList(assetOwnersFromDB,assetOwnersFromMetquay )
      setAssetOwnerList(finalArray)
      setLoading(false)
      },[])
  return (
    <>
        <div className="page-content">
          <Modal isOpen={modal} toggle={toggle} >
            <ModalHeader toggle={toggle}>Confirm invitations?</ModalHeader>
            <ModalBody>
              Are you sure you want to invite {selected.length} Asset Owners to Tracefii ?
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={() => modalConfirmation()}>Yes, Invite</Button>{' '}
              <Button color="secondary" onClick={toggle}>Cancel</Button>
            </ModalFooter>
          </Modal>
          <div className="container-fluid">
            <Row>
              <Col className="col-12">
                <Card> 
                  <CardBody>
                    <CardTitle className="h4">Asset Owners</CardTitle>
                    <p className="card-title-desc text-md">
                      Invite your asset owners in just one click...!
                    </p>
                    {
                      loading ? (
                        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' ,marginTop:"20px" }}>          
                          <ScaleLoader color="#36d7b7" />
                        </div>
                      ) : 
                      (
                        <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="email"
                      columns={columns}
                      data={assetOwnerList}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="email"
                          columns={columns}
                          data={assetOwnerList}
                          search
                        >
                          {toolkitProps => (
                            <>
                              <Row className="mb-2">
                                <Col md="12">         
                                <Row>
                                <div className="d-flex justify-content-between">
                                  <div className="search-box me-2 mb-2">
                                    <div className="position-relative d-flex gap-4 ">
                                      <div className="justify-content-between">
                                        <SearchBar
                                          {...toolkitProps.searchProps}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                      </div>
                                      </div>
                                        <div>
                                        <Button
                                          color="primary"
                                          className="btn btn-primary mx-1"
                                          onClick={handleBulkInvite}
                                        >
                                          Quick Invite
                                        </Button>                                     
                                        </div>
                                  </div>
                                  </Row>  
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                      <div className="table-responsive">
                                        <BootstrapTable
                                        keyField={"id"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        selectRow={selectRow}
                                        classes={
                                          "table align-middle table-nowrap user-select-none"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                  </div>                 
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              </>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                      )
                    }
                    
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
        <Toaster 
         position="bottom-right"
         reverseOrder={false} />
    </>
  )
}

export default BulkInviteNew