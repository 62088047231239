import http from "./httpService"

/**
 * getProductDetails method is used to fetch all the products from stripe
 * @param {string} jwtToken
 */
export async function getProductDetails(jwtToken) {
  http.setJwt(jwtToken)
  const productDetails = await http.get(`/payment/products`)
  return productDetails
}

export async function createCheckoutSession(
  jwtToken,
  productId,
  noOfUsersCount,
  assetOwnerEmail
) {
  // console.log("noOfUsersCount = ", noOfUsersCount)
  http.setJwt(jwtToken)
  const productDetails = await http.post(`/payment/checkout`, {
    productId: productId,
    noOfUsersCount: noOfUsersCount,
    emailAddress: assetOwnerEmail,
  })
  return productDetails
}

export async function getSessionDetails(jwtToken, sessionId) {
  http.setJwt(jwtToken)
  const subscriptionDetails = await http.get(`/payment/checkout-session`, {
    params: { sessionId: sessionId },
  })
  return subscriptionDetails
}

export async function getSubscriptionDetails(jwtToken, subscriptionId) {
  http.setJwt(jwtToken)
  const subscriptionDetails = await http.get(
    `/payment/retrieve-subscriptions`,
    { params: { subscriptionId: subscriptionId } }
  )
  return subscriptionDetails
}

export async function getProductDetailById(jwtToken, productId) {
  http.setJwt(jwtToken)
  const productDetails = await http.get(`/payment/product-byId`, {
    params: { productId: productId },
  })
  return productDetails
}

export async function getPriceDetails(jwtToken) {
  http.setJwt(jwtToken)
  const priceDetails = await http.get(`/payment/price`)
  return priceDetails
}

export async function updateSamePlanSubscriptionDetails(
  jwtToken,
  subscriptionId,
  quantity,
  priceId
) {
  http.setJwt(jwtToken)
  const subscriptionDetails = await http.get(`/payment/update-subscription`, {
    params: {
      subscriptionId: subscriptionId,
      quantity: quantity,
      priceId: priceId,
    },
  })
  return subscriptionDetails
}

export async function upgradePlan(jwtToken, subscriptionId, priceId, quantity) {
  http.setJwt(jwtToken)
  const subscriptionDetails = await http.post(`/payment/upgrade-plan`, {
    subscriptionId: subscriptionId,
    quantity: quantity,
    priceId: priceId,
  })
  return subscriptionDetails
}

/**
 * to get next billing amount
 * @param {string} jwtToken
 * @param {string} customerId
 * @returns
 */
export async function getNextBillingAmount(jwtToken, customerId) {
  http.setJwt(jwtToken)
  const nextInvoiceDetails = await http.get(`/payment/get-next-invoice`, {
    params: { customerId: customerId },
  })
  return nextInvoiceDetails
}

export async function retrieveCheckoutSession(jwtToken, sessionId) {
  http.setJwt(jwtToken)
  const nextInvoiceDetails = await http.get(`/payment/retrieve-session`, {
    params: { sessionId: sessionId },
  })
  return nextInvoiceDetails
}

export async function createNewSessionForDeclinedCardFailuers(
  jwtToken,
  subscriptionId,
  customerId
) {
  http.setJwt(jwtToken)
  const subscriptionDetails = await http.post(`/payment/checkout-session`, {
    subscriptionId: subscriptionId,
    customerId: customerId,
  })
  return subscriptionDetails
}

export async function retrieveSetupIntent(jwtToken, setupIntentId) {
  http.setJwt(jwtToken)
  const nextInvoiceDetails = await http.get(`/payment/retrieve-setup-intent`, {
    params: { setupIntentId: setupIntentId },
  })
  return nextInvoiceDetails
}

/**
 * updatePaymentInformationInSubscription is used to update payment card information in subscription
 * @param {*} jwtToken
 * @param {*} productId
 * @param {*} noOfUsersCount
 * @returns
 */
export async function updatePaymentInformationInSubscription(
  jwtToken,
  subscriptionId,
  paymentMethod
) {
  http.setJwt(jwtToken)
  const subscriptionDetails = await http.get(
    `/payment/update-subscription-after-failure`,
    { paymentMethod: paymentMethod, subscriptionId: subscriptionId }
  )
  return subscriptionDetails
}

export async function updatePaymentInformationInCustomer(
  jwtToken,
  customerId,
  paymentMethod
) {
  http.setJwt(jwtToken)
  const subscriptionDetails = await http.get(
    `/payment/update-customer-after-failure`,
    { paymentMethod: paymentMethod, customerId: customerId }
  )
  return subscriptionDetails
}

export async function cancelSubscription(jwtToken, subscriptionId) {
  http.setJwt(jwtToken)
  const deletedSubscription = await http.get(`/payment/cancel-subscription`, {
    params: { subscriptionId: subscriptionId },
  })
  return deletedSubscription
}

export async function sendMailAfterSuccessfulPayment(
  jwtToken,
  customerMailId,
  assetOwnerName,
  planName,
  paymentAmount,
  isCancel
) {
  http.setJwt(jwtToken)
  const subscriptionDetails = await http.post(`/payment/send-receipt-mail`, {
    customerMailId: customerMailId,
    assetOwnerName: assetOwnerName,
    planName: planName,
    paymentAmount: paymentAmount,
    isCancel: isCancel,
  })
  return subscriptionDetails
}

/**
 * to retrieve a customer by id
 * @param {string} jwtToken
 * @param {string} setupIntentId
 * @returns
 */
export async function retrieveCustomerCards(jwtToken, customerId) {
  http.setJwt(jwtToken)
  const nextInvoiceDetails = await http.get(
    `/payment/list-cards-for-customers`,
    { params: { customerId: customerId } }
  )
  return nextInvoiceDetails
}

export async function openCustomerPortal(jwtToken, customerId) {
  http.setJwt(jwtToken)
  const openCustomerPortal = await http.get(`/payment/open-customer-portal`, {
    params: { customerId: customerId },
  })
  return openCustomerPortal
}

export async function getPriceDetailsById(jwtToken, priceId) {
  http.setJwt(jwtToken)
  const priceDetails = await http.get(`/payment/priceDetails-byId`, {
    params: { priceId: priceId },
  })
  return priceDetails
}

export async function addFeaturesToProduct(
  jwtToken,
  productId,
  productName,
  featuresList
) {
  http.setJwt(jwtToken)
  return await http.post(`/payment/add-features-to-product`, {
    productId: productId,
    productName: productName,
    featuresList: featuresList,
  })
}

export async function getFeaturesForProduct(jwtToken, productId, productName) {
  http.setJwt(jwtToken)
  return await http.get(`/payment/get-product-features`, {
    params: { productId: productId, productName: productName },
  })
}

export async function getCustomersFromStripe(jwtToken, startedAfter, limit){
  http.setJwt(jwtToken)
  return await http.get(`/payment/get-customers-from-stripe`, {
    params: { startedAfter: startedAfter, limit: 20 },
  })
}
