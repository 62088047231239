import React from "react"
import ReactDOM from "react-dom"
import { ToastProvider } from "react-toast-notifications"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { Provider } from "react-redux"
import { Auth0Provider } from "@auth0/auth0-react"
import history from "./history"

import store from "./store"

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,

  // Alternatively, use `process.env.npm_package_version` for a dynamic release version
  // if your build tool supports it.
  release: "tracefii-assetmanagement-app@dev",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

});

const onRedirectCallback = appState => {
  history.replace(appState?.returnTo || window.location.pathname)
}


const app = (
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENTID}
    audience={process.env.REACT_APP_AUTH0_API_URL}
    redirectUri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
    // cacheLocation={"localstorage"}
  >
    <ToastProvider
      autoDismiss
      autoDismissTimeout={6000}
      placement="bottom-right"
    >
      <Provider store={store}>
        <App />
      </Provider>
    </ToastProvider>
  </Auth0Provider>
)

ReactDOM.render(app, document.getElementById("root"))
serviceWorker.unregister()
