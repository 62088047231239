import React  from "react";
import { Card, CardBody, Badge } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search, CSVExport, } from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

export const disableRowFormatter = (cell, row) => {
  return (
    row.required === true ?
      <Badge className={"rounded-pill  font-size-12 badge-soft-success"} rounded-pill >
        Yes
      </Badge> : 
      <Badge className={"rounded-pill  font-size-12 badge-soft-warning"} rounded-pill >
        No
      </Badge> 
  )
}

export const TableForCustomFields = (props) => {
  const {
    customColumns,
    customFields,
    multipleRows,
  } = props;


  /**
   * handleCheckboxClick will be called when the check box is clicked
   * @param {event} e 
   * @param {number} rowIndex 
   * @param {boolean} checked 
   */
  const handleCheckboxClick = (e, rowIndex, checked) => {
    let rows = [];
    customFields[rowIndex].isSelected = checked;
    rows.push(customFields[rowIndex]);
    multipleRows(rows);
  };

  // const setRowStyle  = {
  //     return  {  textDecorationLine:  'line-through' }
  // }

  /**
   *selectRow method will be called when a row is selected 
   */
  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    onSelect: (row, isSelect, rowIndex, e) => {
      let rows = [];
      row.isSelected = isSelect;
      rows.push(row);
      multipleRows(rows);
    },
    onSelectAll: (isSelect, rows, e) => {
      rows.map((row) => {
        row.isSelected = isSelect;
      });
      multipleRows(rows);
    },
    selectionRenderer: ({ checked, disabled, rowIndex }) => {
      return (
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            checked={checked}
            disabled={disabled}
            onChange={() => { }}
          />
          <label
            className="custom-control-label"
            onClick={(e) => {
              e.preventDefault();
              handleCheckboxClick(e, rowIndex, checked);
            }}
          ></label>
        </div>
      );
    },
    selectionHeaderRenderer: ({ indeterminate, ...rest }) => {
      return (
        <div className="custom-control custom-checkbox select-header">
          <input
            type="checkbox"
            className="custom-control-input"
            id="customCheck1"
            ref={(input) => {
              if (input) input.indeterminate = indeterminate;
            }}
            {...rest}
            onChange={() => { }}
          />
          <label
            className="custom-control-label"
            htmlFor="customCheck1"
            onClick={(e) => {
              e.preventDefault();
            }}
          ></label>
        </div>
      );
    },
  };

  const paginationOptions = {
    sizePerPage: 10,
  };

  const rowStyle = (row, rowIndex) => {
    const rowFormat = row.isDisabled === true ? 'line-through' : ''
    return ( {  textDecorationLine:  rowFormat } );
  };

  return (
    <Card>
      <CardBody>
        <ToolkitProvider
          bootstrap4
          keyField="dataField"
          data={customFields}
          columns={customColumns}
          search
        >
          {(props) => (
            <React.Fragment>

              <BootstrapTable
                {...props.baseProps}
                keyField="dataField"
                rowStyle={rowStyle }
                bordered={false}
                selectRow={selectRow}
                pagination={paginationFactory(paginationOptions)}
                wrapperClasses="table-responsive"
              />
            </React.Fragment>
          )}
        </ToolkitProvider>
      </CardBody>
    </Card>
  );
};

