import { useEffect, useState } from "react"
import { Button } from "reactstrap"
import { useToasts } from "react-toast-notifications"
import { useAuth0 } from "@auth0/auth0-react"

//Components
import Spinner from "components/Common/Spinner"
import { AddFeatureModal } from "./AddFeatureModal"
//service files
import { getFeaturesForProduct } from "services/paymentService"
import { addFeaturesToProduct } from "services/paymentService"

export const ProductFeatures = ({ productId, productName }) => {
  const apiurl = process.env.REACT_APP_AUTH0_API_URL
  const { getAccessTokenSilently, getAccessTokenWithPopup, user } = useAuth0()
  const { addToast } = useToasts()
  const [modal, setModal] = useState(false)
  const [addedFeature, setAddedFeature] = useState([])
  const [loading, setLoading] = useState(false)

  /**
   * getAccessToken method is used to fecth jwt access token from auth0
   * @param {string} audience - the api URL
   * @param {string} claim - the permission
   */
  const getAccessToken = async (audience, claim) => {
    let token
    try {
      token = await getAccessTokenSilently({
        audience,
        claim,
      })
    } catch (exception) {
      token = await getAccessTokenWithPopup({ audience, claim })
    }
    return token
  }

  /**
   * To toggle modal to add features
   */
  const toggle = () => {
    setModal(!modal)
  }

  /**
   * updateFeatures function is used to add the feature list to the product
   * @param {list} featureList
   */
  const updateFeatures = async featureList => {
    setLoading(true)
    const jwtToken = await getAccessToken(apiurl, "edit:assets")
    await addFeaturesToProduct(jwtToken, productId, productName, featureList)
      .then(result => {
        setAddedFeature(featureList)
        addToast("Features updated", {
          appearance: "success",
          autoDismiss: true,
        })
      })
      .catch(error => {
        addToast("Couldn't update features", {
          appearance: "error",
          autoDismiss: true,
        })
      })
    setLoading(false)
  }

  const getInitialData = async () => {
    const jwtToken = await getAccessToken(apiurl, "edit:assets")
    await getFeaturesForProduct(jwtToken, productId, productName)
      .then(result => {
        console.log(result)
        setAddedFeature(result.data.attributes.availableFeatures)
      })
      .catch(error => {})
  }

  useEffect(async () => {
    getInitialData()
  }, [])

  return (
    <>
      <div className="text-end">
        <Button
          color="primary"
          className="btn btn-primary mt-2"
          onClick={toggle}
        >
          Add New Features
        </Button>
        <AddFeatureModal
          open={modal}
          toggle={toggle}
          header={"Add Features"}
          productId={productId}
          productName={productName}
          updateFeatures={updateFeatures}
        />
      </div>
      <div>
        <h5>Features Available</h5>
        {loading === false ? (
          addedFeature.map((feature, index) => {
            return <p>{feature}</p>
          })
        ) : (
          <Spinner />
        )}
      </div>
    </>
  )
}
