import http from "./httpService";

/**
 * sendSlackInvitaionMessage method is used to send details of the user who entered for the first time.
 * @param {string} jwtToken - token from user
 * @param {string} email - email of the user
 */
export async function sendSlackInvitaionMessage(jwtToken , email) {
    http.setJwt(jwtToken)
    const message = await http.post(`/dashboard/sendInvitationInSlack` , {
        email : email
    })
    return message;
  }
/**
 * sendSupportMessage method is used to send the support message to Slack and Email.
 * @param {Object} supportObj - Object which contains all the info about the Support message
 */
export async function sendSupportMessage(supportObj) {
  const result = await http.post(`/dashboard/customer-support` , {supportObj})
  return result;
}
