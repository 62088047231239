import React, { useEffect, useState } from "react"
import { Row, Col } from "reactstrap"
import { useAuth0 } from "@auth0/auth0-react"
import axios from "axios"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//Service
import {
  getProductDetails,
  getPriceDetails,getSubscriptionDetails,getNextBillingAmount,cancelSubscription
} from "../../services/paymentService"
import { getAssetOwner } from "../../services/assetownerService"
import { convertDateTimeString, decryptKeyValues } from "../../services/commonService"
import { getServiceProviderById } from "../../services/serviceProvider"


import { user_metadata } from "../../nameSpaceConfig"

//Import Pricing Cards
import CardPricing from "./PaymentComponents/card-pricing"

const Payment = props => {
  const apiurl = process.env.REACT_APP_AUTH0_API_URL
  const { getAccessTokenSilently, getAccessTokenWithPopup, user } = useAuth0()
  const userMetadata = user[user_metadata]
  const [productsListSize, setProductsListSize] = useState(0)
  const [productsList, setProductsList] = useState([])
  const [productPrice, setProductPrice] = useState([])
  const [subscriptionId, setSubscriptionId] = useState("")
  const [subscribedProductId, setSubscribedProductId] = useState("")
  const [usersPurchased, setUsersPurchased] = useState(0)
  const [nextInvoiceDate, setNextInvoiceDate] = useState("")
  const [billingAmount, setBillingAmount] = useState("")
  const [assetOwnerName, setAssetOwnerName] = useState("")
  const [assetOwnerEmail, setAssetOwnerEmail] = useState("")
  const [enableOnlineTutorials, setEnableOnlineTutorial] = useState(false)
  const [loading, setLoading] = useState(false)
  const [activeCustomerId, setActiveCustomerId] = useState("")

  let productPriceObject = {}

  /**
   * getAccessToken method is used to fecth jwt access token from auth0
   * @param {string} audience - the api URL
   * @param {string} claim - the permission
   */
  const getAccessToken = async (audience, claim) => {
    let token
    try {
      token = await getAccessTokenSilently({
        audience,
        claim,
      })
    } catch (exception) {
      token = await getAccessTokenWithPopup({ audience, claim })
    }
    return token
  }

  const onToggleUserGuide = rows => {
    setEnableOnlineTutorial(!enableOnlineTutorials)
  }
  
  useEffect(() => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    const getInitialData = async () => {
      const jwtToken = await getAccessToken(apiurl, "edit:assets")
      let subscriptionId = ""
      let customerId = ""
      let primaryKeyValue = null
      let sortKeyValue = null
      if (
        userMetadata.role.startsWith("Service Provider") ||
        userMetadata.role.startsWith("Asset Owner")
      ) {
        primaryKeyValue = decryptKeyValues(userMetadata.pk)
        sortKeyValue = decryptKeyValues(userMetadata.sk)
      }

      if (userMetadata.role.startsWith("Service Provider") ) {
        await getServiceProviderById(jwtToken, "", primaryKeyValue).then(result => {
          subscriptionId = result[0].attributes.subscriptionId
          setSubscriptionId(subscriptionId)
          customerId = result[0].attributes.customerId
          setAssetOwnerEmail(result[0].attributes.email)
          setAssetOwnerName(result[0].attributes.name)
        })
      }else {
        await getAssetOwner(jwtToken, "", primaryKeyValue).then(result => {
          subscriptionId = result[0].attributes.subscriptionId
          setSubscriptionId(subscriptionId)
          customerId = result[0].attributes.customerId
          setAssetOwnerEmail(result[0].attributes.email)
          setAssetOwnerName(result[0].attributes.name)
        })
      }
      

      
      

      if(subscriptionId !== undefined && subscriptionId !== null && subscriptionId !== "" ){
        await getNextBillingAmount(jwtToken, customerId).then(result => {
          setBillingAmount(result.data.amount_remaining)
        }).catch(error =>{
          
        })
        await getSubscriptionDetails(jwtToken, subscriptionId).then(result => {
          const timestamp = result.data.current_period_end
          const timestampInMilliseconds = timestamp * 1000
          setNextInvoiceDate(convertDateTimeString(new Date(timestampInMilliseconds)))
          setSubscribedProductId(result.data.plan.product)
          setUsersPurchased(result.data.quantity)
        })
      }
      
      await getPriceDetails(jwtToken).then(result => {
        setProductPrice(result.data.data)
      })
      let productListArray = []
      await getProductDetails(jwtToken).then(result => {
        setProductsListSize(result.data.data.length)
        result.data.data.map(product =>{
          if(product.active === true){
            if( userMetadata.role.startsWith("Asset Owner") && product.name.startsWith('Asset')){
              productListArray.push(product)
            }else if( userMetadata.role.startsWith("Service Provider") && product.name.startsWith('Service')){
              productListArray.push(product)
            }
          }
        })
      })
      setProductsList(productListArray)
    }
    getInitialData()
    return () => {
      source.cancel()
    }
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Assets Management" breadcrumbItem="Payment" />
          <Row className="justify-content-center">
            <Col lg={6}>
              <div className="text-center mb-5">
                <h4>Choose your Pricing plan</h4>
              </div>
            </Col>
          </Row>

          <Row>
            {productsList.map((product, key) => (
              <CardPricing
              product={product}
                productPrice={productPrice}
                key={"_pricing_" + key}
                subscriptionId = {subscriptionId}
                subscribedProductId={subscribedProductId}
                usersPurchased = {usersPurchased}
                nextInvoiceDate={nextInvoiceDate}
                billingAmount={billingAmount}
                assetOwnerName={assetOwnerName}
                assetOwnerEmail={assetOwnerEmail}
              />
            ))}
          </Row>
          
        </div>
      </div>
    </React.Fragment>
  )
}

export default Payment
