import React, { useEffect } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react"



const Logout = props => {
  const { logout } = useAuth0()
  useEffect(() => {
    logout()
  })

  return <React.Fragment></React.Fragment>
}


export default withRouter(connect(null, {  })(Logout))
