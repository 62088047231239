import React, { useState } from "react"
import { Row, Col, Card, CardBody, Button, Input, Badge } from "reactstrap"
import BootstrapTable from "react-bootstrap-table-next"

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css"

import {
  metquayWorksFieldForServiceProvider,metquayWorksExpandableFieldForServiceProvider,
} from "../data/works-field"
import "../css/modal-style.css"
import ToolkitProvider from "react-bootstrap-table2-toolkit"

export const WorkTable = props => {
  const {
    records,
    multipleRows,
    getNextAssetData,
    getPreviousAssetData,
    firstKey,
    lastEvaluatedKey,
    onFilter,
    onSearchKeyChange,
    isGood,
    isWarning,
    isDanger,
    count,
    searchKey,
    firstPage,
    lastPage,
    currentPage,
    totalResults
  } = props
  const [goodToggle, setGoodToggle] = useState(isGood)
  const [warningToggle, setWarningToggle] = useState(isWarning)
  const [dangerToggle, setDangerToggle] = useState(isDanger)

  /**
   * assignToggleValues is a common method used to toggle and untoggle(previously selected) buttons based on selected condition
   * @param {boolean} toggleValueForGood
   * @param {boolean} toggleValueForWarning
   * @param {boolean} toggleValueForDanger
   */
  const assignToggleValues = (
    toggleValueForGood,
    toggleValueForWarning,
    toggleValueForDanger
  ) => {
    setGoodToggle(toggleValueForGood)
    setWarningToggle(toggleValueForWarning)
    setDangerToggle(toggleValueForDanger)
  }



  /**
   * expandRow function is provide inner row details of the table
   */
  const expandRow = {
    renderer: row => (
      <div>
        <BootstrapTable
          bootstrap4
          {...props.baseProps}
          keyField="id"
          bordered={false}
          data={[row]}
          columns={metquayWorksExpandableFieldForServiceProvider}
          wrapperClasses="table-responsive"
        ></BootstrapTable>
      </div>
    ),
    showExpandColumn: true,
    onlyOneExpanding: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      return isAnyExpands ? (
        <i className="dripicons-minus" />
      ) : (
        <i className="dripicons-plus" />
      )
    },
    expandColumnRenderer: ({ expanded }) => {
      return expanded ? (
        <i className="dripicons-minus" />
      ) : (
        <i className="dripicons-plus" />
      )
    },
  }




  return (
    <Card>
      <CardBody>
        <ToolkitProvider
          bootstrap4
          keyField="id"
          data={records}
          search
          exportCSV={{ onlyExportFiltered: true, exportAll: false }}
        >
          {props => (
            <React.Fragment>
              <Row>
                <div className="col-md-12 text-end">
                  <Row>
                    

                    <Col className="text-end">
                   
                     
                      <div
                        style={{ flex: 1,  float: "right" }}
                      >
                        <Input
                          placeholder="Search"
                          type="search"
                          className="mb-2 me-2 nm-3 col-lg-4"
                          defaultValue={searchKey}
                          onChange={({ target: { value } }) =>
                            onSearchKeyChange(value)
                          }
                        />
                      </div>
                      
                      
                    </Col>
                   
                  </Row>
                </div>
              </Row>

              <BootstrapTable
                bootstrap4
                {...props.baseProps}
                keyField="id"
                bordered={false}
                // selectRow={selectRow}
                data={records}
                columns={metquayWorksFieldForServiceProvider}
                expandRow={expandRow}
                wrapperClasses="table-responsive"
              />
              <div className="col-md-12">
                <Row>
                <Col className="text-end">
                <Button
                  className="mb-2 me-2"
                  color="primary"
                  onClick={getPreviousAssetData}
                  disabled={totalResults < 50 || firstPage<=50}
                >
                  Prev
                </Button>

                <Button
                  className="mb-2 me-2"
                  color="primary"
                  onClick={getNextAssetData}
                  disabled={totalResults < 50 || currentPage===lastPage}
                >
                  Next
                </Button>
                </Col>
                </Row>
              </div>
              <div className="col-md-12">
              {records.length} record(s) per page
              </div>
            </React.Fragment>
          )}
        </ToolkitProvider>
      </CardBody>

    </Card>
  )
}
