import http from "./httpService"
import { getAssetsCount } from './assetService'
import { getAuthUserDetailsUsingEmailId } from "./userService"
import { checkLoggedInDays, convertDateTimeString } from "./commonService"

/**
 * getAssetOwner method is used to fetch all asset owner data
 * @param {string} jwtToken
 * @param {string} cancelToken
 */
export async function getAssetOwner(jwtToken, cancelToken, pk) {
  http.setJwt(jwtToken)
  const assetOwner = await http.get(`/assetOwner`, {
    params: { pk: pk }
  })
  const data = assetOwner.data.map(item => (item = clean(item)))
  return data
}

/**
 * getAssetOwnerById method is used to fetch all asset owner data
 * @param {string} jwtToken
 * @param {string} cancelToken
 * @param {string} pk
 */
export async function getAssetOwnerById(jwtToken, cancelToken, pk) {
  http.setJwt(jwtToken)
  const assetOwner = await http.get(`/assetOwner/assetOwnerById`, {
    params: { pk: pk }
  })
  const data = assetOwner.data.map(item => (item = clean(item)))
  return data
}

/**
 * getAssetOwnerById method is used to fetch all asset owner data
 * @param {string} jwtToken
 * @param {string} cancelToken
 * @param {string} pk
 */
export async function getAssetsPerAssetOwner(jwtToken, cancelToken, pk) {
  http.setJwt(jwtToken)
  const assetOwner = await http.get(`/assets/perAssetOwner`, { params: { pk: pk } })
  return assetOwner.data
}

/**
 * getAssetOwnerNames function is used to fetch all asset owner names.
 * @param {*} jwtToken - token from user
 */
export async function getAssetOwnerNames(jwtToken) {
  http.setJwt(jwtToken)
  const assetOwner = await http.get(`/assetOwner/assetOwnerName`)
  return assetOwner.data
}

/**
 * getServiceProviderNamesById function is used to fetch all service provider names based on id.
 * @param {string} jwtToken - token from user
 * @param {string} pk - pk hash key
 */
export async function getAssetOwnerNamesById(jwtToken, pk) {
  http.setJwt(jwtToken)
  const headers = {
    pk: pk,
  }
  const assetOwner = await http.get(`/assetOwner/assetOwnerNameById`, {
    params: { pk: pk }
  })
  return assetOwner
}

export async function createNewUser(token, data) {
  http.setJwt(token)
  const assetOwner = await http.post(`/createUser`, data)
  return assetOwner
}

/**
 * updateAssetOwnerData method is used to add / update/ delete asset data to db.
 * @param {object} data - data to be added/ updated/ deleted.
 * @param {string} action - action to be performed add/ update/ delete
 * @param {string} jwtToken - jwtToken from auth0
 */
export async function updateAssetOwnerData(data, oldData, jwtToken) {
  const newData = clean(data)
  http.setJwt(jwtToken)
  delete newData.isSelected
  const dataToBeUpdated = {}
  dataToBeUpdated.newData = newData
  dataToBeUpdated.oldData = oldData
  const assetOwner = await http.put(`/assetOwner`, dataToBeUpdated)
  return assetOwner
}

export async function addAssetOwnerData(data, action, token) {
  const newData = clean(data)
  http.setJwt(token)
  if (action === "Add") {
    const assetOwner = await http.post(`/assetOwner`, newData)
    return assetOwner
  } else if (action === "Edit") {
    const assetOwner = await http.put(`/assetOwner`, newData)
    return assetOwner
  } else if (action === "Delete") {
    const headers = {
      id: newData.id,
      pk: newData.pk,
    }
    const assetOwner = await http.delete(
      `/assetOwner/${newData.id}`,
      { data },
      {
        headers,
        data,
      }
    )

    return assetOwner
  }
}

export async function addAssetOwnerCsvData(data, userData, jwtToken) {
  http.setJwt(jwtToken)
  const assetOwner = await http.post(`/assetOwner/importData`, {
    insertDetails: data,
    userDetails: userData,
  })
  return assetOwner
}

/**
 * getAssetsCount method is used to count the total number of assets.
 * @param {string} cancelToken - token generated from axios.
 */
export async function getAssetOwnersCount(jwtToken, primaryKey) {
  http.setJwt(jwtToken)
  const assetsCount = await http.get(`/assetOwner/count`, { params: { primaryKey: primaryKey } })
  return assetsCount
}

/**
 * updateSubscriptionDetailsInAssetOwner method is used to add / update/ delete asset data to db.
 * @param {object} data - data to be added/ updated/ deleted.
 * @param {string} action - action to be performed add/ update/ delete
 * @param {string} jwtToken - jwtToken from auth0
 */
export async function updateSubscriptionDetailsInAssetOwner(jwtToken, assetOwnerId, assetOwnerSk, subscriptionId, customerId, quantity) {
  http.setJwt(jwtToken)
  const dataToBeUpdated = {}
  dataToBeUpdated.assetOwnerId = assetOwnerId
  dataToBeUpdated.assetOwnerSk = assetOwnerSk
  dataToBeUpdated.subscriptionId = subscriptionId
  dataToBeUpdated.customerId = customerId
  dataToBeUpdated.quantity = quantity
  const assetOwner = await http.put(`/assetOwner/update-subscription-details`, dataToBeUpdated)
  return assetOwner
}

export async function updateAssetOwnerDataInS3Folder(jwtToken, assetOwnerData) {
  http.setJwt(jwtToken)
  const assetOwner = await http.post(`/assetOwner/update-assetOwmer-details-inS3folder`, {
    assetOwnerData: assetOwnerData,
  })
  return assetOwner
}

/**
 * getAssetOwnerDetailsForAdmin function is used to get all asset owner details in th application
 * @param {string} jwtToken 
 * @returns 
 */
export async function getAssetOwnerDetailsForAdmin(jwtToken) {
  http.setJwt(jwtToken);
  const users = await http.get(`/users/getAuthUserDetailsUsingEmailId`, {
    params: { emailId: 'hello@tracefii.com' }
  })
  let auth0UserList = users.data

  let assetOwnerTableDetails = []
  const assetOwnerData = await http.get(`/assetOwner`)
  for (let i = 0; i < assetOwnerData.data.length; i++) {
    let details = {}
    const assetOwnerValues = assetOwnerData.data[i].attributes
    details.assetOwnerName = assetOwnerValues.name;
    details.email = assetOwnerValues.email;
    if (assetOwnerValues.metquayurl === undefined || assetOwnerValues.metquayurl === null || assetOwnerValues.metquayurl === ' ') {
      details.assetOwnerType = "Signed-up Asset Owner"
    } else {
      details.assetOwnerType = "Metquay Integrated"
    }
    if (assetOwnerValues.activeSubscriptionId === undefined || assetOwnerValues.activeSubscriptionId === null || assetOwnerValues.activeSubscriptionId === ' ') {
      details.subscriptionStatus = "Trial"
    } else {
      details.subscriptionStatus = "Active"
    }
    if (assetOwnerValues.freeTrialDays !== undefined && assetOwnerValues.freeTrialDays !== null) {
      details.noOfTrialDaysLeft = assetOwnerValues.freeTrialDays;
    } else {
      details.noOfTrialDaysLeft = 0
    }

    await getAssetsCount(jwtToken, assetOwnerData.data[i].pk).then(result => {
      if (result.data.attributes === undefined || result.data.attributes === null) {
        details.noOfAssetsOwned = 0;
        details.noOfUsers = 0
      } else {
        details.noOfAssetsOwned = result.data.attributes.ALLASSETSCOUNT;
        details.noOfUsers = result.data.attributes.ALLUSERSCOUNT
      }

    })
    for (let j = 0; j < auth0UserList.length; j++) {
      if (auth0UserList[j].email === assetOwnerValues.email) {

        // details.lastLoggedInDate = convertDateTimeString(new Date(auth0UserList[i].last_login))
        details.lastLoggedInDate = new Date(auth0UserList[i].last_login).toLocaleString()
        break;
      }
    }
    assetOwnerTableDetails.push(details)
  }
  // assetOwnerData.data.map(async (assetOwnerDetails) => {

  // })
  return assetOwnerTableDetails
}

export async function updatePreferencesInAssetOwner( jwtToken, assetOwnerId, assetOwnerSk, preferences) {
  http.setJwt(jwtToken)

  const dataToBeUpdated = {}
  dataToBeUpdated.assetOwnerId = assetOwnerId
  dataToBeUpdated.assetOwnerSk = assetOwnerSk
  dataToBeUpdated.preferences = preferences
  const assetOwner = await http.put(`/assetOwner/update-preferences`,dataToBeUpdated)
  return assetOwner
}

export async function updateInvitationInAssetOwner( jwtToken, pk , sk) {
  http.setJwt(jwtToken)
  const data = {
    pk: pk,
    sk: sk
  }
  const assetOwner = await http.put(`/assetOwner/update-ao-status`,data)
  return assetOwner
}
export async function updateAssetsCountInDynamoDb(jwtToken, pk) {
  http.setJwt(jwtToken)
  const data = {
    pk: pk
  }
  const assetOwner = await http.put(`/assetOwner/update-assetsallowed-count`,data)
  return assetOwner
}


export async function finalQuoteUpdate(jwtToken, inquiryId , sk  , status){
  http.setJwt(jwtToken)
  await http.put(`assets/update-final-quote` , {
    inquiryId : inquiryId,
    sk : sk,
    status : status
  })
}


function clean(obj) {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === ""
    ) {
      delete obj[propName]
    }
  }
  return obj
}
