const emailLogsTableData = () => [
    {
      dataField: "data.event",
      text: "Event",
      sort: true,    
    },
    {
      dataField: "sk",
      text: "Timestamp",
      sort: true,
    },
    {
      dataField: "data.sg_message_id",
      text: "Message Id",
      sort: true,
    }
  ]
  
  export default emailLogsTableData;
  