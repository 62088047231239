import http from "./httpService"
import { getAssetsCount } from './assetService'
import { checkLoggedInDays, convertDateTimeString } from "./commonService"

/**
 * getServiceProvider function is used to fetch all service providers from db.
 * @param {string} cancelToken - token generated from axios.
 */
export async function getServiceProvider(jwtToken, cancelToken) {
  http.setJwt(jwtToken)
  const serviceProvider = await http.get(`/serviceProvider`)
  const data = serviceProvider.data.map(item => (item = clean(item)))
  return data
}

/**
 * getServiceProviderById function is used to fetch all service providers from db based on id.
 * @param {*} jwtToken - token from user
 * @param {*} cancelToken - token generated from axios.
 * @param {*} pk - hash key
 */
export async function getServiceProviderById(jwtToken, cancelToken, pk) {
  http.setJwt(jwtToken)
  const serviceProvider = await http.get(
    `/serviceProvider/serviceProviderById`,
    {
      params: { pk: pk }
    }
  )
  const data = serviceProvider.data.map(item => (item = clean(item)))
  return data
}

/**
 * getServiceProviderNames function is used to fetch all service provider names.
 * @param {*} jwtToken - token from user
 * @param {*} cancelToken - token generated from axios.
 */
export async function getServiceProviderNames(jwtToken) {
  http.setJwt(jwtToken)
  const serviceProviders = await http.get(
    `/serviceProvider/serviceProviderName`
  )
  //const data = serviceProviders.data.map((item) => (item = clean(item)));
  return serviceProviders.data
}

/**
 * getServiceProviderNamesById function is used to fetch all service provider names based on id.
 */
export async function getServiceProviderNamesById(jwtToken, pk) {
  http.setJwt(jwtToken)
  const serviceProviders = await http.get(
    `/serviceProvider/serviceProviderNameById`,
    { params: { pk: pk } }
  )
  //const data = serviceProvider.data.map((item) => (item = clean(item)));
  return serviceProviders
}

/**
 * createNewUser method is used to create new userfor a service provider in db.
 * @param {string} token - jwt token fetched from auth0
 * @param {Object} data - data to be added in db
 */
export async function createNewUser(token, data) {
  http.setJwt(token)
  const serviceProvider = await http.post(`/createUser`, data)
  return serviceProvider
}

/**
 * updateAssetOwnerData method is used to add / update/ delete asset data to db.
 * @param {object} data - data to be added/ updated/ deleted.
 * @param {string} action - action to be performed add/ update/ delete
 * @param {string} jwtToken - jwtToken from auth0
 */
export async function updateAssetOwnerData(data, oldData, jwtToken) {
  const newData = clean(data)
  http.setJwt(jwtToken)
  delete newData.isSelected
  const dataToBeUpdated = {}
  dataToBeUpdated.newData = newData
  dataToBeUpdated.oldData = oldData
  const serviceProvider = await http.put(`/serviceProvider`, dataToBeUpdated)
  return serviceProvider
}

/**
 * addServiceProviderData method is used to add or update service provider data to the db.
 * @param {object} data - data to be added / updated
 * @param {string} action - action to be performed
 * @param {string} _id - id of selected data incase of update function
 */
export async function addServiceProviderData(data, action, token) {
  const newData = clean(data)
  http.setJwt(token)
  if (action === "Add") {
    const serviceProvider = await http.post(`/serviceProvider`, newData)
    return serviceProvider
  } else if (action === "Edit") {
    const serviceProvider = await http.put(`/serviceProvider`, newData)
    return serviceProvider
  } else if (action === "Delete") {
    const headers = {
      id: newData.id,
      pk: newData.pk,
    }
    // console.log(data)
    const serviceProvider = await http.delete(
      `/serviceProvider/${newData.id}`,
      { data },
      {
        headers,
        data,
      }
    )

    return serviceProvider
  }
}

/**
 * updateServiceProviderData method is used to update service provider data in db.
 * @param {object} data - data to be updated/ .
 * @param {string} oldData - existing data
 * @param {string} jwtToken - jwtToken from auth0
 */
export async function updateServiceProviderData(data, oldData, jwtToken) {
  const newData = clean(data)
  http.setJwt(jwtToken)
  delete newData.isSelected
  const dataToBeUpdated = {}
  dataToBeUpdated.newData = newData
  dataToBeUpdated.oldData = oldData
  const assetOwner = await http.put(`/serviceProvider`, dataToBeUpdated)
  return assetOwner
}

/**
 * getAssetsCount method is used to count the total number of assets.
 * @param {string} cancelToken - token generated from axios.
 */
export async function getServiceProviderCount(jwtToken, primaryKey) {
  http.setJwt(jwtToken)
  const assetsCount = await http.get(`/serviceProvider/count`, { params: { primaryKey: primaryKey } })
  return assetsCount
}

export async function addServiceProviderCsvData(data, userData, jwtToken) {
  http.setJwt(jwtToken)
  const serviceProvider = await http.post(`/serviceProvider/importData`, {
    insertDetails: data,
    userDetails: userData,
  })
  return serviceProvider
}

/**
 * updateSubscriptionDetailsInAssetOwner method is used to add / update/ delete asset data to db.
 * @param {object} data - data to be added/ updated/ deleted.
 * @param {string} action - action to be performed add/ update/ delete
 * @param {string} jwtToken - jwtToken from auth0
 */
export async function updateSubscriptionDetailsInServcieProvider(jwtToken, serviceProviderId, serviceProviderSk, subscriptionId, customerId, quantity) {
  http.setJwt(jwtToken)
  const dataToBeUpdated = {}
  dataToBeUpdated.serviceProviderId = serviceProviderId
  dataToBeUpdated.serviceProviderSk = serviceProviderSk
  dataToBeUpdated.subscriptionId = subscriptionId
  dataToBeUpdated.customerId = customerId
  dataToBeUpdated.quantity = quantity
  const assetOwner = await http.put(`/serviceProvider/update-subscription-details`, dataToBeUpdated)
  return assetOwner
}

export async function updateVideoUrlForServiceProvider(jwtToken , pk , sk , videoUrl){
  http.setJwt(jwtToken);
  await http.put(`/serviceProvider/video-url` , {
    pk:pk,
    sk:sk,
    videoUrl:videoUrl
  })
}

export async function getServiceProviderDetailsForAdmin(jwtToken) {
  http.setJwt(jwtToken);
  const users = await http.get(`/users/getAuthUserDetailsUsingEmailId`, {
    params: { emailId: 'hello@tracefii.com' }
  })
  let auth0UserList = users.data
  let assetOwnerTableDetails = []
  const serviceProviderData = await http.get(`/serviceProvider`)

  for (let i = 0; i < serviceProviderData.data.length; i++) {
    let details = {}
    let serviceProviderValues = serviceProviderData.data[i].attributes;
    details.serviceProviderName = serviceProviderValues.name;
    details.email = serviceProviderValues.email;
    details.url = serviceProviderValues.metquayurl;
    if (serviceProviderValues.activeSubscriptionId === undefined || serviceProviderValues.activeSubscriptionId === null || serviceProviderValues.activeSubscriptionId === ' ') {
      details.subscriptionStatus = "Trial"
    } else {
      details.subscriptionStatus = "Active"
    }
    if (serviceProviderValues.freeTrialDays !== undefined && serviceProviderValues.freeTrialDays !== null) {
      details.noOfTrialDaysLeft = serviceProviderValues.freeTrialDays;
    } else {
      details.noOfTrialDaysLeft = 0
    }

    await getAssetsCount(jwtToken, serviceProviderData.data[i].pk).then(result => {
      if (result.data.attributes === undefined || result.data.attributes === null) {
        details.noOfAssetOwners = 0;
        details.noOfUsers = 0;
      } else {
        details.noOfAssetOwners = result.data.attributes.ALLASSETOWNERSCOUNT;
        details.noOfUsers = result.data.attributes.ALLUSERSCOUNT
      }

    })
    for (let j = 0; j < auth0UserList.length; j++) {
      if (auth0UserList[j].email === serviceProviderValues.email) {
        // details.lastLoggedInDate = convertDateTimeString(new Date(auth0UserList[i].last_login).toISOString())
        details.lastLoggedInDate = new Date(auth0UserList[i].last_login).toLocaleString()
        break;
      }
    }
    assetOwnerTableDetails.push(details)
  }
  // serviceProvdierData.data.map(async (assetOwnerDetails) => {

  // })
  return assetOwnerTableDetails
}

export async function updateCurrencyOfServiceProvider(jwtToken, userId , currency){
  http.setJwt(jwtToken);
  var encodedCurrency = JSON.stringify(currency)
  await http.put(`/serviceProvider/update-currency-of-serviceprovider` , {
    userId : userId,
    currency : encodedCurrency
  })
}

export async function createQuoteOnInquiry(jwtToken, inquiryId , quote  , status){
  http.setJwt(jwtToken)
  await http.put(`assets/send-quote-to-assetowner` , {
    inquiryId : inquiryId,
    quote : quote,
    status : status
  })
}
function clean(obj) {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === ""
    ) {
      delete obj[propName]
    }
  }
  return obj
}
