const tableData = [
  {
    id: "1",
    name: "1810 ANALYZERS",
    email: "1810analyzers@gmail.com",
    phone: "123456789",
    website: "1810analyzers.org.in",
    countryRegion: "USA",
    addressLine1: "BATON ROUGE, LA  70805",
    addressLine2: "BATON ROUGE, LA  70805",
    city: "LA",
    state: "California",
    zipCode: "90001",
    fax: "",
  },
  {
    id: "2",
    name: "EXXONMOBIL CORPORATION  (BRCP)",
    email: "exxonmobilcorp@gmail.com",
    phone: "+1 800-243-9966",
    website: "exxonmobilcorp.com",
    countryRegion: "USA",
    addressLine1: "12909 High Rd",
    addressLine2: "Lockport",
    city: "IL",
    state: "Illinois",
    zipCode: "60441",
    fax: "",
  },
  {
    id: "3",
    name: "1110 LIGHT ENDS",
    email: "1110lightends@gmail.com",
    phone: "+1 847-499-8300",
    website: "1110lightends.com",
    countryRegion: "USA",
    addressLine1: "5022 S Power RD",
    addressLine2: "SUITE 104",
    city: "Mesa",
    state: "AZ",
    zipCode: "85212",
    fax: "",
  },
  {
    id: "4",
    name: "Cannon Inc",
    email: "cannon@gmail.com",
    phone: "+1 248-488-0371",
    website: "Kaggle",
    countryRegion: "USA",
    addressLine1: "3790 LA HWY 30",
    addressLine2: "ST. GABRIEL, LA  70776",
    city: "LA",
    state: "CA",
    zipCode: "90001",
    fax: "",
  },
  {
    id: "4",
    name: "Nike Inc",
    email: "nike@gmail.com",
    phone: "+1 503-671-6453",
    website: "www.nike.com",
    countryRegion: "USA",
    addressLine1: "Beaverton",
    addressLine2: 887,
    city: "Oregon",
    state: "Oregon",
    zipCode: "97005",
    fax: "",
  },
  {
    id: "5",
    name: "Intel",
    email: "intel@gmail.com",
    phone: "+1 408-765-8080",
    website: "www.intel.in",
    countryRegion: "US",
    addressLine1: "2501 NE Centurt Blvd",
    addressLine2: "Hillsboro",
    city: "Hillsboro",
    state: "OR",
    zipCode: "97124",
    fax: "",
  },
  {
    id: "6",
    name: "Antionio Gorilla",
    email: "antioniogorilla@gmail.com",
    phone: "+1 408-885-6521",
    website: "www.antioniogorilla.in",
    countryRegion: "US",
    addressLine1: "4 high Ridge Park Rd",
    addressLine2: "",
    city: "Stamford",
    state: "Connecticut",
    zipCode: "06905",
    fax: "dfdfdf",
  },
  {
    id: "12",
    name: "Google",
    email: "support.google@gmail.com",
    phone: "+1 887-243-9966",
    website: "www.google.com",
    countryRegion: "US",
    addressLine1: "500 W 2nd St Suite",
    addressLine2: "2900 Austin",
    city: "Austin",
    state: "TX",
    zipCode: "78701",
    fax: "",
  },
  {
    id: "7",
    name: "Metquay Inc Delaware",
    email: "metquay@gmail.com",
    phone: "+1 971-340-8998",
    website: "www.metquay.com",
    countryRegion: "US",
    addressLine1: "2018 156th Ave NE Building F",
    addressLine2: "Suite 100",
    city: "Bellevue",
    state: "WA",
    zipCode: "98007",
    fax: "",
  },
  {
    id: "8",
    name: "Wood Corner",
    email: "woodcorner@gmail.com",
    phone: "(623)-853-7197",
    website: "www.woodcorner.com",
    countryRegion: "US",
    addressLine1: "1839 Arbor Way",
    addressLine2: "",
    city: "Turlock",
    state: "CA",
    zipCode: "95380",
    fax: "",
  },
  {
    id: "9",
    name: "Azure Interior",
    email: "azureinterior@gmail.com",
    phone: "(870)-931-0505",
    website: "www.azureinterior.in",
    countryRegion: "US",
    addressLine1: "4557 De Silva St",
    addressLine2: "",
    city: "Fremont",
    state: "CA",
    zipCode: "94536",
    fax: "",
  },
  {
    id: "10",
    name: "Deco Addict",
    email: "decoaddict@gmail.com",
    phone: "(603)-996-3829",
    website: "www.decoaddict.com",
    countryRegion: "US",
    addressLine1: "77 Santa Barbara Rd",
    addressLine2: "",
    city: "Pleasant Hill",
    state: "CA",
    zipCode: "94523",
    fax: "",
  },
  {
    id: "11",
    name: "Bravo Zulu Network",
    email: "bravozulu@gmail.com",
    phone: "+1 503-671-6453",
    website: "www.bravozulu.in",
    countryRegion: "US",
    addressLine1: "1935 NW 173RD AVE",
    addressLine2: "",
    city: "Beaverton",
    state: "Oregon",
    zipCode: "97005",
    fax: "",
  },
  {
    id: "13",
    name: "Metquay",
    email: "metquay@gmail.com",
    phone: "8921642495",
    website: "www.metquay.com",
    countryRegion: "INDIA",
    addressLine1: "Jayasree , HNO: 76",
    addressLine2: "",
    city: "Trivandrum",
    state: "Kerala",
    zipCode: "605036",
    fax: "",
  },
  {
    id: "14",
    name: "AGMP",
    email: "agmp@gmail.com",
    phone: "+14845219747",
    website: "www.agmp.com",
    countryRegion: "US",
    addressLine1: 2,
    addressLine2: 887,
    city: 54534,
    state: "State",
    zipCode: "1234",
    fax: "dfdfdf",
  },
  {
    id: "15",
    name: "Jabil_JAB_MEX",
    email: "jabiljabmex@gmail.com",
    phone: "(870)-931-0505",
    website: "www.jabiljabmex.com",
    countryRegion: "676",
    addressLine1: "3125 S Westwood Place",
    addressLine2: "",
    city: "West Terre Haute",
    state: "IN",
    zipCode: "47885",
    fax: "",
  },
  {
    id: "16",
    name: "BCS Access Systems US, LLC",
    email: "bcsaccsesssystems@gmail.com",
    phone: "123456789",
    website: "www.bcsaccsesssystems.in",
    countryRegion: "US",
    addressLine1: "2150",
    addressLine2: "Cranebrook Drive",
    city: "Auurn",
    state: "NY",
    zipCode: "13021",
    fax: "",
  },
  {
    id: "17",
    name: "Metquay",
    email: "metquay@gmail.com",
    phone: "(509)-853-1947",
    website: "www.metquay.com",
    countryRegion: "US",
    addressLine1: "2035 Sunset Lake Road",
    addressLine2: "Suite B2",
    city: "Newark",
    state: "Delaware",
    zipCode: "19702",
    fax: "",
  },
  {
    id: "18",
    name: "ZF IL",
    email: "zfil@gmail.com",
    phone: "217-826-3011",
    website: "www.zfil.com",
    countryRegion: "US",
    addressLine1: "902",
    addressLine2: "S 2nd Street",
    city: "Marshall",
    state: "IL",
    zipCode: "62441",
    fax: "",
  },
  {
    id: "19",
    name: "Jabil_MI",
    email: "jabilmi@gmail.com",
    phone: "(803)-315-1698",
    website: "www.jabilmi.in",
    countryRegion: "US",
    addressLine1: "2150",
    addressLine2: "Cranebrook Drive",
    city: "Auurn",
    state: "NY",
    zipCode: "13021",
    fax: "",
  },
  {
    id: "20",
    name: "Zulu Network",
    email: "zulunet@gmail.com",
    phone: "(907) 338-3282",
    website: "www.zulunet.in",
    countryRegion: "US",
    addressLine1: "1118 N MULDOON ROAD",
    addressLine2: "SUITE 170",
    city: "ANCHORAGE",
    state: "AK",
    zipCode: "99504",
    fax: "",
  },
  {
    id: "21",
    name: "Kenneth Sutton",
    email: "kennethsutton@gmail.com",
    phone: "(501) 812-7000",
    website: "www.kennethsutton.com",
    countryRegion: "US",
    addressLine1: "2617 LAKEWOOD VILLAGE DR",
    addressLine2: "",
    city: "NORTH LITTLE ROCK",
    state: "AR",
    zipCode: "72116",
    fax: "",
  },
  {
    id: "22",
    name: "IMBC",
    email: "imbc@gmail.com",
    phone: "(714) 265-4088",
    website: "www.imbc.net",
    countryRegion: "US",
    addressLine1: "3839 W 1ST STREET",
    addressLine2: "SUITE B-4",
    city: "SANTA ANA",
    state: "LA",
    zipCode: "92703",
    fax: "",
  },
  {
    id: "23",
    name: "DS System",
    email: "dsystems@gmail.com",
    phone: "(856) 848-5589",
    website: "www.dsystems.com",
    countryRegion: "US",
    addressLine1: "1750 DEPTFORD CENTER RD",
    addressLine2: "SPACE 1187",
    city: "DEPTFORD",
    state: "NJ",
    zipCode: "08096",
    fax: "",
  },
  {
    id: "24",
    name: "Prism Tech Solutions",
    email: "prismtech@gmail.com",
    phone: "(908) 218-3905",
    website: "www.prismtech.in",
    countryRegion: "US",
    addressLine1: "400 COMMONS WAY, SUITE 1355",
    addressLine2: "BRIDGEWATER COMMONS",
    city: "BRIDGEWATER",
    state: "NJ",
    zipCode: "08807",
    fax: "",
  },
  {
    id: "25",
    name: "EXCON COMPANY",
    email: "excon@gmail.com",
    phone: "(718) 875-7940",
    website: "www.excon.com",
    countryRegion: "US",
    addressLine1: "160 MONTAGUE STREET",
    addressLine2: "",
    city: "BROOKLYN",
    state: "NY",
    zipCode: "11201",
    fax: "",
  },
  {
    id: "26",
    name: "Cannon South Inc",
    email: "cannonsouth@gmail.com",
    phone: "(607) 729-8093",
    website: "www.cannonsouth.in",
    countryRegion: "US",
    addressLine1: "601 HARRY L DR",
    addressLine2: "OAKDALE MALL SUITE 7",
    city: "JOHNSON CITY",
    state: "NY",
    zipCode: "13790",
    fax: "",
  },
  {
    id: "27",
    name: "Azure Tech",
    email: "azuretech@gmail.com",
    phone: "(607) 796-2300",
    website: "www.azuretech.com",
    countryRegion: "US",
    addressLine1: "1510 RT64",
    addressLine2: "SOUTHERN TIER CROSSING",
    city: "HORSEHEADS",
    state: "NY",
    zipCode: "14845",
    fax: "",
  },
  {
    id: "28",
    name: "Woodland",
    email: "woodland@gmail.com",
    phone: "(937) 320-9648",
    website: "www.woodland.co",
    countryRegion: "US",
    addressLine1: "4467 WALNUT ST",
    addressLine2: "A-120",
    city: "DAYTON",
    state: "OH",
    zipCode: "45440",
    fax: "",
  },
  {
    id: "29",
    name: "Deco Addict West",
    email: "decoaddictwest@gmail.com",
    phone: "(330) 779-3336",
    website: "www.decoaddictwest.co",
    countryRegion: "US",
    addressLine1: "6000 MAHONING AVE",
    addressLine2: "SUITE 238",
    city: "YOUNGSTOWN",
    state: "OH",
    zipCode: "44515",
    fax: "",
  },
  {
    id: "30",
    name: "Mac Tech",
    email: "mactech@gmail.com",
    phone: "(918) 431-0520",
    website: "www.mactech.in",
    countryRegion: "US",
    addressLine1: "1909 S MUSKOGEE",
    addressLine2: "STE 100",
    city: "TAHLEQUAH",
    state: "OH",
    zipCode: "74464",
    fax: "",
  },
]

const columns = [
  {
    dataField: "name",
    text: "Name",
    sort: true,
    float: "left",
    type: "string",
    required: true,
  },
  {
    dataField: "email",
    text: "Email",
    sort: true,
    float: "right",
    type: "string",
    required: true,
  },
  {
    dataField: "phone",
    text: "Phone",
    sort: false,
    float: "left",
    type: "string",
    required: true,
  },
  {
    dataField: "website",
    text: "Website",
    sort: true,
    float: "right",
    type: "string",
    required: true,
  },
  {
    dataField: "countryRegion",
    text: "Country/Region",
    sort: false,
    float: "left",
    type: "autoSuggest",
    required: true,
  },
  {
    dataField: "addressLine1",
    text: "AddressLine1",
    sort: true,
    float: "right",
    type: "string",
    required: true,
  },
  {
    dataField: "addressLine2",
    text: "AddressLine2",
    sort: true,
    float: "left",
    type: "string",
    required: false,
  },
  {
    dataField: "city",
    text: "City",
    sort: false,
    float: "right",
    type: "string",
    required: false,
  },
  {
    dataField: "state",
    text: "State",
    sort: true,
    float: "left",
    type: "autoSuggest",
    required: true,
  },
  {
    dataField: "zipCode",
    text: "ZipCode",
    sort: false,
    float: "right",
    type: "string",
    required: true,
  },
  {
    dataField: "fax",
    text: "Fax",
    sort: true,
    float: "left",
    type: "string",
    required: false,
  },
  {
    dataField: "statusValue",
    text: "Status",
    sort: true,
    float: "right",
    type: "dropDown",
    required: false,
  },
]

const defaultSorted = [
  {
    dataField: "id",
    order: "asc",
  },
]

const importColumns = [
  "Name",
  "Email",
  "Phone",
  "Website",
  "Country/Region",
  "AddressLine1",
  "AddressLine2",
  "City",
  "State",
  "ZipCode",
  "Fax",
  "Status",
]

const csvColumns = {
  Name: "name",
  Email: "email",
  Phone: "phone",
  Website: "website",
  "Country/Region": "countryRegion",
  AddressLine1: "addressLine1",
  AddressLine2: "addressLine2",
  City: "city",
  State: "state",
  ZipCode: "zipCode",
  Fax: "fax",
  Status: "statusValue",
}

export { columns, defaultSorted, tableData, importColumns, csvColumns }
