import { useAuth0 } from '@auth0/auth0-react';
import { Select } from 'antd';
import React from 'react'
import { updateCurrencyOfServiceProvider } from 'services/serviceProvider';
import { toast } from "react-hot-toast"

const CurrencySelection = ({userId , user}) => {
  const apiurl = process.env.REACT_APP_AUTH0_API_URL
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()
  
  const getAccessToken = async (audience, claim) => {
    let token
    try {
      token = await getAccessTokenSilently({
        audience,
        claim,
      })
    } catch (exception) {
      try {
        token = await getAccessTokenWithPopup({ audience, claim })
      } catch (ex) {
        token = ""
      }
    }
    return token
  }
    const handleSelectChange = async(value) => {
      const jwtToken = await getAccessToken(apiurl , 'edit:serviceProvider')
      if(value){
        try {
          await updateCurrencyOfServiceProvider(jwtToken , userId , value)
          toast.success("Currency Updated")
        } catch (error) {
          toast.error("Failed to update currency")
        }
       
      }
    };
  return (
    <div>
        <div style={{display:'flex' , gap:'1rem'}}>
            <Select
                placeholder="Default Currency"
                onChange={handleSelectChange}
                style={{width: 120}}
                defaultValue={user.currency ? user.currency : "$"}
            >
          <Option value="$">$</Option>
          <Option value="€">€</Option>
          <Option value="£">£</Option>
          <Option value="¥">¥</Option>
        </Select>
        </div>
    </div>
  )
}

export default CurrencySelection