import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Card, CardBody, Col, Media, Badge } from "reactstrap"
import { loadStripe } from "@stripe/stripe-js"
import { useAuth0 } from "@auth0/auth0-react"
import { useToasts } from "react-toast-notifications"
import CryptoJS from "crypto-js"

//modal
import { CancelSubscriptionModal } from "./CancelSubscriptionModal"
//Service methods
import {
  createCheckoutSession,
  updateSamePlanSubscriptionDetails,
  upgradePlan,sendMailAfterSuccessfulPayment,cancelSubscription
} from "../../../services/paymentService"
import { getUsersById } from "../../../services/userService"
import { updateSubscriptionDetailsInAssetOwner } from "../../../services/assetownerService"
import { updateSubscriptionDetailsInServcieProvider } from "../../../services/serviceProvider"
import { user_metadata, roleConfig } from "../../../nameSpaceConfig"

const CardPricing = props => {
  const {
    product,
    productPrice,
    subscriptionId,
    subscribedProductId,
    usersPurchased,
    nextInvoiceDate,
    billingAmount,
    assetOwnerName,
    assetOwnerEmail,
  } = props
  const { addToast } = useToasts()
  const { getAccessTokenSilently, getAccessTokenWithPopup, user } = useAuth0()
  const userMetadata = user[user_metadata]
  const apiurl = process.env.REACT_APP_AUTH0_API_URL
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)
  const [noOfUsersCount, setNoOfUsersCount] = useState(0)
  const [price, setPrice] = useState(0)
  const [modal, setModal] = useState(false)

  /**
   * getAccessToken method is used to fecth jwt access token from auth0
   * @param {string} audience - the api URL
   * @param {string} claim - the permission
   */
  const getAccessToken = async (audience, claim) => {
    let token
    try {
      token = await getAccessTokenSilently({
        audience,
        claim,
      })
    } catch (exception) {
      token = await getAccessTokenWithPopup({ audience, claim })
    }
    return token
  }

  const onChange = (e, header, type) => {
    setNoOfUsersCount(e.target.value)
  }

  /**
   * decryptKeyValues method is used to decrypt the primary/ sort key values fetched from autho user.
   * @param {string} keyValue
   * @returns string
   */
  const decryptKeyValues = keyValue => {
    return CryptoJS.enc.Base64.parse(keyValue).toString(CryptoJS.enc.Utf8)
  }

  /**
   * to display toaster
   * @param {string} message 
   * @param {string} type 
   */
  const createToaster = (message, type) => {
    addToast(message, {
      appearance: type,
      autoDismiss: true,
    })
  }

  /**
   * To toggle modal for cancel subscription modal
   */
   const toggle = () => {
    setModal(!modal)
  }

  const updateSubscriptions = async (jwtToken, primaryKeyValue, sortKeyValue, subscriptionId, customerId, quantity) => {
    if ( userMetadata.role.startsWith("Service Provider") ) {
      await updateSubscriptionDetailsInServcieProvider(
        jwtToken,
        primaryKeyValue,
        sortKeyValue,
        subscriptionId,
        customerId,
        quantity
      ).then(result => {
        if( quantity === 0 ) {
          addToast("Subsciption have been cancelled successfully", {
            appearance: 'info',
            autoDismiss: true,
          })
        }
        
        
      })
    }else if (  userMetadata.role.startsWith("Asset Owner")) {
      await updateSubscriptionDetailsInAssetOwner(
        jwtToken,
        primaryKeyValue,
        sortKeyValue,
        subscriptionId, customerId,
        quantity
      ).then(result => {
        if( quantity === 0 ) {
          addToast("Subsciption have been cancelled successfully", {
            appearance: 'info',
            autoDismiss: true,
          })
        }
        
      })
    }
  }

  /**
   * cancelSubscriptionForUser function is used to cancel the subscription of an user
   * @param {event} e 
   */
  const cancelSubscriptionForUser = async (e) => {
    const jwtToken = await getAccessToken(apiurl, "edit:assets")
    let primaryKeyValue = null
      let sortKeyValue = null
      let subscriptionCancelled = false
      if (
        userMetadata.role.startsWith("Service Provider") ||
        userMetadata.role.startsWith("Asset Owner")
      ) {
        primaryKeyValue = decryptKeyValues(userMetadata.pk)
        sortKeyValue = decryptKeyValues(userMetadata.sk)
      }
    await cancelSubscription(jwtToken, subscriptionId).then(result => {
      subscriptionCancelled = true
      
    })
    if(subscriptionCancelled){
      // commenting for now - 17/08/2022
      updateSubscriptions( jwtToken, primaryKeyValue, sortKeyValue,null,null, 0)

      //already commented
      // await updateSubscriptionDetailsInAssetOwner(
      //   jwtToken,
      //   primaryKeyValue,
      //   sortKeyValue,
      //   null,
      //   null,
      //   0
      // ).then(result => {
      //   addToast("Subsciption have been cancelled successfully", {
      //     appearance: 'info',
      //     autoDismiss: true,
      //   })
        
      // })
    }
    sendMail(true)
    window.location.reload()
  }

  const sendMail = async(isCancel) => {
    const jwtToken = await getAccessToken(apiurl, "edit:assets")
    await sendMailAfterSuccessfulPayment(jwtToken, assetOwnerEmail, assetOwnerName, product.name,"", isCancel).then(result => {
        
      }
    )
  }

  const submitData = async e => {
    e.preventDefault()
    const stripe = await stripePromise

    const jwtToken = await getAccessToken(apiurl, "edit:assets")
    if (Number(noOfUsersCount) === 0) {
      createToaster("Please update the value of no.of users", "error")
      // addToast("Please update the value of no.of users", {
      //   appearance: "error",
      //   autoDismiss: true,
      // })
    } else {
      if (subscriptionId === undefined || subscriptionId === null) {
        await createCheckoutSession(jwtToken, product.id, noOfUsersCount).then(
          async session => {
            await stripe.redirectToCheckout({ sessionId: session.data.id })
            // sendMail()
          }
        )
        createToaster("Payment successfully completed", "info")
        // send email
        
      } else {
        let primaryKeyValue = null
        let sortKeyValue = null
        if (
          userMetadata.role.startsWith("Service Provider") ||
          userMetadata.role.startsWith("Asset Owner")
        ) {
          primaryKeyValue = decryptKeyValues(userMetadata.pk)
          sortKeyValue = decryptKeyValues(userMetadata.sk)
        }
        let totalUsers = 0
        totalUsers = Number(usersPurchased) + Number(noOfUsersCount)
        if (subscribedProductId === product.id) {
          if (Number(usersPurchased) === Number(noOfUsersCount)) {
            createToaster("Please update the value of no.of users", "error")
          } else if (Number(usersPurchased) < Number(noOfUsersCount)) {
            await updateSamePlanSubscriptionDetails(
              jwtToken,
              subscriptionId,
              noOfUsersCount
            ).then(result => {
              createToaster("Payment successfully updated", "info")
              // send email
              sendMail()
              
            })
            updateSubscriptions( jwtToken, primaryKeyValue, sortKeyValue,subscriptionId,null, noOfUsersCount)
            window.location.reload()
            // await updateSubscriptionDetailsInAssetOwner(
            //   jwtToken,
            //   primaryKeyValue,
            //   sortKeyValue,
            //   subscriptionId,
            //   null,
            //   noOfUsersCount
            // )
          } else {
            let noOfUsersUnderAssetOwner = 0
            await getUsersById(
              null,
              jwtToken,
              primaryKeyValue,
              userMetadata.role,
              sortKeyValue
            )
              .then(result => {
                noOfUsersUnderAssetOwner = result.length
              })
              .catch(error => {
                addToast(error.response.data, {
                  appearance: "error",
                  autoDismiss: true,
                })
              })
            if (noOfUsersUnderAssetOwner < Number(noOfUsersCount)) {
              await updateSamePlanSubscriptionDetails(
                jwtToken,
                subscriptionId,
                noOfUsersCount
              ).then(result => {
                createToaster("Payment successfully updated", "info")
                //send email
                sendMail()
              })
              updateSubscriptions( jwtToken, primaryKeyValue, sortKeyValue,subscriptionId,null, noOfUsersCount)
              window.location.reload()
              // await updateSubscriptionDetailsInAssetOwner(
              //   jwtToken,
              //   primaryKeyValue,
              //   sortKeyValue,
              //   subscriptionId,
              //   null,
              //   noOfUsersCount
              // )
            }
          }
        } else {
          let priceId = ""
          productPrice.map(price => {
            if (price.product === product.id) {
              priceId = price.id
            }
          })
          //If same no.of users or more users are upgrading to other plan
          if (
            Number(noOfUsersCount) > usersPurchased ||
            Number(noOfUsersCount) === usersPurchased
          ) {
            await upgradePlan(
              jwtToken,
              subscriptionId,
              priceId,
              noOfUsersCount
            ).then(result => {
              createToaster("Payment successfully updated", "info")
              //send email
              sendMail()
            })
            updateSubscriptions( jwtToken, primaryKeyValue, sortKeyValue,subscriptionId,null, noOfUsersCount)
            window.location.reload()
            // await updateSubscriptionDetailsInAssetOwner(
            //   jwtToken,
            //   primaryKeyValue,
            //   sortKeyValue,
            //   subscriptionId,
            //   null,
            //   noOfUsersCount
            // )
          } else {
            createToaster("Please update the value of no.of users", "error")
          }
        }
      }
    }
  }

  useEffect(() => {
    const getInitialData = async () => {
      productPrice.map(price => {
        if (price.product === product.id) {
          setPrice((price.unit_amount_decimal/100).toFixed(2))
        }
      })
    }
    getInitialData()
  }, [])

  return (
    <React.Fragment>
      <Col xl="3" md="6">
        <Card className="plan-box" style={{ height: "85%" }}>
          <CardBody className="p-4">
            <Media>
              <Media body>
                {subscribedProductId === product.id ? (
                  <Badge className={"font-size-12 badge-soft-success"} pill>
                    {"Active Plan"}
                  </Badge>
                ) : (
                  " "
                )}
                <h5>{product.name}</h5>
                <p className="text-muted">{product.description}</p>
              </Media>
              <div className="ms-3">
                <i className={"bx " + product.images[0] + " h1 text-primary"} />
              </div>
            </Media>
            <div className="py-4">
              <h2>
                <sup>
                  <small>${price}</small>
                </sup>
                
                <span className="font-size-13">{product.duration}</span>
              </h2>
            </div>
            
    {subscribedProductId===product.id ? 
              <div class="form-row">
              <label class=" col-form-label" htmlFor="name">
                Next Invoice Date : {nextInvoiceDate}
              </label>
              {/* <h2>
                <sup>
                  <small>${usersPurchased}</small>
                </sup>
              </h2> */}
              </div>
    : " "}
    {subscribedProductId===product.id ? 
              <div class="form-row">
              <label class="col-form-label" htmlFor="name">
              Next Billing Amount : {(billingAmount/100).toFixed(2)}
             
              </label>
              </div>
    : " "}

            <div class="form-row">
              <label class="col-sm-5 col-form-label" htmlFor="name">
                No.of Users
              </label>
              <input
                type="number"
                class="form-control col-sm-5"
                name="noOfUsers"
                id="noOfUsers"
                onChange={onChange}
                min={0}
                defaultValue={
                  subscribedProductId === product.id ? usersPurchased : 0
                }
              />
            </div>
            <div className="text-center">
              <button className="btn btn-success m-2" onClick={submitData}>
                {subscribedProductId === undefined || subscribedProductId === ""
                  ? "Purchase"
                  : subscribedProductId === product.id
                  ? "Update"
                  : "Change plans"}
              </button>
              {
                subscribedProductId === undefined || subscribedProductId === ""
                ? ""
                : subscribedProductId === product.id
                ? <button className="btn btn-danger m-2" onClick={toggle} > Cancel Subscription</button>
                : ""
              }
              <CancelSubscriptionModal
            action="cancelSubscription"
            cancelSubscriptionForUser={cancelSubscriptionForUser}
            header={"Cancel Subscription"}
            open={modal}
            toggle={toggle}
          />

              {/* <Link
                to={product.link}
                className="btn btn-primary btn-sm "
              >
                Sign up Now
              </Link> */}
            </div>

            <div className="plan-features mt-5">
              {/* {product.features.map((feature, key) => (
                <p key={"_feature_" + key}>
                  <i className="bx bx-checkbox-square text-primary me-2" />{" "}
                  {feature.title}
                </p>
              ))} */}
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

CardPricing.propTypes = {
  product: PropTypes.object,
}

export default CardPricing
