import React, { useReducer, useEffect, useState } from "react"
import { Row, Col, Button } from "reactstrap"
import { useAuth0 } from "@auth0/auth0-react"
import axios from "axios"
import { useToasts } from "react-toast-notifications"
import _ from "lodash"
import Joyride from "react-joyride"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Spinner from "../../components/Common/Spinner"
//reducers
import { multiplerecordsreducer } from "./reducer/reducers"
import { assetRecordsReducer } from "./reducer/assetReducer"


//Import components
import { TableWithRowExpand } from "./Components/TableWithRowExpand"
import { AssetPageTabComponent } from "./Components/AssetPageTabComponent"
import { ConfirmationModal } from "./Components/ConfirmationModal"
//Service
import {
  addAssetsData,
  getAssets,
  updateAssetsData,
  getFilteredAssets,
  getAssetsCount,
} from "../../services/assetService"
import { decryptKeyValues } from "../../services/commonService"
import {
  getAssetOwner,
  updatePreferencesInAssetOwner,
} from "../../services/assetownerService"
import { getServiceProviderById } from "../../services/serviceProvider"

import { user_metadata } from "../../nameSpaceConfig"

const Assets = props => {
  const apiurl = process.env.REACT_APP_AUTH0_API_URL
  const { getAccessTokenSilently, getAccessTokenWithPopup, user } = useAuth0()
  const { addToast } = useToasts()
  const userMetadata = user[user_metadata]
  const [assetCount, setAssetCount] = useState(0)
  const [formModal, setFormModal] = useState(false)
  const [modal, setModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [records, dispatchRecords] = useReducer(assetRecordsReducer, {
    list: [],
  })
  const [selectRow, setSelectRow] = useState([])
  const [multipleRecords, dispatchMultipleRecords] = useReducer(
    multiplerecordsreducer,
    { list: [] }
  )
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState({})
  const [firstKey, setFirstKey] = useState({})
  const [filterCondition, setFilterCondition] = useState("")
  const [searchKey, setSearchKey] = useState("")
  const [count, setCount] = useState({})
  const [goodToggle, setGoodToggle] = useState(false)
  const [warningToggle, setWarningToggle] = useState(false)
  const [dangerToggle, setDangerToggle] = useState(false)
  const [enableOnlineTutorials, setEnableOnlineTutorial] = useState(false)
  const [assetOwnerId, setAssetOwnerId] = useState("")
  const [assetOwnerSk, setAssetOwnerSk] = useState("")
  const [preferences, setPreferences] = useState({})
  const [expandableRowFields, setExpandableRowFields] = useState([])
  const [mainRowFields, setmainRowFields] = useState([])
  const [totalFields, setTotalFields] = useState([])

  /**
   * getAccessToken method is used to fecth jwt access token from auth0
   * @param {string} audience - the api URL
   * @param {string} claim - the permission
   */
  const getAccessToken = async (audience, claim) => {
    let token
    try {
      token = await getAccessTokenSilently({
        audience,
        claim,
      })
    } catch (exception) {
      token = await getAccessTokenWithPopup({ audience, claim })
    }
    return token
  }

  /**
   * To toggle modal for Delete options
   */
  const toggle = () => {
    setModal(!modal)
  }

  /**
   * To toggle modal for Add/ Edit options
   */
  const toggleFormModal = () => {
    setFormModal(!formModal)
  }

  /**
   * to display toaster
   * @param {string} message
   * @param {string} type
   */
  const createToaster = (message, type) => {
    addToast(message, {
      appearance: type,
      autoDismiss: true,
    })
  }

  /**
   * onFilter method is used to filter asset data based on selected condition
   * @param {object} e
   * @param {string} condition
   */
  const onFilter = async (
    e,
    condition,
    isGoodSelected,
    isWarningSelected,
    isDangerSelected
  ) => {
    setFilterCondition(condition)
    let sortKeyValue = ""
    let primaryKeyValue = ""
    setGoodToggle(isGoodSelected)
    setWarningToggle(isWarningSelected)
    setDangerToggle(isDangerSelected)
    if (
      userMetadata.role.startsWith("Service Provider") ||
      userMetadata.role.startsWith("Asset Owner")
    ) {
      primaryKeyValue = decryptKeyValues(userMetadata.pk)
      sortKeyValue = decryptKeyValues(userMetadata.sk)
    }
    const jwtToken = await getAccessToken(apiurl, "edit:assets")
    if (condition === "") {
      // setSearchKey("")
      getAssetRecords(searchKey)
    } else {
      let filterCount = 0
      if (condition === "good") {
        filterCount = filterCount + count["ALLGOODASSETSCOUNT"]
        // setGoodToggle(true)
      }
      if (condition === "warning") {
        filterCount = filterCount + count["ALLWARNINGASSETSCOUNT"]
        // setWarningToggle(true)
      }
      if (condition === "danger") {
        filterCount = filterCount + count["ALLDANGERASSETSCOUNT"]
        // setDangerToggle(true)
      }
      await getFilteredAssets(
        jwtToken,
        primaryKeyValue,
        // firstKey,
        null,
        false,
        userMetadata.role,
        condition,
        searchKey,
        sortKeyValue
      )
        .then(result => {
          dispatchRecords({
            type: "setRecords",
            payload: result.assetForAssetOwner,
          })
          setAssetCount(filterCount)
          setFirstKey({})
          // if(firstKey !== null){
          //   setLastEvaluatedKey(firstKey)
          // }
          if (result !== null && result !== undefined) {
            setLastEvaluatedKey(result.lastEvaluatedKey)
          }
        })
        .catch(error => {
          createToaster(error.response.data, "error")
        })
    }
  }

  /**
   * selectedRow method is used to set the values of selected row to selectRow.
   * @param {object} row -  selected row data
   * @param {boolean} isSelected -  isSelected flag
   */
  const selectedRow = (row, isSelected) => {
    if (isSelected) {
      setSelectRow(row)
    } else {
      setSelectRow([])
    }
  }

  /**
   * multipleRows method is used when all rows are selected.
   * @param {list} rows - list of selected rows
   */
  const multipleRows = rows => {
    rows.map(row => {
      selectedRow(row, row.isSelected)
    })
    dispatchMultipleRecords(rows)
  }

  /**
   * getPreviousAssetData method is sued to fetch assets record when prev button is clicked
   */
  const getPreviousAssetData = async () => {
    const jwtToken = await getAccessToken(apiurl, "edit:assets")
    let primaryKeyValue = null
    let sortKeyValue = null
    if (
      userMetadata.role.startsWith("Service Provider") ||
      userMetadata.role.startsWith("Asset Owner")
    ) {
      primaryKeyValue = decryptKeyValues(userMetadata.pk)
      sortKeyValue = decryptKeyValues(userMetadata.sk)
    }
    setLoading(true)
    if (
      filterCondition === "good" ||
      filterCondition === "warning" ||
      filterCondition === "danger"
    ) {
      await getFilteredAssets(
        jwtToken,
        primaryKeyValue,
        firstKey,
        false,
        userMetadata.role,
        filterCondition,
        searchKey,
        sortKeyValue
      )
        .then(result => {
          setLoading(false)
          dispatchRecords({
            type: "setRecords",
            payload: result.assetForAssetOwner,
          })
          if (firstKey !== null) {
            setLastEvaluatedKey(firstKey)
          }
          if (result !== null && result !== undefined) {
            setFirstKey(result.lastEvaluatedKey)
          }
        })
        .catch(error => {
          createToaster(error.response.data, "error")
        })
    } else {
      await getAssets(
        jwtToken,
        primaryKeyValue,
        firstKey,
        false,
        userMetadata.role,
        // filterCondition,
        searchKey,
        sortKeyValue
        // goodToggle,
        // warningToggle,
        // dangerToggle
      )
        .then(result => {
          setLoading(false)
          dispatchRecords({
            type: "setRecords",
            payload: result.assetForAssetOwner,
          })
          setLastEvaluatedKey(firstKey)
          setFirstKey(result.lastEvaluatedKey)
        })
        .catch(error => {
          createToaster(error.response.data, "error")
        })
    }
  }

  /**
   * getNextAssetData method is used to fetch assets record when next button is clicked
   */
  const getNextAssetData = async () => {
    const jwtToken = await getAccessToken(apiurl, "edit:assets")
    let primaryKeyValue = null
    let sortKeyValue = null
    if (
      userMetadata.role.startsWith("Service Provider") ||
      userMetadata.role.startsWith("Asset Owner")
    ) {
      primaryKeyValue = decryptKeyValues(userMetadata.pk)
      sortKeyValue = decryptKeyValues(userMetadata.sk)
    }
    setLoading(true)
    if (
      filterCondition === "good" ||
      filterCondition === "warning" ||
      filterCondition === "danger"
    ) {
      await getFilteredAssets(
        jwtToken,
        primaryKeyValue,
        lastEvaluatedKey,
        true,
        userMetadata.role,
        filterCondition,
        searchKey,
        sortKeyValue
      )
        .then(result => {
          dispatchRecords({
            type: "setRecords",
            payload: result.assetForAssetOwner,
          })
          setLoading(false)
          // setAssetCount(filterCount)
          // if(firstKey !== null){

          // }
          if (result !== null && result !== undefined) {
            setFirstKey(lastEvaluatedKey)
            setLastEvaluatedKey(result.lastEvaluatedKey)
          }
        })
        .catch(error => {
          createToaster(error.response.data, "error")
        })
    } else {
      await getAssets(
        jwtToken,
        primaryKeyValue,
        lastEvaluatedKey,
        true,
        userMetadata.role,
        // filterCondition,
        searchKey,
        sortKeyValue
        // goodToggle,
        // warningToggle,
        // dangerToggle
      )
        .then(result => {
          setLoading(false)
          dispatchRecords({
            type: "setRecords",
            payload: result.assetForAssetOwner,
          })
          setFirstKey(lastEvaluatedKey)
          setLastEvaluatedKey(result.lastEvaluatedKey)
        })
        .catch(error => {
          createToaster(error.response.data, "error")
        })
    }
  }

  /**
   * isDisabled method is used to check the mode and to disable/ enable add, edit and delete button
   * @param {string} action - current mode.
   */
  const isDisabled = action => {
    let disabled = true
    let selectedRowCount = 0
    records.list.map(record => {
      if (record.isSelected) {
        disabled = false
        selectedRowCount += 1
      }
    })
    if (action === "Edit") {
      if (selectedRowCount === 1) {
        return false
      } else {
        return true
      }
    }
    return disabled
  }

  const onSearchKeyChange = _.debounce(async e => {
    // e.persist();
    setSearchKey(e)
    let primaryKeyValue = null
    let sortKeyValue = null
    if (
      userMetadata.role.startsWith("Service Provider") ||
      userMetadata.role.startsWith("Asset Owner")
    ) {
      primaryKeyValue = decryptKeyValues(userMetadata.pk)
      sortKeyValue = decryptKeyValues(userMetadata.sk)
    }
    const jwtToken = await getAccessToken(apiurl, "edit:assets")
    if (
      filterCondition === "good" ||
      filterCondition === "warning" ||
      filterCondition === "danger"
    ) {
      setLoading(true)
      await getFilteredAssets(
        jwtToken,
        primaryKeyValue,
        null,
        false,
        userMetadata.role,
        filterCondition,
        e,
        sortKeyValue
      )
        .then(result => {
          dispatchRecords({
            type: "setRecords",
            payload: result.assetForAssetOwner,
          })
          setLoading(false)
          setAssetCount(result.assetForAssetOwner.length)
          setLastEvaluatedKey(result.lastEvaluatedKey)
          // if(firstKey !== null){
          //   setLastEvaluatedKey(firstKey)
          // }
          // if(result !== null && result !== undefined){
          //   setFirstKey(result.lastEvaluatedKey)
          // }
        })
        .catch(error => {
          setLoading(false)
          createToaster(error.response.data, "error")
        })
    } else {
      setLoading(true)
      await getAssets(
        jwtToken,
        primaryKeyValue,
        null,
        false,
        userMetadata.role,
        // filterCondition,
        e,
        sortKeyValue
        // goodToggle,
        // warningToggle,
        // dangerToggle
      )
        .then(result => {
          dispatchRecords({
            type: "setRecords",
            payload: result.assetForAssetOwner,
          })
          setLoading(false)
          // setAssetCount(filterCount)
          setLastEvaluatedKey(result.lastEvaluatedKey)
          // if(firstKey !== null){
          //   setLastEvaluatedKey(firstKey)
          // }
          // if(result !== null && result !== undefined){
          //   setFirstKey(result.lastEvaluatedKey)
          // }
        })
        .catch(error => {
          setLoading(false)
          createToaster(error.response.data, "error")
        })
    }
    selectedRow(null, false)
  }, 1000)

  /**
   * getAssetRecords method is used to load asset records.
   */
  const getAssetRecords = async searchKey => {
    let data
    let decodedPrimaryKey = ""
    let decodedSortKey = ""
    if (
      userMetadata.role.startsWith("Service Provider") ||
      userMetadata.role.startsWith("Asset Owner")
    ) {
      decodedPrimaryKey = decryptKeyValues(userMetadata.pk)
      decodedSortKey = decryptKeyValues(userMetadata.sk)
    }
    let lastkey = null
    const jwtToken = await getAccessToken(apiurl, "add:asset")
    if (userMetadata.role.startsWith("Asset Owner")) {
      await getAssetOwner(jwtToken, "", decodedPrimaryKey).then(result => {
        setAssetOwnerId(result[0].pk)
        setAssetOwnerSk(result[0].sk)
        if (
          result[0].attributes.preferences !== undefined &&
          result[0].attributes.preferences !== null
        ) {
          setPreferences(result[0].attributes.preferences)
          setmainRowFields(result[0].attributes.preferences.mainColumns)
          setExpandableRowFields(
            result[0].attributes.preferences.expandableColumns
          )
          setTotalFields(result[0].attributes.preferences.totalFields)
          // mainRowFields[0].formatter = conditionColumnFormatter
        }
      })
    } else {
      await getServiceProviderById(jwtToken, "", decodedPrimaryKey)
        .then(result => {
          setAssetOwnerId(result[0].pk)
          setAssetOwnerSk(result[0].sk)
          if (
            result[0].attributes.preferences !== undefined &&
            result[0].attributes.preferences !== null
          ) {
            setPreferences(result[0].attributes.preferences)
            setmainRowFields(result[0].attributes.preferences.mainColumns)
            setExpandableRowFields(
              result[0].attributes.preferences.expandableColumns
            )
            setTotalFields(result[0].attributes.preferences.totalFields)
          }
        })
        .catch(error => {})
    }

    await getAssets(
      jwtToken,
      decodedPrimaryKey,
      null,
      null,
      userMetadata.role,
      // null,
      searchKey,
      decodedSortKey
    )
      .then(result => {
        data = result
        data = result.assetForAssetOwner
        setLastEvaluatedKey(result.lastEvaluatedKey)
        setLoading(false)
      })
      .catch(error => {
        createToaster(error.response.data, "error")
        setLoading(false)
      })
    if (data !== undefined) {
      dispatchRecords({ type: "setRecords", payload: data })

      await getAssetsCount(jwtToken, decodedPrimaryKey).then(result => {
        if (result.data.attributes === undefined) {
          setAssetCount(0)
        } else {
          setCount(result.data.attributes)
          setAssetCount(result.data.attributes.ALLASSETSCOUNT)
        }
      })
    }
  }

  /**
   * closeReportTab function will be called when cancel button is clicked in the report tab
   */
  const closeReportTab = () => {
    setSelectRow([])
    selectedRow(selectRow, false)
  }

  /**
   * updateRecords method is used to update/add records in db.
   * @param {object} record - data to be updated.
   */
  const updateRecords = async record => {
    setLoading(true)
    let assetCondition = ""
    try {
      const jwtToken = await getAccessToken(apiurl, "edit:asset")
      if (record.mode === "Delete") {
        let dataToBeDeleted = []
        records.list.map(record => {
          if (record.isSelected) {
            const assetData = {}
            assetData.id = record.id
            assetData.pk = record.pk
            assetData.version = record.version
            assetData.assetNumber = record.assetNumber
            // assetData.assetOwnerId = record.assetOwnerId
            dataToBeDeleted.push(assetData)
          }
        })
        await addAssetsData(dataToBeDeleted, record.mode, jwtToken)
          .then(result => {
            setLoading(false)
            createToaster("Data has been deleted successfully", "info")
          })
          .catch(error => {})
        if (dataToBeDeleted.length === 50) {
          getAssetRecords()
        } else {
          dispatchRecords({ type: "Delete", payload: selectRow })
        }
        setSelectRow([])

        setAssetCount(assetCount - dataToBeDeleted.length)
      } else if (record.mode === "Add") {
        record.role = userMetadata.firstName
        if (
          userMetadata.role.startsWith("Service Provider") ||
          userMetadata.role.startsWith("Asset Owner")
        ) {
          record.pk = decryptKeyValues(userMetadata.pk)
          record.sortKeyValue = decryptKeyValues(userMetadata.sk)
        }
        if (userMetadata.lastName !== undefined) {
          record.role = userMetadata.firstName + " " + userMetadata.lastName
        }
        record.modifiedDate = new Date()
        await addAssetsData(record, record.mode, jwtToken)
          .then(result => {
            setLoading(false)
            record.isSelected = false
            dispatchRecords({
              type: record.mode,
              payload: { data: result.data, selectedFilter: filterCondition },
            })
            createToaster("Data has been added successfully", "info")
          })
          .catch(error => {
            setLoading(false)
            createToaster(error.response.data, "error")
          })
        setSelectRow([])
        // setAssetCount(records.list.length + 1)
      } else {
        if (userMetadata !== undefined && userMetadata.pk !== undefined) {
          record.pk = decryptKeyValues(userMetadata.pk)
        }
        record.id = selectRow.id
        record.role = userMetadata.firstName
        if (userMetadata.lastName !== undefined) {
          record.role = userMetadata.firstName + " " + userMetadata.lastName
        }
        await updateAssetsData(record, selectRow, jwtToken)
          .then(result => {
            setLoading(false)
            record.isSelected = false
            dispatchRecords({
              type: record.mode,
              payload: { data: result.data, selectedFilter: filterCondition },
            })
            createToaster("Data has been edited successfully", "info")
          })
          .catch(error => {
            setLoading(false)
            createToaster(error.response.data, "error")
          })
        setSelectRow([])
        setLoading(false)
      }
      let decodedPrimaryKey = ""
      if (
        userMetadata.role.startsWith("Service Provider") ||
        userMetadata.role.startsWith("Asset Owner")
      ) {
        decodedPrimaryKey = decryptKeyValues(userMetadata.pk)
      }
      setTimeout(async function () {
        await getAssetsCount(jwtToken, decodedPrimaryKey).then(result => {
          setCount(result.data.attributes)
          if (result.data.attributes === undefined) {
            setAssetCount(0)
          } else {
            setAssetCount(result.data.attributes.ALLASSETSCOUNT)
            if (filterCondition === "good") {
              setAssetCount(result.data.attributes.ALLGOODASSETSCOUNT)
            }
            if (filterCondition === "warning") {
              setAssetCount(result.data.attributes.ALLWARNINGASSETSCOUNT)
            }
            if (filterCondition === "danger") {
              setAssetCount(result.data.attributes.ALLDANGERASSETSCOUNT)
            }
          }
        })
      }, 1000)
    } catch (expection) {
      createToaster("Error occured while updating data.", "error")
      setLoading(false)
    }
  }

  useEffect(() => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    /**
     * getInitialData method is used to fetch all the records from db and display in table.
     */
    const getInitialData = async () => {
      try {
        setLoading(true)
        getAssetRecords()
      } catch (error) {
        if (axios.isCancel(error)) {
        } else {
          throw error
        }
      }
    }
    getInitialData()
    return () => {
      source.cancel()
    }
  }, [])

  const onToggleUserGuide = rows => {
    setEnableOnlineTutorial(!enableOnlineTutorials)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Assets Management" breadcrumbItem="Assets" />
          {loading ? (
                  <Spinner />
                ) : (
          <div>
            {/* <Row>
              <div
                className="form-check form-switch user-guide-color-style "
                // style={{ flex: 1, marginLeft: "3%", float: "left" }}
              >
                <label
                  className="form-check-label user-guide-color-style col-md-12  "
                  color="primary"
                  htmlFor="userGuideCustomSwitch"
                >
                  User Guide
                </label>
                <input
                  type="checkbox"
                  className="form-check-input user-guide-color-style col-md-12  "
                  id="userGuideCustomSwitch"
                  // checked={goodToggle}
                  onChange={e => {
                    onToggleUserGuide(e, "good")
                  }}
                />
              </div>
            </Row> */}
            <Row>
              {userMetadata.role.startsWith("Asset Owner") ? (
                <div className="col-md-12 text-end">
                  <Button
                    className="mb-2 me-2  add-assets"
                    color="primary"
                    onClick={toggleFormModal}
                    disabled={!isDisabled("Add")}
                    id={"add-assets"}
                  >
                    Add
                  </Button>

                  <Button
                    className="mb-2 me-2 edit-assets"
                    color="primary"
                    onClick={toggleFormModal}
                    disabled={isDisabled("Edit")}
                  >
                    Edit
                  </Button>

                  <Button
                    id="UncontrolledPopover2"
                    className="mb-2 me-2"
                    color="warning"
                    onClick={toggle}
                    disabled={isDisabled("Delete")}
                  >
                    Delete
                  </Button>
                  <AssetPageTabComponent
                    updateRecords={updateRecords}
                    action={selectRow.length === 0 ? "Add" : "Edit"}
                    modalheader={
                      selectRow.length === 0 ? "Add Asset" : "Edit Asset"
                    }
                    // columns={totalColumns}
                    totalFieldsForModal={totalFields}
                    selectedRow={selectRow}
                    open={formModal}
                    toggle={toggleFormModal}
                    type={"formComponent"}
                    userMetadata={userMetadata}
                    activeTabValue={"1"}
                    closeReportTab={closeReportTab}
                  />
                </div>
              ) : (
                ""
              )}
            </Row>
            <ConfirmationModal
              action="Delete"
              updateRecords={updateRecords}
              selectedRow={selectRow}
              header={"Delete Asset"}
              open={modal}
              toggle={toggle}
            />
            <Row>
              <div className="col-md-12 text-start">
                {assetCount} Asset(s) found
              </div>
            </Row>
            <Row>
              <Col>
                
                  <TableWithRowExpand
                    mainRowFields={mainRowFields}
                    expandableRowFields={expandableRowFields}
                    records={records.list}
                    multipleRows={multipleRows}
                    selectedRow={selectedRow}
                    userData={userMetadata}
                    getNextAssetData={getNextAssetData}
                    getPreviousAssetData={getPreviousAssetData}
                    firstKey={firstKey}
                    lastEvaluatedKey={lastEvaluatedKey}
                    onFilter={onFilter}
                    onSearchKeyChange={onSearchKeyChange}
                    isGood={goodToggle}
                    isWarning={warningToggle}
                    isDanger={dangerToggle}
                    count={count}
                    searchKey={searchKey}
                    totalFieldsForModal={totalFields}
                  ></TableWithRowExpand>
                
              </Col>
            </Row>
          </div>
          )}
        </div>
        
      </div>

      {enableOnlineTutorials ? (
        <Joyride
          run={true}
          callback={() => null}
          showSkipButton={true}
          continuous={true}
          steps={[
            {
              content: "Click add button to add new assets",
              target: ".add-assets",
            },
            {
              content: "Click edit button to edit  already existing assets",
              target: ".edit-assets",
            },
          ]}
        />
      ) : (
        ""
      )}
    </React.Fragment>
  )
}

export default Assets
