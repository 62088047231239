import React, { useReducer } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { dataReducer } from "../reducer/reducers";

export const ConfirmationModal = (props) => {
  const { updateRecords, header, open, toggle, selectedRow } = props;
  const [data, dispatchData] = useReducer(dataReducer, []);

  /** 
   * submitData method is called when ok button is clicked in modal.
   * @param {object} e -  click event
  */
  const submitData = (e) => {
    e.preventDefault();
    dispatchData({ type: "setRecords", data: selectedRow });
    data["mode"] = "Delete";
    updateRecords(data);
    toggle();
  };

  return (
    <React.Fragment>
      <Modal isOpen={open}>
        <ModalHeader toggle={toggle}>{header}</ModalHeader>
        <ModalBody>
          <p>Are you sure you want to delete?</p>
        </ModalBody>
        <ModalFooter>
          <div className="form-group text-right">
            <button
              className="btn btn-danger m-2"
              type="submit"
              onClick={submitData}
            >
              Delete
            </button>
            <button className="btn btn-primary" onClick={toggle}>
              Cancel
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};
