import React, { useState, useReducer, useEffect } from "react"
import {
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  Row,
  Card,
  CardBody,
  CardText,
  Col,
  TabContent,
  TabPane,
} from "reactstrap"
import axios from "axios"
import { useAuth0 } from "@auth0/auth0-react"
import classnames from "classnames"

//reducer
import { dataReducer } from "../reducer/reducers"

//components
import { AssetDetails } from "./AssetDetails"
import ReportComponent from "./ReportComponent"

//css style files
import "../css/modal-style.css"
import "../css/modal-style.css"
import "react-dropdown/style.css"

//service files
import { fetchUploadedReportNames } from "../../../services/assetService"

export const AssetPageTabComponent = props => {
  const {
    updateRecords,
    action,
    // columns,
    totalFieldsForModal,
    selectedRow,
    open,
    toggle,
    modalheader,
    userMetadata,
    activeTabValue,
    closeReportTab,
  } = props
  const apiurl = process.env.REACT_APP_AUTH0_API_URL
  const { getAccessTokenSilently, getAccessTokenWithPopup, user } = useAuth0()
  const [data, dispatchData] = useReducer(dataReducer, [])
  const [activeTab, setActiveTab] = useState(activeTabValue)
  const [files, setFiles] = useState([]) 

  /**
   * getAccessToken method is used to fecth jwt access token from auth0
   * @param {string} audience - the api URL
   * @param {string} claim - the permission
   */
  const getAccessToken = async (audience, claim) => {
    let token
    try {
      token = await getAccessTokenSilently({
        audience,
        claim,
      })
    } catch (exception) {
      token = await getAccessTokenWithPopup({ audience, claim })
    }
    return token
  }

/**
 * toggleTab function is called when a tab is selected
 * @param {number} tab 
 */
  const toggleTab = async (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
    if(tab === "2"){
      const jwtToken = await getAccessToken(apiurl, "add:asset")
      await fetchUploadedReportNames(jwtToken, selectedRow.assetNumber, selectedRow.pk).then(result => {
        setFiles(result.data)
       }).catch(error =>{

       })

    }
  }

 
  useEffect(() => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    dispatchData({
      type: "setDataToModalonChange",
      data: { id: "status", value: "Active" },
    })
    dispatchData({
      type: "setDataToModalonChange",
      data: { id: "serviceType", value: "Calibration" },
    })
    /**
     * getInitialData method is used to fetch all the records from db and display in table.
     */
    const getInitialData = async () => {
      try {

      } catch (error) {
        if (axios.isCancel(error)) {
        } else {
          throw error
        }
      }
    }
    getInitialData()
    return () => {
      source.cancel()
    }
  }, [])

  return (
    <React.Fragment>
      <Modal size="xl" isOpen={open}>
        <ModalHeader toggle={toggle}>{modalheader}</ModalHeader>
        <ModalBody>
          <Row>
            <Card size="xl">
              <CardBody size="xl">
                { 
                action === 'Add' ?
                <Nav tabs className="nav-tabs-custom nav-justified">
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "1",
                      })}
                      onClick={() => {
                        toggleTab("1")
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="fas fa-home"></i>
                      </span>
                      <span className="d-none d-sm-block">Asset Details</span>
                    </NavLink>
                  </NavItem>                 
                </Nav>
                :
                <Nav tabs className="nav-tabs-custom nav-justified">
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "1",
                      })}
                      onClick={() => {
                        toggleTab("1")
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="fas fa-home"></i>
                      </span>
                      <span className="d-none d-sm-block">Asset Details</span>
                    </NavLink>
                  </NavItem>
         
                  
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "2",
                      })}
                      onClick={() => {
                        toggleTab("2")
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="far fa-user"></i>
                      </span>
                      <span className="d-none d-sm-block">Report</span>
                    </NavLink>
                  </NavItem>
                 
                </Nav>
              }
                {/* <Nav tabs className="nav-tabs-custom nav-justified">
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "1",
                      })}
                      onClick={() => {
                        toggleTab("1")
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="fas fa-home"></i>
                      </span>
                      <span className="d-none d-sm-block">Asset Details</span>
                    </NavLink>
                  </NavItem>
         
                  
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "2",
                      })}
                      onClick={() => {
                        toggleTab("2")
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="far fa-user"></i>
                      </span>
                      <span className="d-none d-sm-block">Report</span>
                    </NavLink>
                  </NavItem>
                 
                </Nav> */}

                <TabContent
                  size="xl"
                  activeTab={activeTab}
                  className="p-3 text-muted"
                >
                  <TabPane size="xl" tabId="1">
                    <Row>
                      <Col sm="12">
                        <CardText className="mb-0">
                          <AssetDetails
                            updateRecords={updateRecords}
                            action={action}
                            modalheader={modalheader}
                            // columns={columns}
                            totalFieldsForModal={totalFieldsForModal}
                            selectedRow={selectedRow}
                            type={"formComponent"}
                            userMetadata={userMetadata}
                            toggle={toggle}
                          ></AssetDetails>
                        </CardText>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      <Col sm="12">
                        <CardText className="mb-0">
                          <ReportComponent selectedRow={selectedRow} files={files} toggle={toggle} closeReportTab={closeReportTab}></ReportComponent>
                        </CardText>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
            {/* </Col> */}
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}
