import { useEffect, useState } from "react"
import {
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import { useAuth0 } from "@auth0/auth0-react"

//service methods
import { getFeaturesForProduct,} from "services/paymentService"

const featuresAvailable = [
  "ASSET",
  "PREFERENCE",
  "EMAIL ALERT",
  "DEFAULT FIELDS",
  "CUSTOM FIELDS",
]

export const AddFeatureModal = props => {
  const { header, open, toggle, productId, productName, updateFeatures } = props
  const { getAccessTokenSilently, getAccessTokenWithPopup, user } = useAuth0()
  const apiurl = process.env.REACT_APP_AUTH0_API_URL
  const [addedFeature, setAddedFeature] = useState(featuresAvailable)

  /* getAccessToken method is used to fecth jwt access token from auth0
   * @param {string} audience - the api URL
   * @param {string} claim - the permission
   */
  const getAccessToken = async (audience, claim) => {
    let token
    try {
      token = await getAccessTokenSilently({
        audience,
        claim,
      })
    } catch (exception) {
      token = await getAccessTokenWithPopup({ audience, claim })
    }
    return token
  }

  /**
   * onUpdate method will be called when the Update button in the modal is clicked
   */
  const onUpdate = async () => {
    updateFeatures(addedFeature)
    toggle()
  }

  /**
   * onFeatureAdd method will be called when the features are selected  in the add features modal
   * @param {event} e 
   * @param {string} feature 
   */
  const onFeatureAdd = async (e, feature) => {
    if (e.target.checked) {
      addedFeature.push(feature)
    } else {
      const index = addedFeature.indexOf(feature)
      addedFeature.splice(index, 1)
    }
  }

  const getInitialData = async () => {
    const jwtToken = await getAccessToken(apiurl, "edit:assets")
    await getFeaturesForProduct(jwtToken, productId, productName)
      .then(result => {
        console.log(result)
        setAddedFeature(result.data.attributes.availableFeatures)
      })
      .catch(error => {})
  }

  useEffect(() => {
    getInitialData()
  }, [])

  return (
    <>
      <Modal isOpen={open}>
        <ModalHeader toggle={toggle}>{header}</ModalHeader>
        <ModalBody>
          {featuresAvailable.map((feature, index) => {
            return (
              <div className={"form-group"} style={{ marginTop: "1%" }}>
                <Input
                  type="checkbox"
                  id={"isMandatory"}
                  style={{ margin: "1%" }}
                  defaultChecked={addedFeature.includes(feature)}
                  onChange={e => onFeatureAdd(e, feature)}
                />
                <label id={"isMandatory"}>{feature}</label>
              </div>
            )
          })}
          <div className="text-end">
            <Button
              color="primary"
              className="btn btn-primary mt-2"
              onClick={onUpdate}
            >
              Update
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}