import PropTypes from "prop-types"
import React, { Suspense } from "react"
import { Container, } from "reactstrap"
import { useAuth0 } from "@auth0/auth0-react"
import Joyride from "react-joyride"

// Pages Components
const AdminDashboard = React.lazy(() => import("./AdminDashboard"));
const UserDashboard = React.lazy(() => import("./UserDashboard"));

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb1"

//i18n
import { withTranslation } from "react-i18next"
import { user_metadata } from "../../nameSpaceConfig"

const Dashboard = props => {
  const { user } = useAuth0()
  const userMetadata = user[user_metadata]
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs breadcrumbItem={props.t("Dashboard")} />
          <Suspense fallback={<div>Loading...</div>}>
          {
            userMetadata.role === "Admin" ?
            <AdminDashboard />
            :
            <UserDashboard></UserDashboard>
          }
          </Suspense>
        </Container>
      </div>
      <Joyride
        run={true}
        callback={() => null}
        showSkipButton={true}
        continuous={true}
        steps={[
          {
            content:
              "Double click or press enter on this cell to open the DropDownEditor",
            target: ".assets-health",
          },
          {
            content:
              "Double click or press enter on this cell to open the DropDownEditor",
            target: ".last-tranaction",
          },
        ]}
      />
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Dashboard)
