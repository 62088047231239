import React, { useReducer, useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
} from "reactstrap";
import { useToasts } from "react-toast-notifications";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Spinner from "../../components/Common/Spinner";
//Import Table
import { TableForAsset } from "./Components/TableForAsset";
//Import columns
import { userColumns } from "./data/user-fields";
//Import Modal
import { UserModal } from "./Components/UserModal";
import { ConfirmationModal } from "./Components/ConfirmationModal";
//reducers
import { recordsReducer, multiplerecordsreducer } from "./reducer/reducers";
//Service
import {
  addUserData,
  getUsers,
  getUsersById, getRoleDataForUserCreation
} from "../../services/userService";
import { decryptKeyValues } from "../../services/commonService"
import { user_metadata } from "../../nameSpaceConfig";

const Users = (props) => {
  const { addToast } = useToasts();
  const apiurl = process.env.REACT_APP_AUTH0_API_URL
  const { getAccessTokenSilently, getAccessTokenWithPopup, user } = useAuth0();
  const [formModal, setFormModal] = useState(false);
  const userMetadata = user[user_metadata];
  const [modal, setModal] = useState(false);
  const [assetCount, setAssetCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [records, dispatchRecords] = useReducer(recordsReducer, { list: [] });
  const [selectRow, setSelectRow] = useState([]);
  const [
    multipleRecords,
    dispatchMultipleRecords,
  ] = useReducer(multiplerecordsreducer, { list: [] });

  /**
   * getAccessToken method is used to fecth jwt access token from auth0
   * @param {string} audience - the api URL
   * @param {string} claim - the permission
   */
  const getAccessToken = async (audience, claim) => {
    let token;
    try {
      token = await getAccessTokenSilently({
        audience,
        claim,
      });
    } catch (exception) {
      token = await getAccessTokenWithPopup({ audience, claim });
    }
    return token;
  };

  /**
   * To toggle modal for Delete options
   */
  const toggle = () => {
    if(userMetadata.role == "Service Provider Default User" || userMetadata.role == "Asset Owner Default User"){
      createToaster("Cannot be deleted.","error" )
    }else{
      setModal(!modal);
    }
  };

  /**
   * To toggle modal for Add/ Edit options
   */
   const toggleFormModal = async (event, mode) => {
    if(mode === "Add"){
      const jwtToken = await getAccessToken(apiurl, "read:user");
      const deData = decryptKeyValues(userMetadata.pk)
      let noOfUserAllowed = 0
      let userType = "ASSETOWNER"
      if (userMetadata.role.startsWith("Service Provider")) {
        userType = "SERVICEPROVIDER"
      }
      if (userMetadata.role.startsWith("Asset Owner")) {
        userType = "ASSETOWNER"
      }
      await getRoleDataForUserCreation( jwtToken, deData, userType)
      .then((result) => {
        noOfUserAllowed = result[0].attributes.noOfUsersAllowed
        // data = result;
        // setLoading(false);
        // createToaster("Data has been loaded successfully","info")
      })
      .catch((error) => {
        // createToaster(error.response.data,"error")
        // setLoading(false);
      });
      if(noOfUserAllowed <= 0){
        createToaster("Please purchase a new user to proceed.","error" )
      }else{
        setFormModal(!formModal);
      }
    }else{
      setFormModal(!formModal);
    }
  };

  /**
   * selectedRow method is used to set the values of selected row to selectRow.
   * @param {object} row -  selected row data
   * @param {boolean} isSelected -  isSelected flag
   */
  const selectedRow = (row, isSelected) => {
    if (isSelected) {
      setSelectRow(row);
    } else {
      setSelectRow([]);
    }
  };

  /**
   * multipleRows method is used when all rows are selected.
   * @param {list} rows - list of selected rows
   */
  const multipleRows = (rows) => {
    rows.map((row) => {
      selectedRow(row, row.isSelected);
    });
    dispatchMultipleRecords(rows);
  };

  /**
   * to display toaster
   * @param {string} message 
   * @param {string} type 
   */
   const createToaster = (message, type) => {
    addToast(message, {
      appearance: type,
      autoDismiss: true,
    })
  }

  /**
   * updateCSV method is used to add the data from CSV to table.
   * @param {object} csvdata - data to be imported.
   */
  const updateCsv = (csvdata) => {
    dispatchRecords({ type: "setCSVRecords", payload: csvdata });
  };

  /**
   * isDisabled method is used to check the mode and to disable/ enable add, edit and delete button
   * @param {string} action - current mode.
   */
  const isDisabled = (action) => {
    let disabled = true;
    let selectedRowCount = 0;
    records.list.map((record) => {
      if (record.isSelected) {
        disabled = false;
        selectedRowCount += 1;
      }
    });
    if (action === "Edit") {
      if (selectedRowCount === 1) {
        return false;
      } else {
        return true;
      }
    }
    return disabled;
  };

  /**
   * updateRecords method is used to update/add records in db.
   * @param {object} record - data to be updated
   */
  const updateRecords = async (record) => {
    setLoading(true);
    try {
      if (record.mode === "Delete") {
        let dataToBeDeleted = [];
        records.list.map(record =>{
          if(record.isSelected){
            const userData = {};
            userData.id = record.id;
            userData.pk = record.pk;
            userData.email = record.emailAddress
            userData.version = record.version
            userData.userType = record.userType
            dataToBeDeleted.push(userData);
          }
        });
        const jwtToken = await getAccessToken(apiurl, "read:user");
        await addUserData(dataToBeDeleted, record.mode, jwtToken)
          .then((result) => {
            setLoading(false);
            dispatchRecords({ type: "Delete", payload: selectRow });
            createToaster('Data deleted successfully',"info" )
            setAssetCount(assetCount - dataToBeDeleted.length)
          })
          .catch((error) => {
            createToaster(error.response.data,"error" )
            setLoading(false);
          });
          // setSelectRow([]);

      } else {
        if (userMetadata.role.startsWith("Service Provider")) {
          record.sk = decryptKeyValues(userMetadata.pk)
        }
        if (userMetadata.role.startsWith("Asset Owner")) {
          record.sk = decryptKeyValues(userMetadata.sk)
        }
        const jwtToken = await getAccessToken(apiurl, "read:user");
        await addUserData(record, record.mode, jwtToken)
          .then((result) => {
            setLoading(false);
            record.isSelected=false;
            dispatchRecords({ type: record.mode, payload: { data: result.data, page : record.mode} });
            let message = 'Data added successfully'
            if(record.mode==='Edit'){
              message = 'Data has been edited successfully'
            } else{
              setAssetCount(records.list.length + 1)
            }
            createToaster(message,"info" )
          })
          .catch((error) => {
            createToaster(error.response.data,"error" )
            setLoading(false);
          });
          setSelectRow([]);
      }
    } catch (expection) {
      createToaster("Error occurred while performing this operation.","error" )
    }
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    let data;
    let deData;
    /**
     * getInitialData method is used to fetch all the records from db and display in table.
     */
    const getInitialData = async () => {
      try {
        setLoading(true);
        const jwtToken = await getAccessToken(apiurl, "read:user");
        if (userMetadata.role === "Admin") {
          await getUsers(source.token, jwtToken)
            .then((result) => {
              data = result;
              setLoading(false);
              // createToaster("Data has been loaded successfully","info" )
            })
            .catch((error) => {
              createToaster(error.response.data,"error")
              setLoading(false);
            });
          if (data !== undefined) {
            dispatchRecords({ type: "setRecords", payload: data });
            setAssetCount(data.length);
          }
        } else if (
          userMetadata !== undefined &&
          userMetadata.pk !== undefined
        ) {
          const assetOwnerSortKey = decryptKeyValues(userMetadata.sk)
          deData = decryptKeyValues(userMetadata.pk)
          await getUsersById(source.token, jwtToken, deData, userMetadata.role, assetOwnerSortKey)
            .then((result) => {
              data = result;
              setLoading(false);
              // createToaster("Data has been loaded successfully","info")
            })
            .catch((error) => {
              createToaster(error.response.data,"error")
              setLoading(false);
            });
          if (data !== undefined) {
            dispatchRecords({ type: "setRecords", payload: data });
            setAssetCount(data.length);
          }
        } else {
          createToaster("Unauthorised user","error")
          setLoading(false);
        }
      } catch (error) {
        if (axios.isCancel(error)) {
        } else {
          throw error;
        }
      }
    };
    getInitialData();

    return () => {
      source.cancel();
    };
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Asset Management" breadcrumbItem="Users" />
          <Row>
            {
              userMetadata.role == "Asset Owner Default User" || userMetadata.role == "Service Provider Default User" || userMetadata.role == "Admin" ?
              <div className="col-md-12 text-end">
              <Button
                className="mb-2 me-2"
                color="primary"
                onClick={e => toggleFormModal(e, "Add")}
                disabled={!isDisabled("Add")}
              >
                Add
              </Button>

              <Button
                className="mb-2 me-2"
                color="primary"
                onClick={toggleFormModal}
                disabled={isDisabled("Edit")}
              >
                Edit
              </Button>
              <UserModal
                updateRecords={updateRecords}
                action={selectRow.length === 0 ? "Add" : "Edit"}
                modalheader={selectRow.length === 0 ? "Add User" : "Edit User"}
                columns={userColumns}
                selectedRow={selectRow}
                open={formModal}
                toggle={toggleFormModal}
                type={"formComponent"}
                userMetadata={userMetadata}
              />

              <Button
                id="UncontrolledPopover2"
                className="me-2 mb-2"
                color="warning"
                onClick={toggle}
                disabled={isDisabled("Delete")}
              >
                Delete
              </Button>
            </div>
            : ''
            }
            
          </Row>
          <ConfirmationModal
            action="Delete"
            updateRecords={updateRecords}
            selectedRow={selectRow}
            header={"Delete User"}
            open={modal}
            toggle={toggle}
          />
          <Row>
            <div className="col-md-12 text-start">{assetCount} User(s) found</div>
          </Row>
          <Row>
            <Col>
              {loading ? (
                <Spinner />
              ) : (
                <TableForAsset
                  columnsCustomer={userColumns}
                  records={records.list}
                  multipleRows={multipleRows}
                  selectedRow={selectedRow}
                  updateCsv={updateCsv}
                />
              )}
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Users;
