import React, { useRef, useState, useEffect } from "react"
import { Table, Row, Input } from "reactstrap"
import { useToasts } from "react-toast-notifications"
import S3FileUpload from "react-s3"
import AWS from "aws-sdk"
import { useAuth0 } from "@auth0/auth0-react"
import { ArrowDownSquareFill, TrashFill } from "react-bootstrap-icons"

import Spinner from "../../../components/Common/Spinner"
//service
import {
  fetchUploadedReportNames,
  deleteReportFile,
  updateAssetsData,
} from "../../../services/assetService"

const ReportComponent = props => {
  const apiurl = process.env.REACT_APP_AUTH0_API_URL
  const fileInput = useRef()
  const { selectedRow, files, toggle, closeReportTab } = props
  const [reportNumber, setReportNumber] = useState("")
  const [fileList, setFileList] = useState(files)
  const [loading, setLoading] = useState(false)
  const { getAccessTokenSilently, getAccessTokenWithPopup, user } = useAuth0()
  const { addToast } = useToasts()
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  /**
   * getAccessToken method is used to fecth jwt access token from auth0
   * @param {string} audience - the api URL
   * @param {string} claim - the permission
   */
  const getAccessToken = async (audience, claim) => {
    let token
    try {
      token = await getAccessTokenSilently({
        audience,
        claim,
      })
    } catch (exception) {
      token = await getAccessTokenWithPopup({ audience, claim })
    }
    return token
  }

  /**
   * to display toaster
   * @param {string} message 
   * @param {string} type 
   */
   const createToaster = (message, type) => {
    addToast(message, {
      appearance: type,
      autoDismiss: true,
    })
  }

  /**
   * onChange method is called when the report number value is changed
   * @param {event} e 
   */
  const onChange = e => {
    setReportNumber(e.target.value)
  }


  /**
   * cancelData function will be called when the 
   * @param {event} e 
   */
  const cancelData = e => {
    e.preventDefault()
    toggle()
  }


  /**
   * downloadFile function is sued to download the report file
   * @param {event} e 
   * @param {string} reportNumber 
   */
  const downloadFile = async (e, reportNumber) => {
    const fileName = reportNumber.replaceAll("/", "*-*")
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_ACCESS_ID,
      secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
    })
    const params = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key:
      process.env.REACT_APP_DIR_NAME + "/" + selectedRow.pk + "/" +selectedRow['Asset#'] + "/" + fileName,
    }
    const s3 = new AWS.S3()
    await s3.getObject(params, (err, data) => {
      let blob = new Blob([data.Body], { type: data.ContentType })
      let link = document.createElement("a")
      link.href = window.URL.createObjectURL(blob)
      link.download = fileName
      link.click()
      // console.log(data)
    })
  }

  /**
   * convertFileSizeKbToMb functiomn is used to convert file size from Kb to Mb
   * @param {number} fileSize 
   * @returns number
   */
  const convertFileSizeKbToMb = (fileSize) =>{
    const fileSizeInString = fileSize.toString()
    return (parseInt(fileSizeInString.replaceAll(" KB", "")) / (1024 * 1024).toFixed(2));
  }

  /**
   * changeFileName method is used to change the file name
   * @param {array} list 
   */
  const changeFileName =(list)=>{
    list.map(file =>{
      const newName = file.name.replaceAll("*-*", "/")
      file.name = newName
      file.size = convertFileSizeKbToMb(file.size) + " MB"
    })
  }

  /**
   * removeFile function is used to delete the file
   * @param {event} e 
   * @param {string} fileName 
   */
  const removeFile = async (e, fileName) => {
    const changedFileName = fileName.replaceAll("/", "*-*")
    const jwtToken = await getAccessToken(apiurl, "add:asset")
    // await deleteReportFile(jwtToken, selectedRow.assetNumber, changedFileName, selectedRow.pk).then(
      await deleteReportFile(jwtToken, selectedRow['Asset#'], changedFileName, selectedRow.pk).then(
      result => {
        createToaster("File deleted successfully","info" )
      }
    )
    let reportFiles = {}
    // await fetchUploadedReportNames(jwtToken, selectedRow.assetNumber, selectedRow.pk)

    await fetchUploadedReportNames(jwtToken, selectedRow['Asset#'], selectedRow.pk)    .then(result => {
        setLoading(true)
        changeFileName(result.data)
        setFileList(result.data)
        reportFiles = result.data
        setLoading(false)
      })
      .catch(error => {
      })
    let record = selectedRow
    if(reportFiles.length > 0){
      record.reportNumber = reportFiles[reportFiles.length - 1].name.replaceAll("*-*", "/")
    }else{
      record.reportNumber = ""
    }
    record.isReportTabChanged = true
    record.isReportAdded = false
    await updateAssetsData(record, selectedRow, jwtToken)
      .then(result => {})
      .catch(error => {})
  }

  /**
   * uploadFileInS3Folder function is used to upload the file in s3 folder
   * @param {file} file 
   * @param {object} config 
   */
  const uploadFileInS3Folder = async (file, config) => {
    await S3FileUpload.uploadFile(file, config)
      .then(result => {
        createToaster("Your file has been uploaded successfully","info" )
      })
      .catch(error => {
        createToaster("Couldnt upload file. Please try again","error" )
      })
  }

  /**
   * fileUpload function is called when a new file is uploaded
   * @param {event} event 
   */
  const fileUpload = async event => {
    setLoading(true)
    let file = fileInput.current.files[0]
    if (file !== undefined) {
      if(convertFileSizeKbToMb(file.size) > 2){
        setError(true)
        setErrorMessage("This file cannot be uploaded. Default size is 2MB")
        createToaster("This file cannot be uploaded. Default size is 2MB","error" )
      }else{
        setError(false)
        if (
          selectedRow['Asset#'] === undefined ||
          selectedRow['Asset#'] === null
        ) {
        } else {
          setError(false)
          const fileNameArray = file.name.split(".")
          const fileType = fileNameArray[fileNameArray.length - 1]
          if (fileNameArray[fileNameArray.length - 1] === "pdf") {
            const reportName = reportNumber.replaceAll("/", "*-*")
            const fileName = reportName + "." + fileType
            Object.defineProperty(file, "name", { value: fileName })
            const config = {
              bucketName: process.env.REACT_APP_BUCKET_NAME,
              // dirName: process.env.REACT_APP_DIR_NAME + "/"  + selectedRow.pk+ "/" + selectedRow.assetNumber,
              dirName: process.env.REACT_APP_DIR_NAME + "/"  + selectedRow.pk+ "/" + selectedRow['Asset#'],
              region: process.env.REACT_APP_REGION,
              accessKeyId: process.env.REACT_APP_ACCESS_ID,
              secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
            }
            await uploadFileInS3Folder(file, config)
          } else {
            createToaster("Please select version as PDF to proceed.","error" )
            setError(true)
            setErrorMessage("Please select a PDF." )
          }
        }
      }
     
      setLoading(true)
      const jwtToken = await getAccessToken(apiurl, "add:asset")
      // await fetchUploadedReportNames(jwtToken, selectedRow.assetNumber, selectedRow.pk)
      await fetchUploadedReportNames(jwtToken, selectedRow['Asset#'], selectedRow.pk)
        .then(result => {
          changeFileName(result.data)
          setFileList(result.data)
          setLoading(false)
        })
        .catch(error => {
        })
      let record = selectedRow
      record.reportNumber = reportNumber
      record.assetOwnerId = selectedRow.pk
      record.pk = selectedRow.pk
      record.isReportTabChanged = true
      record.isReportAdded = true
      await updateAssetsData(record, selectedRow, jwtToken)
        .then(result => {})
        .catch(error => {})
    }
    setReportNumber("")
    setLoading(false)
  }

  useEffect(() => {
    const getInitialData = async () => {
      try {
        const jwtToken = await getAccessToken(apiurl, "add:asset")
        // if (
        //   selectedRow.assetNumber !== undefined &&
        //   selectedRow.assetNumber !== null
        // ) {
          if (
            selectedRow['Asset#'] !== undefined &&
            selectedRow['Asset#'] !== null
          ) {
          await fetchUploadedReportNames(
            jwtToken,
            // selectedRow.assetNumber,selectedRow.pk
            selectedRow['Asset#'],selectedRow.pk
          )
            .then(result => {
              changeFileName(result.data)
              setFileList(result.data)
            })
            .catch(error => {})

        }
      } catch (error) {
        throw error
      }
    }
    getInitialData()
  }, [])
  return (
    <React.Fragment>
      {loading ? (
        <Spinner />
      ) : (
        <div>
          <form className="pl-3 pr-3 ">
            <Row>
            {
                  error ? <div  color="red" className="error" style={{ flex: 1, marginLeft: "5%", marginBottom: "2%", float: "left" }}>
                    <span color="red"> {errorMessage} </span>
                  </div> : ''
                }
            </Row>
            <Row>
            
              <div
                className={" form-group"}
                style={{ flex: 1, marginLeft: "5%", float: "left" }}
              >
                <label>Report Number</label>
                <Input
                  id={"reportNumber"}
                  name={"reportNumber"}
                  className="form-control"
                  defaultValue={reportNumber}
                  value={reportNumber}
                  onChange={onChange}
                  maxlength={15}
                  data-error="This is a required field."
                ></Input>
              </div>
              <div
                className={" form-group"}
                style={{ flex: 1, marginLeft: "5%", float: "left" }}
              >
                <label>Select a File</label>
                {/* <label> */}
                  <input
                    type="file"
                    className="form-control"
                    ref={fileInput}
                    disabled={reportNumber.trim() === "" ? true : false}
                    onChange={fileUpload}
                  />
                {/* </label> */}
              </div>
            </Row>
          </form>
          <hr className="mt-2" style={{ flex: 1, marginLeft: "5%"}}/>
              
          <Row>
            <div className="">
              <div className="d-flex flex-wrap" style={{ flex: 1, marginLeft: "5%", marginTop: "5%" }}>
                <h5 className="font-size-16 me-3">Recent Files</h5>
              </div>
              
              <div className="table-responsive file-manager" style={{ flex: 1, marginLeft: "5%"}}>
                <Table className="table align-middle table-nowrap table-hover mb-0">
                  <thead>
                    <tr>
                      <th scope="col">Report Number</th>
                      <th scope="col">Date modified</th>
                      <th scope="col" colSpan="2">
                        Size
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {fileList.map((recentfile, key) => (
                      <tr key={key}>
                        <td>{recentfile.name}</td>
                        <td>{recentfile.lastModified}</td>
                        <td>{recentfile.size} </td>
                        <td>
                          <ArrowDownSquareFill
                            className="mb-2 mr-2"
                            color="primary"
                            onClick={e => downloadFile(e, recentfile.name)}
                          ></ArrowDownSquareFill>
                        </td>
                        <td>
                          <TrashFill
                            className="mb-2 mr-2"
                            color="danger"
                            onClick={e => removeFile(e, recentfile.name)}
                          ></TrashFill>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <div className="text-end text-bottom" style={{  marginTop: "1%" }}>
                <button className="btn btn-primary m-2" onClick={cancelData}>
            Save
          </button>
                
                <button className="btn btn-primary" onClick={cancelData}>
            Close
          </button>
          </div>
              </div>
            </div>
          </Row>
        </div>
      )}
    </React.Fragment>
  )
}

export default ReportComponent
