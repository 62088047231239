import axios from "axios"
import { toast } from "react-toastify"

axios.defaults.baseURL = process.env.REACT_APP_ASSET_SERVICE_URL
axios.interceptors.response.use(
  res => {
    toast.success(res.data)
    return res
  },
  error => {
    const expectedError =
      error.response &&
      error.response.status >= 400 &&
      error.response.status <= 500

    if (!expectedError) {
      //logger.log(error);
      toast.error("Sorry unexpected error occured")
    }

    if (expectedError) toast.error(error.response.data)

    return Promise.reject(error)
  }
)

/**
 * setJwt method is used to set jwtToken fetched from asset to the http headers.
 * @param {string} token
 */
function setJwt(token) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setJwt,
}
