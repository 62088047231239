import React, { useReducer, useState, useEffect } from "react";
import {  Input, Label, InputGroup } from "reactstrap";
import axios from "axios"
import { useAuth0 } from "@auth0/auth0-react"
import { useToasts } from "react-toast-notifications"

import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
//reducer files
import { dataReducer } from "../reducer/reducers";
import { convertDateFormat } from "../reducer/assetReducer";
//Service files
import { decryptKeyValues } from "../../../services/commonService"
import { getServiceProviderNames } from "../../../services/serviceProvider"
import { getAssetOwnerNames, getAssetOwner } from "../../../services/assetownerService"
import { getUserNamesById, getOwnerNameAndId } from "../../../services/userService"


export const DynamicFieldsModal = (props) => {
  const apiurl = process.env.REACT_APP_AUTH0_API_URL
  const { getAccessTokenSilently, getAccessTokenWithPopup, user } = useAuth0()
  const { addToast } = useToasts()
  const { totalFieldsForModal, userMetadata, toggle, updateRecords, action, selectedRow } = props;
  const [data, dispatchData] = useReducer(dataReducer, []);
  const [assetOwnerData, setAssetOwners] = useState([])
  const [serviceProviderData, setServiceProviders] = useState([])
  const [ownerData, setOwnerData] = useState([])
  const [spKeyValueMap, setSpKeyValueMap] = useState({})
  const [aoKeyValueMap, setAoKeyValueMap] = useState({})
  const [spName, setSpNameMap] = useState({})
  const [aoName, setAoNameMap] = useState({})
  const [aoAddress, setAoAddressMap] = useState({})
  const [spAddress, setSpAddressMap] = useState({})
  const [ownerIdMap, setOwnerIdMap] = useState({})
  const [servicedDate, setServicedDate] = useState(new Date())
  const [dueDate, setDueDate] = useState(
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() + 1
    )
  )
  const [count, setCount] = useState(0)
  const [dateFields, setDateFields] = useState({})
  const [disableSaveButton, setDisableSaveButton] = useState(true)

  /**
     * getAccessToken method is used to fecth jwt access token from auth0
     * @param {string} audience - the api URL
     * @param {string} claim - the permission
     */
  const getAccessToken = async (audience, claim) => {
    let token
    try {
      token = await getAccessTokenSilently({
        audience,
        claim,
      })
    } catch (exception) {
      token = await getAccessTokenWithPopup({ audience, claim })
    }
    return token
  }

  /**
     * to display toaster
     * @param {string} message
     * @param {string} type
     */
  const createToaster = (message, type) => {
    addToast(message, {
      appearance: type,
      autoDismiss: true,
    })
  }

  const onChange = e => {
    e.preventDefault()
    setDataForEdit()
    setDisableSaveButton(false)
    dispatchData({
      type: "setDataToModalonChange",
      data: { id: e.target.id, value: e.target.value },
    })


  }

  /**
   * setDataForEdit method is used to populate the modal with selected row values
   */
   const setDataForEdit = () => {
    if (action === "Edit" && count === 0) {
      dispatchData({ type: "setRecords", data: selectedRow })
      setCount(count + 1)
    }
  }

  const dropDownChange = async (e, dataField) => {
    setDataForEdit()
    dispatchData({
      type: "setDataToModalonChange",
      data: { id: dataField, value: e.value },
    })
    if(dataField === 'Owner'){
      console.log(ownerData)
    }
  }

  /**
   * getKey method is used to get the key value from map
   * @param {string} val
   * @param {map} map
   * @returns string
   */
   const getKey = (val, map) => {
    return Object.keys(map).find(key => map[key] === val)
  }

  /**
   * accountChange function is called when service provider and asset owner component value is changed
   * @param {event} e
   * @param {string} dataField
   */
   const accountChange = (e, dataField) => {
    setDataForEdit()
    if (dataField === "Owner") {
      const ownerId = getKey(e.target.value, ownerIdMap)
      if(ownerId !== undefined && ownerId !== null){
        dispatchData({
          type: "setDataToModalonChange",
          data: { id: "OwnerId", value: ownerId },
        })
      }else{
        if(ownerIdMap[0] !== undefined && ownerIdMap[0] !== null && ownerIdMap[0] !== ''){
          dispatchData({
            type: "setDataToModalonChange",
            data: { id: "OwnerId", value: ownerIdMap[0] },
          })
        }else{
          dispatchData({
            type: "setDataToModalonChange",
            data: { id: "OwnerId", value: decryptKeyValues(userMetadata.pk) },
          })
        }
        // dispatchData({
        //   type: "setDataToModalonChange",
        //   data: { id: "OwnerId", value: ownerId },
        // })
      }
      setDisableSaveButton(false)
      dispatchData({
        type: "setDataToModalonChange",
        data: { id: "Owner", value: e.target.value },
      })
    } 
  }

  
  /**
   * onDateFieldChange method is used to set the data for date fields on change
   * @param {event} e 
   * @param {string} header 
   * @param {string} type 
   */
  const onDateFieldChange = (e, header, type) => {
    setDataForEdit()
    if (type === "date") {
      setDisableSaveButton(false)
      const selectedDate = new Date(e)
      if(dateFields['Date1'] === 'ServicedDate'){
        console.log('asdfg')
      }
      Object.values(dateFields).map(value =>{
        if(value === header){
          dispatchData({
            type: "setDataToModalonChange",
            data: { id: getKey(value, dateFields), value: selectedDate.toISOString() },
          })
        }
      })
     
      if (header === "ServicedDate") {
        
        const month = ("0" + (selectedDate.getMonth() + 1)).slice(-2)
        const day = ("0" + selectedDate.getDate()).slice(-2)
        const dateInYearMonthDayFormat = [
          selectedDate.getFullYear(),
          month,
          day,
        ].join("-")
        // setServicedDate(dateInYearMonthDayFormat)
        setServicedDate(new Date(dateInYearMonthDayFormat))
        setDueDate(new Date(dateInYearMonthDayFormat))
        dispatchData({
          type: "setDataToModalonChange",
          data: { id: header, value: selectedDate.toISOString() },
        })
      } else if (header === "DueDate") {
        setDueDate(e)
        dispatchData({
          type: "setDataToModalonChange",
          data: { id: header, value: selectedDate.toISOString() },
        })
      } else{
        dispatchData({
          type: "setDataToModalonChange",
          data: { id: header, value: selectedDate.toISOString() },
        })
      }
      
    }
  }

  /**
   * submitData method is called when we click the ok button in modal.
   * @param {object} e
   */
  const submitData = e => {
    e.preventDefault()
    // if(action === 'Edit'){
      
    // }
    data["mode"] = action
    if(data.DueDate !== undefined && data.DueDate !== null && data.DueDate !== ''){
      data.dueDateFormatted = convertDateFormat(data.DueDate)
    }
    if(data.ServicedDate !== undefined && data.ServicedDate !== null && data.ServicedDate !== ''){
      data.servicedDateFormatted = convertDateFormat(data.ServicedDate)
    }
    if (userMetadata.role.startsWith("Asset Owner")) {
      data["assetOwnerId"] = decryptKeyValues(userMetadata.pk)
      if (data["assetOwnerId"].startsWith("USER")) {
        const sortKeyValue = decryptKeyValues(userMetadata.sk)
        data["sortKeyValue"] = sortKeyValue
        const keyList = sortKeyValue.split("#")
        for (var i = 0; i < keyList.length; i++) {
          if (keyList[i] === "ASSETOWNERID") {
            data["assetOwnerId"] = keyList[i] + "#" + keyList[i + 1]
          }
        }
      }
      data["assetOwnerName"] = aoName[data["assetOwnerId"]]
      data["assetOwnerDetails"] = aoAddress[data["assetOwnerId"]]
    } else if (userMetadata.role.startsWith("Service Provider")) {
      // data["serviceProviderId"] = decryptKeyValues(userMetadata.pk)
      // data["serviceProviderName"] = spName[data["assetOwnerId"]]
      // data["spDetails"] = spAddress[data["assetOwnerId"]]
    }
    // console.log(data)
    data.isReportTabChanged = false
    updateRecords(data)
    toggle()
  }

  /**
  * cancelData function is called when cancel button in the modal is clicked
  * @param {event} e
  */
  const cancelData = e => {
    e.preventDefault()

    toggle()
  }

  /**
   * getInitialData function is called to fetch the assetownerid and assetownerSk values.
   */
  const getInitialData = async () => {
    let deData = null
    setDataForEdit()
    setDisableSaveButton(true)
    try {
      const jwtToken = await getAccessToken(apiurl, "edit:customer")
      if (action === "Add" ) {
        dispatchData({
          type: "setDataToModalonChange",
          data: { id: "ServicedDate", value: new Date() },
        })
        dispatchData({
          type: "setDataToModalonChange",
          data: { id: "DueDate", value: new Date() },
        })
      }
      
      // setTotalFields(totalFieldsForModal.splice(0))
      await getServiceProviderNames(jwtToken)
        .then(result => {
          setServiceProviders(result.names)
          setSpKeyValueMap(result.values)
          setSpNameMap(result.nameofCustomer)
          setSpAddressMap(result.address)
        })
        .catch(error => {
          createToaster(
            "Error occurred while loading service provider data",
            "error"
          )
        })

      await getAssetOwnerNames(jwtToken)
        .then(result => {
          setAssetOwners(result.names)
          setAoKeyValueMap(result.values)
          setAoNameMap(result.nameofCustomer)
          setAoAddressMap(result.address)
        })
        .catch(error => {
          createToaster(
            "Error occurred while loading asset owner data",
            "error"
          )
        })

      if (
        userMetadata.role.startsWith("Service Provider") ||
        userMetadata.role.startsWith("Asset Owner")
      ) {
        const pk = decryptKeyValues(userMetadata.pk)
        let customFields = []
        await getAssetOwner(jwtToken, "", pk).then(result => {
          if (result[0].attributes.preferences !== undefined && result[0].attributes.preferences !== null) {
            customFields = result[0].attributes.preferences.customFields
            setDateFields(result[0].attributes.preferences.dateFields)
          }
        })
        // decodedSortKey = decryptKeyValues(userMetadata.sk)
        // await getUserNamesById(jwtToken, pk)
        //   .then(result => {
        //     setOwnerData(result.data)
        //   })
        //   .catch(error => { })
        await getOwnerNameAndId(jwtToken, pk)
            .then(result => {
              // setOwnerData(result.data)
              setOwnerData(result.ownerNameList)
              setOwnerIdMap(result.ownerNameAndEmailMap)
            }).catch(error => { })
      }
    } catch (error) {
      if (axios.isCancel(error)) {
      } else {
        throw error
      }
    }
  }

  useEffect(() => {
    getInitialData()
  }, [])

  return (
    <React.Fragment>
      <div >

        <form className="pl-3 pr-3 " onSubmit={submitData}>
          {
            totalFieldsForModal.map((fields, index) => (
              fields.isDisabled !== true && fields.dataField !== 'Condition'  ?
                <div className={"form-group "} style={{ width: '48%', float: fields.float }}>
                  {
                    fields.dataField === 'Owner' ? 
                    <div className={" form-group"} style={{ marginTop: "1%" }}>
                    <label htmlFor={fields.dataField}>Owner </label>
                  <input
                    className="form-control"
                    list="ownerNames"
                    name={fields.dataField}
                    id={fields.dataField}
                    autoComplete="off"
                    defaultValue={action === "Add" ? "" : selectedRow[fields.dataField]}
                    onChange={e => accountChange(e, fields.dataField)}
                  />
                  <datalist id="ownerNames">
                    {ownerData.map(item => (
                      <option value={item} />
                    ))}
                  </datalist>
                  </div>
                   :
                    fields.type === 'Dropdown' ?
                      <div className={" form-group"} style={{ marginTop: "1%" }}>
                        <label htmlFor={fields.dataField}>{fields.text}
                        {fields.required ? (
                            <span className="required-field"></span>
                          ) : (
                            ""
                          )}
                        </label>
                        <Input
                    className="form-control"
                    list={fields.dataField}
                    name={fields.dataField}
                    id={fields.dataField}
                    autoComplete="off"
                    defaultValue={action === "Add" ? "" : selectedRow[fields.dataField]}
                    onChange={onChange}
                  />
                  {/* <datalist id={fields.dataField  === "Model"? "Model" : "Make"}>
                    {fields.dropdownValues.map(item => (
                      <option value={item} />
                    ))}
                  </datalist> */}
                  {/* only dropdown cannot enter input values */}
                        {/* <Dropdown
                          id={fields.dataField}
                          options={fields.dropdownValues}
                          // value={fields.dropdownValues !== undefined ? fields.dropdownValues[0] : ''}
                          onChange={e => dropDownChange(e, fields.dataField)}
                          required={fields.required}
                          defaultValue={
                            action === "Add" ? fields.dropdownValues !== undefined ? fields.dropdownValues[0] : '' : selectedRow[fields.dataField]
                          }
                        /> */}
                      </div>
                      :
                      fields.type === "Date" || fields.type === "date" ? 
                      <div className={"form-group"} style={{ marginTop: "1%" }}>
                  <Label>{fields.text}
                  {fields.required ? (
                            <span className="required-field"></span>
                          ) : (
                            ""
                          )}
                  </Label>
                  <InputGroup>
                    <Flatpickr
                      className="form-control d-block"
                      // required={fields.required}
                          // onChange={onChange}
                      onChange={date => onDateFieldChange(date, fields.dataField, "date")}
                      required={fields.required === true ? true : false}
                      options={{
                        altInput: true,
                        altFormat: "F j, Y",
                        // dateFormat: "Y-m-d",
                        dateFormat: "d-m-Y",
                        maxDate: fields.dataField === "ServicedDate" ? new Date() : '',
                        minDate: fields.dataField === "DueDate" ? action === "Add"
                        ? new Date(servicedDate)
                        : new Date(selectedRow["ServicedDate"]) :'',
                        // minDate: action === "Add"
                        //   ? new Date(serviceDate)
                        //   : new Date(selectedRow["serviceDate"]),
                        defaultDate:
                          action === "Add"
                            ? new Date(servicedDate)
                            : new Date(selectedRow[fields.dataField]),
                            
                      }}
                    />
                  </InputGroup>
                </div>
                       :
                      <div className={"form-group"} style={{ marginTop: "1%" }}>
                        <label htmlFor={fields.text} id={fields.dataField}>
                          {fields.text}
                          {fields.required ? (
                            <span className="required-field"></span>
                          ) : (
                            ""
                          )}
                        </label>
                        <input
                          id={fields.dataField}
                          type={fields.type}
                          name={fields.text}
                          disabled={action === "Edit" ? fields.text === "Asset#" ? true : false : false}
                          // style={{ textDecorationLine: 'line-through' }}
                          className="form-control"
                          defaultValue={
                            action === "Add" ? "" : selectedRow[fields.dataField]
                          }
                          required
                          // required={fields.required === true ? true : false}
                          data-error="This is a required field."
                          // required={true}
                          onChange={onChange}
                        ></input>
                      </div>
                  }
                </div> : ''
            ))
          }
          {/* <ModalFooter> */}
          <div className="modal-footer text-end text-bottom" style={{ float: 'right',  }} >
            <button className="btn btn-primary m-2" type="submit" onClick={submitData} disabled={disableSaveButton}>
              Save
            </button>
            <button className="btn btn-primary" onClick={cancelData}>
              Cancel
            </button>
          </div>
          {/* </ModalFooter> */}
        </form>
      </div>
    </React.Fragment>
  );
};
