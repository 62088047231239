import React, { useReducer, useEffect, useState } from "react"
import {
    Row, Col, Card, CardBody, CardText, CardTitle, Nav, NavItem, NavLink, TabContent,
    TabPane,
} from "reactstrap"
import { useAuth0 } from "@auth0/auth0-react"

import axios from "axios"
import { useToasts } from "react-toast-notifications"
import _ from "lodash"
import classnames from "classnames"
import { Link } from "react-router-dom"


import { RemoveFieldsTab } from "./PreferenceComponents/RemoveFieldsTab"
import CustomFieldsTabComponent from "./PreferenceComponents/CustomFieldsTabComponent"
import EmailAlertComponent from "./PreferenceComponents/EmailAlertComponent"

//reducers
import { customFieldReducer, customDropDownFieldReducer } from "./reducer/reducers"


//Import columns
import {  assetColumnsForPreference } from "./data/asset-fields"


//Service files
import {
    getAssetOwner,
    updatePreferencesInAssetOwner,
} from "../../services/assetownerService"
import { getServiceProviderById } from "../../services/serviceProvider"
import { decryptKeyValues, checkLoggedInDays } from "../../services/commonService"
import {
    getSubscriptionDetails,
} from "../../services/paymentService"
import { getAuthUserDetails } from "../../services/userService"

import { user_metadata } from "../../nameSpaceConfig"

const Preferences = props => {
    const apiurl = process.env.REACT_APP_AUTH0_API_URL
    const { getAccessTokenSilently, getAccessTokenWithPopup, user } = useAuth0()
    const { addToast } = useToasts()
    const userMetadata = user[user_metadata]
    const [inputCount, setInputCount] = useState(0)
    const [totalDynamicFields, setTotalDynamicFields] = useState([])
    const [fields, setFields] = useState([])
    const [currentIndex, setCurrentIndex] = useState(0)
    const [previousIndex, setPreviousIndex] = useState(0)
    const [fieldObject, dispatchFieldObject] = useReducer(customFieldReducer, [])
    const [fieldMap, setFieldMap] = useState({})
    const [assetOwnerId, setAssetOwnerId] = useState('')
    const [assetOwnerSk, setAssetOwnerSk] = useState('')
    const [openRemoveFieldsModal, setOpenRemoveFieldsModal] = useState(false)
    const [fieldsNeedToBeShown, setFieldsNeedToBeShown] = useState(assetColumnsForPreference)
    const [showDropDownValueFields, setShowDropdownValueFields] = useState(false)
    const [showDDValues, setShowDDValues] = useState({
        0: false
    })
    const [data, dispatchData] = useReducer(customDropDownFieldReducer, [])
    const [activeTab1, setactiveTab1] = useState("5");
    const [customFields, dispatchCustomFields] = useReducer(customDropDownFieldReducer, { list: [] })
    // const [customFields, setCustomFields] = useState([])
    const [existingFields, setExistingFields] = useState(assetColumnsForPreference)
    // const [preferences, setPreferences] = useState({})
    // const [expandableRowFields, setExpandableRowFields] = useState([])
    // const [mainRowFields, setmainRowFields] = useState([])
    // const [totalFields, setTotalFields] = useState([])
    const [addedCustomFields, setAddedCustomFields] = useState([])
    const [addedDefaultFields, setAddedDefaultFields] = useState([])
    const toggle1 = tab => {
        if (activeTab1 !== tab) {
            setactiveTab1(tab);
        }
    };
    const [subscriptionStatus, setSubscriptionStatus] = useState("")
    // const [emailAlertList, setEmailAlertList] = useState({})
    const [emailAlertList, setEmailAlertList] = useState([])
    const [dateFields, setDateFields] = useState([])

    const toggleRemoveFieldModal = () => {
        setOpenRemoveFieldsModal(!openRemoveFieldsModal);
    };

    /**
     * getAccessToken method is used to fecth jwt access token from auth0
     * @param {string} audience - the api URL
     * @param {string} claim - the permission
     */
    const getAccessToken = async (audience, claim) => {
        let token
        try {
            token = await getAccessTokenSilently({
                audience,
                claim,
            })
        } catch (exception) {
            token = await getAccessTokenWithPopup({ audience, claim })
        }
        return token
    }

    const onDynamicFieldsCountChange = (e) => {
        setInputCount(e.target.value)
    }

    const updateCustomFields = (updatedFields, mode) => {
        if (mode === 'Add') {
            customFields.list.push(updatedFields)
            savePreferences(customFields.list, null)
        } else if (mode === 'Edit') {
            customFields.list.map((customField, index) => {
                if (customField.id === updatedFields.id) {
                    customFields.list.splice(index, 1, updatedFields);
                }
            })
            savePreferences(customFields.list, null)
        } else if(mode === 'Delete'){
            updatedFields.map((customField) => {
                customFields.list.map((field, index) =>{
                    if (customField.id === field.id) {
                        customFields.list.splice(index, 1);
                    }
                })
            })
            console.log(customFields)
            // console.log(updatedFields)
            savePreferences(customFields.list, null)
        } else if(mode === 'EnableDisable'){
            savePreferences(updatedFields.list, null)
        }

        // savePreferences(updatedFields.list, null)
        
    }

    /**
     * removeExistingFields methids 
     * @param {list} existingFields 
     */
    const removeExistingFields = (existingFields) => {
        setExistingFields(existingFields)
        savePreferences()
    }

    /**
     * 
     * @param {*} emailAlertData 
     */
    const updateEmailAlertSettings = (emailAlertData, mode) => {
        // setEmailAlertList(emailAlertData)
        // savePreferences(null, emailAlertData)
        if (mode === 'Add') {
            emailAlertList.push(emailAlertData)
            console.log(emailAlertList)
            savePreferences(null, emailAlertList )
        } else if (mode === 'Edit') {
            emailAlertList.map((customField, index) => {
                if (customField.id === emailAlertData.id) {
                    emailAlertList.splice(index, 1, emailAlertData);
                }
            })
            savePreferences(null ,emailAlertList)
        } else if(mode === 'Delete'){
            emailAlertData.map((emailAlert) => {
                emailAlertList.map((field, index) =>{
                    if (emailAlert.id === field.id) {
                        emailAlertList.splice(index, 1);
                    }
                })
            })
            console.log(emailAlertList)
            // console.log(emailAlertData)
            savePreferences(null, emailAlertList)
        } else if(mode === 'EnableDisable'){
            savePreferences(null,emailAlertData)
        }
    }

    /**
     * onSaveButtonClick function will be called 
     * @param {event} e 
     */
    const savePreferences = async (customFieldsAddedByUser, emailAlertData) => {
        // e.preventDefault()
        let fieldsAddedByUser = []
        if (customFieldsAddedByUser === undefined || customFieldsAddedByUser === null) {
            fieldsAddedByUser = customFields.list
        } else {
            fieldsAddedByUser = customFieldsAddedByUser
        }
        // if (emailAlertData === undefined || emailAlertData === null) {
        //     emailAlertData = emailAlertList
        // }
        if (emailAlertData === undefined || emailAlertData === null) {
            emailAlertData = emailAlertList
        }
        let existingFieldList = []
        // console.log(customFields.list)
        //******after adding default fields */
        // // console.log(existingFields)
        existingFields.map(field => {
            if (field.toBeRemoved !== true) {
                existingFieldList.push(field)
            }
        })
        // console.log(existingFieldList)
        //**** */

        const jwtToken = await getAccessToken(apiurl, "edit:customer")
        const totalFields = [...existingFieldList, ...fieldsAddedByUser]
        // const totalFields = [...existingFields, ...fieldsAddedByUser]
        let importFields = []
        const mainColumns = []
        const expandableColumns = []
        let floatPosition = true
        totalFields.map((newField, value) => {
            if (newField.isDisabled !== true && newField.dataField !== 'reportNumber') {
                importFields.push(newField.text)
                if(newField.dataField === 'Report'){
                    expandableColumns.push(newField)
                }else{
                    if (value < (totalFields.length / 2)) {
                        mainColumns.push(newField)
                    } else {
                        expandableColumns.push(newField)
                    }
                    if (newField.dataField !== 'Report') {
                        if (floatPosition === true) {
                            newField.float = 'right'
                        } else {
                            newField.float = 'left'
                        }
                        floatPosition = !floatPosition
                    }
                }
                
            } 
           
        })
        totalFields.map((field, value) => {
            if (field.dataField === 'Report') {
                totalFields.splice(value, 1)
            }
        })
        const preferences = {
            //new added custom fields
            customFields: fieldsAddedByUser,
            //already existing fields --- only admin can remove fields
            existingFields: existingFields,
            //cloumns to be shown in table
            mainColumns: mainColumns,
            //columns to be be shown while expanding
            expandableColumns: expandableColumns,
            //list of all fields to be shown in modal
            totalFields: totalFields,
            //list of field names to set data while importing
            importFields: importFields,
            //email alert preferences
            emailAlert: emailAlertData,
            //datefields
            dateFields: dateFields,
        }
        console.log('preferences')
        console.log(preferences)
        //to update user preferences
        /** */
        await updatePreferencesInAssetOwner(
            jwtToken,
            assetOwnerId,
            assetOwnerSk,
            preferences
        )
            .then(result => {
                addToast('Preferences have been updated successfully', {
                    appearance: 'info',
                    autoDismiss: true,
                })
            })
            .catch(error => {
            })
    }

    const onRemoveFieldsModalChange = (fieldsNeeded) => {
        setFieldsNeedToBeShown(fieldsNeeded)
    }

    // const onMandatoryCheckboxClick = (e) =>{
    //     console.log()
    // }

    /**
     * onInputFieldChange function will be called when the value is dynamic input fields are changed
     * @param {event} e 
     * @param {string} field 
     * @param {number} index 
     */
    const onInputFieldChange = (e, field, index) => {
        if (fieldMap[index] === undefined) {
            fieldMap[index] = {}
        }
        if (field === "type") {
            fieldObject['type'] = e.value
            fieldMap[index]['type'] = e.value
            if (e.value === 'Dropdown') {
                dispatchData({
                    type: "setDataOnFieldChange",
                    data: { id: index, value: true },
                })
            } else {
                dispatchData({
                    type: "setDataOnFieldChange",
                    data: { id: index, value: false },
                })
            }
        } else if (field === "text") {
            fieldObject['text'] = e.target.value
            fieldMap[index]['text'] = e.target.value
        } else if (field === 'required') {
            fieldObject['required'] = e.target.checked
            fieldMap[index]['required'] = e.target.checked
        } else if (field === 'dropDownValues') {
            const values = e.target.value
            let valueList = values.split(",")
            fieldMap[index]['dropdownValueList'] = valueList
        }
        setPreviousIndex(index)
    }

    /**
     * getInitialData function is called to fetch the assetownerid and assetownerSk values.
     */
    const getInitialData = async () => {
        let deData = null
        let customFields = []
        let existingFieldsFromDb = []
        let noOfFreeTrialDays = 0
        try {
            const jwtToken = await getAccessToken(apiurl, "edit:customer")
            if (userMetadata !== undefined && userMetadata.pk !== undefined) {
                deData = decryptKeyValues(userMetadata.pk)
            }
            if (userMetadata.role.startsWith("Asset Owner")) {
                let assetOwnerDetails = {}
                await getAssetOwner(jwtToken, "", deData).then(result => {
                    assetOwnerDetails = result[0]
                })
                noOfFreeTrialDays = assetOwnerDetails.attributes.freeTrialDays
                if (assetOwnerDetails.attributes.subscriptionId !== undefined & assetOwnerDetails.attributes.subscriptionId !== null) {
                    let productId = ''
                    await getSubscriptionDetails(jwtToken, assetOwnerDetails.attributes.subscriptionId).then(async (result) => {
                        productId = result.data.plan.product
                        if (result.data.status === 'active') {
                            setSubscriptionStatus("Active")
                        } if (result.data.status === 'incomplete') {
                            // create new session to add payment information
                            // while creating session pass subscription id and customer id



                        } else {

                        }
                    })
                } else {


                    getAuthUserDetails(jwtToken, user.sub).then(result => {
                        const differenceBetweenCreatedDateAndCurrentDate = checkLoggedInDays(result.data.created_at)
                        // if (differenceBetweenCreatedDateAndCurrentDate <= noOfFreeTrialDays) {
                        if (differenceBetweenCreatedDateAndCurrentDate <= noOfFreeTrialDays) {
                            setSubscriptionStatus("Active")
                            //   return <Redirect to={{ pathname: "/dashboard", state: { from: props.location } }} />
                        } else {
                            setSubscriptionStatus("Active")
                            //   return <Redirect to={{ pathname: "/assetManagement-payment", state: { from: props.location } }} />
                        }
                    })
                    // setSubscriptionStatus("FreeTrial")
                }
                setAssetOwnerId(assetOwnerDetails.pk)
                setAssetOwnerSk(assetOwnerDetails.sk)
                if (assetOwnerDetails.attributes.preferences !== undefined && assetOwnerDetails.attributes.preferences !== null) {
                    setAddedCustomFields(assetOwnerDetails.attributes.preferences.customFields)
                    setAddedDefaultFields(assetOwnerDetails.attributes.preferences.existingFields)
                    setDateFields(assetOwnerDetails.attributes.preferences.dateFields)
                    customFields = assetOwnerDetails.attributes.preferences.customFields
                    existingFieldsFromDb = assetOwnerDetails.attributes.preferences.existingFields
                    if(assetOwnerDetails.attributes.preferences.emailAlert !== undefined && assetOwnerDetails.attributes.preferences.emailAlert !== null){
                        setEmailAlertList(assetOwnerDetails.attributes.preferences.emailAlert)
                    }
                }
            } else {
                let serviceProviderDetails = {}
                await getServiceProviderById(jwtToken, "", deData)
                    .then(result => {
                        assetOwnerDetails = result[0]
                        setAssetOwnerId(result[0].pk)
                        setAssetOwnerSk(result[0].sk)

                        if (serviceProviderDetails.attributes.preferences !== undefined && serviceProviderDetails.attributes.preferences !== null) {
                            setAddedCustomFields(result[0].attributes.preferences.customFields)
                            setAddedDefaultFields(result[0].attributes.preferences.existingFields)
                            // setEmailAlertList(serviceProviderDetails.attributes.preferences.emailAlert)
                            customFields = result[0].attributes.preferences.customFields
                            existingFieldsFromDb = result[0].attributes.preferences.existingFields
                            if(serviceProviderDetails.attributes.preferences.emailAlert !== undefined && serviceProviderDetails.attributes.preferences.emailAlert !== null){
                                setEmailAlertList(serviceProviderDetails.attributes.preferences.emailAlert)
                            }
                        }
                    })
                    .catch(error => {
                    })
            }
            if (customFields !== undefined) {
                customFields.map(field => {
                    dispatchCustomFields({
                        type: "setCustomFields",
                        data: { id: 'customValues', value: field },
                    })
                })
            }

            if (existingFieldsFromDb !== undefined) {
                existingFieldsFromDb.map(addedFields => {
                    existingFields.map(field => {
                        if (addedFields.dataField === field.dataField) {
                            field.toBeRemoved = addedFields.toBeRemoved
                        }
                    })
                })
            }

        } catch (error) {
            if (axios.isCancel(error)) {
            } else {
                throw error
            }
        }
    }

    useEffect(() => {
        getInitialData()
    }, [])


    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <Col >
                            {
                                subscriptionStatus === "" ? <div></div>
                                    : subscriptionStatus === "FreeTrial" ?
                                        <div>
                                            <Card>

                                                <CardBody>
                                                    Please subscribe to a plan to avail this feature
                                                    <Link to="/assetManagement-payment" className={"rounded-pill  font-size-12 badge-soft-success"}>
                                                        Plan
                                                    </Link>
                                                </CardBody>
                                            </Card>
                                        </div>
                                        :
                                        <Card>
                                            <CardBody>
                                                <CardTitle className="h4">User Preferences</CardTitle>

                                                <Nav tabs className="nav-tabs-custom nav-justified">
                                                    <NavItem>
                                                        <NavLink
                                                            style={{ cursor: "pointer" }}
                                                            className={classnames({
                                                                active: activeTab1 === "5",
                                                            })}
                                                            onClick={() => {
                                                                toggle1("5");
                                                            }}
                                                        >
                                                            {/* Add Custom Fields */}
                                                            Custom Fields
                                                        </NavLink>
                                                    </NavItem>
                                                    {/* {
                                                        userMetadata.role === "Admin " ?  */}
                                                    <NavItem>
                                                        <NavLink
                                                            style={{ cursor: "pointer" }}
                                                            className={classnames({
                                                                active: activeTab1 === "6",
                                                            })}
                                                            onClick={() => {
                                                                toggle1("6");
                                                            }}
                                                        >
                                                            {/* Remove Existing Fields */}
                                                            Default Fields
                                                        </NavLink>
                                                    </NavItem>
                                                    {/* : ''
                                                    } */}

                                                    <NavItem>
                                                        <NavLink
                                                            style={{ cursor: "pointer" }}
                                                            className={classnames({
                                                                active: activeTab1 === "7",
                                                            })}
                                                            onClick={() => {
                                                                toggle1("7");
                                                            }}
                                                        >
                                                            Email Alert Setting
                                                        </NavLink>
                                                    </NavItem>
                                                    {/* <NavItem>
                                                        <NavLink
                                                            style={{ cursor: "pointer" }}
                                                            className={classnames({
                                                                active: activeTab1 === "8",
                                                            })}
                                                            onClick={() => {
                                                                toggle1("8");
                                                            }}
                                                        >
                                                            Settings
                                                        </NavLink>
                                                    </NavItem> */}
                                                </Nav>

                                                <TabContent activeTab={activeTab1} className="p-3 text-muted">
                                                    <TabPane tabId="5">
                                                        <Row>
                                                            <Col sm="12">
                                                                <CardText className="mb-0">
                                                                    < CustomFieldsTabComponent updateCustomFields={updateCustomFields} addedCustomFields={addedCustomFields} />
                                                                </CardText>
                                                            </Col>
                                                        </Row>
                                                    </TabPane>
                                                    <TabPane tabId="6">
                                                        <Row>
                                                            <Col sm="12">
                                                                <CardText className="mb-0">
                                                                    < RemoveFieldsTab header={"Delete User"}
                                                                        assetColumnsForAssetOwnerLogin={assetColumnsForPreference}
                                                                        removeExistingFields={removeExistingFields} addedDefaultFields={addedDefaultFields} />
                                                                </CardText>
                                                            </Col>
                                                        </Row>
                                                    </TabPane>
                                                    <TabPane tabId="7">
                                                        <Row>
                                                            <Col sm="12">
                                                                <CardText className="mb-0">
                                                                    < EmailAlertComponent updateEmailAlertSettings={updateEmailAlertSettings} />
                                                                </CardText>
                                                            </Col>
                                                        </Row>
                                                    </TabPane>

                                                    <TabPane tabId="8">
                                                        <Row>
                                                            <Col sm="12">
                                                                <CardText className="mb-0">
                                                                </CardText>
                                                            </Col>
                                                        </Row>
                                                    </TabPane>
                                                </TabContent>
                                            </CardBody>
                                        </Card>
                            }

                        </Col>
                    </div>

                </div>
            </div>
        </React.Fragment>
    )
}

export default Preferences