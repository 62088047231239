import http from './httpService';

/**
 * getServiceHistoryRecords method is used to fetch all users from db.
 * @param {string} cancelToken - token generated from axios.
 */
export async function getServiceHistoryRecords(cancelToken, jwtToken) {
  http.setJwt(jwtToken);
  const assets = await http.get(`/serviceHistory`);
  const data = assets.data.map((item) => (item = clean(item)));
  return data;
}

/**
 * getUsers method is used to fetch all users from db.
 * @param {string} cancelToken - token generated from axios.
 */
export async function getServiceHistoryRecordsPerAO(cancelToken, jwtToken, pk) {
  http.setJwt(jwtToken);
  const assets = await http.get(`/serviceHistory/perAo`, {
     params: { pk: pk }
  });
  const data = assets.data.map((item) => (item = clean(item)));
  return data;
}

/**
 * getAssetsPerSp method is used to fetch all assets under a service provider.
 * @param {string} cancelToken - token generated from axios.
 * @param {string} pk - service provider id
 */
export async function getServiceHistoryRecordsPerSp(cancelToken, jwtToken, pk) {
  http.setJwt(jwtToken);
  const assets = await http.get(`/serviceHistory/perSp`, {
     params: { pk: pk }
  });
  const data = assets.data.map((item) => (item = clean(item)));
  return data;
}

/**
 * getAssetsCount method is used to count the total number of assets.
 * @param {string} cancelToken - token generated from axios.
 */
export async function getServiceHistoryRecordsCount(cancelToken, jwtToken) {
  http.setJwt(jwtToken);
  const assetsCount = await http.get(`/serviceHistory/count`);
  return assetsCount;
}

/**
 * getAssetsCount method is used to count the total number of assets.
 * @param {string} cancelToken - token generated from axios.
 */
export async function getServiceHistoryRecordsCountById(
  cancelToken,
  jwtToken,
  pk
) {
  http.setJwt(jwtToken);
  const assetsCount = await http.get(`/serviceHistory/countById`, {
     params: { pk: pk }
  });
  return assetsCount;
}

/**
 * addAssetsData method is used to add / update/ delete asset data to db.
 * @param {object} data - data to be added/ updated/ deleted.
 * @param {string} action - action to be performed add/ update/ delete
 * @param {string} jwtToken - jwtToken from auth0
 */
export async function addServiceHistoryData(data, action, jwtToken) {
  const newData = clean(data);
  http.setJwt(jwtToken);
  if (action === 'Add') {
    // console.log('data to be added ');
    // console.log(newData);
    const assets = await http.post(`/serviceHistory`, newData);
    return assets;
  } else if (action === 'Edit') {
    delete newData.isSelected;
    const assets = await http.put(`/serviceHistory`, newData);
    return assets;
  } else if (action === 'Delete') {
    let assets;
    newData.map(async (deleteData) => {
      if (deleteData.isSelected) {
        const id = deleteData.id;
        const headers = {
          id: id,
          pk: deleteData.pk,
        };
        assets = await http.delete(`/serviceHistory/${id}`, {headers, data});
      }
    });

    return assets;
  }
}

export async function downloadPdf(jwtToken, certificateNumber) {
  http.setJwt(jwtToken);
  await http.get(`/serviceHistory/fileDownload`, { params: { certificateNumber: certificateNumber }});
}

export async function metquayIntegration(jwtToken) {
  http.setJwt(jwtToken);
  await http.get(`/serviceHistory/metquauy`);
}
export async function allEmailAlertLogs(jwtToken, email) {
  http.setJwt(jwtToken);
  const logsData =   await http.get(`/serviceHistory/get-email-logs`, { params: { pk: email }});
  return logsData
}
function clean(obj) {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === ''
    ) {
      delete obj[propName];
    }
  }
  return obj;
}
