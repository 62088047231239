import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import "react-drawer/lib/react-drawer.css"
import { Link } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react"
import {TbHelp} from 'react-icons/tb'
// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu, Badge , DropdownItem,Modal, ModalHeader, ModalBody } from "reactstrap"

// Import menuDropdown

import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"
const apiurl = process.env.REACT_APP_AUTH0_API_URL

// import images

import metquay_logo from "../../assets/images/tracefii.png"

//i18n
import { withTranslation } from "react-i18next"

// Redux Store
import {
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions"
import toast, { Toaster } from 'react-hot-toast';
import { sendVerificationMail } from "services/userService"
import CustomerSupport from "./CustomerSupport"

const Header = props => {
  const { user,getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()
  const [search, setsearch] = useState(false)
  const [emailDropdownOpen, setEmailDropdownOpen] = useState(false);
  const emailToggle = () => setEmailDropdownOpen(!emailDropdownOpen);
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => setModalOpen(!modalOpen);
  const [position, setPosition] = useState()
  const [open, setOpen] = useState(false)
  const toggleTopDrawer = () => {
    setPosition("right")
    setOpen(!open)
  }

  const onDrawerClose = () => {
    setOpen(false)
  }
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const getAccessToken = async (audience, claim) => {
    let token
    try {
      token = await getAccessTokenSilently({
        audience,
        claim,
      })
    } catch (exception) {
      try {
        token = await getAccessTokenWithPopup({ audience, claim })
      } catch (ex) {
        token = ""
      }
    }
    return token
  }

  function tToggle() {
    var body = document.body
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable")
    } else {
      body.classList.toggle("vertical-collpsed")
      body.classList.toggle("sidebar-enable")
    }
  }

  useEffect(() => {
    return () => {}
  }, [])
  async function resendVerificationEmail(){
    const jwtToken = await getAccessToken(apiurl, "add:asset")
    const userData = {
      emailAddress : user.email,
      firstName : user.nickname
    }
    sendVerificationMail(jwtToken , userData)
    toast.success("Verification Email Sent")
  }
  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box d-lg-none d-md-block">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={metquay_logo} alt="" height="22" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={metquay_logo} alt="" height="22" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle()
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
          </div>
          <div className="d-flex">
            <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                onClick={() => {
                  setsearch(!search)
                }}
                type="button"
                className="btn header-item noti-icon "
                id="page-header-search-dropdown"
              >
                <i className="mdi mdi-magnify" />
              </button>
              <div
                className={
                  search
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              ></div>
            </div>
          </div>

          <div className="dropdown d-none d-lg-inline-block ms-1">
            {  !user.email_verified ? (
              <Badge
                className={" font-size-12 badge-soft-danger"}
              >
                <Dropdown isOpen={emailDropdownOpen} toggle={emailToggle}>
                <DropdownToggle caret color="red">
                 <u>Email Not Verified</u> 
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={resendVerificationEmail}>Send Verification Email Again</DropdownItem>
                </DropdownMenu>
              </Dropdown>
              </Badge>
             
            ) : (
              " "
            )}
            <Badge>
            <Dropdown color="white" isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle color="white" style={{boxShadow:"none" , outline:"none"}}>
              <TbHelp size={20}/>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={toggleModal} data-toggle="modal" data-target=".bs-example-modal-center">Contact Support</DropdownItem>
            </DropdownMenu>
              </Dropdown>
            </Badge>
            <Modal isOpen={modalOpen} toggle={toggleModal} centered={true}>
              <ModalHeader toggle={toggleModal}>Contact Support</ModalHeader>
              <ModalBody>
               <CustomerSupport 
               toggleModal = {toggleModal}
               user = {user}
               />
              </ModalBody>
           </Modal>
            <ProfileMenu />

            {/* <div
              onClick={toggleTopDrawer}
              disabled={open}
              className="dropdown d-inline-block"
            >
              <button
                type="button"
                className="btn header-item noti-icon right-bar-toggle "
              >
                <i className="bx bx-cog bx-spin" />
              </button>
            </div> */}
          </div>
        </div>
      </header>
      <Toaster 
         position="bottom-right"
         reverseOrder={false} />
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType,  leftMenu, leftSideBarType } =
    state.Layout
  return { layoutType,  leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header))
