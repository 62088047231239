import React, { useState } from "react"
import { Row, Col, Card, CardBody, Button, Input, Badge } from "reactstrap"
import BootstrapTable from "react-bootstrap-table-next"
import { useAuth0 } from "@auth0/auth0-react"
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css"

import {
  metquayAssetsColumn,
  assetExpandableColumnsForAssetOwnerLogin,
} from "../data/asset-fields"
import "../css/modal-style.css"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { toast } from "react-hot-toast"
import { createRequstForQuote, getQuotesForAssetOwner } from "services/assetService"
import RequestForQuotesModal from "./RequestForQuotesModal"

/**
 * conditionColumnFormatter is sued to format condition column data bsed on cal due date.
 * @param {object} cell
 * @param {object} row
 * @returns react component
 */
export const conditionColumnFormatterForMetquayAssets = (cell, row) => {
  let result = new Date()
  result.setDate(result.getDate() + 90)
  const badgeColour =
    new Date(row.dueDate) < new Date()
      ? "danger"
      : new Date(row.dueDate) < new Date(result)
      ? "warning"
      : "success"
  return (
    <Badge className={"rounded-pill  font-size-12 badge-soft-" + badgeColour } rounded-pill >
      {badgeColour === "danger"
        ? "Danger"
        : badgeColour === "success"
        ? "Good"
        : "Warning"}
    </Badge>
  )
}

export const TableForManagedAssets = props => {
  const { getAccessTokenSilently, getAccessTokenWithPopup, user } = useAuth0()
  const {
    records,
    multipleRows,
    getNextAssetsData,
    getPreviousAssetsData,
    firstPage , 
    lastPage , 
    currentPage,
    firstKey,
    lastEvaluatedKey,
    onFilter,
    onSearchKeyChange,
    isGood,
    isWarning,
    isDanger,
    count,
    searchKey,
    selectedRow,
    selectedAsset,
    assetOwnerData,
  } = props
  const [goodToggle, setGoodToggle] = useState(isGood)
  const [warningToggle, setWarningToggle] = useState(isWarning)
  const [dangerToggle, setDangerToggle] = useState(isDanger)
  const [prevCondition, setPrevCondition] = useState("")
  const apiurl = process.env.REACT_APP_AUTH0_API_URL
  const [selectedRows , setSelectedRows] = useState([])
    /**
   * getAccessToken method is used to fecth jwt access token from auth0
   * @param {string} audience - the api URL
   * @param {string} claim - the permission
   */
    const getAccessToken = async (audience, claim) => {
      let token
      try {
        token = await getAccessTokenSilently({
          audience,
          claim,
        })
      } catch (exception) {
        token = await getAccessTokenWithPopup({ audience, claim })
      }
      return token
    }
  
  /**
   * assignToggleValues is a common method used to toggle and untoggle(previously selected) buttons based on selected condition
   * @param {boolean} toggleValueForGood
   * @param {boolean} toggleValueForWarning
   * @param {boolean} toggleValueForDanger
   */
  const assignToggleValues = (
    toggleValueForGood,
    toggleValueForWarning,
    toggleValueForDanger
  ) => {
    setGoodToggle(toggleValueForGood)
    setWarningToggle(toggleValueForWarning)
    setDangerToggle(toggleValueForDanger)
  }

  /**
   * onToggle method is call the filter method based on selected condition
   * @param {event} e
   * @param {string} condition
   */
  const onToggle = async (e, condition) => {
    if (prevCondition === condition) {
      assignToggleValues(false, false, false)
      onFilter(e, "")
      setPrevCondition("", false, false, false)
    } else {
      if (condition == "good") {
        assignToggleValues(true, false, false)
        onFilter(e, condition, true, false, false)
      } else if (condition == "warning") {
        assignToggleValues(false, true, false)
        onFilter(e, condition, false, true, false)
      } else if (condition == "danger") {
        assignToggleValues(false, false, true)
        onFilter(e, condition, false, false, true)
      }

      setPrevCondition(condition)
    }
  }

  /**
   * expandRow function is provide inner row details of the table
   */
  const expandRow = {
    renderer: row => (
      <div>
        <BootstrapTable
          bootstrap4
          {...props.baseProps}
          keyField="id"
          bordered={false}
          data={[row]}
          columns={assetExpandableColumnsForAssetOwnerLogin}
          wrapperClasses="table-responsive"
        ></BootstrapTable>

        
      </div>
    ),
    showExpandColumn: true,
    onlyOneExpanding: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      return isAnyExpands ? (
        <i className="dripicons-minus" />
      ) : (
        <i className="dripicons-plus" />
      )
    },
    expandColumnRenderer: ({ expanded }) => {
      return expanded ? (
        <i className="dripicons-minus" />
      ) : (
        <i className="dripicons-plus" />
      )
    },
  }


  /**
   * handleCheckboxClick will be called when the check box is clicked
   * @param {event} e 
   * @param {number} rowIndex 
   * @param {boolean} checked 
   */
  const handleCheckboxClick = (e, rowIndex, checked) => {
    let rows = []
    records[rowIndex].isSelected = checked
    rows.push(records[rowIndex])
    multipleRows(rows)
  }

   const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    onSelect: (row, isSelect) => {
    if(isSelect){
      setSelectedRows([...selectedRows , row])
    }else{
      const filteredArray = selectedRows.filter((singleRow)=>singleRow.serialNo !== row.serialNo)
      setSelectedRows([...filteredArray])
    }
    },
    onSelectAll: (isSelect, rows) => {
      console.log(isSelect , rows)
      if(isSelect){
        setSelectedRows([...rows])
      }else{
        setSelectedRows([])
      }
    },
    selectionRenderer: ({ checked, disabled, rowIndex }) => {
      return (
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            checked={checked}
            disabled={disabled}
            onChange={() => { }}
          />
          <label
            className="custom-control-label"
            onClick={(e) => {
              e.preventDefault();
              handleCheckboxClick(e, rowIndex, checked);
            }}
          ></label>
        </div>
      );
    },
    selectionHeaderRenderer: ({ indeterminate, ...rest }) => {
      return (
        <div className="custom-control custom-checkbox select-header">
          <input
            type="checkbox"
            className="custom-control-input"
            id="customCheck1"
            ref={(input) => {
              if (input) input.indeterminate = indeterminate;
            }}
            {...rest}
            onChange={() => { }}
          />
          <label
            className="custom-control-label"
            htmlFor="customCheck1"
            onClick={(e) => {
              e.preventDefault();
            }}
          ></label>
        </div>
      );
    },
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleQuoteRequest =async()=>{
    if((selectedRows.length) === 0){
      toast.error("Please select an asset")
      }else{
        setIsModalOpen(true)
      } 
  }
  const getAccessTokenFunction = async()=>{
    const jwtToken = await getAccessToken(apiurl , 'add:asset')
    return jwtToken
  }
  return (
    <Card>
      <RequestForQuotesModal isModalOpen={isModalOpen} assets={selectedRows} setIsModalOpen={setIsModalOpen} assetOwnerData={assetOwnerData} toast={toast} createRequstForQuote={createRequstForQuote} getAccessTokenFunction={getAccessTokenFunction} onSelectAll={selectRow.onSelectAll} />
      <CardBody>
        <ToolkitProvider
          bootstrap4
          keyField="id"
          data={records}
          search
          exportCSV={{ onlyExportFiltered: true, exportAll: false }}
        >
          {props => (
            <React.Fragment>
              <Row>
                <div className="col-md-12 text-end">
                  <Row>
                    <Col className="text-start">
                    
                      <div
                        className="form-check form-switch good-color-style"
                        style={{ flex: 1, marginLeft: "3%", float: "left" }}
                      >
                        <input
                          type="checkbox"
                          className="form-check-input good-color-style"
                          id="customSwitch1"
                          checked={goodToggle}
                          onChange={e => {
                            onToggle(e, "good")
                          }}
                        />
                        <label
                          className="form-check-label good-color-style"
                          color="primary"
                          htmlFor="customSwitch1"
                        >
                          Good (
                          {count.ALLGOODASSETSCOUNT !== undefined
                            ? count.ALLGOODASSETSCOUNT
                            : ""}
                          )
                        </label>
                      </div>
                      <div
                        className="form-check form-switch good-color-style"
                        style={{ flex: 1, marginLeft: "3%", float: "left" }}
                      >
                        <input
                          type="checkbox"
                          className="form-check-input warning-color-style"
                          id="customSwitch2"
                          checked={warningToggle}
                          onChange={e => {
                            onToggle(e, "warning")
                          }}
                        />
                        <label className="form-check-label" htmlFor="customSwitch2">
                          Warning (
                          {count.ALLWARNINGASSETSCOUNT !== undefined
                            ? count.ALLWARNINGASSETSCOUNT
                            : ""}
                          )
                        </label>
                      </div>
                      <div
                        className="form-check form-switch good-color-style"
                        style={{ flex: 1, marginLeft: "3%", float: "left" }}
                      >
                        <input
                          type="checkbox"
                          class="form-check-input danger-color-style"
                          id="customSwitch3"
                          checked={dangerToggle}
                          onChange={e => {
                            onToggle(e, "danger")
                          }}
                        />
                        <label class="form-check-label" htmlFor="customSwitch3">
                          Danger (
                          {count.ALLDANGERASSETSCOUNT !== undefined
                            ? count.ALLDANGERASSETSCOUNT
                            : ""}
                          )
                        </label>
                      </div>
                    </Col>

                    <Col className="text-end">
                      <div
                        style={{ flex: 1,  float: "right" }}
                      >
                        <Input
                          placeholder="Search"
                          type="search"
                          className="mb-2 me-2 nm-3 col-lg-4"
                          defaultValue={searchKey}
                          onChange={({ target: { value } }) =>
                            onSearchKeyChange(value)
                          }
                        />
                        </div>   
                        <Button style={{marginRight:'1rem'}} color="primary" onClick={handleQuoteRequest}>Send Request For Quote</Button>    
                    </Col>
                   
                  </Row>
                </div>
              </Row>

              <BootstrapTable
                bootstrap4
                {...props.baseProps}
                keyField="id"
                bordered={false}
                selectRow={selectRow}
                data={records}
                columns={metquayAssetsColumn}
                expandRow={expandRow}
                wrapperClasses="table-responsive"
              />
              <div className="col-md-12">
                <Row>
                <Col className=" text-start">
                <Badge className={"rounded-pill  font-size-12 badge-soft-success"} pill>
                  Good{" "}
                </Badge>
                <label className="mb-2 me-2">{" (Due>90 days), "}</label>
                <Badge className={"rounded-pill  font-size-12 badge-soft-warning"} pill>
                  Warning{" "}
                </Badge>
                <label className="mb-2 me-2">{" (Due<90 days),  "}</label>
                <Badge className={"rounded-pill  font-size-12 badge-soft-danger"} pill>
                  Danger{" "}
                </Badge>
                <label className="mb-2 me-2">{" ( Past Due)"}</label>
                </Col>
                <Col className="text-end">
                <Button
                  className="mb-2 me-2"
                  color="primary"
                  onClick={getPreviousAssetsData}
                  disabled={firstPage <= 50}
                >
                  Prev
                </Button>

                <Button
                  className="mb-2 me-2"
                  color="primary"
                  onClick={getNextAssetsData}
                  disabled={currentPage===lastPage}
                >
                  Next
                </Button>
                </Col>
                </Row>
              </div>
              <div className="col-md-12">
              {records.length} record(s) per page
              </div>
            </React.Fragment>
          )}
        </ToolkitProvider>
      </CardBody>
    </Card>
  )
}
