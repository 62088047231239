import React from "react";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search, CSVExport, } from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

export const TableForAsset = (props) => {
  const {
    columnsCustomer,
    records,
    updateCsv,
    multipleRows,
  } = props;
  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;

  /**
   * handleFiles function will be called when a file is uploaded to import
   * @param {file object} files 
   */
  const handleFiles = (files) => {
    const reader = new FileReader();
    reader.onload = function (e) {
      try {
        let result = [];
        const csvData = reader.result;
        const lines = csvData.split("\n");
        const headers = lines[0].split(",");
        for (let i = 1; i < lines.length - 1; i++) {
          let obj = {};
          const currentline = lines[i].split(",");
          for (let j = 0; j < headers.length; j++) {
            obj[headers[j].replaceAll('"', '')] = currentline[j].replaceAll('"', '');
          }
          result.push(obj);
        }
        updateCsv(result);
      } catch (exception) {
        // console.log("Error occured while parsing CSV file", exception);
      }
    };
    reader.readAsText(files[0]);
  };

  /**
   * handleCheckboxClick will be called when the check box is clicked
   * @param {event} e 
   * @param {number} rowIndex 
   * @param {boolean} checked 
   */
  const handleCheckboxClick = (e, rowIndex, checked) => {
    let rows = [];
    records[rowIndex].isSelected = checked;
    rows.push(records[rowIndex]);
    multipleRows(rows);
  };

  /**
   *selectRow method will be called when a row is selected 
   */
  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    onSelect: (row, isSelect, rowIndex, e) => {
      let rows = [];
      row.isSelected = isSelect;
      rows.push(row);
      multipleRows(rows);
    },
    onSelectAll: (isSelect, rows, e) => {
      rows.map((row) => {
        row.isSelected = isSelect;
      });
      multipleRows(rows);
    },
    selectionRenderer: ({ checked, disabled, rowIndex }) => {
      return (
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            checked={checked}
            disabled={disabled}
            onChange={() => { }}
          />
          <label
            className="custom-control-label"
            onClick={(e) => {
              e.preventDefault();
              handleCheckboxClick(e, rowIndex, checked);
            }}
          ></label>
        </div>
      );
    },
    selectionHeaderRenderer: ({ indeterminate, ...rest }) => {
      return (
        <div className="custom-control custom-checkbox select-header">
          <input
            type="checkbox"
            className="custom-control-input"
            id="customCheck1"
            ref={(input) => {
              if (input) input.indeterminate = indeterminate;
            }}
            {...rest}
            onChange={() => { }}
          />
          <label
            className="custom-control-label"
            htmlFor="customCheck1"
            onClick={(e) => {
              e.preventDefault();
            }}
          ></label>
        </div>
      );
    },
  };

  const paginationOptions = {
    sizePerPage: 10,
  };

  return (
    <Card>
      <CardBody>
        <ToolkitProvider
          bootstrap4
          keyField="id"
          data={records}
          columns={columnsCustomer}
          search
          exportCSV={{ onlyExportFiltered: true, exportAll: false }}
        >
          {(props) => ( 
            <React.Fragment>
              <Row>
                <div className="col-md-12 text-end">
                  <Col className="text-end">
                    <div
                        style={{ flex: 3,  float: "right" }}
                      >
                        <ExportCSVButton
                          {...props.csvProps}
                          className="btn btn-primary mb-2 me-2"
                        >
                          Export
                        </ExportCSVButton>
                      </div>
                      </Col>
                      <Col className="text-end">
                      <div
                        style={{ flex: 1,  float: "right", marginRight: "1%" }}
                      >
                        <SearchBar {...props.searchProps} />
                      </div>
                      
                      
                    </Col>
                </div>
              </Row>
              <BootstrapTable
                {...props.baseProps}
                keyField="id"
                bordered={false}
                selectRow={selectRow}
                pagination={paginationFactory(paginationOptions)}
                wrapperClasses="table-responsive"
              />
            </React.Fragment>
          )}
        </ToolkitProvider>
      </CardBody>
    </Card>
  );
};

