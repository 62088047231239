import { Card, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { columnsForAssetOwnerInquiry, columnsForAssetOwnerQuotes } from './columnsForAoQuotes'

const AssetOwnerMetquayIntegratedQuotes = ({quotes , user}) => {
  const [quotesArr , setQuotesArr] = useState([])

  const assetsTable = (data) => {
    return <Table columns={columnsForAssetOwnerQuotes} dataSource={data} pagination={false} />;
  };
  
  useEffect(() => {
    const filteredQuotes = quotes.filter((quote) =>
        quote.status === "Awaiting Review" || quote.status === "Awaiting Confirmation"
  );
  setQuotesArr(filteredQuotes)
  }, [quotes])
  
  return (
    <Card>
    <Table dataSource={quotesArr} columns={columnsForAssetOwnerInquiry} rowKey={(record)=>(record.pk)}
        expandable={{
          expandedRowRender : (record) => assetsTable(record.attributes.assets),
        }}
        scroll={{ x: 800 , y: 350 }}
      />
    </Card>
  )
}

export default AssetOwnerMetquayIntegratedQuotes